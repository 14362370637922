import { createContext, useContext } from 'react';

import { type createSoftPhoneStore } from './SoftPhoneStore';

type SoftPhoneStoreContextSuccessValue = {
  store: ReturnType<typeof createSoftPhoneStore>;
  requiresAuth: false;
  isPending: false;
};
type SoftPhoneStoreContextRequiresAuthValue = {
  store: ReturnType<typeof createSoftPhoneStore>;
  requiresAuth: true;
  isPending: false;
};
type SoftPhoneStoreContextPendingValue = { store: null; requiresAuth: null; isPending: true };

export type SoftPhoneStoreContextValue =
  | SoftPhoneStoreContextSuccessValue
  | SoftPhoneStoreContextRequiresAuthValue
  | SoftPhoneStoreContextPendingValue;

export const SoftPhoneStoreContext = createContext<SoftPhoneStoreContextValue | null>(null);

export const useSoftPhoneStoreContext = () => {
  const store = useContext(SoftPhoneStoreContext);
  if (!store) {
    throw new Error('useSoftPhoneStore must be used within a SoftPhoneStoreProvider');
  }
  return store;
};

export const useProtectedSoftPhoneStore = () => {
  const { store } = useSoftPhoneStoreContext();
  if (!store) {
    throw new Error(
      'useProtectedSoftPhoneStore must be used only when store existence is guaranteed',
    );
  }
  return store;
};
