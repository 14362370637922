import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

export const castToNumber = z.preprocess((val) => Number(val), z.number());
export const requiredPhoneNumberSchema = z
  .string()
  .min(1, 'Required')
  .refine(isPossiblePhoneNumber, 'This phone number cannot exist')
  .refine(
    (value) =>
      isValidPhoneNumber(value, {
        defaultCountry: 'US',
      }),
    'Invalid phone number',
  );

const TOOL_FUND_PURCHASE_ORDER_MAX_LENGTH = 200;

export const toolFundPurchaseOrderSchema = z.string().max(TOOL_FUND_PURCHASE_ORDER_MAX_LENGTH, {
  message: `Purchase order must be less than ${TOOL_FUND_PURCHASE_ORDER_MAX_LENGTH} characters`,
});
