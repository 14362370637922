import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const transactionSchema = z.object({
  amount: z.number().min(0.01, 'Amount must be greater than 0'),
  notes: z.string().min(1, 'Notes are required'),
  transactionTypeId: z.coerce.number().min(1, 'Transaction type is required'),
});

export type TransactionFormData = z.infer<typeof transactionSchema>;

export const defaultTransactionValues: TransactionFormData = {
  amount: 0,
  notes: '',
  transactionTypeId: 0,
};

export const useTransactionForm = ({
  defaultValues,
  disabled,
}: {
  defaultValues: TransactionFormData;
  disabled?: boolean;
}) => {
  return useForm<TransactionFormData>({
    resolver: zodResolver(transactionSchema),
    defaultValues: defaultValues,
    disabled,
  });
};
