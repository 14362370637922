export function monitorAudioLevels(remoteStream: MediaStream): [Promise<void>, () => void] {
  let intervalId: NodeJS.Timeout | null = null;
  let audioContext: AudioContext | null = null;

  const cleanupFn = () => {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
    if (audioContext) {
      audioContext.close();
      audioContext = null;
    }
  };

  const promise = new Promise<void>((resolve) => {
    // Create an AudioContext
    const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 512; // Adjust FFT size if needed for smoother analysis

    // Create a MediaStreamSource from the remote audio stream
    const source = audioContext.createMediaStreamSource(remoteStream);
    source.connect(analyser);

    const dataArray = new Uint8Array(analyser.frequencyBinCount);

    // Function to check audio level
    function checkAudio(): void {
      analyser.getByteFrequencyData(dataArray);

      // Calculate the average volume level
      const averageVolume = dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;

      // Threshold to detect sound (adjust based on your requirements)
      const soundThreshold = 2;

      if (averageVolume > soundThreshold) {
        console.log('Audio detected!');
        cleanupFn();
        resolve(); // Resolve the promise when audio is detected
      } else {
        console.log('No audio detected.');
      }
    }

    // Start monitoring every 100ms
    intervalId = setInterval(checkAudio, 100);
  });

  return [promise, cleanupFn];
}

export const cleanupRTC = ({ peerConnection }: { peerConnection: RTCPeerConnection }) => {
  peerConnection.getSenders().forEach((sender) => {
    if (sender.track) {
      sender.track.stop();
    }
  });
  peerConnection.onicecandidate = null;
  peerConnection.ontrack = null;
  peerConnection.oniceconnectionstatechange = null;
  peerConnection.onsignalingstatechange = null;
  peerConnection.ondatachannel = null;

  peerConnection.close();
};

export const softphoneLog = (...args: any[]) => {
  console.log('%c[KalosSoftphone]', 'background: #dc2626; color: white;', ...args);
};
