import { Button, Dialog, DialogContent, DialogTrigger } from '@kalos/ui';
import { FilterIcon } from 'lucide-react';
import React from 'react';

export const FilterDialog = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="fixed bottom-4 right-4 z-50">
      <Dialog>
        <DialogTrigger asChild>
          <Button
            size="sm"
            className="relative flex items-center justify-center gap-1 rounded-full"
          >
            <span>Filter</span>
            <FilterIcon size={15} />
          </Button>
        </DialogTrigger>
        <DialogContent className="top-1/3 ">{children}</DialogContent>
      </Dialog>
    </div>
  );
};
