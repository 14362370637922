import { create } from 'zustand';

import { createSelectors } from '../../../tools/zustand';

type SoftPhoneWidgetStore = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const useSoftPhoneWidgetStoreBase = create<SoftPhoneWidgetStore>()((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen }),
}));

export const useSoftPhoneWidgetStore = createSelectors(useSoftPhoneWidgetStoreBase);
