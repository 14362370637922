// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "softphone.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "softphone.proto" (syntax proto3)
// tslint:disable
import { MeResponse } from "./gotoconnect";
import { GetCallHistoryResponse } from "./gotoadmin";
import { GetCallHistoryRequest } from "./gotoadmin";
import { UpdateUserPresenceRequest } from "./gotoconnect";
import { GetUserPresenceRequest } from "./gotoconnect";
import { GoToConversationList } from "./gotoconnect";
import { GoToMessageList } from "./gotoconnect";
import { GoToMessage } from "./gotoconnect";
import { GetUserTokenRequest } from "./gotoconnect";
import { ExchangeCodeRequestWithRedirect } from "./gotoconnect";
import { Bool } from "./common";
import { ExchangeCodeRequest } from "./gotoconnect";
import { GetAuthURLResponse } from "./gotoconnect";
import { GetAuthURLRequest } from "./gotoconnect";
import { GoToCall } from "./gotoconnect";
import { Notification } from "./gotoconnect";
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { MessageMedia } from "./gotoconnect";
import { UserPresence } from "./gotoconnect";
/**
 * @generated from protobuf enum AgentStatus
 */
export var AgentStatus;
(function (AgentStatus) {
    /**
     * @generated from protobuf enum value: AGENT_STATUS_UNKNOWN = 0;
     */
    AgentStatus[AgentStatus["UNKNOWN"] = 0] = "UNKNOWN";
    /**
     * @generated from protobuf enum value: AGENT_STATUS_AVAILABLE = 1;
     */
    AgentStatus[AgentStatus["AVAILABLE"] = 1] = "AVAILABLE";
    /**
     * @generated from protobuf enum value: AGENT_STATUS_ON_CALL = 2;
     */
    AgentStatus[AgentStatus["ON_CALL"] = 2] = "ON_CALL";
    /**
     * @generated from protobuf enum value: AGENT_STATUS_UNAVAILABLE = 3;
     */
    AgentStatus[AgentStatus["UNAVAILABLE"] = 3] = "UNAVAILABLE";
    /**
     * @generated from protobuf enum value: AGENT_STATUS_OFFLINE = 4;
     */
    AgentStatus[AgentStatus["OFFLINE"] = 4] = "OFFLINE";
})(AgentStatus || (AgentStatus = {}));
// @generated message type with reflection information, may provide speed optimized methods
class InitializeClientRequest$Type extends MessageType {
    constructor() {
        super("InitializeClientRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "client_info", kind: "message", T: () => ClientInfo }
        ]);
    }
    create(value) {
        const message = { userId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* ClientInfo client_info */ 2:
                    message.clientInfo = ClientInfo.internalBinaryRead(reader, reader.uint32(), options, message.clientInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* ClientInfo client_info = 2; */
        if (message.clientInfo)
            ClientInfo.internalBinaryWrite(message.clientInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InitializeClientRequest
 */
export const InitializeClientRequest = new InitializeClientRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClientInfo$Type extends MessageType {
    constructor() {
        super("ClientInfo", [
            { no: 1, name: "device_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "platform", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "app_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { deviceType: "", platform: "", appVersion: "", deviceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_type */ 1:
                    message.deviceType = reader.string();
                    break;
                case /* string platform */ 2:
                    message.platform = reader.string();
                    break;
                case /* string app_version */ 3:
                    message.appVersion = reader.string();
                    break;
                case /* string device_id */ 4:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string device_type = 1; */
        if (message.deviceType !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceType);
        /* string platform = 2; */
        if (message.platform !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.platform);
        /* string app_version = 3; */
        if (message.appVersion !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.appVersion);
        /* string device_id = 4; */
        if (message.deviceId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ClientInfo
 */
export const ClientInfo = new ClientInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClientSession$Type extends MessageType {
    constructor() {
        super("ClientSession", [
            { no: 1, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "ready", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "assigned_extensions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "notification_channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "status", kind: "enum", T: () => ["AgentStatus", AgentStatus, "AGENT_STATUS_"] },
            { no: 7, name: "current_call", kind: "message", T: () => ActiveCall },
            { no: 8, name: "agent_info", kind: "message", T: () => AgentInfo },
            { no: 9, name: "user_phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { sessionId: "", deviceId: "", ready: false, assignedExtensions: [], notificationChannelId: "", status: 0, userPhoneNumber: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string session_id */ 1:
                    message.sessionId = reader.string();
                    break;
                case /* string device_id */ 2:
                    message.deviceId = reader.string();
                    break;
                case /* bool ready */ 3:
                    message.ready = reader.bool();
                    break;
                case /* repeated string assigned_extensions */ 4:
                    message.assignedExtensions.push(reader.string());
                    break;
                case /* string notification_channel_id */ 5:
                    message.notificationChannelId = reader.string();
                    break;
                case /* AgentStatus status */ 6:
                    message.status = reader.int32();
                    break;
                case /* ActiveCall current_call */ 7:
                    message.currentCall = ActiveCall.internalBinaryRead(reader, reader.uint32(), options, message.currentCall);
                    break;
                case /* AgentInfo agent_info */ 8:
                    message.agentInfo = AgentInfo.internalBinaryRead(reader, reader.uint32(), options, message.agentInfo);
                    break;
                case /* string user_phone_number */ 9:
                    message.userPhoneNumber = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string session_id = 1; */
        if (message.sessionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionId);
        /* string device_id = 2; */
        if (message.deviceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deviceId);
        /* bool ready = 3; */
        if (message.ready !== false)
            writer.tag(3, WireType.Varint).bool(message.ready);
        /* repeated string assigned_extensions = 4; */
        for (let i = 0; i < message.assignedExtensions.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.assignedExtensions[i]);
        /* string notification_channel_id = 5; */
        if (message.notificationChannelId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.notificationChannelId);
        /* AgentStatus status = 6; */
        if (message.status !== 0)
            writer.tag(6, WireType.Varint).int32(message.status);
        /* ActiveCall current_call = 7; */
        if (message.currentCall)
            ActiveCall.internalBinaryWrite(message.currentCall, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* AgentInfo agent_info = 8; */
        if (message.agentInfo)
            AgentInfo.internalBinaryWrite(message.agentInfo, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string user_phone_number = 9; */
        if (message.userPhoneNumber !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.userPhoneNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ClientSession
 */
export const ClientSession = new ClientSession$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClientSessionList$Type extends MessageType {
    constructor() {
        super("ClientSessionList", [
            { no: 1, name: "sessions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClientSession }
        ]);
    }
    create(value) {
        const message = { sessions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ClientSession sessions */ 1:
                    message.sessions.push(ClientSession.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ClientSession sessions = 1; */
        for (let i = 0; i < message.sessions.length; i++)
            ClientSession.internalBinaryWrite(message.sessions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ClientSessionList
 */
export const ClientSessionList = new ClientSessionList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActiveCall$Type extends MessageType {
    constructor() {
        super("ActiveCall", [
            { no: 1, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "remote_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "remote_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "start_time", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "is_outbound", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "is_muted", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "is_on_hold", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { callId: "", remoteNumber: "", remoteName: "", startTime: 0n, isOutbound: false, isMuted: false, isOnHold: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string call_id */ 1:
                    message.callId = reader.string();
                    break;
                case /* string remote_number */ 2:
                    message.remoteNumber = reader.string();
                    break;
                case /* string remote_name */ 3:
                    message.remoteName = reader.string();
                    break;
                case /* int64 start_time */ 4:
                    message.startTime = reader.int64().toBigInt();
                    break;
                case /* bool is_outbound */ 5:
                    message.isOutbound = reader.bool();
                    break;
                case /* bool is_muted */ 6:
                    message.isMuted = reader.bool();
                    break;
                case /* bool is_on_hold */ 7:
                    message.isOnHold = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string call_id = 1; */
        if (message.callId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.callId);
        /* string remote_number = 2; */
        if (message.remoteNumber !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.remoteNumber);
        /* string remote_name = 3; */
        if (message.remoteName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.remoteName);
        /* int64 start_time = 4; */
        if (message.startTime !== 0n)
            writer.tag(4, WireType.Varint).int64(message.startTime);
        /* bool is_outbound = 5; */
        if (message.isOutbound !== false)
            writer.tag(5, WireType.Varint).bool(message.isOutbound);
        /* bool is_muted = 6; */
        if (message.isMuted !== false)
            writer.tag(6, WireType.Varint).bool(message.isMuted);
        /* bool is_on_hold = 7; */
        if (message.isOnHold !== false)
            writer.tag(7, WireType.Varint).bool(message.isOnHold);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ActiveCall
 */
export const ActiveCall = new ActiveCall$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgentInfo$Type extends MessageType {
    constructor() {
        super("AgentInfo", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "presence", kind: "message", T: () => UserPresence }
        ]);
    }
    create(value) {
        const message = { userId: 0, name: "", email: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                case /* UserPresence presence */ 4:
                    message.presence = UserPresence.internalBinaryRead(reader, reader.uint32(), options, message.presence);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        /* UserPresence presence = 4; */
        if (message.presence)
            UserPresence.internalBinaryWrite(message.presence, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AgentInfo
 */
export const AgentInfo = new AgentInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TerminateClientRequest$Type extends MessageType {
    constructor() {
        super("TerminateClientRequest", [
            { no: 1, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { sessionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string session_id */ 1:
                    message.sessionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string session_id = 1; */
        if (message.sessionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TerminateClientRequest
 */
export const TerminateClientRequest = new TerminateClientRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartCallRequest$Type extends MessageType {
    constructor() {
        super("StartCallRequest", [
            { no: 1, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "sdp", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { sessionId: "", phoneNumber: "", sdp: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string session_id */ 1:
                    message.sessionId = reader.string();
                    break;
                case /* string phone_number */ 2:
                    message.phoneNumber = reader.string();
                    break;
                case /* string sdp */ 3:
                    message.sdp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string session_id = 1; */
        if (message.sessionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionId);
        /* string phone_number = 2; */
        if (message.phoneNumber !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.phoneNumber);
        /* string sdp = 3; */
        if (message.sdp !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.sdp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StartCallRequest
 */
export const StartCallRequest = new StartCallRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallId$Type extends MessageType {
    constructor() {
        super("CallId", [
            { no: 1, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "sdp", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { callId: "", sessionId: "", sdp: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string call_id */ 1:
                    message.callId = reader.string();
                    break;
                case /* string session_id */ 2:
                    message.sessionId = reader.string();
                    break;
                case /* string sdp */ 3:
                    message.sdp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string call_id = 1; */
        if (message.callId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.callId);
        /* string session_id = 2; */
        if (message.sessionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sessionId);
        /* string sdp = 3; */
        if (message.sdp !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.sdp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CallId
 */
export const CallId = new CallId$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DTMFRequest$Type extends MessageType {
    constructor() {
        super("DTMFRequest", [
            { no: 1, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "digits", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { callId: "", sessionId: "", digits: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string call_id */ 1:
                    message.callId = reader.string();
                    break;
                case /* string session_id */ 2:
                    message.sessionId = reader.string();
                    break;
                case /* string digits */ 3:
                    message.digits = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string call_id = 1; */
        if (message.callId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.callId);
        /* string session_id = 2; */
        if (message.sessionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sessionId);
        /* string digits = 3; */
        if (message.digits !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.digits);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DTMFRequest
 */
export const DTMFRequest = new DTMFRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscribeRequest$Type extends MessageType {
    constructor() {
        super("SubscribeRequest", [
            { no: 1, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { sessionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string session_id */ 1:
                    message.sessionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string session_id = 1; */
        if (message.sessionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubscribeRequest
 */
export const SubscribeRequest = new SubscribeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ForwardCallRequest$Type extends MessageType {
    constructor() {
        super("ForwardCallRequest", [
            { no: 1, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "extension", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { callId: "", number: "", extension: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string call_id */ 1:
                    message.callId = reader.string();
                    break;
                case /* string number */ 2:
                    message.number = reader.string();
                    break;
                case /* string extension */ 3:
                    message.extension = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string call_id = 1; */
        if (message.callId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.callId);
        /* string number = 2; */
        if (message.number !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.number);
        /* string extension = 3; */
        if (message.extension !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.extension);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ForwardCallRequest
 */
export const ForwardCallRequest = new ForwardCallRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateAgentStatusRequest$Type extends MessageType {
    constructor() {
        super("UpdateAgentStatusRequest", [
            { no: 1, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "status", kind: "enum", T: () => ["AgentStatus", AgentStatus, "AGENT_STATUS_"] },
            { no: 3, name: "update_presence", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { sessionId: "", status: 0, updatePresence: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string session_id */ 1:
                    message.sessionId = reader.string();
                    break;
                case /* AgentStatus status */ 2:
                    message.status = reader.int32();
                    break;
                case /* bool update_presence */ 3:
                    message.updatePresence = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string session_id = 1; */
        if (message.sessionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionId);
        /* AgentStatus status = 2; */
        if (message.status !== 0)
            writer.tag(2, WireType.Varint).int32(message.status);
        /* bool update_presence = 3; */
        if (message.updatePresence !== false)
            writer.tag(3, WireType.Varint).bool(message.updatePresence);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateAgentStatusRequest
 */
export const UpdateAgentStatusRequest = new UpdateAgentStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateTestCallsRequest$Type extends MessageType {
    constructor() {
        super("GenerateTestCallsRequest", [
            { no: 1, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "number_of_calls", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "delay_between_calls_ms", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "call_duration_ms", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "test_caller_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "test_caller_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { sessionId: "", numberOfCalls: 0, delayBetweenCallsMs: 0, callDurationMs: 0, testCallerName: "", testCallerNumber: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string session_id */ 1:
                    message.sessionId = reader.string();
                    break;
                case /* int32 number_of_calls */ 2:
                    message.numberOfCalls = reader.int32();
                    break;
                case /* int32 delay_between_calls_ms */ 3:
                    message.delayBetweenCallsMs = reader.int32();
                    break;
                case /* int32 call_duration_ms */ 4:
                    message.callDurationMs = reader.int32();
                    break;
                case /* string test_caller_name */ 5:
                    message.testCallerName = reader.string();
                    break;
                case /* string test_caller_number */ 6:
                    message.testCallerNumber = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string session_id = 1; */
        if (message.sessionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionId);
        /* int32 number_of_calls = 2; */
        if (message.numberOfCalls !== 0)
            writer.tag(2, WireType.Varint).int32(message.numberOfCalls);
        /* int32 delay_between_calls_ms = 3; */
        if (message.delayBetweenCallsMs !== 0)
            writer.tag(3, WireType.Varint).int32(message.delayBetweenCallsMs);
        /* int32 call_duration_ms = 4; */
        if (message.callDurationMs !== 0)
            writer.tag(4, WireType.Varint).int32(message.callDurationMs);
        /* string test_caller_name = 5; */
        if (message.testCallerName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.testCallerName);
        /* string test_caller_number = 6; */
        if (message.testCallerNumber !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.testCallerNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GenerateTestCallsRequest
 */
export const GenerateTestCallsRequest = new GenerateTestCallsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageRequest$Type extends MessageType {
    constructor() {
        super("MessageRequest", [
            { no: 1, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "media", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageMedia }
        ]);
    }
    create(value) {
        const message = { sessionId: "", customerPhoneNumber: "", body: "", media: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string session_id */ 1:
                    message.sessionId = reader.string();
                    break;
                case /* string customer_phone_number */ 2:
                    message.customerPhoneNumber = reader.string();
                    break;
                case /* string body */ 3:
                    message.body = reader.string();
                    break;
                case /* repeated MessageMedia media */ 4:
                    message.media.push(MessageMedia.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string session_id = 1; */
        if (message.sessionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionId);
        /* string customer_phone_number = 2; */
        if (message.customerPhoneNumber !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerPhoneNumber);
        /* string body = 3; */
        if (message.body !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.body);
        /* repeated MessageMedia media = 4; */
        for (let i = 0; i < message.media.length; i++)
            MessageMedia.internalBinaryWrite(message.media[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MessageRequest
 */
export const MessageRequest = new MessageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FetchMessagesRequest$Type extends MessageType {
    constructor() {
        super("FetchMessagesRequest", [
            { no: 1, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "customer_first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "oldest_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "newest_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { sessionId: "", customerPhoneNumber: "", customerLastName: "", customerFirstName: "", oldestTimestamp: "", newestTimestamp: "", pageMarker: "", pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string session_id */ 1:
                    message.sessionId = reader.string();
                    break;
                case /* string customer_phone_number */ 2:
                    message.customerPhoneNumber = reader.string();
                    break;
                case /* string customer_last_name */ 3:
                    message.customerLastName = reader.string();
                    break;
                case /* string customer_first_name */ 4:
                    message.customerFirstName = reader.string();
                    break;
                case /* string oldest_timestamp */ 5:
                    message.oldestTimestamp = reader.string();
                    break;
                case /* string newest_timestamp */ 6:
                    message.newestTimestamp = reader.string();
                    break;
                case /* string page_marker */ 7:
                    message.pageMarker = reader.string();
                    break;
                case /* int32 page_size */ 8:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string session_id = 1; */
        if (message.sessionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionId);
        /* string customer_phone_number = 2; */
        if (message.customerPhoneNumber !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerPhoneNumber);
        /* string customer_last_name = 3; */
        if (message.customerLastName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerLastName);
        /* string customer_first_name = 4; */
        if (message.customerFirstName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customerFirstName);
        /* string oldest_timestamp = 5; */
        if (message.oldestTimestamp !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.oldestTimestamp);
        /* string newest_timestamp = 6; */
        if (message.newestTimestamp !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.newestTimestamp);
        /* string page_marker = 7; */
        if (message.pageMarker !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.pageMarker);
        /* int32 page_size = 8; */
        if (message.pageSize !== 0)
            writer.tag(8, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FetchMessagesRequest
 */
export const FetchMessagesRequest = new FetchMessagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FetchConversationsRequest$Type extends MessageType {
    constructor() {
        super("FetchConversationsRequest", [
            { no: 1, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "oldest_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "newest_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "unread_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { sessionId: "", oldestTimestamp: "", newestTimestamp: "", pageMarker: "", pageSize: 0, unreadOnly: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string session_id */ 1:
                    message.sessionId = reader.string();
                    break;
                case /* string oldest_timestamp */ 2:
                    message.oldestTimestamp = reader.string();
                    break;
                case /* string newest_timestamp */ 3:
                    message.newestTimestamp = reader.string();
                    break;
                case /* string page_marker */ 4:
                    message.pageMarker = reader.string();
                    break;
                case /* int32 page_size */ 5:
                    message.pageSize = reader.int32();
                    break;
                case /* bool unread_only */ 6:
                    message.unreadOnly = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string session_id = 1; */
        if (message.sessionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionId);
        /* string oldest_timestamp = 2; */
        if (message.oldestTimestamp !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.oldestTimestamp);
        /* string newest_timestamp = 3; */
        if (message.newestTimestamp !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.newestTimestamp);
        /* string page_marker = 4; */
        if (message.pageMarker !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.pageMarker);
        /* int32 page_size = 5; */
        if (message.pageSize !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageSize);
        /* bool unread_only = 6; */
        if (message.unreadOnly !== false)
            writer.tag(6, WireType.Varint).bool(message.unreadOnly);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FetchConversationsRequest
 */
export const FetchConversationsRequest = new FetchConversationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DBClientSession$Type extends MessageType {
    constructor() {
        super("DBClientSession", [
            { no: 1, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "notification_channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "assigned_extensions", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "agent_info_json", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "last_updated", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "active_call_json", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "ready", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { sessionId: "", deviceId: "", userId: 0, notificationChannelId: "", assignedExtensions: "", agentInfoJson: "", status: 0, lastUpdated: "", activeCallJson: "", ready: false, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string session_id */ 1:
                    message.sessionId = reader.string();
                    break;
                case /* string device_id */ 2:
                    message.deviceId = reader.string();
                    break;
                case /* int32 user_id */ 3:
                    message.userId = reader.int32();
                    break;
                case /* string notification_channel_id */ 4:
                    message.notificationChannelId = reader.string();
                    break;
                case /* string assigned_extensions */ 5:
                    message.assignedExtensions = reader.string();
                    break;
                case /* string agent_info_json */ 6:
                    message.agentInfoJson = reader.string();
                    break;
                case /* int32 status */ 7:
                    message.status = reader.int32();
                    break;
                case /* string last_updated */ 8:
                    message.lastUpdated = reader.string();
                    break;
                case /* string active_call_json */ 9:
                    message.activeCallJson = reader.string();
                    break;
                case /* bool ready */ 10:
                    message.ready = reader.bool();
                    break;
                case /* repeated string field_mask */ 11:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 12:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 13:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 14:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string session_id = 1; */
        if (message.sessionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionId);
        /* string device_id = 2; */
        if (message.deviceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deviceId);
        /* int32 user_id = 3; */
        if (message.userId !== 0)
            writer.tag(3, WireType.Varint).int32(message.userId);
        /* string notification_channel_id = 4; */
        if (message.notificationChannelId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.notificationChannelId);
        /* string assigned_extensions = 5; */
        if (message.assignedExtensions !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.assignedExtensions);
        /* string agent_info_json = 6; */
        if (message.agentInfoJson !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.agentInfoJson);
        /* int32 status = 7; */
        if (message.status !== 0)
            writer.tag(7, WireType.Varint).int32(message.status);
        /* string last_updated = 8; */
        if (message.lastUpdated !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.lastUpdated);
        /* string active_call_json = 9; */
        if (message.activeCallJson !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.activeCallJson);
        /* bool ready = 10; */
        if (message.ready !== false)
            writer.tag(10, WireType.Varint).bool(message.ready);
        /* repeated string field_mask = 11; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 12; */
        if (message.pageNumber !== 0)
            writer.tag(12, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 13; */
        if (message.orderBy !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 14; */
        if (message.orderDir !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DBClientSession
 */
export const DBClientSession = new DBClientSession$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SessionID$Type extends MessageType {
    constructor() {
        super("SessionID", [
            { no: 1, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { sessionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string session_id */ 1:
                    message.sessionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string session_id = 1; */
        if (message.sessionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SessionID
 */
export const SessionID = new SessionID$Type();
/**
 * @generated ServiceType for protobuf service SoftphoneService
 */
export const SoftphoneService = new ServiceType("SoftphoneService", [
    { name: "InitializeClient", options: {}, I: InitializeClientRequest, O: ClientSession },
    { name: "TerminateClient", options: {}, I: TerminateClientRequest, O: Empty },
    { name: "SubscribeToEvents", serverStreaming: true, options: {}, I: SubscribeRequest, O: Notification },
    { name: "StartCall", options: {}, I: StartCallRequest, O: GoToCall },
    { name: "AnswerCall", options: {}, I: CallId, O: GoToCall },
    { name: "RejectCall", options: {}, I: CallId, O: GoToCall },
    { name: "EndCall", options: {}, I: CallId, O: Empty },
    { name: "ForwardCall", options: {}, I: ForwardCallRequest, O: Empty },
    { name: "ToggleMute", options: {}, I: CallId, O: GoToCall },
    { name: "ToggleHold", options: {}, I: CallId, O: GoToCall },
    { name: "SendDTMF", options: {}, I: DTMFRequest, O: GoToCall },
    { name: "GetAuthURL", options: {}, I: GetAuthURLRequest, O: GetAuthURLResponse },
    { name: "ExchangeCode", options: {}, I: ExchangeCodeRequest, O: Bool },
    { name: "ExchangeCodeWithRedirect", options: {}, I: ExchangeCodeRequestWithRedirect, O: Bool },
    { name: "RefreshOAuthToken", options: {}, I: Empty, O: Bool },
    { name: "GetUserToken", options: {}, I: GetUserTokenRequest, O: Bool },
    { name: "SendMessage", options: {}, I: MessageRequest, O: GoToMessage },
    { name: "FetchMessages", options: {}, I: FetchMessagesRequest, O: GoToMessageList },
    { name: "FetchConversations", options: {}, I: FetchConversationsRequest, O: GoToConversationList },
    { name: "UpdateAgentStatus", options: {}, I: UpdateAgentStatusRequest, O: ClientSession },
    { name: "GetUserPresence", options: {}, I: GetUserPresenceRequest, O: UserPresence },
    { name: "UpdateUserPresence", options: {}, I: UpdateUserPresenceRequest, O: UserPresence },
    { name: "GetCallHistory", options: {}, I: GetCallHistoryRequest, O: GetCallHistoryResponse },
    { name: "GetMe", options: {}, I: Empty, O: MeResponse },
    { name: "FetchCurrentCallQueue", options: {}, I: Empty, O: ClientSessionList },
    { name: "GenerateTestCalls", options: {}, I: GenerateTestCallsRequest, O: Empty },
    { name: "CreateNotificationChannel", options: {}, I: SessionID, O: Bool }
]);
