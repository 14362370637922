import { LoadingIcon } from '@kalos/ui';
import { type ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useSoftPhoneStoreContext } from '../context/SoftPhoneStore.context';

type Props = {
  children: ReactNode;
};

export const GoToAuthWrapper = ({ children }: Props) => {
  const { requiresAuth, isPending } = useSoftPhoneStoreContext();

  if (isPending) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingIcon className="size-10" />
      </div>
    );
  }

  if (requiresAuth) {
    sessionStorage.setItem('softphone_return_url', window.location.pathname);
    return <Navigate to="/softphone/oauth-callback" />;
  }

  return <div>{children}</div>;
};
