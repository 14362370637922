import { GoToAuthWrapper } from '../../Softphone/components/GoToAuthWrapper';
import { SessionWrapper } from '../../Softphone/components/SessionWrapper';
import CustomerServiceDashboard from '../main';

export const CustomerServiceDashboardWrapper = () => {
  return (
    <GoToAuthWrapper>
      <SessionWrapper>
        <CustomerServiceDashboard />
      </SessionWrapper>
    </GoToAuthWrapper>
  );
};
