import { ApiKey } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { ApiKeyClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type ApiKeyFilter = EntityFilter<ApiKey>;

export const getApiKeyClientGetQueryConfig = (filter: ApiKeyFilter) => ({
  queryKey: [queryKeys.apiKey.root, queryKeys.apiKey.getApiKeyHash(filter)],
  queryFn: () => {
    const req = ApiKey.create(filter);
    return ApiKeyClientService.Get(req);
  },
});

type GetApiKeyResult = Awaited<ReturnType<typeof ApiKeyClientService.Get>>;
export const useApiKeyClientServiceQuery = <TSelectedData = GetApiKeyResult,>({
  filter = {},
  enabled = true,
  select,
}: {
  filter?: ApiKeyFilter;
  enabled?: boolean;
  select?: (data: GetApiKeyResult) => TSelectedData;
} = {}) => {
  return useQuery({
    ...getApiKeyClientGetQueryConfig(filter),
    enabled,
    select,
  });
};
