import { type Event, type TimesheetDepartment } from '@kalos/kalos-rpc';
import { addMinutes, format, parseISO } from 'date-fns';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';

import {
  EVENT_STATUS_LIST,
  JOB_STATUS_COLORS,
  OPTION_BLANK,
  PAYMENT_TYPE_LIST_OPTIONS,
} from '../../../../constants';
import { makeFakeRows } from '../../../../tools/helpers';
import { type Options, type Schema } from '../../Form';
import { InfoTable } from '../../InfoTable';
import { type Option, PlainForm } from '../../PlainForm';
import { returnCorrectTimeField } from '../utils';

const IS_RESIDENTIAL: Options = [
  { label: 'Commercial', value: 0 },
  { label: 'Residential', value: 1 },
];

interface Props {
  loading: boolean;
  disabled: boolean;
  serviceItem: Event;
  propertyEvents: Event[];
  jobTypeOptions: Option[];
  jobSubtypeOptions: Option[];
  onChange: (serviceItem: Event) => void;
  onValid?: (valid: boolean) => void;
  onInitSchema: (fields: string[]) => void;
  className?: string;
  formErrors?: { [key: string]: string };
  canBeCallback?: boolean; // if false, doesn't display 'Is Callback?' checkbox
  canArchive?: boolean;
}

export const Request: FC<Props> = ({
  serviceItem,
  propertyEvents,
  loading,
  disabled,
  jobTypeOptions,
  className,
  jobSubtypeOptions,
  onChange,
  onValid,
  onInitSchema,
  canBeCallback,
  canArchive,
  formErrors,
}) => {
  const [initSchemaCalled, setInitSchemaCalled] = useState<boolean>(false);
  const [resetId, setResetId] = useState<number>(0);

  const JOB_STATUS_OPTIONS = useMemo(() => {
    const isArchiveAdmin = canArchive;
    const isCurrentlyArchived = serviceItem.logJobStatus === 'Archived';

    return EVENT_STATUS_LIST.filter(
      (label) => isArchiveAdmin || isCurrentlyArchived || label !== 'Archived',
    ) // Include 'Archived' based on new condition
      .map((label) => ({
        label,
        value: label,
        color: `#${JOB_STATUS_COLORS[label]}`,
      }));
  }, [canArchive, serviceItem.logJobStatus]); // Add event.log_jobStatus as a dependency

  const handleChange = useCallback(
    (tempData: Event) => {
      //const { jobTypeId, jobSubtypeId, logJobStatus } = data;
      const jobTypeId = tempData.jobTypeId.toString() == '' ? 0 : tempData.jobTypeId;
      const jobSubtypeId = tempData.jobSubtypeId;
      const logJobStatus = tempData.logJobStatus;

      tempData.jobType = jobTypeOptions.find((value) => value.value === jobTypeId)?.label || '';
      tempData.jobSubtype =
        jobSubtypeOptions.find((value) => value.value === jobSubtypeId)?.label || '';
      tempData.color = JOB_STATUS_COLORS[logJobStatus];

      const formData = {
        jobTypeId: tempData.jobTypeId.toString() == '' ? 0 : tempData.jobTypeId,
        jobType: jobTypeOptions.find(({ value }) => value === jobTypeId)?.label || '',
        jobSubtype: jobSubtypeOptions.find(({ value }) => value === jobSubtypeId)?.label || '',
        color: JOB_STATUS_COLORS[logJobStatus],
      };
      let serviceItemJobTypeId = serviceItem.jobTypeId;
      if (serviceItemJobTypeId.toString() == '') {
        serviceItemJobTypeId = 0;
      }
      if (formData.jobTypeId !== serviceItemJobTypeId) {
        tempData.jobSubtypeId = 0;
        console.log('set jobsubtype');
        setResetId(resetId + 1);
      }
      if (!tempData.isCallback && serviceItem.isCallback) {
        tempData.callbackOriginalId = 0;
        console.log('set callback');
        setResetId(resetId + 1);
      }

      if (tempData.dateStarted >= tempData.dateEnded) {
        const correctTimeEnd = returnCorrectTimeField(tempData.dateEnded);
        const correctTimeStart = returnCorrectTimeField(tempData.dateStarted);
        console.log('timestarted', tempData.dateStarted);
        console.log('timefinished', tempData.dateEnded);

        const updatedDate = tempData.dateStarted.split(' ');
        const date = updatedDate[0];
        const fullCorrectTime = `${date} ${correctTimeEnd}:00`;

        if (correctTimeStart >= correctTimeEnd) {
          const correctTime = returnCorrectTimeField(tempData.dateStarted);

          let fullCorrectTime = `${date} ${correctTime}:00`;

          const dateAddFifteen = new Date(parseISO(fullCorrectTime));

          fullCorrectTime = format(addMinutes(dateAddFifteen, 15), 'yyyy-MM-dd HH:mm:ss');
          console.log('time we got', tempData.dateStarted);
          console.log('full new correct time due to going over', fullCorrectTime);
          tempData.dateEnded = fullCorrectTime;
        } else {
          tempData.dateEnded = fullCorrectTime;
        }
        if (tempData.fieldMask.find((el) => el == 'DateEnded') == undefined) {
          tempData.fieldMask.push('DateEnded');
        }
      }
      if (tempData.dateEnded < tempData.dateStarted) {
        console.log('edned less than');
        const correctTime = returnCorrectTimeField(tempData.dateStarted);
        const updatedDate = tempData.dateEnded.split(' ');
        const date = updatedDate[0];
        const fullCorrectTime = `${date} ${correctTime}:00`;
        tempData.dateStarted = fullCorrectTime;

        if (tempData.fieldMask.find((el) => el == 'DateStarted') == undefined) {
          tempData.fieldMask.push('DateStated');
        }
      }
      onChange(tempData);
      onValid?.(false);
    },
    [onChange, jobSubtypeOptions, jobTypeOptions, resetId, serviceItem, onValid],
  );
  const handleResetId = useCallback(() => {
    setResetId(resetId + 1);
    console.log('we were called to reset id');
  }, [resetId]);

  const callbackOriginalOptions: Option[] = useMemo(
    () => [
      { label: OPTION_BLANK, value: 0 },
      ...propertyEvents.map((event) => ({
        label: `${event.logJobNumber} - ${event.name}`,
        value: event.id,
      })),
    ],
    [propertyEvents],
  );
  const isCallback = serviceItem.isCallback;
  const SCHEMA: Schema<Event> = useMemo(
    () => [
      [
        {
          label: 'Date of Service',
          name: 'dateStarted',
          type: 'mui-datetime',
          required: true,
          onClose: handleResetId,
          disabled: serviceItem.logJobStatus === 'Archived',
        },
        {
          label: 'End Date',
          name: 'dateEnded',
          type: 'mui-datetime',
          required: true,
          onClose: handleResetId,
          disabled: serviceItem.logJobStatus === 'Archived',
        },
      ],
      [
        {
          label: 'Sector',
          name: 'isResidential',
          required: false,
          type: 'number',
          options: IS_RESIDENTIAL,

          forceShrinkLabel: true,
          disabled: serviceItem.logJobStatus === 'Archived',
        },
        {
          label: 'Department',
          name: 'departmentId',
          filter: (a: TimesheetDepartment) => {
            if (a.teamOnly) return false;
            return true;
          },
          required: true,
          type: 'department',

          disabled: serviceItem.logJobStatus === 'Archived',
        },
        {
          label: 'Job Status',
          name: 'logJobStatus',
          required: true,
          type: 'text',
          options: JOB_STATUS_OPTIONS,
          disabled: serviceItem.logJobStatus === 'Archived' && !canArchive,
        },
        {
          label: 'Employee(s) Assigned',
          name: 'logTechnicianAssigned',
          type: 'technicians',
          disabled: serviceItem.logJobStatus === 'Archived',
          required: false,
        },
      ],
      [
        {
          label: 'Job Type',
          name: 'jobTypeId',
          required: true,
          type: 'number',
          options: jobTypeOptions,
          disabled: serviceItem.logJobStatus === 'Archived',
        },
        {
          label: 'Sub Type',
          name: 'jobSubtypeId',
          type: 'number',
          options: jobSubtypeOptions,
          disabled: serviceItem.logJobStatus === 'Archived',
        },
      ],
      [
        {
          label: 'Payment Type',
          name: 'logPaymentType',
          required: true,
          options: PAYMENT_TYPE_LIST_OPTIONS,
          type: 'text',
          disabled: serviceItem.logJobStatus === 'Archived',
        },
        {
          label: 'Amount Quoted',
          name: 'amountQuoted',
          type: 'text',
          startAdornment: '$',
          disabled: serviceItem.logJobStatus === 'Archived',
        },
        {
          label: 'Tracking Number',
          name: 'logPo',
          type: 'text',
          disabled: serviceItem.logJobStatus === 'Archived',
        },
      ],
      [
        {
          label: 'Diagnostic Quoted',
          name: 'diagnosticQuoted',
          type: 'checkbox',
          removeExcessCheckboxArea: true,
          disabled: serviceItem.logJobStatus === 'Archived',
        },
        {
          label: 'Is LMPC?',
          name: 'isLmpc',
          type: 'checkbox',
          removeExcessCheckboxArea: true,
          disabled: serviceItem.logJobStatus === 'Archived',
        },
        {
          label: 'Is Callback?',
          name: 'isCallback',
          type: 'checkbox',
          removeExcessCheckboxArea: true,
          invisible: canBeCallback === false,
          disabled: serviceItem.logJobStatus === 'Archived',
        },
        {
          label: 'Callback Regarding Job',
          name: 'callbackOriginalId',
          type: 'number',
          options: callbackOriginalOptions,
          disabled: !isCallback || serviceItem.logJobStatus === 'Archived',
          invisible: canBeCallback === false,
        },
      ],
      [
        {
          label: 'High Priority?',
          name: 'highPriority',
          type: 'checkbox',
          removeExcessCheckboxArea: true,
          disabled: serviceItem.logJobStatus === 'Archived',
        },
        {},
      ],
      [
        {
          label: 'Brief Description',
          name: 'name',
          description: 'Used on Calendar',
          helperText: 'Used on Calendar',
          required: true,
          multiline: true,
          minRows: 5,
          maxRows: 5,
          disabled: serviceItem.logJobStatus === 'Archived',
        },
        {
          label: 'Service Needed',
          name: 'description',
          required: true,
          multiline: true,
          minRows: 5,
          maxRows: 5,
          disabled: serviceItem.logJobStatus === 'Archived',
        },
        {
          label: 'Job Notes',
          name: 'logNotes',
          description: 'For internal use',
          helperText: 'For Internal Use',
          multiline: true,
          minRows: 5,
          maxRows: 5,
          disabled: serviceItem.logJobStatus === 'Archived',
        },
      ],
      [
        {
          name: 'propertyId',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logJobNumber',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'id',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logVersion',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'notes',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'propertyBilling',
          type: 'hidden',
          invisible: true,
        },

        {
          name: 'logPaymentType',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logPaymentStatus',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'logBillingDate',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'totalamountrow1',
          type: 'hidden',
          invisible: true,
        },
        {
          name: 'fieldMask',
          type: 'hidden',
          invisible: true,
        },
        {
          type: 'hidden',
          invisible: true,
          name: 'servicesperformedrow1',
        },
        {
          type: 'hidden',
          invisible: true,
          name: 'servicesperformedrow2',
        },

        {
          type: 'hidden',
          invisible: true,
          name: 'totalamountrow2',
        },

        {
          type: 'hidden',
          invisible: true,
          name: 'servicesperformedrow3',
        },

        {
          type: 'hidden',
          invisible: true,
          name: 'totalamountrow3',
        },

        {
          type: 'hidden',
          invisible: true,
          name: 'servicesperformedrow4',
        },

        {
          type: 'hidden',
          invisible: true,
          name: 'totalamountrow4',
        },

        {
          name: 'materialUsed',
          type: 'hidden',
          invisible: true,
        },
        {
          type: 'hidden',
          invisible: true,
          name: 'materialTotal',
        },

        {
          type: 'hidden',
          invisible: true,
          name: 'discount',
        },
        {
          type: 'hidden',
          invisible: true,
          name: 'discountcost',
        },
        {
          type: 'number',
          invisible: true,
          name: 'contractId',
        },
        {
          type: 'text',
          invisible: true,
          name: 'contractNumber',
        },
      ],
    ],
    [
      callbackOriginalOptions,
      canBeCallback,
      serviceItem.logJobStatus,
      handleResetId,
      isCallback,
      jobSubtypeOptions,
      jobTypeOptions,
      JOB_STATUS_OPTIONS,
      canArchive,
    ],
  );
  useEffect(() => {
    if (!initSchemaCalled) {
      setInitSchemaCalled(true);
      const fields = SCHEMA.map((item) =>
        item.map(({ name }) => name).filter((name) => name),
      ).reduce((aggr, item) => [...aggr, ...item], []);
      onInitSchema(fields as string[]);
    }
  }, [initSchemaCalled, setInitSchemaCalled, onInitSchema, SCHEMA]);
  if (loading) return <InfoTable data={makeFakeRows(4, 5)} loading />;
  return (
    <div className={className}>
      <div
        style={{
          borderTop: '5px solid #ccc',
          marginTop: '20px',
          paddingTop: '20px',
        }}
      >
        <PlainForm
          //@ts-ignore
          key={resetId}
          schema={SCHEMA}
          data={serviceItem}
          onChange={handleChange}
          disabled={disabled}
          validations={formErrors}
        />
      </div>
    </div>
  );
};
