import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '@kalos/ui';
import { useState } from 'react';

import { ToolFundCatalog } from './ToolFundCatalog';

export const ToolFundCatalogDialog = ({ trigger }: { trigger: React.ReactNode }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="@container max-h-[90vh] max-w-7xl overflow-y-auto p-0 pt-10">
        <DialogTitle className="mx-4 text-xl font-semibold">Tool Catalog</DialogTitle>

        <ToolFundCatalog />
      </DialogContent>
    </Dialog>
  );
};
