export const PAYMENT_TYPE_OPTIONS = [
  'Cash',
  'Check',
  'Credit Card',
  'Paypal',
  'Stripe',
  'Billing',
  'Financing',
  'AOR Warranty',
  'Service Warranty',
  'Extended Warranty',
  'Pre-Paid',
  'No Charge',
  'Account Transfer',
  'Charity',
] as const;

export const PAYMENT_STATUS_OPTIONS = ['Pending', 'Billed', 'Canceled', 'Paid'];
