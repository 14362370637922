import './ServiceCalls.module.less';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  ActivityLog,
  Event,
  getPropertyAddress,
  type Property,
  ServicesRendered,
  User,
} from '@kalos/kalos-rpc';
import {
  Badge,
  Button,
  DataTable,
  DataTableColumnHeader,
  DataTablePagination,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useAlert,
  useConfirm,
} from '@kalos/ui';
import { MagnifyingGlassIcon, TrashIcon } from '@radix-ui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  type ColumnDef,
  getCoreRowModel,
  type OnChangeFn,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { addHours, format } from 'date-fns';
import { produce } from 'immer';
import { debounce } from 'lodash';
import { MessageSquareDiff, Receipt, Redo } from 'lucide-react';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useGetLoadingColumns } from '../../../components/utils';
import {
  FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME,
  OPTION_BLANK,
  ROWS_PER_PAGE,
} from '../../../constants';
import { useAuth } from '../../../context/AuthContext';
import { queryKeys } from '../../../hooks/react-query/constants';
import {
  type EventFilter,
  useEventBatchGetQuery,
  useEventDeleteMutation,
  useEventUpdateMutation,
} from '../../../hooks/react-query/useEventsQuery';
import { usePropertiesBatchGetQuery } from '../../../hooks/react-query/usePropertiesQuery';
import {
  ActivityLogClientService,
  EventClientService,
  formatDate,
  formatTime,
  navigateNewTab,
  ServicesRenderedClientService,
  timestamp,
  trailingZero,
  usd,
} from '../../../tools/helpers';
import { type ZodObjectWithModelKeys } from '../../../tools/typeguargs';
import { AddServiceCall } from '../../AddServiceCallGeneral/components/AddServiceCall';
import { PropertiesSearch } from '../../ComponentsLibrary/AdvancedSearch/PropertiesSearch/PropertiesSearch';
import { Form as FormOld, type Schema } from '../../ComponentsLibrary/Form';
import { InfoTable } from '../../ComponentsLibrary/InfoTable';
import { Link } from '../../ComponentsLibrary/Link';
import { Modal } from '../../ComponentsLibrary/Modal';
import { SectionBar } from '../../ComponentsLibrary/SectionBar';
import { SellSheet } from '../../ComponentsLibrary/SellSheet';
import { Proposal } from '../../ComponentsLibrary/ServiceCall/components/Proposal';
import { ServiceRequest } from '../../ComponentsLibrary/ServiceCall/requestIndex';

const staticJobs = Array.from({ length: 3 }, (_, i) => Event.create({ id: i }));
interface Props {
  className?: string;
  userID: number;
  propertyId?: number;
  viewedAsCustomer?: boolean;
  onCreateDoc?: () => void;
}

interface State {
  movingEntry?: Event;
  viewingEntry?: Event;
  addingCustomerEntry?: Event;
  addingServiceCall?: boolean;
  editingServiceCall: boolean;
  serviceCallId: number;
  sellSheetEntry: Event | undefined;
  saving: boolean;
  confirmingAdded: boolean;
  showText?: string;
  proposalEvent: Event | undefined;
  proposalEventPropData: ServicesRendered[];
}

type ServiceCallSearchFields = Pick<Event, 'name' | 'description'>;
const serviceCallSearchSchema = z.object({
  description: z.string(),
  name: z.string(),
}) satisfies ZodObjectWithModelKeys<ServiceCallSearchFields>;
const defaultSearchValues: ServiceCallSearchFields = {
  description: '',
  name: '',
};

const useServiceCallSearchForm = () => {
  return useForm<z.infer<typeof serviceCallSearchSchema>>({
    resolver: zodResolver(serviceCallSearchSchema),
    mode: 'onChange',
    defaultValues: defaultSearchValues,
  });
};

export const ServiceCalls: FC<Props> = ({
  className = '',
  userID,
  propertyId = 0,
  viewedAsCustomer,
  onCreateDoc,
}) => {
  const [state, setState] = useState<State>({
    movingEntry: undefined,
    sellSheetEntry: undefined,
    viewingEntry: undefined,
    addingCustomerEntry: undefined,
    addingServiceCall: false,
    editingServiceCall: false,
    serviceCallId: 0,
    saving: false,
    confirmingAdded: false,
    proposalEvent: undefined,
    proposalEventPropData: [],
  });

  const loggedUser = useAuth().user;

  const [sortingState, setSortingState] = useState<SortingState>([]);

  const searchForm = useServiceCallSearchForm();
  const [filter, setFilter] = useState<EventFilter>(searchForm.getValues());

  useEffect(() => {
    const subscription = searchForm.watch(
      debounce((values) => {
        setFilter(values);
        setPage(0);
      }, FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME),
    );

    return () => subscription.unsubscribe();
  }, [searchForm]);

  const handleSetState = useCallback(function handleSetState(partialState: Partial<State>) {
    setState((currState) =>
      produce(currState, (draft) => {
        return { ...draft, ...partialState };
      }),
    );
  }, []);

  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  const queryClient = useQueryClient();

  const setMoving = useCallback(
    (movingEntry?: Event) => handleSetState({ movingEntry }),
    [handleSetState],
  );

  const setSellSheetEntry = useCallback(
    (sellSheetEntry?: Event) => () => handleSetState({ sellSheetEntry }),
    [handleSetState],
  );

  const setViewing = useCallback(
    (viewingEntry?: Event) => handleSetState({ viewingEntry }),
    [handleSetState],
  );

  const setAddingCustomerEntry = (addingCustomerEntry?: Event) => () =>
    handleSetState({ addingCustomerEntry });

  const toggleConfirmingAdded = () => handleSetState({ confirmingAdded: !state.confirmingAdded });

  const handleServiceCallAddToggle = () =>
    handleSetState({ addingServiceCall: !state.addingServiceCall });

  const handleReview = useCallback(
    (event: Event) => {
      handleSetState({ editingServiceCall: true, serviceCallId: event.id });
    },
    [handleSetState],
  );

  const handleRowClick = (event: Event) => {
    navigateNewTab(`/jobs/${event.id}`);
  };

  const handleCustomerAddEvent = async (formData: Event) => {
    const [, timeEnded] = format(
      addHours(new Date(`${formData.dateStarted} ${formData.timeStarted}`), 1),
      'yyyy-MM-dd HH:mm',
    ).split(' ');
    const data = Event.create();
    data.timeEnded = timeEnded;
    data.name = 'Online Service Request';
    data.logJobStatus = 'Requested';
    data.color = 'efc281';
    data.description = [formData.description, formData.notes].join(', ');
    data.notes = '';
    data.dateEnded = formData.dateEnded;
    console.log(data);
    handleSetState({ saving: true });
    await EventClientService.upsertEvent(data);

    handleSetState({
      saving: false,
      addingCustomerEntry: undefined,
      confirmingAdded: !state.confirmingAdded,
      // isLoaded: false,
    });
  };

  const makeCustomerEntry = () => {
    const req = Event.create();
    if (propertyId) {
      req.propertyId = propertyId;
    }
    req.description = '0';
    req.dateStarted = timestamp(true);
    const [valHour, valMinutes] = timestamp().substr(11, 5).split(':');
    let minutes = +valMinutes;
    if (minutes >= 45) {
      minutes = 45;
    } else if (minutes >= 30) {
      minutes = 30;
    } else if (minutes >= 15) {
      minutes = 15;
    } else {
      minutes = 0;
    }
    req.timeStarted = `${valHour}:${trailingZero(minutes)}`;
    return req;
  };

  const serviceCallJobsFilter = useMemo<EventFilter>(() => {
    const sorting = sortingState.at(0);
    const orderBy = sorting ? sorting.id : 'date_started';
    const orderDir = sorting ? (sorting.desc ? 'DESC' : 'ASC') : 'DESC';
    return {
      propertyId: propertyId ? propertyId : undefined,
      customer: propertyId ? undefined : User.create({ id: userID }),
      orderBy,
      orderDir,
      pageNumber: page,
      isActive: 1,
      name: filter.name ? `%${filter.name}%` : undefined,
      description: filter.description ? `%${filter.description}%` : undefined,
    };
  }, [filter.description, filter.name, page, propertyId, sortingState, userID]);

  const jobsQuery = useEventBatchGetQuery({
    filters: serviceCallJobsFilter,
  });

  const propertiesQueryEnabled = viewedAsCustomer;
  const propertiesQuery = usePropertiesBatchGetQuery({
    filter: {
      pageNumber: 0,
      withoutLimit: true,
      userId: userID,
    },
    enabled: propertiesQueryEnabled,
  });

  const loading = jobsQuery.isPending || (propertiesQueryEnabled && propertiesQuery.isPending);

  const updateEventMutation = useEventUpdateMutation();

  const handleMoveJobToProperty = async (property: Property) => {
    const { movingEntry } = state;
    if (movingEntry) {
      const entry = Event.create();
      entry.id = movingEntry.id;
      entry.propertyId = property.id;
      entry.fieldMask = ['PropertyId'];
      await updateEventMutation.mutateAsync(entry);
      await ActivityLogClientService.Create(
        ActivityLog.create({
          propertyId: propertyId,
          userId: loggedUser.id,
          activityName: `Job ${entry.id} moved to ${property.address}`,
        }),
      );
      await ActivityLogClientService.Create(
        ActivityLog.create({
          propertyId: property.id,
          userId: loggedUser.id,
          activityName: `Job ${entry.id} moved from ${property.address}`,
        }),
      );

      handleSetState({
        movingEntry: undefined,
      });
    }
  };

  const alert = useAlert();
  const canDoProposals = useMemo(() => {
    return loggedUser.permissionGroups.find((permission) => permission.name === 'Proposal');
  }, [loggedUser.permissionGroups]);

  const handleSetProposalEvent = useCallback(
    async (proposalEvent?: Event) => {
      if (proposalEvent === undefined) {
        handleSetState({ proposalEvent: undefined, proposalEventPropData: [] });
      } else {
        const srReq = ServicesRendered.create();
        srReq.eventId = proposalEvent.id;
        srReq.isActive = 1;
        try {
          const results = await ServicesRenderedClientService.BatchGet(srReq);
          {
            if (results) {
              handleSetState({
                proposalEventPropData: results.results,
                proposalEvent: proposalEvent,
              });
            } else {
              handleSetState({ proposalEvent: proposalEvent });
            }
          }
        } catch (err) {
          console.error('Error getting proposal event', err);
          alert('Error getting proposal event');
        }
      }
    },
    [alert, handleSetState],
  );

  const auth = useAuth();

  const canDeleteJobs = useMemo(() => {
    return auth.user.permissionGroups.some(
      (permission) =>
        (permission.type === 'role' && permission.name === 'Manager') ||
        (permission.type === 'privilege' && permission.name === 'CanDeleteJobs'),
    );
  }, [auth.user.permissionGroups]);

  const tableColumns = useMemo<ColumnDef<Event>[]>(() => {
    const customerColumns: ColumnDef<Event>[] = [
      {
        accessorKey: 'dateStarted',
        header(props) {
          <DataTableColumnHeader title="Date / Time" column={props.column} />;
        },
        meta: {
          className: 'w-32',
        },
        cell(props) {
          const value =
            formatDate(props.row.original.dateStarted) +
            ' ' +
            formatTime(props.row.original.timeStarted) +
            ' - ' +
            formatTime(props.row.original.timeEnded);
          return <div className={props.column.columnDef.meta?.className}>{value}</div>;
        },
      },
      {
        id: 'address',
        header(props) {
          return <DataTableColumnHeader title="Address" column={props.column} />;
        },
        meta: {
          className: 'w-32',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {getPropertyAddress(props.row.original.property)}
            </div>
          );
        },
      },
      {
        accessorKey: 'description',
        header(props) {
          return <DataTableColumnHeader title="Brief Description" column={props.column} />;
        },
        meta: {
          className: 'w-52',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {props.row.original.description}
            </div>
          );
        },
      },
      {
        accessorKey: 'logJobStatus',
        header(props) {
          return <DataTableColumnHeader title="Status" column={props.column} />;
        },
        meta: {
          className: 'w-32 flex gap-1.5 items-center',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <div
                className="size-3 rounded-full"
                style={{
                  backgroundColor: '#' + props.row.original.color,
                }}
              />
              <span>{props.row.original.logJobStatus}</span>
            </div>
          );
        },
      },
      {
        id: 'actions',
        meta: {
          className: 'w-32 flex items-center gap-2',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <Button onClick={() => setViewing(props.row.original)} size="icon" variant="outline">
                <MagnifyingGlassIcon />
              </Button>
            </div>
          );
        },
      },
    ];
    return viewedAsCustomer
      ? customerColumns
      : [
          {
            accessorKey: 'dateStarted',
            id: 'date_started',
            header(props) {
              return (
                <DataTableColumnHeader
                  title="Date / Time"
                  column={props.column}
                  hideVisibilityToggle
                />
              );
            },
            meta: {
              className: 'w-32',
            },
            cell(props) {
              const value =
                formatDate(props.row.original.dateStarted) +
                ' ' +
                formatTime(props.row.original.timeStarted) +
                ' - ' +
                formatTime(props.row.original.timeEnded);
              return <div className={props.column.columnDef.meta?.className}>{value}</div>;
            },
          },
          {
            id: 'log_jobStatus',
            accessorKey: 'logJobStatus',
            header(props) {
              return (
                <DataTableColumnHeader
                  title="Job Status"
                  column={props.column}
                  hideVisibilityToggle
                />
              );
            },
            meta: {
              className: 'w-32 flex gap-1.5 items-center',
            },
            cell(props) {
              return (
                <div className={props.column.columnDef.meta?.className}>
                  <div
                    className="size-3 rounded-full"
                    style={{
                      backgroundColor: '#' + props.row.original.color,
                    }}
                  />
                  <span>{props.row.original.logJobStatus}</span>
                </div>
              );
            },
          },
          {
            accessorKey: 'jobSubtype',
            id: 'job_type_id, job_subtype_id',
            header(props) {
              return (
                <DataTableColumnHeader
                  title="Job Type / Subtype"
                  column={props.column}
                  hideVisibilityToggle
                />
              );
            },
            meta: {
              className: 'w-40',
            },
            cell(props) {
              return (
                <div className={props.column.columnDef.meta?.className}>
                  {props.row.original.jobType +
                    (props.row.original.jobSubtype ? ' / ' + props.row.original.jobSubtype : '')}
                </div>
              );
            },
          },
          {
            id: 'id',
            accessorKey: 'id',
            header(props) {
              return (
                <DataTableColumnHeader
                  title="Job Number"
                  column={props.column}
                  hideVisibilityToggle
                />
              );
            },
            meta: {
              className: 'w-40',
            },
            cell(props) {
              return (
                <div className={props.column.columnDef.meta?.className}>
                  {props.row.original.id}
                </div>
              );
            },
          },
          {
            id: 'name',
            accessorKey: 'name',
            header(props) {
              return (
                <DataTableColumnHeader title="Name" column={props.column} hideVisibilityToggle />
              );
            },
            meta: {
              className: 'w-40 break-all line-clamp-2',
            },
            cell(props) {
              return (
                <div className={props.column.columnDef.meta?.className}>
                  {props.row.original.name}
                </div>
              );
            },
          },
          {
            id: 'description',
            accessorKey: 'description',
            header(props) {
              return (
                <DataTableColumnHeader
                  title="Description"
                  column={props.column}
                  hideVisibilityToggle
                />
              );
            },
            meta: {
              className: 'w-40',
            },
            cell(props) {
              const fullDescription = props.row.original.description;
              const isTruncated = fullDescription.length > 100;
              const displayDescription = isTruncated
                ? fullDescription.slice(0, 97) + '...'
                : fullDescription;

              return (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Badge
                        variant="outline"
                        className={`${props.column.columnDef.meta?.className} cursor-pointer whitespace-normal`}
                      >
                        {displayDescription}
                      </Badge>
                    </TooltipTrigger>
                    {isTruncated && (
                      <TooltipContent className="max-w-md p-2">
                        <p className="break-words">{fullDescription}</p>
                      </TooltipContent>
                    )}
                  </Tooltip>
                </TooltipProvider>
              );
            },
          },

          {
            id: 'contract_number',
            accessorKey: 'contractNumber',
            header(props) {
              return (
                <DataTableColumnHeader
                  title="Contract Number"
                  column={props.column}
                  hideVisibilityToggle
                />
              );
            },
            meta: {
              className: 'w-40',
            },
            cell(props) {
              return (
                <div className={props.column.columnDef.meta?.className}>
                  {props.row.original.contractNumber}
                </div>
              );
            },
          },
          {
            id: 'actions',
            meta: {
              className: 'w-52 flex items-center gap-2',
            },
            cell(props) {
              const entry = props.row.original;
              return (
                <div className={props.column.columnDef.meta?.className}>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipContent>Review</TooltipContent>
                      <TooltipTrigger asChild>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleReview(entry);
                          }}
                          size="icon"
                          variant="outline"
                        >
                          <MessageSquareDiff size={15} />
                        </Button>
                      </TooltipTrigger>
                    </Tooltip>

                    {canDoProposals && (
                      <Tooltip>
                        <TooltipContent>Create/Edit Proposal</TooltipContent>
                        <TooltipTrigger asChild>
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSetProposalEvent(entry);
                            }}
                            size="icon"
                            variant="outline"
                          >
                            <Receipt size={15} />
                          </Button>
                        </TooltipTrigger>
                      </Tooltip>
                    )}

                    <Tooltip>
                      <TooltipContent>Move</TooltipContent>
                      <TooltipTrigger asChild>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setMoving(entry);
                          }}
                          size="icon"
                          variant="outline"
                        >
                          <Redo size={15} />
                        </Button>
                      </TooltipTrigger>
                    </Tooltip>

                    {canDeleteJobs && <DeleteJobTableAction original={entry} />}

                    <Tooltip>
                      <TooltipContent>Create Sell sheet</TooltipContent>
                      <TooltipTrigger asChild>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSellSheetEntry(entry)();
                          }}
                          size="icon"
                          variant="outline"
                        >
                          <Receipt size={15} />
                        </Button>
                      </TooltipTrigger>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              );
            },
          },
        ];
  }, [
    canDoProposals,
    handleReview,
    handleSetProposalEvent,
    setMoving,
    setSellSheetEntry,
    setViewing,
    viewedAsCustomer,
    canDeleteJobs,
  ]);

  const loadingColumns = useGetLoadingColumns(tableColumns);
  const onSortingChange = useCallback<OnChangeFn<SortingState>>((updater) => {
    setSortingState(updater);
    setPage(0);
  }, []);

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    enableSorting: !viewedAsCustomer,
    columns: !loading ? tableColumns : loadingColumns,
    data: !loading ? jobsQuery.data?.results ?? [] : staticJobs,
    state: {
      sorting: sortingState,
    },
    onSortingChange,
  });

  const SCHEMA_CUSTOMER_ENTRY: Schema<Event> = [
    [
      {
        name: 'propertyId',
        label: 'Property',
        required: true,
        options: [
          { value: '0', label: OPTION_BLANK },
          ...(propertiesQuery.data?.results ?? []).map((p) => ({
            value: p.id,
            label: getPropertyAddress(p),
          })),
        ],
      },
    ],
    [
      {
        name: 'description',
        label: 'Service Requested',
        options: [
          { value: '0', label: OPTION_BLANK },
          ...[
            'A/C Repair',
            'A/C Maintenance',
            'Electrical Repair',
            'Free Replacement/Upgrade Estimate',
            'Pool Heater Repair',
            'Construction',
          ].map((value) => ({ value, label: value })),
        ],
        required: true,
      },
    ],
    [
      {
        name: 'dateStarted',
        label: 'Date Requested',
        type: 'date',
        required: true,
      },
      {
        name: 'timeStarted',
        label: 'Time Requested',
        type: 'time',
        required: true,
      },
    ],
    [
      {
        name: 'notes',
        label: 'Notes',
        multiline: true,
      },
    ],
  ];

  useEffect(() => {
    if (jobsQuery.data) {
      setTotalCount(jobsQuery.data.totalCount);
    }
  }, [jobsQuery.data]);

  const pageCount = Math.ceil(totalCount / ROWS_PER_PAGE);
  return (
    <div className={className}>
      <div className="flex flex-wrap items-center justify-between gap-2 bg-gray-200 p-2">
        <div className="flex flex-wrap items-center gap-2">
          <h2 className="text-2xl">{`${viewedAsCustomer ? 'Active ' : ''}Jobs`}</h2>
          {!!pageCount && (
            <DataTablePagination
              currentPage={page}
              pageCount={pageCount}
              setPage={setPage}
              className="max-w-max"
            />
          )}
          {!!totalCount && <p>Total count: {totalCount}</p>}
        </div>
        <div className="flex flex-wrap items-center gap-2">
          {viewedAsCustomer ? (
            <Button size="sm" onClick={setAddingCustomerEntry(makeCustomerEntry())}>
              Add Job
            </Button>
          ) : (
            <Button size="sm" onClick={handleServiceCallAddToggle}>
              Add Job
            </Button>
          )}
        </div>
      </div>

      <Form {...searchForm}>
        <form
          className="grid grid-cols-2 gap-2 p-2"
          onSubmit={searchForm.handleSubmit(console.log)}
        >
          <FormField
            control={searchForm.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={searchForm.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />
        </form>
      </Form>

      <div className="max-w-full overflow-auto">
        <DataTable
          table={table}
          onRowClick={loading ? undefined : viewedAsCustomer ? () => setViewing() : handleRowClick}
        />
      </div>
      {state.viewingEntry && (
        <Modal open onClose={() => setViewing()} fullScreen>
          <SectionBar
            title="Job Details"
            actions={[{ label: 'Close', onClick: () => setViewing() }]}
            fixedActions
          />
          <InfoTable
            data={[
              [
                {
                  label: 'Address',
                  value: getPropertyAddress(state.viewingEntry.property),
                },
              ],
              [
                {
                  label: 'Date/Time',
                  value: `${formatDate(state.viewingEntry.dateStarted)} ${formatTime(
                    state.viewingEntry.timeStarted,
                  )} - ${formatTime(state.viewingEntry.timeEnded)}`,
                },
              ],
              [
                {
                  label: 'Employee(s) Assigned',
                  value: state.viewingEntry.logTechnicianAssigned === '0' ? 'Unnassigned' : '...', // TODO
                },
              ],
              [
                {
                  label: 'Invoice Number',
                  value: state.viewingEntry.logJobNumber,
                },
              ],
              [
                {
                  label: 'Tracking Number',
                  value: state.viewingEntry.logPo,
                },
              ],
              [
                {
                  label: 'Description of Service Needed',
                  value: state.viewingEntry.description,
                },
              ],
              [
                {
                  label: 'Services Rendered',
                  value: state.viewingEntry.logServiceRendered, // TODO
                },
              ],
              ...(state.viewingEntry.notes
                ? [
                    [
                      {
                        label: 'Invoice Notes',
                        value: state.viewingEntry.notes,
                      },
                    ],
                  ]
                : []),
              ...(!!state.viewingEntry.servicesperformedrow1 || !!state.viewingEntry.totalamountrow1
                ? [
                    [
                      {
                        label: 'Services Performed (1)',
                        value: state.viewingEntry.servicesperformedrow1,
                      },
                      {
                        label: 'Total Amount (1)',
                        value: state.viewingEntry.totalamountrow1,
                      },
                    ],
                  ]
                : []),
              ...(!!state.viewingEntry.servicesperformedrow2 || !!state.viewingEntry.totalamountrow2
                ? [
                    [
                      {
                        label: 'Services Performed (2)',
                        value: state.viewingEntry.servicesperformedrow2,
                      },
                      {
                        label: 'Total Amount (2)',
                        value: state.viewingEntry.totalamountrow2,
                      },
                    ],
                  ]
                : []),
              ...(!!state.viewingEntry.servicesperformedrow3 || !!state.viewingEntry.totalamountrow3
                ? [
                    [
                      {
                        label: 'Services Performed (3)',
                        value: state.viewingEntry.servicesperformedrow3,
                      },
                      {
                        label: 'Total Amount (3)',
                        value: state.viewingEntry.totalamountrow3,
                      },
                    ],
                  ]
                : []),
              ...(!!state.viewingEntry.servicesperformedrow4 || !!state.viewingEntry.totalamountrow4
                ? [
                    [
                      {
                        label: 'Services Performed (4)',
                        value: state.viewingEntry.servicesperformedrow4,
                      },
                      {
                        label: 'Total Amount (4)',
                        value: state.viewingEntry.totalamountrow4,
                      },
                    ],
                  ]
                : []),
              ...(state.viewingEntry.materialUsed
                ? [
                    [
                      {
                        label: 'Material Used',
                        value: state.viewingEntry.materialUsed,
                      },
                    ],
                  ]
                : []),
              ...(+state.viewingEntry.discountcost !== 0
                ? [
                    [
                      {
                        label: 'Invoice Discount',
                        value: usd(+state.viewingEntry.discountcost),
                      },
                    ],
                  ]
                : []),
              ...(+state.viewingEntry.logAmountCharged !== 0
                ? [
                    [
                      {
                        label: 'Total Amount',
                        value: usd(+state.viewingEntry.logAmountCharged),
                      },
                    ],
                  ]
                : []),
            ]}
          />
        </Modal>
      )}
      {state.addingCustomerEntry && (
        <Modal open onClose={() => setAddingCustomerEntry(undefined)}>
          <FormOld
            title="Online Service Request"
            data={state.addingCustomerEntry}
            schema={SCHEMA_CUSTOMER_ENTRY}
            onClose={() => setAddingCustomerEntry(undefined)}
            onSave={handleCustomerAddEvent}
            intro={
              <div className="ServiceCallsCustomerIntro">
                The contact information from your online account will be used for this service
                request.
              </div>
            }
            disabled={state.saving}
          />
        </Modal>
      )}
      {state.confirmingAdded && (
        <Modal open onClose={toggleConfirmingAdded}>
          <SectionBar
            title="Thank you!"
            actions={[{ label: 'Close', onClick: toggleConfirmingAdded }]}
          />
          <div className="ServiceCallsCustomerThank">
            <p className="ServiceCallsCustomerThankIntro">
              Your service request is now active on our schedule.
            </p>
            <p>
              A Kalos Services representative will contact you shortly during regular business hours
              to confirm scheduling.
            </p>
            <p>
              For emergency requests please additionally call{' '}
              <Link href="tel:3522437088">352-243-7088</Link>.
            </p>
          </div>
        </Modal>
      )}
      {state.showText && (
        <Modal open onClose={() => handleSetState({ showText: undefined })} compact>
          <SectionBar
            actions={[
              {
                label: 'Close',
                onClick: () => handleSetState({ showText: undefined }),
              },
            ]}
          />
          <div style={{ padding: '1rem', maxWidth: 320 }}>{state.showText}</div>
        </Modal>
      )}
      {state.proposalEvent &&
        state.proposalEvent.customer != undefined &&
        state.proposalEvent.property != undefined && (
          <Modal
            open
            styles={{ minWidth: '60%' }}
            onClose={() => handleSetProposalEvent(undefined)}
          >
            <SectionBar
              title="Proposal"
              subtitle={`Proposal will be sent to ${state.proposalEvent.customer?.email}`}
              actions={[
                {
                  label: 'Close',
                  onClick: () => handleSetProposalEvent(undefined),
                },
              ]}
            >
              <Proposal
                className="mt-4"
                serviceItem={state.proposalEvent}
                property={state.proposalEvent.property!}
                customer={state.proposalEvent.customer!}
                servicesRendered={state.proposalEventPropData}
                reload={() => handleSetProposalEvent(undefined)}
              />
            </SectionBar>
          </Modal>
        )}
      {state.addingServiceCall && (
        <AddServiceCall
          propertyId={propertyId}
          userId={userID}
          openServiceCall={true}
          onClose={() => {
            handleSetState({ addingServiceCall: false });
            queryClient.invalidateQueries({ queryKey: [queryKeys.events.root] });
          }}
        />
      )}
      {state.sellSheetEntry && (
        <Modal
          open
          onClose={() => {
            handleSetState({ sellSheetEntry: undefined });
          }}
        >
          <SectionBar
            title="Sell Sheet/ AC Quote"
            actions={[
              {
                label: 'Close',
                onClick: () => handleSetState({ sellSheetEntry: undefined }),
              },
            ]}
          >
            <SellSheet
              onCreateDoc={onCreateDoc}
              propertyId={state.sellSheetEntry.propertyId!}
              jobNumber={state.sellSheetEntry.id}
            />
          </SectionBar>
        </Modal>
      )}
      {state.movingEntry && (
        <Modal
          open={state.movingEntry != undefined}
          onClose={() => {
            handleSetState({ movingEntry: undefined });
          }}
        >
          <SectionBar
            title="Select Property To Move"
            actions={[
              {
                label: 'Close',
                onClick: () => handleSetState({ movingEntry: undefined }),
              },
            ]}
          >
            <PropertiesSearch
              exceptionId={propertyId}
              onSelectProperty={(data) => {
                handleSetState({ movingEntry: undefined });
                handleMoveJobToProperty(data);
              }}
            />
          </SectionBar>
        </Modal>
      )}
      {state.editingServiceCall && (
        <Modal
          open
          onClose={() => {
            handleSetState({ editingServiceCall: false });
            queryClient.invalidateQueries({ queryKey: [queryKeys.events.root] });
          }}
          fullScreen
        >
          <ServiceRequest
            propertyId={propertyId!}
            serviceCallId={state.serviceCallId}
            onClose={() => {
              handleSetState({ editingServiceCall: false });
              queryClient.invalidateQueries({ queryKey: [queryKeys.events.root] });
            }}
          />
        </Modal>
      )}
    </div>
  );
};

function DeleteJobTableAction({ original }: { original: Event }) {
  const confirm = useConfirm();
  const deleteEventMutation = useEventDeleteMutation();

  const handleDelete = useCallback(async () => {
    if (
      !(await confirm({
        title: 'Are you sure you want to delete this job?',
        body: `${original.jobType}${original.jobSubtype ? ' / ' + original.jobSubtype : ''} ${formatDate(original.dateStarted)} ${formatTime(original.timeStarted)} ${formatTime(original.timeEnded)}`,
      }))
    )
      return;
    try {
      await deleteEventMutation.mutateAsync(original);
      toast({
        title: 'Job deleted successfully',
        variant: 'success',
      });
    } catch {
      toast({
        title: 'An error occurred while deleting the job',
        variant: 'destructive',
      });
    }
  }, [confirm, deleteEventMutation, original]);

  return (
    <Tooltip>
      <TooltipContent>Delete</TooltipContent>
      <TooltipTrigger asChild>
        <Button
          isLoading={deleteEventMutation.isPending}
          disabled={deleteEventMutation.isPending}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
          size="icon"
          variant="outline"
        >
          <TrashIcon />
        </Button>
      </TooltipTrigger>
    </Tooltip>
  );
}
