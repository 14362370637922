import { BaseClient } from "../BaseClient";
import { type AssignUserToQueueRequest, type GetCallQueuesRequest } from "../compiled-protos/gotoadmin";
import { GoToAdminServiceClient } from "../compiled-protos/gotoadmin.client";

export class GoToAdminClient extends BaseClient {
  self: GoToAdminServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new GoToAdminServiceClient(this.transport);
  }

  public async AssignUserToQueue(req: AssignUserToQueueRequest) {
    const res = await this.self.assignUserToQueue(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async GetCallQueues(req: GetCallQueuesRequest) {
    const res = await this.self.getCallQueues(req, this.getMetaDataWithoutCache()).response;
    return res;
  }
}
