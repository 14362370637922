import { ToolFundTransaction, ToolFundTransactionType } from '@kalos/kalos-rpc';
import { type ToolFundTransactionTypeList } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { InventoryClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type ToolFundTransactionFilter = EntityFilter<ToolFundTransaction>;

export const getToolFundTransactionQueryKeys = (filter: ToolFundTransactionFilter) => [
  queryKeys.toolFundTransaction.root,
  queryKeys.toolFundTransaction.getHash(filter),
];

// Get single transaction
export const useToolFundTransactionQuery = ({
  filters = {},
  enabled = true,
}: {
  filters?: ToolFundTransactionFilter;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: getToolFundTransactionQueryKeys(filters),
    queryFn: () => {
      const req = ToolFundTransaction.create(filters);
      return InventoryClientService.GetToolFundTransaction(req);
    },
    enabled,
  });
};

// Batch get transactions
export const useBatchGetToolFundTransactionQuery = ({
  filters = {},
  enabled = true,
}: {
  filters?: ToolFundTransactionFilter;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.toolFundTransaction.root,
      queryKeys.toolFundTransaction.list,
      queryKeys.toolFundTransaction.getHash(filters),
    ],
    queryFn: async () => {
      const req = ToolFundTransaction.create(filters);
      return await InventoryClientService.BatchGetToolFundTransaction(req);
    },
    enabled,
  });
};

// Create transaction mutation
export const useToolFundTransactionCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (transaction: ToolFundTransaction) => {
      return await InventoryClientService.CreateToolFundTransaction(transaction);
    },
    onSuccess: async () => {
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: [queryKeys.toolFundTransaction.root] }),
        queryClient.invalidateQueries({ queryKey: [queryKeys.toolFundBalance.root] }),
      ]);
    },
  });
};

// Update transaction mutation
export const useToolFundTransactionUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (transaction: ToolFundTransaction) => {
      return await InventoryClientService.UpdateToolFundTransaction(transaction);
    },
    onSuccess: async () => {
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: [queryKeys.toolFundTransaction.root] }),
        queryClient.invalidateQueries({ queryKey: [queryKeys.toolFundBalance.root] }),
      ]);
    },
  });
};

// Delete transaction mutation
export const useToolFundTransactionDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (transaction: ToolFundTransaction) => {
      return await InventoryClientService.DeleteToolFundTransaction(transaction);
    },
    onSuccess: async () => {
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: [queryKeys.toolFundTransaction.root] }),
        queryClient.invalidateQueries({ queryKey: [queryKeys.toolFundBalance.root] }),
      ]);
    },
  });
};

// Add this query for transaction types
export const useBatchGetToolFundTransactionTypeQuery = <
  TSelectData = ToolFundTransactionTypeList,
>({
  filters = {},
  enabled = true,
  select,
}: {
  filters?: EntityFilter<ToolFundTransactionType>;
  enabled?: boolean;
  select?: (data: ToolFundTransactionTypeList) => TSelectData;
} = {}) => {
  return useQuery({
    queryKey: [
      queryKeys.toolFundTransaction.root,
      queryKeys.toolFundTransaction.transactionTypes,
      queryKeys.toolFundTransaction.getTransactionTypesHash(filters),
    ],
    queryFn: async () => {
      const req = ToolFundTransactionType.create(filters);
      return await InventoryClientService.BatchGetToolFundTransactionType(req);
    },
    enabled,
    select,
  });
};
