import { Document, URLObject } from '@kalos/kalos-rpc';
import {
  Button,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useConfirm,
} from '@kalos/ui';
import {
  DownloadIcon,
  EnvelopeClosedIcon,
  ExternalLinkIcon,
  Pencil1Icon,
  TrashIcon,
} from '@radix-ui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import { z } from 'zod';

import { Prompt } from '../../../components/Prompt';
import { queryKeys } from '../../../hooks/react-query/constants';
import {
  useDeleteDocumentMutation,
  useDocumentUpdateMutation,
} from '../../../hooks/react-query/useDocumentsQuery';
import { S3ClientService } from '../../../tools/helpers';

const handleOpenInNewTab =
  (filename: string, type: number) =>
  async (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const res = filename.match(/\d{2}-(\d{3,})[A-z]?-/);
    if (filename.toLowerCase().startsWith('maintenance') && res) {
      // TODO
      alert('replace with modal to view maintenance sheet');
    } else {
      const url = URLObject.create();
      url.key = filename;
      url.bucket = type === 5 ? 'testbuckethelios' : 'kalosdocs-prod';
      const dlURL = await S3ClientService.GetDownloadURL(url);
      window.open(dlURL!.url, '_blank');
    }
  };
const updateFileNameSchema = z.string().min(1, 'Filename cannot be empty');

export const DocumentsTableActions = ({
  original,
  withDownloadIcon,
  renderEditing,
  deletable,
  handleToggleEditing,
  handleOpenModal,
}: {
  original: Document;
  withDownloadIcon: boolean;
  renderEditing: boolean;
  deletable: boolean;
  handleToggleEditing: (doc?: Document) => void;
  handleOpenModal: (doc: Document) => void;
}) => {
  const handleDownload = useCallback(async (filename: string, type: number) => {
    try {
      await S3ClientService.download(filename, type === 5 ? 'testbuckethelios' : 'kalosdocs-prod');
    } catch (err) {
      console.error(`An error occurred while getting the download URL: ${err}`);
    }
  }, []);
  const queryClient = useQueryClient();
  const deleteDocumentMutation = useDeleteDocumentMutation();
  const confirm = useConfirm();

  const handleDelete = useCallback(
    async (entry: Document) => {
      if (
        !(await confirm({
          title: 'Delete Document',
          body: 'Are you sure you want to delete this document?',
        }))
      ) {
        return;
      }

      try {
        await deleteDocumentMutation.mutateAsync({
          document: entry,
          bucket: entry.type === 5 ? 'testbuckethelios' : 'kalosdocs-prod',
        });
        toast({
          title: 'Document deleted successfully',
          variant: 'success',
        });
      } catch (e) {
        queryClient.invalidateQueries({
          queryKey: [queryKeys.documents.root],
        });
        console.error(
          `An error was caught while deleting an S3 bucket entry and deleting a document: ${e}`,
        );
        toast({
          title: 'An error occurred while deleting the document',
          variant: 'destructive',
        });
      }
    },
    [confirm, deleteDocumentMutation, queryClient],
  );

  const updateDocumentMutation = useDocumentUpdateMutation();
  const [isEditingFileName, setIsEditingFileName] = useState(false);
  const handleEditFilename = useCallback(
    async ({ prompt }: { prompt: string }) => {
      const req = Document.create();
      req.id = original.id;
      req.description = prompt;
      req.fieldMask = ['Description'];
      try {
        await updateDocumentMutation.mutateAsync(req);
        setIsEditingFileName(false);
        toast({
          title: 'Document updated successfully',
          variant: 'success',
        });
      } catch {
        toast({
          title: 'An error occurred while updating the document',
          variant: 'destructive',
        });
      }
    },
    [original.id, updateDocumentMutation],
  );

  const isUpdating = updateDocumentMutation.isPending;
  const isDeleting = deleteDocumentMutation.isPending;
  const isDoingAction = isUpdating || isDeleting;
  return (
    <TooltipProvider delayDuration={150}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            disabled={isDoingAction}
            onClick={handleOpenInNewTab(original.filename, original.type)}
          >
            <ExternalLinkIcon />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Open in other tab</TooltipContent>
      </Tooltip>

      {withDownloadIcon && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              onClick={() => handleDownload(original.filename, original.type)}
              disabled={isDoingAction}
            >
              <DownloadIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Download</TooltipContent>
        </Tooltip>
      )}

      {renderEditing && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              onClick={() => handleToggleEditing(original)}
              disabled={isDoingAction}
            >
              <Pencil1Icon />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Edit</TooltipContent>
        </Tooltip>
      )}
      {deletable && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              isLoading={isDeleting}
              variant="outline"
              size="icon"
              onClick={() => handleDelete(original)}
              disabled={isDoingAction}
            >
              <TrashIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Delete</TooltipContent>
        </Tooltip>
      )}
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            onClick={() => handleOpenModal(original)}
            disabled={isDoingAction}
          >
            <EnvelopeClosedIcon />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Email Document</TooltipContent>
      </Tooltip>

      <Tooltip>
        <TooltipContent>Edit Filename</TooltipContent>
        <Prompt
          title="Update Filename"
          isLoading={isUpdating}
          trigger={
            <TooltipTrigger asChild>
              <Button variant="outline" size="icon">
                <Pencil1Icon />
              </Button>
            </TooltipTrigger>
          }
          label="Filename"
          open={isEditingFileName}
          onOpenChange={setIsEditingFileName}
          defaultValue={original.description}
          promptSchema={updateFileNameSchema}
          onSubmit={handleEditFilename}
        />
      </Tooltip>
    </TooltipProvider>
  );
};
