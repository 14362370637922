import { Direction } from '@kalos/kalos-rpc';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@kalos/ui';
import { useMemo, useState } from 'react';

import { useSoftPhoneWidgetStore } from '../../../Softphone/context/SoftPhoneWidgetStore';
import { useGetCallHistoryQuery } from '../../../Softphone/hooks/useGetCallHistoryQuery';
import { useCallStore } from '../../../Softphone/SoftPhoneWidget/components/CallStore';
import { CallLogTable } from './CallLogTable';

type TabValue = 'ALL' | `${Direction}`;

const TAB_VALUES: TabValue[] = ['ALL', `${Direction.INBOUND}`, `${Direction.OUTBOUND}`];

const formatOutgoingNumber = (number: string): string => {
  const digits = number.replace(/\D/g, '');
  if (!digits.startsWith('1') && digits.length > 4) {
    return `+1${digits}`;
  }
  return `+${digits}`;
};

const ITEMS_PER_PAGE = 10;

export const CallLog = () => {
  const [cursor, setCursor] = useState<string>('');
  const { data: callHistory, isLoading } = useGetCallHistoryQuery({
    filter: {
      pageSize: ITEMS_PER_PAGE,
      pageMarker: cursor,
    },
  });

  const [previousCursors, setPreviousCursors] = useState<string[]>([]);

  const handleNextPage = () => {
    if (callHistory?.nextPageMarker) {
      setPreviousCursors((prev) => [...prev, cursor]);
      setCursor(callHistory.nextPageMarker);
    }
  };

  const handlePreviousPage = () => {
    const newPreviousCursors = [...previousCursors];
    const previousCursor = newPreviousCursors.pop() || '';
    setPreviousCursors(newPreviousCursors);
    setCursor(previousCursor);
  };

  const filteredCallsByType = useMemo(() => {
    const items = callHistory?.items ?? [];

    return TAB_VALUES.reduce(
      (acc, type) => {
        if (type === 'ALL') {
          acc[type] = items;
        } else {
          acc[type] = items.filter((call) => `${call.direction}` === type);
        }
        return acc;
      },
      {} as Record<TabValue, typeof items>,
    );
  }, [callHistory]);

  const setOutboundNumber = useCallStore.use.setOutboundNumber();
  const setIsOpen = useSoftPhoneWidgetStore.use.setIsOpen();

  const handleInitiateCall = (phoneNumber: string) => {
    if (phoneNumber.length < 10) return;
    const formattedNumber = formatOutgoingNumber(phoneNumber);
    setOutboundNumber(formattedNumber);
    setIsOpen(true);
  };

  const handleViewJob = (jobId: string) => {
    console.log('Navigating to job:', jobId);
  };

  return (
    <div className="flex flex-col gap-4">
      <Tabs defaultValue="ALL" className="w-full">
        <TabsList>
          <TabsTrigger value="ALL">All</TabsTrigger>
          <TabsTrigger value={`${Direction.INBOUND}`}>Inbound</TabsTrigger>
          <TabsTrigger value={`${Direction.OUTBOUND}`}>Outbound</TabsTrigger>
        </TabsList>

        {TAB_VALUES.map((tabValue) => (
          <TabsContent key={tabValue} value={tabValue}>
            <CallLogTable
              calls={filteredCallsByType[tabValue] || []}
              onInitiateCall={handleInitiateCall}
              onViewJob={handleViewJob}
              isLoading={isLoading}
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
              hasNextPage={!!callHistory?.nextPageMarker}
              hasPreviousPage={previousCursors.length > 0}
            />
          </TabsContent>
        ))}
      </Tabs>
    </div>
  );
};
