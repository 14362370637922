import {
  ClassCode,
  CostReportReq,
  getPropertyAddress,
  NULL_TIME,
  type OrderDir,
  PerDiem,
  PerDiemRow,
  Task,
  TimesheetLine,
  Transaction,
  TransactionAccount,
  Trip,
  User,
} from '@kalos/kalos-rpc';
import { Dialog, DialogContent } from '@kalos/ui';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { default as Button } from '@mui/material/Button';
import { default as Collapse } from '@mui/material/Collapse/Collapse';
import { differenceInMinutes, endOfWeek, format, parseISO, startOfWeek } from 'date-fns';
import { type ComponentProps, type FC, Fragment, useCallback, useEffect, useReducer } from 'react';

import { IRS_SUGGESTED_MILE_FACTOR } from '../../../constants';
import {
  ClassCodeClientService,
  downloadCSV,
  EventClientService,
  formatDate,
  roundNumber,
  TimesheetDepartmentClientService,
  TransactionAccountClientService,
  usd,
  UserClientService,
} from '../../../tools/helpers';
import { TransactionFilesGallery } from '../../AltGallery/main';
import { Loader } from '../../Loader/main';
import { Button as KalosButton } from '../Button';
import { InfoTable } from '../InfoTable';
import { PrintList } from '../PrintList';
import { PrintPage } from '../PrintPage';
import { PrintParagraph } from '../PrintParagraph';
import { PrintTable } from '../PrintTable';
import { SectionBar } from '../SectionBar';
import { Tabs } from '../Tabs';
import { type DataList } from '../Timesheet/reducer';
import { EmployeeSummaryView } from './EmployeeSummaryView';
import { ACTIONS, reducer, type WeekClassCodeBreakdownSubtotal } from './reducer';

export interface Props {
  serviceCallId: number;
  onClose?: () => void;
  title?: ComponentProps<typeof SectionBar>['title'];
  loader?: React.ReactNode;
}

export type SearchType = {
  technicians: string;
  statusId: number;
  priorityId: number;
};
export type Week = {
  weekStart: string;
  weekEnd: string;
  code: number;
};
export const GetTotalTransactions = (transactions: Transaction[]) => {
  return transactions.reduce((aggr, txn) => aggr + txn.amount, 0);
};

const tabs = ['JobDetails', 'Transactions', 'PerDiems', 'Timesheets', 'Trips', 'Spiffs'] as const;

const transformTimesheetsToDataList = (timesheets: TimesheetLine[]): DataList => {
  const dataList: DataList = {};

  timesheets.forEach((timesheet) => {
    if (!timesheet.timeStarted) return;
    const date = timesheet.timeStarted.split(' ')[0];
    if (!dataList[date]) {
      dataList[date] = {
        servicesRenderedList: [],
        timesheetLineList: [],
        payroll: { total: 0, billable: 0, unbillable: 0 },
        timeoffs: [],
      };
    }
    dataList[date].timesheetLineList.push(timesheet);
  });

  return dataList;
};

export const CostReportCSV: FC<Props> = ({ serviceCallId, onClose, title, loader }) => {
  const [state, dispatch] = useReducer(reducer, {
    loaded: false,
    loading: true,
    perDiems: [],
    timesheets: [],
    transactions: [],
    users: [],
    costCenterTotals: {},
    loadingEvent: true,
    loadedInit: false,
    event: undefined,
    laborTotals: {},
    transactionSort: { sortBy: 'amount', sortDir: 'ASC' },
    laborTotalsDropDownActive: false,
    transactionAccounts: [],
    trips: [],
    costCenterDropDownActive: false,
    tripsTotal: 0,
    tasks: [],
    classCodes: [],
    dropDowns: [],
    transactionDropDowns: [],
    classCodeDropdowns: [],
    timesheetWeeklySubtotals: [],
    totalHoursWorked: 0,
    activeTab: tabs[0],
    printStatus: 'idle' as const,
    openGalleryId: undefined,
    showEmployeeSummary: false,
  });

  const handlePrint = useCallback(async () => {
    dispatch({ type: ACTIONS.SET_PRINT_STATUS, data: 'loading' });
    dispatch({ type: ACTIONS.SET_PRINT_STATUS, data: 'loaded' });
  }, []);
  const handlePrinted = useCallback(
    () => dispatch({ type: ACTIONS.SET_PRINT_STATUS, data: 'idle' }),
    [],
  );
  const loadEvent = useCallback(async () => {
    dispatch({ type: ACTIONS.SET_LOADING_EVENT, data: true });

    const event = await EventClientService.LoadEventByServiceCallID(serviceCallId);
    if (event) {
      dispatch({ type: ACTIONS.SET_EVENT, data: event });
      dispatch({ type: ACTIONS.SET_LOADING_EVENT, data: false });
    }
  }, [serviceCallId]);

  const loadInit = useCallback(async () => {
    await loadEvent();
    dispatch({ type: ACTIONS.SET_LOADED_INIT, data: true });
  }, [loadEvent]);
  const sortTransactions = useCallback(
    (transactions: Transaction[], sortDir: OrderDir, sortBy: string) => {
      let temp = transactions;
      if (sortBy === 'timestamp') {
        temp = temp.sort((a, b) => {
          const dateA = new Date(a.timestamp.split(' ')[0]);
          const dateB = new Date(b.timestamp.split(' ')[0]);

          if (sortDir === 'ASC') {
            return dateA.valueOf() - dateB.valueOf();
          } else {
            return dateB.valueOf() - dateA.valueOf();
          }
        });
      }

      if (sortBy === 'amount') {
        temp = temp.sort((a, b) => {
          if (sortDir === 'ASC') {
            return a.amount - b.amount;
          } else {
            return b.amount - a.amount;
          }
        });
      }

      if (sortBy === 'cost_center_id') {
        temp = temp.sort((a, b) => {
          if (sortDir === 'ASC') {
            return a.costCenterId - b.costCenterId;
          } else {
            return b.costCenterId - a.costCenterId;
          }
        });
      }

      if (sortBy === 'job_number') {
        temp = temp.sort((a, b) => {
          if (sortDir === 'ASC') {
            return a.jobId - b.jobId;
          } else {
            return b.jobId - a.jobId;
          }
        });
      }
      if (sortBy === 'owner_name') {
        temp = temp.sort((a, b) => {
          if (sortDir === 'ASC') {
            const splitA = a.ownerName.split(' ');
            const splitB = b.ownerName.split(' ');
            const lastA = splitA[splitA.length - 1].toLowerCase();
            const lastB = splitB[splitB.length - 1].toLowerCase();
            const firstA = splitA[0].toLowerCase();
            const firstB = splitB[0].toLowerCase();

            if (lastA + firstA < lastB + firstB) {
              return -1;
            }

            if (lastA + firstA > lastB + firstB) {
              return 1;
            }
            return 0;
          } else {
            const splitA = a.ownerName.split(' ');
            const splitB = b.ownerName.split(' ');
            const lastA = splitA[splitA.length - 1].toLowerCase();
            const lastB = splitB[splitB.length - 1].toLowerCase();
            const firstA = splitA[0].toLowerCase();
            const firstB = splitB[0].toLowerCase();

            if (lastA + firstA > lastB + firstB) {
              return -1;
            }

            if (lastA + firstA < lastB + firstB) {
              return 1;
            }
            return 0;
          }
        });
      }
      if (sortBy === 'description') {
        temp = temp.sort((a, b) => {
          if (sortDir === 'ASC') {
            return a.vendor.localeCompare(b.vendor);
          } else {
            return b.vendor.localeCompare(a.vendor);
          }
        });
      }
      return temp;
    },
    [],
  );
  const load = useCallback(async () => {
    const promises: Promise<void>[] = [];
    let timesheets: TimesheetLine[] = [];
    let classCodes: ClassCode[] = [];
    let perDiems: PerDiem[] = [];
    let transactions: Transaction[] = [];
    let trips: Trip[] = [];
    let tasks: Task[] = [];

    dispatch({ type: ACTIONS.SET_LOADING, data: true });
    promises.push(
      new Promise<void>(async (resolve) => {
        try {
          const classCodeReq = ClassCode.create();

          classCodes = (await ClassCodeClientService.BatchGet(classCodeReq))!.results;
          dispatch({
            type: ACTIONS.SET_CLASS_CODES,
            data: classCodes,
          });
          const mappedResults = classCodes.map((code) => ({
            classCodeId: code.id,
            active: 0,
          }));
          dispatch({
            type: ACTIONS.SET_CLASS_CODE_DROPDOWNS,
            data: mappedResults,
          });
          resolve();
        } catch (err) {
          console.log('error fetching class codes', err);
        }
      }),
    );
    promises.push(
      new Promise<void>(async (resolve) => {
        try {
          const userReq = User.create();
          userReq.isEmployee = 1;
          userReq.overrideLimit = true;
          const users = (await UserClientService.BatchGet(userReq)).results;
          dispatch({
            type: ACTIONS.SET_USERS,
            data: users,
          });
          resolve();
        } catch (err) {
          console.log('error fetching users', err);
        }
      }),
    );
    promises.push(
      new Promise<void>(async (resolve) => {
        try {
          const perDiemReq = PerDiem.create();
          perDiemReq.withRows = true;
          perDiemReq.isActive = true;
          perDiemReq.withoutLimit = true;
          perDiemReq.payrollProcessed = true;
          const row = PerDiemRow.create();
          row.serviceCallId = serviceCallId;
          perDiemReq.referenceRow = row;
          const taskReq = Task.create();
          taskReq.eventId = serviceCallId;
          taskReq.billable = 1;
          taskReq.isActive = true;
          taskReq.withoutLimit = true;
          const timesheetReq = TimesheetLine.create();
          //Currently using custom sql, so only Reference number matters
          timesheetReq.withoutLimit = true;
          timesheetReq.referenceNumber = serviceCallId.toString();
          timesheetReq.isActive = 1;
          timesheetReq.orderBy = 'time_started';
          timesheetReq.orderDir = 'ASC';
          const tripReq = Trip.create();
          tripReq.isActive = true;
          tripReq.jobNumber = serviceCallId;
          tripReq.approved = true;
          tripReq.withoutLimit = true;
          const transactionReq = Transaction.create();
          transactionReq.jobId = serviceCallId;
          transactionReq.isActive = 1;
          transactionReq.withoutLimit = true;
          const reportReq = CostReportReq.create();
          reportReq.perDiemReq = perDiemReq;
          reportReq.taskReq = taskReq;
          reportReq.timesheetReq = timesheetReq;
          reportReq.transactionReq = transactionReq;
          reportReq.tripReq = tripReq;

          try {
            const allResults = await EventClientService.BatchGetCostReportData(reportReq);
            if (allResults) {
              if (allResults.perDiemResults !== undefined) {
                perDiems = allResults.perDiemResults.results;
              }
              if (allResults.taskResults !== undefined) {
                tasks = allResults.taskResults.results;
              }
              if (allResults.timesheetResults !== undefined) {
                timesheets = allResults.timesheetResults.results;
              }
              if (allResults.tripResults !== undefined) {
                trips = allResults.tripResults.results;
              }
              if (allResults.transactionResults !== undefined) {
                transactions = allResults.transactionResults.results;
              }
            }
          } catch (err) {
            console.log('error with fetch data function:', err);
          }

          resolve();
        } catch (err) {
          console.log('error fetching data', err);
        }
      }),
    );
    promises.push(
      new Promise<void>(async (resolve) => {
        const accountReq = TransactionAccount.create();

        try {
          const accountRes = (await TransactionAccountClientService.BatchGet(accountReq))!.results;
          dispatch({
            type: ACTIONS.SET_TRANSACTION_ACCOUNTS,
            data: accountRes,
          });

          const costCenterMap = accountRes.map((account) => ({
            costCenterId: account.id,
            active: 0,
          }));
          dispatch({
            type: ACTIONS.SET_TRANSACTION_DROPDOWNS,
            data: costCenterMap,
          });
          resolve();
        } catch (err) {
          console.log('error fetching accounts,', err);
        }
      }),
    );
    Promise.all(promises).then(async () => {
      console.log('all promises executed without error, setting loaded');

      let allTripsTotal = 0;
      trips.forEach((trip) => {
        // Subtracting 30 miles flat from trip distance in accordance

        allTripsTotal += trip.distanceInMiles * IRS_SUGGESTED_MILE_FACTOR;
      });
      const costCenterTemp: { [key: string]: number } = {};
      for (let i = 0; i < transactions.length; i++) {
        const keyValue = `${transactions[i].costCenterId}-${transactions[i].costCenter?.description}`;
        if (costCenterTemp[keyValue]) {
          costCenterTemp[keyValue] += transactions[i].amount;
        } else {
          //
          costCenterTemp[keyValue] = transactions[i].amount;
        }
      }
      dispatch({ type: ACTIONS.SET_COST_CENTER_TOTALS, data: costCenterTemp });

      dispatch({ type: ACTIONS.SET_TRIPS_TOTAL, data: allTripsTotal });
      const mappedResults = perDiems.map((perdiem) => ({
        perDiemId: perdiem.id,
        active: 0,
      }));
      dispatch({ type: ACTIONS.SET_DROPDOWNS, data: mappedResults });
      const arr: PerDiem[] = [];
      perDiems.forEach((result) => {
        let isIncluded = false;
        arr.forEach((arrItem) => {
          if (arrItem.id == result.id) isIncluded = true;
        });
        if (!isIncluded) {
          arr.push(result);
        }
      });
      for (let i = 0; i < arr.length; i++) {
        const tempRowList = arr[i].rows.filter((row) => row.serviceCallId === serviceCallId);
        arr[i].rows = tempRowList;
      }
      for (let i = 0; i < timesheets.length; i++) {
        timesheets[i].hoursWorked = roundNumber(
          differenceInMinutes(
            parseISO(timesheets[i].timeFinished),
            parseISO(timesheets[i].timeStarted),
          ) / 60,
        );
      }

      if (timesheets.length > 0) {
        const weekList: WeekClassCodeBreakdownSubtotal[] = [];

        for (let i = 0; i < timesheets.length; i++) {
          let startWeek = new Date(parseISO(timesheets[i].timeStarted));
          startWeek = startOfWeek(startWeek, { weekStartsOn: 6 });
          let endWeek = new Date(parseISO(timesheets[i].timeStarted));
          endWeek = endOfWeek(endWeek, { weekStartsOn: 6 });
          const weekStruct = {
            weekStart: format(startWeek, 'yyyy-MM-dd'),
            weekEnd: format(endWeek, 'yyyy-MM-dd'),
            employeeId: timesheets[i].technicianUserId,
            hoursSubtotal: timesheets[i].hoursWorked,
            classCodeId: timesheets[i].classCodeId,
          };
          const findExisting = weekList.findIndex(
            (week) =>
              week.classCodeId == weekStruct.classCodeId &&
              week.weekEnd == weekStruct.weekEnd &&
              week.weekStart === weekStruct.weekStart &&
              week.employeeId == weekStruct.employeeId,
          );
          if (findExisting != -1) {
            weekList[findExisting].hoursSubtotal += weekStruct.hoursSubtotal;
          } else {
            weekList.push(weekStruct);
          }
        }
        dispatch({
          type: ACTIONS.SET_TIMESHEET_WEEKLY_SUBTOTALS,
          data: weekList,
        });
      }

      const laborTemp = state.laborTotals;
      for (let i = 0; i < timesheets.length; i++) {
        const keyValue = timesheets[i].classCodeId.toString();
        if (laborTemp[keyValue]) {
          laborTemp[keyValue] += timesheets[i].hoursWorked;
        } else {
          laborTemp[keyValue] = timesheets[i].hoursWorked;
        }
      }
      dispatch({ type: ACTIONS.SET_LABOR_TOTALS, data: laborTemp });

      let total = 0;
      timesheets.forEach((timesheet) => (total = total + timesheet.hoursWorked));
      dispatch({
        type: ACTIONS.SET_ALL_DATA,
        data: {
          timesheetRes: timesheets,
          transactionRes: sortTransactions(transactions, 'ASC', 'amount'),
          perDiemRes: arr,
          taskRes: tasks,
          tripRes: trips,
        },
      });

      dispatch({ type: ACTIONS.SET_TOTAL_HOURS_WORKED, data: total });
      dispatch({ type: ACTIONS.SET_LOADING, data: false });
      dispatch({ type: ACTIONS.SET_LOADED, data: true });
    });
  }, [serviceCallId, state.laborTotals, sortTransactions]);
  const createReport = (section: string) => {
    const totalMeals = state.perDiems.reduce((aggr, pd) => aggr + pd.amountProcessedMeals, 0);
    const totalLodging = state.perDiems.reduce((aggr, pd) => aggr + pd.amountProcessedLodging, 0);
    const totalTransactions = state.transactions.reduce((aggr, pd) => aggr + pd.amount, 0);
    let fullString = '';
    const find = ',';
    const re = new RegExp(find, 'g');
    if (section === 'JobDetails' && state.event) {
      fullString = ' Type , Cost + `\r\n`';

      const address = getPropertyAddress(state.event.property).replace(re, '');
      fullString =
        fullString +
        address +
        ',' +
        formatDate(state.event!.dateStarted) +
        formatDate(state.event!.dateEnded) +
        `\r\n`;
      const costString =
        'Type, Total \r\n Total Hours Worked ,' +
        state.totalHoursWorked +
        ' \r\n Transactions,' +
        totalTransactions +
        '\r\n' +
        'Meals,' +
        totalMeals +
        '\r\n' +
        'Lodging,' +
        totalLodging +
        '\r\n' +
        'Spiffs,' +
        totalTasksBillable +
        '\r\nTrips Total,' +
        state.tripsTotal +
        '\r\n ';

      fullString = costString;
    }
    if (section == 'Timesheets') {
      fullString =
        ' Technician,Department,Approved By,Time Started,Time Ended,Description,Hours,Notes' +
        `\r\n`;
      for (let i = 0; i < state.timesheets.length; i++) {
        const t = state.timesheets[i];
        const tempString =
          t.technicianUserName.replace(',', ' ') +
          ',' +
          t.departmentName.replace(',', ' ') +
          ',' +
          t.adminApprovalUserName.replace(',', ' ') +
          ',' +
          t.timeStarted.replace(',', ' ') +
          ',' +
          t.timeFinished.replace(',', ' ') +
          ',' +
          t.briefDescription.replace(',', ' ') +
          ',' +
          t.hoursWorked +
          ',' +
          t.notes.replace(',', '') +
          `\r\n`;
        fullString = fullString + tempString;
      }
    }
    if (section == 'Transactions' && state.transactions) {
      fullString =
        'Transaction Report' +
        `\r\n` +
        ' Department,Owner,Cost Center / Vendor,Date,Amount,Notes' +
        `\r\n`;
      for (let i = 0; i < state.transactions.length; i++) {
        const t = state.transactions[i];
        const tempString =
          t.department?.classification.replace(',', ' ') +
          '-' +
          t.department?.description.replace(',', ' ') +
          ',' +
          t.ownerName.replace(',', '') +
          ',' +
          t.costCenter?.description +
          '-' +
          t.vendor.replace(',', ' ') +
          ',' +
          formatDate(t.timestamp) +
          ',' +
          usd(t.amount) +
          ',' +
          t.notes.replace(',', ' ') +
          `\r\n`;
        fullString = fullString + tempString;
      }
    }
    if (section == 'PerDiems' && state.perDiems) {
      fullString =
        ' Department,Owner,Submitted Date,Approved By,Approved Date,Total Meals,Total Lodging,Notes' +
        `\r\n`;
      for (let i = 0; i < state.perDiems.length; i++) {
        const t = state.perDiems[i];

        const tempString =
          TimesheetDepartmentClientService.getDepartmentName(t.department!).replace(re, '') +
          ',' +
          t.ownerName +
          ',' +
          (t.dateSubmitted != NULL_TIME ? formatDate(t.dateSubmitted) : '-') +
          ',' +
          (t.approvedByName || '-') +
          ',' +
          (t.dateApproved != NULL_TIME ? formatDate(t.dateApproved) : '-') +
          ',' +
          usd(t.amountProcessedMeals) +
          ',' +
          (t.amountProcessedLodging != 0 ? usd(t.amountProcessedLodging) : '-') +
          ',' +
          t.notes +
          `\r\n`;
        fullString = fullString + tempString;
      }
    }
    if (section == 'Trips' && state.trips) {
      fullString = 'Date ,Destination,Distance (in Miles),Home Travel ,Cost, Notes\r\n';
      for (let i = 0; i < state.trips.length; i++) {
        const t = state.trips[i];
        const tempString =
          formatDate(t.date) +
          ',' +
          t.originAddress.replace(re, ' ') +
          ',' +
          t.destinationAddress.replace(re, ' ') +
          ',' +
          t.distanceInMiles +
          ',' +
          (t.homeTravel == true ? 'Yes' : 'No') +
          ',' +
          usd(Number((t.distanceInMiles * IRS_SUGGESTED_MILE_FACTOR).toFixed(2))) +
          ',' +
          t.notes.replace(re, '') +
          `\r\n`;
        fullString = fullString + tempString;
      }
    }
    if (section == 'Spiffs' && state.tasks) {
      fullString = 'Date Performed,Type,Details,Flat Rate?,Amount, Notes\r\n';
      for (let i = 0; i < state.tasks.length; i++) {
        const t = state.tasks[i];
        const tempString =
          formatDate(t.datePerformed) +
          ',' +
          t.billableType.replace(re, ' ') +
          ',' +
          t.details.replace(re, ' ') +
          ',' +
          t.flatRate +
          ',' +
          usd(t.billableType === 'Spiff' ? t.spiffAmount : t.toolpurchaseCost) +
          ',' +
          t.notes.replace(re, '') +
          `\r\n`;
        fullString = fullString + tempString;
      }
    }
    downloadCSV(state.activeTab + ' Report For ' + serviceCallId, fullString);
  };
  useEffect(() => {
    if (!state.loadedInit) {
      loadInit();
    }
    if (state.loadedInit == true && state.loaded === false) {
      load();
    }
  }, [state.loadedInit, loadInit, state.loaded, load]);
  const costCenterArray = [['', '']];
  const laborCostArray = [['', '']];
  state.transactionAccounts.forEach((account) => {
    const findAccount = `${account.id}-${account.description}`;
    if (state.costCenterTotals[findAccount]) {
      const value = [findAccount, usd(state.costCenterTotals[findAccount])];
      costCenterArray.push(value);
    }
  });

  state.classCodes.forEach((code) => {
    const findAccount = code.id;
    if (state.laborTotals[findAccount]) {
      const value = [code.description, `${state.laborTotals[findAccount]} hour(s)`];
      laborCostArray.push(value);
    }
  });
  const totalMeals = state.perDiems.reduce((aggr, pd) => aggr + pd.amountProcessedMeals, 0);
  const totalLodging = state.perDiems.reduce((aggr, pd) => aggr + pd.amountProcessedLodging, 0);
  const totalTransactions = state.transactions.reduce((aggr, pd) => aggr + pd.amount, 0);
  const totalTasksBillable = state.tasks.reduce((aggr, pd) => aggr + pd.spiffAmount, 0);

  const weekArray: Week[] = [];

  for (let i = 0; i < state.timesheetWeeklySubtotals.length; i++) {
    const temp = state.timesheetWeeklySubtotals[i];
    const found = weekArray.find(
      (week) => temp.weekStart == week.weekStart && week.code == temp.classCodeId,
    );
    if (found == undefined) {
      weekArray.push({
        weekEnd: temp.weekEnd,
        weekStart: temp.weekStart,
        code: temp.classCodeId,
      });
    }
  }
  return state.loaded ? (
    <SectionBar key="ReportPage" uncollapsable={true} title={title}>
      <style>{`
  .InfoTableItem {
    font-size: 10px;
    font-size: 1.0vw;

    }
  `}</style>
      <div className="print-actions">
        <PrintPage
          buttonProps={{
            label: 'Print Cost Report',
            loading: state.loading,
          }}
          downloadLabel="Download Cost Report"
          downloadPdfFilename="Cost-Report"
          headerProps={{ title: 'Project Cost Report' }}
          onPrint={handlePrint}
          onPrinted={handlePrinted}
          status={state.printStatus}
          key={state.printStatus}
          className="EditProjectAsideContent"
        >
          <PrintParagraph tag="h2" key="eventPDFHeader">
            Project Details
          </PrintParagraph>
          {state.event && (
            <PrintList
              key="eventColumns"
              items={[
                <Fragment key="address">
                  <strong>Address: </strong>
                  {getPropertyAddress(state.event.property)}
                </Fragment>,
                <Fragment key="startDate">
                  <strong>Start Date: </strong>
                  {formatDate(state.event.dateStarted)}
                </Fragment>,
                <Fragment key="endDate">
                  <strong>End Date: </strong>
                  {formatDate(state.event.dateEnded)}
                </Fragment>,
                <Fragment key="jobNumber">
                  <strong>Job Number: </strong>
                  {state.event.logJobNumber}
                </Fragment>,
              ]}
            />
          )}
          <PrintParagraph tag="h2" key="totalHeader1">
            Summary Info
          </PrintParagraph>
          <PrintTable
            key="totalColumns1"
            columns={[
              { title: 'Type', align: 'left' },
              { title: 'Total', align: 'right' },
            ]}
            data={[
              [
                'Total Hours Worked',
                state.totalHoursWorked > 1
                  ? `${state.totalHoursWorked} hrs`
                  : state.totalHoursWorked == 0
                    ? 'None'
                    : `${state.totalHoursWorked} hr`,
              ],
              [
                <PrintTable
                  key="TransactionBreakdownCategory"
                  columns={[
                    { title: '', align: 'left' },
                    { title: '', align: 'left' },
                  ]}
                  data={laborCostArray.map((labor) => {
                    return labor;
                  })}
                ></PrintTable>,
              ],
            ]}
          />
          <PrintParagraph tag="h2" key="totalHeader2">
            Costs
          </PrintParagraph>
          <PrintTable
            key="totalColumns2"
            columns={[
              { title: 'Type', align: 'left' },
              { title: 'Cost', align: 'right' },
            ]}
            data={[
              ['Transactions', usd(totalTransactions)],
              [
                <PrintTable
                  key="TransactionBreakdownCategory"
                  columns={[
                    { title: 'Type', align: 'left' },
                    { title: 'SubCost', align: 'right' },
                  ]}
                  data={costCenterArray.map((costCenter) => {
                    return costCenter;
                  })}
                ></PrintTable>,
              ],
              ['Meals', usd(totalMeals)],
              ['Lodging Processed', usd(totalLodging)],
              ['Spiffs', usd(totalTasksBillable)],
              ['Trips Total', usd(state.tripsTotal)],
              [
                '',
                <strong key="stronk">
                  TOTAL:{' '}
                  {usd(
                    totalMeals +
                      totalLodging +
                      totalTransactions +
                      totalTasksBillable +
                      state.tripsTotal,
                  )}
                </strong>,
              ],
            ]}
          />
          <PrintParagraph tag="h2" key="transactionColumns">
            Transactions
          </PrintParagraph>
          {state.transactionAccounts
            .filter(
              (account) =>
                state.costCenterTotals[`${account.id}-${account.description}`] != undefined,
            )
            .map((account) => (
              <div key={account.id}>
                <PrintParagraph
                  tag="h3"
                  key={`${account.id}-${account.description}`}
                >{`${account.id}-${account.description}`}</PrintParagraph>
                <PrintTable
                  key={`${account.id}-${account.description}${account.accountCategory}`}
                  columns={[
                    {
                      title: 'Department',
                      align: 'left',
                    },
                    {
                      title: 'Owner',
                      align: 'left',
                      widthPercentage: 10,
                    },
                    {
                      title: 'Cost Center / Vendor',
                      align: 'left',
                      widthPercentage: 10,
                    },
                    {
                      title: 'Date',
                      align: 'left',
                      widthPercentage: 10,
                    },
                    {
                      title: 'Amount',
                      align: 'left',
                      widthPercentage: 10,
                    },
                    {
                      title: 'Notes',
                      align: 'right',
                      widthPercentage: 20,
                    },
                  ]}
                  data={state.transactions
                    .filter((transaction) => transaction.costCenterId === account.id)
                    .map((txn) => {
                      return [
                        txn.department ? (
                          <>
                            {txn.department?.classification} - {txn.department?.description}
                          </>
                        ) : (
                          '-'
                        ),
                        txn.ownerName,
                        <Fragment key={txn.id}>
                          {`${txn.costCenter?.description} - `}
                          <br />
                          {txn.vendor}
                        </Fragment>,
                        formatDate(txn.timestamp),
                        usd(txn.amount),
                        txn.notes,
                      ];
                    })}
                />
              </div>
            ))}
          <PrintParagraph tag="h2" key="perDiemColumns">
            Per Diem
          </PrintParagraph>
          {state.perDiems
            .sort((a, b) => (a.dateSubmitted > b.dateSubmitted ? -1 : 1))
            .map((pd) => {
              const rows = pd.rows;
              const lodgingRows = rows.filter((row) => row.mealsOnly == false);
              const totalMeals = pd.amountProcessedMeals;
              const totalLodging = pd.amountProcessedLodging;
              if (totalMeals == 0 && totalLodging == 0) {
                return null; // Don't show it
              }
              return (
                <div key={pd.id + 'pdf'}>
                  <PrintParagraph tag="h3">
                    Per Diem {pd.dateSubmitted.split(' ')[0] != NULL_TIME.split(' ')[0] ? '-' : ''}{' '}
                    {pd.dateSubmitted.split(' ')[0] != NULL_TIME.split(' ')[0]
                      ? pd.dateSubmitted.split(' ')[0]
                      : ''}{' '}
                    {pd.ownerName ? '-' : ''} {pd.ownerName}
                  </PrintParagraph>
                  <div
                    key={'PerDiemTablePDF' + pd.id}
                    style={{
                      breakInside: 'avoid',
                      display: 'inline-block',
                      width: '100%',
                    }}
                  >
                    <PrintTable
                      columns={[
                        {
                          title: 'Department',
                          align: 'left',
                        },
                        {
                          title: 'Owner',
                          align: 'left',
                          widthPercentage: 10,
                        },
                        {
                          title: 'Submitted At',
                          align: 'left',
                          widthPercentage: 10,
                        },
                        {
                          title: 'Approved By',
                          align: 'left',
                          widthPercentage: 10,
                        },
                        {
                          title: 'Approved At',
                          align: 'left',
                          widthPercentage: 10,
                        },
                        {
                          title: 'Total Meals',
                          align: 'left',
                          widthPercentage: 10,
                        },
                        {
                          title: 'Total Lodging',
                          align: 'left',
                          widthPercentage: 10,
                        },
                        {
                          title: 'Notes',
                          align: 'right',
                          widthPercentage: 20,
                        },
                      ]}
                      data={[
                        [
                          TimesheetDepartmentClientService.getDepartmentName(pd.department!),
                          pd.ownerName,
                          pd.dateSubmitted != NULL_TIME ? formatDate(pd.dateSubmitted) : '-',
                          pd.approvedByName || '-',
                          pd.dateApproved != NULL_TIME ? formatDate(pd.dateApproved) : '-',
                          usd(totalMeals),
                          totalLodging != 0 ? usd(totalLodging) : '-',
                          pd.notes,
                        ],
                      ]}
                    />
                  </div>
                  <PrintParagraph tag="h4">Per Diem Days</PrintParagraph>
                  <div
                    key={'PerDiemDaysPDF'}
                    style={{
                      breakInside: 'avoid',
                      display: 'inline-block',
                      width: '100%',
                    }}
                  >
                    <PrintTable
                      columns={[
                        {
                          title: 'Date',
                          align: 'left',
                        },
                        {
                          title: 'Zip Code',
                          align: 'left',
                          widthPercentage: 10,
                        },
                        {
                          title: 'Meals Only',
                          align: 'left',
                          widthPercentage: 10,
                        },
                        {
                          title: 'Meals',
                          align: 'left',
                          widthPercentage: 10,
                        },
                        {
                          title: 'Lodging',
                          align: 'left',
                          widthPercentage: 10,
                        },
                        {
                          title: 'Notes',
                          align: 'right',
                          widthPercentage: 20,
                        },
                      ]}
                      data={rows.map((pdr) => {
                        return [
                          formatDate(pdr.dateString),
                          pdr.zipCode,
                          pdr.mealsOnly ? 'Yes' : 'No',
                          usd(pd.amountProcessedMeals / lodgingRows.length),
                          pdr.mealsOnly === false
                            ? usd(pd.amountProcessedLodging / lodgingRows.length)
                            : '-',
                          pdr.notes,
                        ];
                      })}
                    />
                  </div>
                </div>
              );
            })}
          <PrintParagraph tag="h2" key="timesheetsHeader">
            Labor Summary
          </PrintParagraph>
          {state.showEmployeeSummary ? (
            <EmployeeSummaryView
              data={transformTimesheetsToDataList(state.timesheets)}
              startDate={new Date()}
            />
          ) : (
            state.classCodes
              .filter((code) => state.laborTotals[code.id] != undefined)
              .map((code) => (
                <div key={code.id}>
                  <PrintParagraph
                    key={`${code.id}-${code.description}`}
                    tag="h3"
                  >{`${code.id}-${code.description}`}</PrintParagraph>

                  <PrintTable
                    columns={[
                      {
                        title: 'Employee',
                        align: 'left',
                      },
                      {
                        title: 'Department',
                        align: 'left',
                        widthPercentage: 10,
                      },
                      {
                        title: 'Approved By',
                        align: 'left',
                        widthPercentage: 10,
                      },
                      {
                        title: 'Time Started',
                        align: 'left',
                        widthPercentage: 10,
                      },
                      {
                        title: 'Time Finished',
                        align: 'left',
                        widthPercentage: 10,
                      },
                      {
                        title: 'Brief Description',
                        align: 'left',
                        widthPercentage: 10,
                      },
                      {
                        title: 'Hours Worked',
                        align: 'left',
                        widthPercentage: 10,
                      },
                      {
                        title: 'Notes',
                        align: 'right',
                        widthPercentage: 20,
                      },
                    ]}
                    data={state.timesheets
                      .filter((tsl) => tsl.classCodeId === code.id)
                      .map((tsl) => {
                        return [
                          tsl.technicianUserName,
                          tsl.departmentName,
                          tsl.adminApprovalUserName,
                          formatDate(tsl.timeStarted),
                          formatDate(tsl.timeFinished),
                          tsl.briefDescription,
                          `${tsl.hoursWorked} hour(s)`,
                          tsl.notes,
                        ];
                      })}
                  />
                </div>
              ))
          )}
          <PrintParagraph tag="h2" key="tripsHeader">
            Related Trips
          </PrintParagraph>
          {state.trips &&
            state.trips.map((trip) => {
              return (
                <div
                  key={trip.id + 'pdf'}
                  style={{
                    breakInside: 'avoid',
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <PrintTable
                    key="tripTable"
                    columns={[
                      {
                        title: 'Date',
                        align: 'left',
                      },
                      {
                        title: 'Origin Address',
                        align: 'left',
                        widthPercentage: 20,
                      },
                      {
                        title: 'Destination Address',
                        align: 'left',
                        widthPercentage: 20,
                      },
                      {
                        title: 'Distance (Miles)',
                        align: 'left',
                        widthPercentage: 10,
                      },
                      {
                        title: 'Notes',
                        align: 'left',
                        widthPercentage: 10,
                      },
                      {
                        title: 'Home Travel',
                        align: 'right',
                        widthPercentage: 10,
                      },
                      {
                        title: `Cost (${usd(IRS_SUGGESTED_MILE_FACTOR)}/mi)`,
                        align: 'right',
                        widthPercentage: 10,
                      },
                      {
                        title: 'Per Diem Row ID',
                        align: 'right',
                        widthPercentage: 10,
                      },
                    ]}
                    data={[
                      [
                        formatDate(trip.date),
                        trip.originAddress,
                        trip.destinationAddress,
                        trip.distanceInMiles.toFixed(2),
                        trip.notes,
                        trip.homeTravel,
                        `${usd(
                          Number((trip.distanceInMiles * IRS_SUGGESTED_MILE_FACTOR).toFixed(2)),
                        )}`,
                        trip.perDiemRowId,
                      ],
                    ]}
                  />
                </div>
              );
            })}
          <PrintParagraph tag="h2" key="tasksHeader">
            Spiffs/Bonus/Commission
          </PrintParagraph>
          {state.tasks &&
            state.tasks.map((task) => {
              return (
                <div
                  key={task.id + 'pdf'}
                  style={{
                    breakInside: 'avoid',
                    display: 'inline-block',
                    width: '100%',
                  }}
                >
                  <PrintTable
                    key="tripTable"
                    columns={[
                      {
                        title: 'Type',
                        align: 'left',
                      },
                      {
                        title: 'Date Performed',
                        align: 'left',
                        widthPercentage: 20,
                      },
                      {
                        title: 'Details',
                        align: 'left',
                        widthPercentage: 20,
                      },
                      {
                        title: 'Flat Rate?',
                        align: 'left',
                        widthPercentage: 10,
                      },
                      {
                        title: 'Amount',
                        align: 'left',
                        widthPercentage: 10,
                      },
                      {
                        title: 'Notes',
                        align: 'right',
                        widthPercentage: 10,
                      },
                    ]}
                    data={[
                      [
                        task.billableType,
                        formatDate(task.datePerformed),
                        task.details,
                        task.flatRate,
                        task.billableType === 'Spiff'
                          ? usd(task.spiffAmount)
                          : usd(task.toolpurchaseCost),
                        task.notes,
                      ],
                    ]}
                  />
                </div>
              );
            })}
        </PrintPage>
        <KalosButton
          style={{ display: 'inline-block' }}
          label="Download CSV of Page"
          onClick={() => {
            createReport(state.activeTab);
          }}
        ></KalosButton>
        {onClose && <KalosButton onClick={onClose} label="Close"></KalosButton>}
      </div>
      <Tabs
        onChange={(e) => dispatch({ type: ACTIONS.SET_ACTIVE_TAB, data: tabs[e] })}
        tabs={[
          {
            label: 'Job Details',
            content: state.event && (
              <div>
                <PrintList
                  className="p-4"
                  items={[
                    <div key="address" style={{ fontSize: '1.0vw' }}>
                      <strong>Address: </strong>
                      {getPropertyAddress(state.event.property)}
                    </div>,
                    <div key="startDate" style={{ fontSize: '1.0vw' }}>
                      <strong>Start Date: </strong>
                      {formatDate(state.event.dateStarted)}
                    </div>,
                    <div key="endDate" style={{ fontSize: '1.0vw' }}>
                      <strong>End Date: </strong>
                      {formatDate(state.event.dateEnded)}
                    </div>,
                    <div key="jobNumber" style={{ fontSize: '1.0vw' }}>
                      <strong>Job Number: </strong>
                      {state.event.logJobNumber}
                    </div>,
                  ]}
                />
                <div key="CostSummary">
                  <InfoTable
                    columns={[
                      { name: 'Type', align: 'left' },
                      { name: 'Total', align: 'left' },
                      { name: '', align: 'right' },
                    ]}
                    data={[
                      [
                        {
                          value: (
                            <div key="laborheader">
                              Total Hours Worked
                              <div key="LaborTypesContainer">
                                <Collapse
                                  key={'LaborCollapseTypes'}
                                  in={state.laborTotalsDropDownActive === true}
                                >
                                  {state.classCodes.map((code) => {
                                    const findAccount = code.id;
                                    if (state.laborTotals[findAccount]) {
                                      return (
                                        <div key={'laborValue' + findAccount}>
                                          {code.description}
                                        </div>
                                      );
                                    }
                                    return null;
                                  })}
                                </Collapse>
                              </div>
                            </div>
                          ),
                        },
                        {
                          value: (
                            <div key="laborTotalHeader">
                              {state.totalHoursWorked > 1
                                ? `${state.totalHoursWorked} hrs`
                                : state.totalHoursWorked == 0
                                  ? 'None'
                                  : `${state.totalHoursWorked} hr`}
                              <div key="LaborCollapseValueHeader">
                                <Collapse
                                  key={'LaborCollapseValues'}
                                  in={state.laborTotalsDropDownActive === true}
                                >
                                  {state.classCodes.map((code) => {
                                    const findAccount = code.id;
                                    if (state.laborTotals[findAccount]) {
                                      return (
                                        <div
                                          key={
                                            'laborTotal' +
                                            state.laborTotals[findAccount] +
                                            code.description
                                          }
                                        >
                                          {`${state.laborTotals[findAccount]} hour(s)`}
                                        </div>
                                      );
                                    }
                                    return null;
                                  })}
                                </Collapse>
                              </div>
                            </div>
                          ),
                        },
                        {
                          value: (
                            <div key="LaborTotalsButton">
                              <Button
                                key={'dropDownbuttonLabor'}
                                onClick={() => {
                                  dispatch({
                                    type: ACTIONS.SET_LABOR_TOTALS_DROPDOWN_ACTIVE,
                                    data: !state.laborTotalsDropDownActive,
                                  });
                                }}
                              >
                                {state.laborTotalsDropDownActive == true ? (
                                  <ExpandLess></ExpandLess>
                                ) : (
                                  <ExpandMore></ExpandMore>
                                )}
                              </Button>
                            </div>
                          ),
                        },
                      ],
                    ]}
                  />
                  <PrintParagraph className="pb-1 pl-3" tag="h2">
                    Costs
                  </PrintParagraph>
                  <InfoTable
                    columns={[
                      { name: 'Type', align: 'left' },
                      { name: 'Cost', align: 'left' },
                      { name: '', align: 'right' },
                    ]}
                    data={[
                      [
                        {
                          value: (
                            <div key="CostCenterCollapseTypesContainer">
                              Transactions
                              <Collapse
                                key={'CostCenterCollapseTypes'}
                                in={state.costCenterDropDownActive === true}
                              >
                                {state.transactionAccounts.map((account) => {
                                  const findAccount = `${account.id}-${account.description}`;
                                  if (state.costCenterTotals[findAccount]) {
                                    return (
                                      <div
                                        key={'transactionAccountValue' + findAccount + account.id}
                                      >
                                        {findAccount}
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                              </Collapse>
                            </div>
                          ),
                        },
                        {
                          value: (
                            <div key="CostCenterCollapseValueHeader">
                              {usd(totalTransactions)}
                              <Collapse
                                key={'CostCenterCollapseValues'}
                                in={state.costCenterDropDownActive === true}
                              >
                                {state.transactionAccounts.map((account) => {
                                  const findAccount = `${account.id}-${account.description}`;
                                  if (state.costCenterTotals[findAccount]) {
                                    return (
                                      <div
                                        key={
                                          'transactionAccountValue' +
                                          state.costCenterTotals[findAccount] +
                                          findAccount
                                        }
                                      >
                                        {usd(state.costCenterTotals[findAccount])}
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                              </Collapse>
                            </div>
                          ),
                        },
                        {
                          value: (
                            <div key="transactionTotalsButton">
                              <Button
                                key={'dropDownbuttonTransactions'}
                                onClick={() => {
                                  dispatch({
                                    type: ACTIONS.SET_COST_CENTER_DROPDOWN_ACTIVE,
                                    data: !state.costCenterDropDownActive,
                                  });
                                }}
                              >
                                {state.costCenterDropDownActive == true ? (
                                  <ExpandLess></ExpandLess>
                                ) : (
                                  <ExpandMore></ExpandMore>
                                )}
                              </Button>
                            </div>
                          ),
                        },
                      ],
                      [{ value: 'Meals' }, { value: usd(totalMeals) }, { value: '' }],
                      [{ value: 'Lodging' }, { value: usd(totalLodging) }, { value: '' }],
                      [{ value: 'Spiffs' }, { value: usd(totalTasksBillable) }, { value: '' }],
                      [{ value: 'Trips Total' }, { value: usd(state.tripsTotal) }, { value: '' }],
                      [
                        {
                          value: <strong key="stronk1">TOTAL:</strong>,
                        },
                        {
                          value: (
                            <strong key="stronk2">
                              TOTAL:
                              {usd(
                                totalMeals +
                                  totalLodging +
                                  totalTransactions +
                                  totalTasksBillable +
                                  state.tripsTotal,
                              )}
                            </strong>
                          ),
                        },
                        { value: '' },
                      ],
                    ]}
                  />
                </div>
              </div>
            ),
          },

          {
            label: 'Transactions',
            content: state.transactionAccounts
              .filter(
                (account) =>
                  state.costCenterTotals[`${account.id}-${account.description}`] != undefined,
              )
              .map((account) => (
                <SectionBar
                  title={`${account.id}-${account.description}`}
                  subtitle={usd(state.costCenterTotals[`${account.id}-${account.description}`])}
                  key={'header' + account.id}
                >
                  <InfoTable
                    key={account.id}
                    styles={{ width: '100%', padding: 10 }}
                    columns={[
                      {
                        name: 'Department',
                        align: 'left',
                      },
                      {
                        name: 'Owner',
                        align: 'left',
                      },
                      {
                        name: 'Cost Center / Vendor',
                        align: 'left',
                      },
                      {
                        name: 'Date',
                        align: 'left',
                        dir:
                          state.transactionSort.sortDir &&
                          state.transactionSort.sortBy == 'timestamp'
                            ? state.transactionSort.sortDir
                            : undefined,
                        onClick: () => {
                          const dir = state.transactionSort.sortDir == 'ASC' ? 'DESC' : 'ASC';
                          dispatch({
                            type: ACTIONS.SET_TRANSACTION_SORT,
                            data: {
                              sortBy: 'timestamp',
                              sortDir: dir,
                            },
                          });
                          dispatch({
                            type: ACTIONS.SET_TRANSACTIONS,
                            data: sortTransactions(state.transactions, dir, 'timestamp'),
                          });
                        },
                      },
                      {
                        name: 'Amount',
                        align: 'left',
                        dir:
                          state.transactionSort.sortDir && state.transactionSort.sortBy == 'amount'
                            ? state.transactionSort.sortDir
                            : undefined,
                        onClick: () => {
                          const dir = state.transactionSort.sortDir == 'ASC' ? 'DESC' : 'ASC';
                          dispatch({
                            type: ACTIONS.SET_TRANSACTION_SORT,
                            data: {
                              sortBy: 'amount',
                              sortDir: dir,
                            },
                          });
                          dispatch({
                            type: ACTIONS.SET_TRANSACTIONS,
                            data: sortTransactions(state.transactions, dir, 'amount'),
                          });
                        },
                      },
                      {
                        name: 'Notes',
                        align: 'left',
                      },
                      {
                        name: 'Actions',
                        align: 'left',
                      },
                    ]}
                    data={state.transactions
                      .filter((transaction) => transaction.costCenterId === account.id)
                      .map((txn) => {
                        return [
                          {
                            value: txn.department ? (
                              <div key={txn.id + txn.description}>
                                {txn.department?.classification} - {txn.department?.description}
                              </div>
                            ) : (
                              '-'
                            ),
                          },

                          { value: txn.ownerName },

                          {
                            value: `${txn.costCenter?.description} - ${txn.vendor}`,
                          },
                          { value: formatDate(txn.timestamp) },
                          { value: usd(txn.amount) },

                          {
                            value: <div style={{ fontSizeAdjust: '0.5' }}>{txn.notes}</div>,
                          },
                          {
                            value: (
                              <KalosButton
                                label="View Photos"
                                onClick={() =>
                                  dispatch({
                                    type: ACTIONS.SET_OPEN_GALLERY_ID,
                                    data: txn.id,
                                  })
                                }
                              >
                                <Dialog
                                  open={state.openGalleryId === txn.id}
                                  onOpenChange={() =>
                                    dispatch({
                                      type: ACTIONS.SET_OPEN_GALLERY_ID,
                                      data: undefined,
                                    })
                                  }
                                >
                                  <DialogContent className="h-screen w-screen max-w-[95vw] p-4">
                                    <div className="flex h-full w-full overflow-auto">
                                      <TransactionFilesGallery
                                        key="receiptPhotos"
                                        title="Transaction Photos"
                                        transactionId={txn.id}
                                        subTitle="View photos"
                                      />
                                    </div>
                                  </DialogContent>
                                </Dialog>
                              </KalosButton>
                            ),
                          },
                        ];
                      })}
                  />
                </SectionBar>
              )),
          },

          {
            label: 'PerDiems',
            content: state.users
              .filter((user) => state.perDiems.find((perDiem) => perDiem.userId === user.id))
              .map((user) => {
                {
                  return (
                    <SectionBar
                      key={user.id + user.dateCreated}
                      title={`PerDiems For ${user.firstname} ${user.lastname}`}
                    >
                      {state.perDiems
                        .sort((a, b) => (a.dateSubmitted > b.dateSubmitted ? -1 : 1))
                        .filter((perdiem) => perdiem.userId == user.id)
                        .map((pd) => {
                          const rows = pd.rows;
                          const lodgingRows = rows.filter((row) => row.mealsOnly == false);
                          const totalMeals = pd.amountProcessedMeals;
                          const totalLodging = pd.amountProcessedLodging;
                          return (
                            <div key={pd.id.toString() + 'div'}>
                              <div
                                style={{
                                  breakInside: 'avoid',
                                  display: 'inline-block',
                                  width: '100%',
                                }}
                                key="PerDiemContainer"
                              >
                                <InfoTable
                                  columns={[
                                    {
                                      name: 'Department',
                                      align: 'left',
                                    },
                                    {
                                      name: 'Owner',
                                      align: 'left',
                                    },
                                    {
                                      name: 'Submitted At',
                                      align: 'left',
                                    },
                                    {
                                      name: 'Approved By',
                                      align: 'left',
                                    },
                                    {
                                      name: 'Approved At',
                                      align: 'left',
                                    },
                                    {
                                      name: 'Total Meals',
                                      align: 'left',
                                    },
                                    {
                                      name: 'Total Lodging',
                                      align: 'left',
                                    },
                                    {
                                      name: 'Notes',
                                      align: 'left',
                                    },
                                  ]}
                                  data={[
                                    [
                                      {
                                        value: TimesheetDepartmentClientService.getDepartmentName(
                                          pd.department!,
                                        ),
                                      },
                                      { value: pd.ownerName },
                                      {
                                        value:
                                          pd.dateSubmitted != NULL_TIME
                                            ? formatDate(pd.dateSubmitted)
                                            : '-',
                                      },
                                      { value: pd.approvedByName || '-' },
                                      {
                                        value:
                                          pd.dateApproved != NULL_TIME
                                            ? formatDate(pd.dateApproved)
                                            : '-',
                                      },
                                      { value: usd(totalMeals) },
                                      {
                                        value: totalLodging != 0 ? usd(totalLodging) : '-',
                                      },
                                      { value: pd.notes },
                                    ],
                                  ]}
                                />
                                <Button
                                  key={'dropDownbutton' + pd.id.toString()}
                                  onClick={() => {
                                    const tempDropDowns = state.dropDowns;
                                    const dropdown = tempDropDowns.findIndex(
                                      (dropdown) => dropdown.perDiemId === pd.id,
                                    );
                                    if (tempDropDowns[dropdown]) {
                                      if (tempDropDowns[dropdown].active == 0)
                                        tempDropDowns[dropdown].active = 1;
                                      else tempDropDowns[dropdown].active = 0;
                                    }
                                    dispatch({
                                      type: ACTIONS.SET_DROPDOWNS,
                                      data: tempDropDowns,
                                    });
                                  }}
                                >
                                  Details
                                  {state.dropDowns.find((dropdown) => dropdown.perDiemId === pd.id)!
                                    .active == 1 ? (
                                    <ExpandLess></ExpandLess>
                                  ) : (
                                    <ExpandMore></ExpandMore>
                                  )}
                                </Button>
                              </div>
                              <Collapse
                                key={pd.id.toString() + 'collapse'}
                                in={
                                  state.dropDowns.find((dropdown) => dropdown.perDiemId === pd.id)
                                    ?.active == 1
                                    ? true
                                    : false
                                }
                              >
                                <InfoTable
                                  key={pd.id.toString() + 'days'}
                                  columns={[
                                    {
                                      name: 'Date',
                                      align: 'left',
                                    },
                                    {
                                      name: 'Zip Code',
                                      align: 'left',
                                    },
                                    {
                                      name: 'Meals Only',
                                      align: 'left',
                                    },
                                    {
                                      name: 'Meals',
                                      align: 'left',
                                    },
                                    {
                                      name: 'Lodging',
                                      align: 'left',
                                    },
                                    {
                                      name: 'Notes',
                                      align: 'left',
                                    },
                                  ]}
                                  data={rows.map((pdr) => {
                                    return [
                                      {
                                        value: formatDate(pdr.dateString),
                                      },
                                      { value: pdr.zipCode },
                                      {
                                        value: pdr.mealsOnly ? 'Yes' : 'No',
                                      },
                                      {
                                        value: usd(pd.amountProcessedMeals / rows.length),
                                      },
                                      {
                                        value:
                                          pdr.mealsOnly === false
                                            ? usd(pd.amountProcessedLodging / lodgingRows.length)
                                            : '-',
                                      },
                                      { value: pdr.notes },
                                    ];
                                  })}
                                />
                              </Collapse>
                            </div>
                          );
                        })}
                    </SectionBar>
                  );
                }
              }),
          },

          {
            label: 'Timesheets',
            content: (
              <>
                <KalosButton
                  onClick={() =>
                    dispatch({
                      type: ACTIONS.SET_SHOW_EMPLOYEE_SUMMARY,
                      data: !state.showEmployeeSummary,
                    })
                  }
                  label={
                    state.showEmployeeSummary ? 'Show Class Code View' : 'Show Employee Summary'
                  }
                />
                {state.showEmployeeSummary ? (
                  <EmployeeSummaryView
                    data={transformTimesheetsToDataList(state.timesheets)}
                    startDate={new Date()}
                  />
                ) : (
                  state.classCodes
                    .filter((code) => state.laborTotals[code.id] != undefined)
                    .map((code) => (
                      <SectionBar
                        title={`${code.id}-${code.description}`}
                        subtitle={`${state.laborTotals[code.id]} hour(s)`}
                        key={'header' + code.id}
                      >
                        {weekArray
                          .filter((week) => week.code === code.id)
                          .map((weekMapValue) => (
                            <div key={weekMapValue.weekStart}>
                              <PrintParagraph
                                tag="h2"
                                className="pl-[7px]"
                              >{`Week Of ${weekMapValue.weekStart}`}</PrintParagraph>
                              <InfoTable
                                columns={[
                                  {
                                    name: 'Employee',
                                    align: 'left',
                                  },
                                  {
                                    name: 'Week',
                                    align: 'left',
                                  },
                                  {
                                    name: 'Total Hours',
                                    align: 'left',
                                  },
                                ]}
                                data={state.timesheetWeeklySubtotals
                                  .filter(
                                    (week) =>
                                      week.classCodeId == code.id &&
                                      weekMapValue.weekStart == week.weekStart,
                                  )
                                  .map((week) => {
                                    return [
                                      {
                                        value: `${
                                          state.users.find((user) => user.id === week.employeeId)
                                            ?.firstname
                                        }-${
                                          state.users.find((user) => user.id === week.employeeId)
                                            ?.lastname
                                        }`,
                                      },
                                      {
                                        value: `${week.weekStart}-${week.weekEnd}`,
                                      },
                                      { value: `${week.hoursSubtotal} hour(s)` },
                                    ];
                                  })}
                              />
                            </div>
                          ))}
                        <div key="TimesheetDetails">
                          <Button
                            key={'dropDownbutton' + code.id.toString()}
                            onClick={() => {
                              const tempDropDowns = state.classCodeDropdowns;
                              const dropdown = tempDropDowns.findIndex(
                                (dropdown) => dropdown.classCodeId === code.id,
                              );
                              if (tempDropDowns[dropdown]) {
                                if (tempDropDowns[dropdown].active == 0)
                                  tempDropDowns[dropdown].active = 1;
                                else tempDropDowns[dropdown].active = 0;
                              }
                              dispatch({
                                type: ACTIONS.SET_CLASS_CODE_DROPDOWNS,
                                data: tempDropDowns,
                              });
                            }}
                          >
                            Details
                            {state.classCodeDropdowns.find(
                              (dropdown) => dropdown.classCodeId === code.id,
                            )!.active == 1 ? (
                              <ExpandLess></ExpandLess>
                            ) : (
                              <ExpandMore></ExpandMore>
                            )}
                          </Button>
                          <Collapse
                            key={code.id.toString() + 'collapse'}
                            in={
                              state.classCodeDropdowns.find(
                                (dropdown) => dropdown.classCodeId === code.id,
                              )?.active == 1
                                ? true
                                : false
                            }
                          >
                            <InfoTable
                              columns={[
                                {
                                  name: 'Technician',
                                  align: 'left',
                                },
                                {
                                  name: 'Department',
                                  align: 'left',
                                },
                                {
                                  name: 'Approved By',
                                  align: 'left',
                                },
                                {
                                  name: 'Time Started',
                                  align: 'left',
                                },
                                {
                                  name: 'Time Finished',
                                  align: 'left',
                                },
                                {
                                  name: 'Brief Description',
                                  align: 'left',
                                },
                                {
                                  name: 'Hours Worked',
                                  align: 'left',
                                },
                                {
                                  name: 'Notes',
                                  align: 'right',
                                },
                              ]}
                              data={state.timesheets
                                .filter((timesheet) => timesheet.classCodeId === code.id)
                                .map((tsl) => {
                                  return [
                                    {
                                      value: tsl.technicianUserName + ` (${tsl.technicianUserId})`,
                                    },
                                    { value: tsl.departmentName },
                                    {
                                      value: tsl.adminApprovalUserName,
                                    },
                                    {
                                      value: formatDate(tsl.timeStarted) || '-',
                                    },
                                    {
                                      value: formatDate(tsl.timeFinished) || '-',
                                    },
                                    { value: tsl.briefDescription },
                                    {
                                      value:
                                        tsl.hoursWorked != 0
                                          ? tsl.hoursWorked > 1
                                            ? `${tsl.hoursWorked} hrs`
                                            : `${tsl.hoursWorked} hr`
                                          : '-',
                                    },
                                    { value: tsl.notes },
                                  ];
                                })}
                            />
                          </Collapse>
                        </div>
                      </SectionBar>
                    ))
                )}
              </>
            ),
          },

          {
            label: 'Trips',
            content: state.users
              .filter((user) => state.trips.find((trip) => trip.userId === user.id))
              .map((user) => {
                return (
                  <SectionBar
                    key={user.id + 'Trips'}
                    title={`${user.firstname} ${user.lastname} Trips`}
                  >
                    {state.trips &&
                      state.trips
                        .filter((el) => el.userId == user.id)
                        .map((trip) => {
                          return (
                            <div
                              key={trip.id}
                              style={{
                                breakInside: 'avoid',
                                display: 'inline-block',
                                width: '100%',
                              }}
                            >
                              <InfoTable
                                columns={[
                                  {
                                    name: 'Date',
                                    align: 'left',
                                  },
                                  {
                                    name: 'Origin Address',
                                    align: 'left',
                                  },
                                  {
                                    name: 'Destination Address',
                                    align: 'left',
                                  },
                                  {
                                    name: 'Distance (Miles)',
                                    align: 'left',
                                  },
                                  {
                                    name: 'Notes',
                                    align: 'left',
                                  },
                                  {
                                    name: 'Home Travel',
                                    align: 'right',
                                  },
                                  {
                                    name: `Cost (${usd(IRS_SUGGESTED_MILE_FACTOR)}/mi)`,
                                    align: 'right',
                                  },
                                  {
                                    name: 'Per Diem Row ID',
                                    align: 'right',
                                  },
                                ]}
                                data={[
                                  [
                                    { value: formatDate(trip.date) },
                                    { value: trip.originAddress },
                                    { value: trip.destinationAddress },
                                    {
                                      value: trip.distanceInMiles.toFixed(2),
                                    },
                                    { value: trip.notes },
                                    { value: trip.homeTravel },
                                    {
                                      value: `${usd(
                                        Number(
                                          (
                                            trip.distanceInMiles * IRS_SUGGESTED_MILE_FACTOR
                                          ).toFixed(2),
                                        ),
                                      )}
                          ${''}`,
                                    },
                                    { value: trip.perDiemRowId },
                                  ],
                                ]}
                              />
                            </div>
                          );
                        })}
                  </SectionBar>
                );
              }),
          },
          {
            label: 'Spiffs',
            content: state.users
              .filter((user) => state.tasks.find((task) => task.externalId === user.id))
              .map((user) => {
                return (
                  <SectionBar
                    key={user.id + 'Spiffs'}
                    title={`${user.firstname} ${user.lastname} Spiffs`}
                    subtitle={`Total: ${usd(
                      state.tasks &&
                        state.tasks
                          .filter((task) => task.externalId === user.id)
                          .reduce((acc, val) => acc + val.spiffAmount, 0),
                    )}`}
                  >
                    {state.tasks &&
                      state.tasks
                        .filter((task) => task.externalId === user.id)
                        .map((task) => {
                          return (
                            <div
                              key={task.id}
                              style={{
                                breakInside: 'avoid',
                                display: 'inline-block',
                                width: '100%',
                              }}
                            >
                              <InfoTable
                                columns={[
                                  {
                                    name: 'Type',
                                    align: 'left',
                                  },
                                  {
                                    name: 'Date Performed',
                                    align: 'left',
                                  },
                                  {
                                    name: 'Details',
                                    align: 'left',
                                  },
                                  {
                                    name: 'Amount',
                                    align: 'left',
                                  },
                                  {
                                    name: 'Notes',
                                    align: 'right',
                                  },
                                ]}
                                data={[
                                  [
                                    { value: task.billableType },
                                    {
                                      value: formatDate(task.datePerformed),
                                    },
                                    {
                                      value: task.details,
                                    },
                                    { value: usd(task.spiffAmount) },
                                    { value: task.notes },
                                  ],
                                ]}
                              />
                            </div>
                          );
                        })}
                  </SectionBar>
                );
              }),
          },
        ]}
      ></Tabs>
    </SectionBar>
  ) : loader ? (
    loader
  ) : (
    <Loader className="Job-Cost-Loading" />
  );
};
