import { zodResolver } from '@hookform/resolvers/zod';
import { type User } from '@kalos/kalos-rpc';
import {
  Alert,
  AlertTitle,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  PhoneInput,
  toast,
} from '@kalos/ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useStore } from 'zustand';

import { useUserQueryInline } from '../../../../hooks/react-query/useUserQuery';
import { requiredPhoneNumberSchema } from '../../../../tools/schemas';
import { useProtectedSoftPhoneStore } from '../../context/SoftPhoneStore.context';
import { useCallStore } from './CallStore';
import { monitorAudioLevels } from './utils';

const callFormSchema = z.object({
  phoneNumber: requiredPhoneNumberSchema,
});

export const OutboundCallShell = () => {
  const store = useProtectedSoftPhoneStore();
  const session = useStore(store, (state) => state.session);
  if (!session) return null;

  return <OutboundCall />;
};
const OutboundCall = () => {
  const outboundNumber = useCallStore.use.outboundNumber();

  const form = useForm<z.infer<typeof callFormSchema>>({
    resolver: zodResolver(callFormSchema),
    defaultValues: {
      phoneNumber: outboundNumber,
    },
  });

  useEffect(() => {
    if (outboundNumber) {
      form.reset({ phoneNumber: outboundNumber });
    }
  }, [outboundNumber, form]);

  const store = useProtectedSoftPhoneStore();

  const peerConnection = useCallStore.use.peerConnection();
  const isCalling = useCallStore.use.isCalling();
  const isPendingSound = useCallStore.use.isPendingSound();
  const setPeerConnection = useCallStore.use.setPeerConnection();
  const setIsCalling = useCallStore.use.setIsCalling();
  const setIsPendingSound = useCallStore.use.setIsPendingSound();
  const setRemoteStream = useCallStore.use.setRemoteStream();
  const setLocalStream = useCallStore.use.setLocalStream();
  const addOnCallEndListener = useCallStore.use.addOnCallEndListener();
  const handleReset = useCallStore.use.handleReset();
  const setCurrentCall = useCallStore.use.setCurrentCall();
  const setCurrentCaller = useCallStore.use.setCurrentCaller();

  const { fetchUser } = useUserQueryInline();

  const handleSubmit = form.handleSubmit(async (data) => {
    const session = store.getState().session;
    let peerConnection: RTCPeerConnection | null = null;
    try {
      if (!session) throw new Error('Session not found');
      peerConnection = new RTCPeerConnection();

      const remoteStream = new MediaStream();

      const searchPhoneNumber = data.phoneNumber.replace('+1', '').replace(' ', '');
      console.log(data.phoneNumber, 'data.phoneNumber', `%${searchPhoneNumber}%`);

      let customer: User | null = null;
      try {
        customer = await fetchUser({
          filter: {
            phone: `%${searchPhoneNumber}%`,
          },
        });
      } catch {
        console.warn('No customer found for outbound call');
      }

      peerConnection.ontrack = (event) => {
        console.log(event, '');
        remoteStream.addTrack(event.track);
        setRemoteStream(remoteStream);

        const soundStream = event.streams[0];

        // Start monitoring using AudioContext
        const [promise, cleanupFn] = monitorAudioLevels(soundStream);
        addOnCallEndListener(cleanupFn);
        promise
          .then(() => {
            console.log('Audio is now coming through!');
            setIsPendingSound(false);
            if (customer) {
              setCurrentCaller(customer);
            }
          })
          .catch((err) => {
            console.error('Error monitoring audio:', err);
          });
      };

      // Add client audio track to peer connection
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setLocalStream(stream);
      stream.getTracks().forEach((track) => peerConnection?.addTrack(track, stream));
      // create SDP offer
      setIsCalling(true);
      const offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);

      if (!offer.sdp) throw new Error('SDP offer not found');

      const call = await store
        .getState()
        .client.StartCall(session.sessionId, data.phoneNumber, offer.sdp);

      const remoteDesc = new RTCSessionDescription({
        type: 'answer',
        sdp: call.sdp,
      });
      await peerConnection.setRemoteDescription(remoteDesc);
      setIsCalling(false);
      setIsPendingSound(true);
      setPeerConnection(peerConnection);
      setCurrentCall({ ...call, source: 'outbound' });
    } catch (error) {
      handleReset();

      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to start call',
        variant: 'destructive',
      });
    }
  });

  return (
    <>
      <Form {...form}>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
          <FormField
            control={form.control}
            name="phoneNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Phone Number</FormLabel>
                <FormControl>
                  <PhoneInput {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <Button type="submit" disabled={!!peerConnection || isCalling}>
            Call
          </Button>
        </form>
      </Form>

      {isCalling && (
        <Alert variant="info" className="mt-4 flex items-center justify-center gap-2">
          <div className="size-5 animate-ping rounded-full bg-sky-500" />
          <AlertTitle>Calling...!</AlertTitle>
        </Alert>
      )}

      {isPendingSound && (
        <Alert variant="warning" className="mt-4  flex items-center justify-center gap-2">
          <div className="size-5 animate-ping rounded-full bg-yellow-500" />
          <AlertTitle>Waiting for response...</AlertTitle>
        </Alert>
      )}
    </>
  );
};
