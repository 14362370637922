import { Card, CardContent, CardHeader, CardTitle, LoadingIcon } from '@kalos/ui';
import { format } from 'date-fns';

import { useSearchUserByPhone } from '../../../../../hooks/react-query/useSearchUserByPhone';
import { UserPropertiesList } from '../../../../Softphone/SoftPhoneWidget/components/UserPropertiesList';

interface UserDetailsProps {
  phoneNumber: string;
}

export const UserDetails = ({ phoneNumber }: UserDetailsProps) => {
  const { data, isLoading } = useSearchUserByPhone({
    phoneNumber,
    enabled: phoneNumber.length > 4,
  });

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center p-4">
        <LoadingIcon className="size-10" />
      </div>
    );
  }

  if (!data?.userActivities?.length && !data?.user) {
    return (
      <div className="flex h-full items-center justify-center p-4">
        <p className="text-muted-foreground">No user activities found</p>
      </div>
    );
  }

  // Limit activities to the 5 most recent
  const recentActivities = data.userActivities.slice(0, 5);

  return (
    <div className="flex h-full flex-col gap-6 overflow-y-auto p-4">
      <Card className="shadow-sm">
        <CardHeader className="space-y-2 pb-3">
          <CardTitle className="flex flex-col gap-1">
            <span className="text-xl font-bold">
              {data.user?.firstname} {data.user?.lastname}
            </span>
            {data.user?.businessname && (
              <span className="text-muted-foreground text-base font-normal">
                {data.user?.businessname}
              </span>
            )}
          </CardTitle>
        </CardHeader>
      </Card>

      {data?.user && <UserPropertiesList userId={data.user.id} />}

      {recentActivities.length > 0 && (
        <Card className="shadow-sm">
          <CardContent className="flex flex-col gap-4">
            <h4 className="font-semibold">Activity</h4>
            <div className="bg-muted/30 max-h-[200px] space-y-3 overflow-y-auto rounded-md p-3">
              {recentActivities.map((activity, index) => (
                <div
                  key={index}
                  className="bg-card hover:bg-accent/10 rounded-lg border p-3 shadow-sm transition-colors"
                >
                  <div className="flex items-center justify-between gap-2">
                    <span className="font-medium">{activity.dataType}</span>
                    {activity.expiringDate && (
                      <span className="text-muted-foreground whitespace-nowrap text-sm">
                        Date: {format(new Date(activity.expiringDate), 'MM/dd/yyyy')}
                      </span>
                    )}
                  </div>
                  <p className="text-muted-foreground mt-1 text-sm">{activity.typeDescription}</p>
                  <p className="mt-1 text-xs">Ref: {activity.dataInternalReference}</p>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
