import { type Event } from '@kalos/kalos-rpc';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Card,
  CardContent,
  CardHeader,
  CardSkeleton,
  CardTitle,
  DateInput,
  Separator,
  Skeleton,
  TooltipProvider,
  WeekPicker,
} from '@kalos/ui';
import { Typography } from '@mui/material';
import { useQueries } from '@tanstack/react-query';
import { addDays, addHours, addMinutes, format, startOfDay, startOfWeek } from 'date-fns';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { JOB_STATUS_COLORS } from '../../../constants';
import { getCallWindowQueryConfig } from '../../../hooks/react-query/useEventsQuery';
import { useJobTypesQuery } from '../../../hooks/react-query/useJobTypesQuery';
import { getJobTypeBreakdown } from '../../ServiceCalendar/components/utils';
import { BreakDownBadge } from './BreakDownBage';

const generateTimeWindowsWithinTheDay = (date: Date) => {
  const windows = [];
  const timeZone = 'America/New_York';

  // Get the start of the day in the specified time zone
  const startOfTodayInTimeZone = utcToZonedTime(startOfDay(date), timeZone);
  startOfTodayInTimeZone.setHours(8, 0, 0, 0); // Set to 8:00 AM in Eastern Time

  let startTime = startOfTodayInTimeZone;
  const finalWindowStart = addMinutes(addHours(startOfTodayInTimeZone, 15), 45); // 11:45 PM local time

  // Create 4-hour windows for the entire day
  while (startTime <= finalWindowStart) {
    const windowEndTime = addHours(startTime, 1);
    const displayTime = `${formatTz(startTime, 'h aa', { timeZone })} - ${formatTz(windowEndTime, 'h aa', { timeZone })}`;

    let color;
    const hour = startTime.getHours();
    if (hour < 12) {
      color = '#FFA07A'; // Light Orange for 8 AM to 12 PM
    } else if (hour >= 12 && hour < 17) {
      color = '#FF8C00'; // Darker Orange for 12 PM to 5 PM
    } else {
      color = '#9370DB'; // Purple for 4 PM onwards
    }

    windows.push({
      startTime: formatTz(startTime, 'yyyy-MM-dd HH:mm:ss', { timeZone }),
      endTime: formatTz(windowEndTime, 'yyyy-MM-dd HH:mm:ss', { timeZone }),
      displayTime,
      color,
    });

    // Move to the next hour
    startTime = addHours(startTime, 1);
  }

  // Ensure a final window is included if the last generated window's start time is before 11:45 PM
  const lastWindow = windows[windows.length - 1];
  if (lastWindow.startTime !== formatTz(finalWindowStart, 'yyyy-MM-dd HH:mm:ss', { timeZone })) {
    windows.push({
      startTime: formatTz(finalWindowStart, 'yyyy-MM-dd HH:mm:ss', { timeZone }),
      endTime: formatTz(addHours(finalWindowStart, 4), 'yyyy-MM-dd HH:mm:ss', { timeZone }),
      displayTime: `${formatTz(finalWindowStart, 'h:mm aa', { timeZone })} - ${formatTz(addHours(finalWindowStart, 4), 'h:mm aa', { timeZone })}`,
      color: '#9370DB', // Purple for the last window
    });
  }

  return windows;
};

// 1) we need to get all days from Monday to Sunday
// 2) for each day we generate 4 time windows: 8AM - 12AM, 1PM - 5PM,  6PM - 10PM, 11PM - 4AM
const generateTimeWindowsWithinTheWeek = (date: Date) => {
  // Get start of week (Monday)
  const startOfWeekDate = startOfWeek(date, { weekStartsOn: 0 });

  // Generate array of 7 days starting from Monday
  const days = Array.from({ length: 7 }, (_, i) => addDays(startOfWeekDate, i));

  // Generate windows for each day
  return days.reduce<
    Array<{
      startTime: string;
      endTime: string;
      displayTime: string;
      color: string;
      day: Date;
    }>
  >((windows, currentDay) => {
    const dayStart = startOfDay(currentDay);

    // Define window configurations
    const windowConfigs = [
      {
        startHour: 8,
        endHour: 12,
        color: '#FFA07A',
      },
      {
        startHour: 13,
        endHour: 17,
        color: '#FF8C00',
      },
      {
        startHour: 18,
        endHour: 22,
        color: '#9370DB',
      },
      {
        startHour: 23,
        endHour: 28, // 4 AM next day (24 + 4)
        color: '#9370DB',
      },
    ];

    // Generate windows for current day
    const dayWindows = windowConfigs.map((config) => {
      const windowStart = addHours(dayStart, config.startHour);
      const windowEnd = addHours(dayStart, config.endHour);

      return {
        startTime: formatTz(windowStart, 'yyyy-MM-dd HH:mm:ss'),
        endTime: formatTz(windowEnd, 'yyyy-MM-dd HH:mm:ss'),
        displayTime: `${formatTz(windowStart, 'h aa')} - ${formatTz(windowEnd, 'h aa')}`,
        color: config.color,
        day: currentDay,
      };
    });

    return [...windows, ...dayWindows];
  }, []);
};

const removeLastSegment = (dateTimeString: string) => {
  // Replace colons with hyphens
  const formattedString = dateTimeString.replace(/:/g, '-');
  // Remove the last '-00' segment
  return formattedString.substring(0, formattedString.lastIndexOf('-'));
};

// 1) refactor to use query
// 2) add type "day" | "week"
export const CallWindow: React.FC<{
  type?: 'day' | 'week';
}> = ({ type = 'day' }) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const timeWindowsDay = useMemo(
    () => generateTimeWindowsWithinTheDay(selectedDate),
    [selectedDate],
  );
  const timeWindowsWeek = useMemo(
    () => generateTimeWindowsWithinTheWeek(selectedDate),
    [selectedDate],
  );

  const jobTypesQuery = useJobTypesQuery();

  const jobsInWindowQueryConfigs = useMemo(() => {
    const timeWindows = type === 'day' ? timeWindowsDay : timeWindowsWeek;
    return timeWindows.map((window) => {
      return {
        ...getCallWindowQueryConfig({
          startTime: removeLastSegment(window.startTime),
          endTime: removeLastSegment(window.endTime),
        }),
        refetchInterval: 60000,
        enabled: jobTypesQuery.isSuccess,
      };
    });
  }, [jobTypesQuery.isSuccess, timeWindowsDay, timeWindowsWeek, type]);

  const jobsInWindowQueries = useQueries({
    queries: jobsInWindowQueryConfigs,
    combine(result) {
      if (result.some((r) => r.isPending))
        return { isPending: true, data: null, isError: false, isSuccess: false };
      if (result.some((r) => r.isError))
        return { isPending: false, data: null, isError: true, isSuccess: false };
      const timeWindows = type === 'day' ? timeWindowsDay : timeWindowsWeek;
      for (const query of result) {
        for (const job of query.data?.jobsInWindow || []) {
          const jobType = jobTypesQuery.data?.find((jobType) => jobType.id === job.jobTypeId);
          job.jobType = jobType?.name || '';
        }
      }
      return {
        isPending: false,
        isSuccess: true,
        data: {
          windows: timeWindows.reduce(
            (acc, window, index) => {
              acc[window.startTime] = result[index].data?.jobsInWindow || [];
              return acc;
            },
            {} as { [key: string]: Event[] },
          ),
          callCounts: timeWindows.reduce(
            (acc, window, index) => {
              acc[window.startTime] = result[index].data?.callCount || 0;
              return acc;
            },
            {} as { [key: string]: number },
          ),
        },
        isError: false,
      };
    },
  });

  return (
    <div className="p-4">
      <div className="mb-4 flex items-center justify-between">
        {type === 'day' ? (
          <DateInput value={selectedDate} onChange={setSelectedDate} id="call-window-date-input" />
        ) : (
          <WeekPicker
            value={selectedDate}
            onChange={(date) => {
              if (date) setSelectedDate(date);
            }}
          />
        )}
      </div>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {!jobsInWindowQueries.data ? (
            type === 'week' ? (
              <CallWindowWeekSkeleton />
            ) : (
              Array.from({ length: 4 }).map((_, index) => <CardSkeleton key={index} />)
            )
          ) : // : timeWindows.map((window, index) => {
          //   const callCount = jobsInWindowQueries.data?.callCounts[window.startTime] || 0;
          //   const jobs = jobsInWindowQueries.data?.windows[window.startTime] || [];

          //   return (
          //     <CallWindowDay key={index} window={window} callCount={callCount} jobs={jobs} />
          //   );
          // })}
          type === 'day' ? (
            <CallWindowDay
              windows={jobsInWindowQueries.data?.windows}
              callCounts={jobsInWindowQueries.data?.callCounts}
              timeWindows={timeWindowsDay}
            />
          ) : (
            <CallWindowWeek
              windows={jobsInWindowQueries.data?.windows}
              callCounts={jobsInWindowQueries.data?.callCounts}
              timeWindows={timeWindowsWeek}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const CallWindowWeekSkeleton = () => {
  return Array.from({ length: 7 }).map((_, index) => {
    return <Skeleton key={index} className="h-60 w-48" />;
  });
};
const CallWindowWeek = ({
  windows,
  callCounts,
  timeWindows,
}: {
  windows: Record<string, Event[]>;
  callCounts: Record<string, number>;
  timeWindows: ReturnType<typeof generateTimeWindowsWithinTheWeek>[number][];
}) => {
  const daysWithWindows = useMemo(() => {
    // make an array of 7 days with 4 time windows each
    return Array.from({ length: 7 }, (_, index) => {
      return {
        day: timeWindows[index * 4].day,
        windows: timeWindows.slice(index * 4, index * 4 + 4),
      };
    });
  }, [timeWindows]);

  return daysWithWindows.map((day, index) => {
    const allJobsFromDay = day.windows.flatMap(
      (window) => Object.values(windows[window.startTime]) || [],
    );

    const pendingCalls = allJobsFromDay.filter((job) => job.logJobStatus === 'Pend Sched');
    const completedCalls = allJobsFromDay.filter((job) => job.logJobStatus === 'Completed');
    const confirmedCalls = allJobsFromDay.filter((job) => job.logJobStatus === 'Confirmed');

    const pendingCallsBreakdown = getJobTypeBreakdown(pendingCalls);
    const completedCallsBreakdown = getJobTypeBreakdown(completedCalls);
    const confirmedCallsBreakdown = getJobTypeBreakdown(confirmedCalls);
    const totalCallsBreakdown = getJobTypeBreakdown(allJobsFromDay);

    return (
      <Card key={index}>
        <CardHeader className="flex flex-row gap-2">
          <div className="flex size-8 max-w-max items-center justify-center rounded-full border border-gray-400 bg-gray-200 p-3 text-center text-sm shadow-inner">
            {day.day.getDate()}
          </div>
          <CardTitle>{format(day.day, 'EEEE')}</CardTitle>
        </CardHeader>
        <TooltipProvider>
          <div className="flex justify-center gap-1">
            <BreakDownBadge
              breakdown={totalCallsBreakdown}
              totalCount={allJobsFromDay.length}
              status="Total"
            />
            <BreakDownBadge
              breakdown={completedCallsBreakdown}
              totalCount={completedCalls.length}
              status="Completed"
            />
            <BreakDownBadge
              breakdown={pendingCallsBreakdown}
              totalCount={pendingCalls.length}
              status="Pending"
            />
            <BreakDownBadge
              breakdown={confirmedCallsBreakdown}
              totalCount={confirmedCalls.length}
              status="Confirmed"
            />
          </div>
        </TooltipProvider>
        <CardContent>
          {day.windows.map((window, index) => {
            return (
              <div className="space-y-2" key={index}>
                <div className="flex items-center justify-between gap-2">
                  <div>{window.displayTime}</div>
                  <div className="text-base text-gray-500">{callCounts[window.startTime]}</div>
                </div>
                <Separator decorative className="bg-gray-500" />
              </div>
            );
          })}
        </CardContent>
      </Card>
    );
  });
};

const CallWindowDay = ({
  windows,
  callCounts,
  timeWindows,
}: {
  windows: Record<string, Event[]>;
  callCounts: Record<string, number>;
  timeWindows: ReturnType<typeof generateTimeWindowsWithinTheDay>[number][];
}) => {
  const navigate = useNavigate();

  const handleJobClick = (jobId: number) => {
    navigate(`/jobs/${jobId}`);
  };

  return timeWindows.map((window, index) => {
    const callCount = callCounts[window.startTime] || 0;
    const jobs = windows[window.startTime] || [];

    return (
      <Card
        key={index}
        className="rounded-lg border shadow-sm"
        style={{ backgroundColor: window.color }}
      >
        <CardTitle className="rounded-t-lg bg-gray-100 p-2 text-center text-lg font-semibold">
          Window
        </CardTitle>

        <CardContent className="p-4">
          <Typography variant="h6" className="text-center text-base font-bold">
            {window.displayTime}
          </Typography>
          <Typography
            variant="body1"
            className="mt-2 text-center text-sm"
            style={{ fontWeight: 'bold' }}
          >
            Call Count: {callCount}
          </Typography>
        </CardContent>
        {jobs.length > 0 && (
          <Accordion type="single" collapsible>
            <AccordionItem value={`item`}>
              <AccordionTrigger className="rounded-t-md bg-white px-3 py-1 text-base font-bold shadow-sm">
                Call Information
              </AccordionTrigger>
              <AccordionContent className="rounded-b-md bg-white px-2 py-1 shadow-sm">
                {jobs.map((job, idx) => (
                  <div key={job.id} className="mb-3 p-2">
                    <div className="mb-2 rounded-md bg-white px-2 py-1 shadow-sm">
                      <span
                        className="cursor-pointer text-sm font-bold underline"
                        onClick={() => handleJobClick(job.id)}
                      >
                        Job ID: {job.id}
                      </span>
                    </div>
                    <div className="mb-2 inline-flex items-center gap-2 rounded-md bg-white px-2 py-1 shadow-sm">
                      <div
                        className="h-2 w-2 rounded-full"
                        style={{
                          backgroundColor: `#${JOB_STATUS_COLORS[job.logJobStatus] || JOB_STATUS_COLORS['*']}`,
                        }}
                      />
                      <span className="text-xs font-bold">Job Status: {job.logJobStatus}</span>
                    </div>
                    <div className="mb-2 rounded-md bg-white px-2 py-1 shadow-sm">
                      <span className="text-xs font-bold">Job Name: {job.name}</span>
                    </div>
                  </div>
                ))}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )}
      </Card>
    );
  });
};
