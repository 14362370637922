import { zodResolver } from '@hookform/resolvers/zod';
import { AssetInstance, NULL_TIME, Requisition, User } from '@kalos/kalos-rpc';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  DataTable,
  DataTableColumnHeader,
  DataTablePagination,
  DateInput,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormField,
  FormItem,
  FormMessage,
  Skeleton,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  toast,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@kalos/ui';
import { ArrowRightIcon, CalendarIcon } from '@radix-ui/react-icons';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { type ColumnDef, getCoreRowModel } from '@tanstack/react-table';
import { useReactTable } from '@tanstack/react-table';
import { format, parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useGetLoadingColumns } from '../../../components/utils';
import { ROWS_PER_PAGE } from '../../../constants';
import { useAuth } from '../../../context/AuthContext';
import {
  useAssetInstanceUpdateMutation,
  useBatchGetAssetInstanceQuery,
} from '../../../hooks/react-query/useAssetInstanceQuery';
import { useToolFundCatalogQuery } from '../../../hooks/react-query/useToolFundCatalogQuery';
import {
  useBatchGetRequisitionQuery,
  useRequisitionUpdateMutation,
} from '../../../hooks/react-query/useToolFundQuery';
import {
  useGetToolFundSettings,
  useUserToolFundBalanceQuery,
} from '../../../hooks/react-query/useToolFundSettingsQuery';
import { timestamp } from '../../../tools/helpers';
import { TechniciansComboboxPicker } from '../../ComponentsLibrary/Pickers/newPickers/TechnicianComboboxPicker';
import { RequisitionFormDialog } from './RequisitionFormDialog';
import { ToolFundCatalogDialog } from './ToolFundCatalogDialog';
const LOADING_REQUISITIONS: Requisition[] = Array.from({ length: 5 }, (_, idx) =>
  Requisition.create({
    id: idx,
    userId: 0,
    eventId: 0,
    requestDate: '',
    statusId: 0,
  }),
);

const LOADING_ASSETS: AssetInstance[] = Array.from({ length: 5 }, (_, idx) =>
  AssetInstance.create({
    id: idx,
    itemId: 0,
    fulfillmentId: 0,
    assetTag: '',
    currentLocationId: 0,
  }),
);

interface ToolFundRequestsProps {
  userId: number;
}

const reassignSchema = z.object({
  employeeId: z.number().min(1, { message: 'Please select an employee' }),
});

const defaultReassignValues = {
  employeeId: 0,
};

type ReassignFormValues = z.infer<typeof reassignSchema>;

const ReassignDialog = ({ requisition }: { requisition: Requisition }) => {
  const [isOpen, setIsOpen] = useState(false);

  const form = useForm<ReassignFormValues>({
    resolver: zodResolver(reassignSchema),
    defaultValues: defaultReassignValues,
  });

  useEffect(() => {
    if (!isOpen) {
      form.reset(defaultReassignValues);
    }
  }, [form, isOpen]);

  const requisitionUpdateMutation = useRequisitionUpdateMutation();

  const handleReassign = async (values: ReassignFormValues) => {
    if (!values.employeeId) return;

    try {
      await requisitionUpdateMutation.mutateAsync(
        Requisition.create({
          id: requisition.id,
          userId: Number(values.employeeId),
          fieldMask: ['UserId'],
        }),
      );

      toast({
        title: 'Success',
        description: 'Request reassigned successfully',
        variant: 'success',
      });

      setIsOpen(false);
      form.reset();
    } catch (err) {
      console.error('Failed to reassign requisition:', err);
      toast({
        title: 'Error',
        description: 'Failed to reassign request',
        variant: 'destructive',
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <TooltipProvider>
        <Tooltip>
          <DialogTrigger asChild>
            <TooltipTrigger asChild>
              <Button variant="outline" size="icon">
                <ArrowRightIcon />
              </Button>
            </TooltipTrigger>
          </DialogTrigger>
          <TooltipContent>Reassign Request</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Reassign Request</DialogTitle>
          <DialogDescription>
            Select an employee to reassign this tool request to.
          </DialogDescription>
        </DialogHeader>

        <form onSubmit={form.handleSubmit(handleReassign)} className="space-y-4">
          <Form {...form}>
            <FormField
              control={form.control}
              name="employeeId"
              render={({ field }) => (
                <FormItem>
                  <TechniciansComboboxPicker
                    onSelect={(u) => field.onChange(u?.id ?? 0)}
                    selected={field.value.toString()}
                    disabled={field.disabled}
                    emptyLabel="Select an employee to reassign to"
                    placeholder="Search Employee..."
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter className="flex flex-col gap-2">
              <Button
                variant="outline"
                type="button"
                disabled={requisitionUpdateMutation.isPending}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={requisitionUpdateMutation.isPending}
                isLoading={requisitionUpdateMutation.isPending}
              >
                Reassign
              </Button>
            </DialogFooter>
          </Form>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const getDefaultVestingDate = (asset: AssetInstance) => {
  if (!asset.vestingDate) return undefined;

  const timestamp = Number(asset.vestingDate);
  if (isNaN(timestamp)) return undefined;

  const date = new Date(timestamp);
  return isNaN(date.getTime()) ? undefined : date;
};

const UpdateVestingDateDialog = ({ asset }: { asset: AssetInstance }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [vestingDate, setVestingDate] = useState<Date | undefined>(getDefaultVestingDate(asset));

  useEffect(() => {
    if (!isOpen) {
      setVestingDate(getDefaultVestingDate(asset));
    }
  }, [asset, isOpen]);

  const updateMutation = useAssetInstanceUpdateMutation();

  const handleUpdate = async () => {
    if (!vestingDate || isNaN(vestingDate.getTime())) {
      toast({
        title: 'Error',
        description: 'Please select a valid date',
        variant: 'destructive',
      });
      return;
    }

    try {
      const date = new Date(vestingDate);
      date.setHours(23, 59, 59, 999);

      await updateMutation.mutateAsync(
        AssetInstance.create({
          id: asset.id,
          vestingDate: timestamp(false, date),
          fieldMask: ['VestingDate'],
        }),
      );

      toast({
        title: 'Success',
        description: 'Vesting date updated successfully',
        variant: 'success',
      });

      setIsOpen(false);
    } catch (err) {
      console.error('Failed to update vesting date:', err);
      toast({
        title: 'Error',
        description: 'Failed to update vesting date',
        variant: 'destructive',
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <TooltipProvider>
        <Tooltip>
          <DialogTrigger asChild>
            <TooltipTrigger asChild>
              <Button variant="outline" size="icon">
                <CalendarIcon className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
          </DialogTrigger>
          <TooltipContent>Update Vesting Date</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Update Vesting Date</DialogTitle>
          <DialogDescription>Set a new vesting date for this tool.</DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          <FormItem>
            <DateInput
              value={vestingDate}
              onChange={(date) => {
                if (date && !isNaN(date.getTime())) {
                  setVestingDate(date);
                }
              }}
              mode="day"
              disableManualInput
            />
          </FormItem>

          <DialogFooter className="gap-2">
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={handleUpdate}
              disabled={!vestingDate || isNaN(vestingDate.getTime()) || updateMutation.isPending}
              isLoading={updateMutation.isPending}
            >
              Update
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const MarkAsReceivedDialog = ({ asset }: { asset: AssetInstance }) => {
  const [isOpen, setIsOpen] = useState(false);
  const updateMutation = useAssetInstanceUpdateMutation();

  const handleMarkAsReceived = async () => {
    try {
      const now = new Date();
      const formattedDate = format(now, 'yyyy-MM-dd HH:mm:ss');

      await updateMutation.mutateAsync(
        AssetInstance.create({
          id: asset.id,
          acquisitionDate: formattedDate,
          fieldMask: ['AcquisitionDate'],
        }),
      );

      toast({
        title: 'Success',
        description: 'Tool marked as received',
        variant: 'success',
      });

      setIsOpen(false);
    } catch (err) {
      console.error('Failed to mark tool as received:', err);
      toast({
        title: 'Error',
        description: 'Failed to mark tool as received',
        variant: 'destructive',
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <TooltipProvider>
        <Tooltip>
          <DialogTrigger asChild>
            <TooltipTrigger asChild>
              <Button variant="outline" size="icon">
                ✓
              </Button>
            </TooltipTrigger>
          </DialogTrigger>
          <TooltipContent>Mark as Received</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Mark Tool as Received</DialogTitle>
          <DialogDescription>
            Are you sure you want to mark this tool as received? This will set the acquisition date
            to today.
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="gap-2">
          <Button variant="outline" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleMarkAsReceived}
            disabled={updateMutation.isPending}
            isLoading={updateMutation.isPending}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const EmployeeToolLog = ({ userId }: ToolFundRequestsProps) => {
  const [activeTab, setActiveTab] = useState('pending');
  const [page, setPage] = useState(0);

  const { data: balance, isLoading: isLoadingBalance } = useUserToolFundBalanceQuery(
    User.create({ id: userId }),
  );

  const { data: allPendingRequests, isLoading: isLoadingPendingRequests } =
    useBatchGetRequisitionQuery({
      filters: { userId, statusId: 1, pageNumber: -1, isActive: true },
    });

  const pendingCost = useMemo(() => {
    return (
      allPendingRequests?.results?.reduce(
        (total, req) => total + (req.totalEstimatedCost || 0),
        0,
      ) ?? 0
    );
  }, [allPendingRequests]);

  const { data: requestsData, isLoading: isLoadingRequests } = useBatchGetRequisitionQuery({
    filters: {
      userId,
      statusId: 1,
      pageNumber: page,
      withItems: true,
      withFulfillments: true,
      withStatus: true,
    },
  });
  const { data: assetsData, isLoading: isLoadingAssets } = useBatchGetAssetInstanceQuery({
    filters: {
      currentUserId: userId,
      pageNumber: page,
      //isActive: 1,
    },
  });

  const { user: loggedUser } = useAuth();
  const isToolFundAdmin = loggedUser.permissionGroups.some((perm) => perm.name === 'ToolFundAdmin');
  const isOwnLog = loggedUser.id === userId;
  const requestColumns: ColumnDef<Requisition>[] = useMemo(
    () => [
      {
        id: 'toolName',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} hideVisibilityToggle title="Tool Name" />
        ),
        cell: ({ row }) => {
          const catalogItemName = row.original.items[0]?.customItemName;
          return catalogItemName ?? 'Unknown Tool';
        },
      },
      {
        accessorKey: 'dateCreated',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle column={column} title="Request Date" />
        ),
        cell: ({ row }) => new Date(row.original.requestDate).toLocaleDateString(),
      },
      {
        accessorKey: 'status',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle column={column} title="Status" />
        ),
        cell: 'Pending',
      },
      {
        accessorKey: 'totalEstimatedCost',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle column={column} title="Cost" />
        ),
        cell: ({ row }) => `$${(row.original.totalEstimatedCost || 0).toFixed(2)}`,
      },
      {
        id: 'actions',
        cell: ({ row }) => {
          const requisition = row.original;
          return isToolFundAdmin ? <ReassignDialog requisition={requisition} /> : null;
        },
      },
    ],
    [isToolFundAdmin],
  );

  const { data: catalogData } = useToolFundCatalogQuery({
    filter: { pageNumber: -1 },
    select(data) {
      return new Map(data.results.map((item) => [item.id, item]));
    },
  });

  const assetColumns: ColumnDef<AssetInstance>[] = useMemo(
    () => [
      {
        id: 'toolName',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} hideVisibilityToggle title="Tool Name" />
        ),
        cell: ({ row }) => {
          const asset = row.original;
          const catalogItem = catalogData?.get(asset.itemId);
          return catalogItem?.name || asset.assetTag || 'Unknown Tool';
        },
      },
      {
        id: 'acquisitionDate',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle column={column} title="Acquisition Date" />
        ),
        cell: ({ row }) => {
          const acquisitionDate = row.original.acquisitionDate;
          if (!acquisitionDate || acquisitionDate === NULL_TIME || acquisitionDate === '') {
            return 'N/A';
          }
          return format(parseISO(acquisitionDate), 'MM/dd/yyyy');
        },
      },
      {
        id: 'vestingDate',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle column={column} title="Vesting Date" />
        ),
        cell: ({ row }) => {
          const vestingDate = row.original.vestingDate;
          if (!vestingDate) return 'N/A';

          const date = parseISO(vestingDate);
          return isNaN(date.getTime()) ? 'Invalid Date' : format(date, 'MM/dd/yyyy');
        },
      },
      {
        id: 'actions',
        cell: ({ row }) => {
          const asset = row.original;
          return (
            <div className="flex gap-2">
              {isToolFundAdmin && <UpdateVestingDateDialog asset={asset} />}
              {isOwnLog && (asset.acquisitionDate == NULL_TIME || asset.acquisitionDate == '') && (
                <MarkAsReceivedDialog asset={asset} />
              )}
            </div>
          );
        },
      },
    ],
    [isToolFundAdmin, isOwnLog, catalogData],
  );

  const loadingRequestsColumns = useGetLoadingColumns(requestColumns);

  const requestsTable = useReactTable<Requisition>({
    data: isLoadingRequests ? LOADING_REQUISITIONS : requestsData?.results ?? [],
    columns: isLoadingRequests ? loadingRequestsColumns : requestColumns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id.toString(),
    enableSorting: false,
  });

  const loadingAssetsColumns = useGetLoadingColumns(assetColumns);

  const assetsTable = useReactTable<AssetInstance>({
    data: isLoadingAssets ? LOADING_ASSETS : assetsData?.results ?? [],
    columns: isLoadingAssets ? loadingAssetsColumns : assetColumns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id.toString(),
  });

  const pageCount = {
    pending: requestsData?.totalCount ? Math.ceil(requestsData.totalCount / ROWS_PER_PAGE) : 0,
    tools: assetsData?.totalCount ? Math.ceil(assetsData.totalCount / ROWS_PER_PAGE) : 0,
  };

  const netBalance = useMemo(() => {
    return (balance?.currentBalance ?? 0) - pendingCost;
  }, [balance?.currentBalance, pendingCost]);

  const { data: toolFundSettings, isLoading: isLoadingSettings } = useGetToolFundSettings({
    userId,
  });

  const isEligibleForToolFund = useMemo(() => {
    const hasPositiveBalance = netBalance > 0;
    return (toolFundSettings?.isEligible ?? false) && hasPositiveBalance;
  }, [toolFundSettings, netBalance]);

  const isLoading = isLoadingBalance || isLoadingPendingRequests || isLoadingSettings;

  return (
    <Card className="max-w-[100vw] px-0 pt-6 sm:pt-0">
      <CardHeader className="flex flex-col items-center justify-between pt-0 sm:pr-12 md:flex-row">
        <CardTitle>Employee Tool Log</CardTitle>
        <div className="flex flex-col gap-2 md:flex-row">
          <ToolFundCatalogDialog
            trigger={
              <Button variant="outline" disabled={isLoading}>
                View Tool Catalog
              </Button>
            }
          />
          {isLoading ? (
            <Button disabled>
              <Skeleton className="h-4 w-24" />
            </Button>
          ) : isEligibleForToolFund ? (
            <RequisitionFormDialog userId={userId} />
          ) : (
            <Button disabled>
              {toolFundSettings?.isEligible
                ? 'Insufficient Balance'
                : 'Not Eligible to Request Tools'}
            </Button>
          )}
        </div>
      </CardHeader>
      <CardContent className="p-0">
        <div className="space-y-4">
          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-muted-foreground text-sm font-medium">Available Balance</p>
                  {isLoading ? (
                    <Skeleton className="h-8 w-24" />
                  ) : (
                    <h2 className="text-2xl font-bold">${netBalance.toFixed(2)}</h2>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>

          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList>
              <TabsTrigger value="pending" disabled={isLoading}>
                Pending Requests
              </TabsTrigger>
              <TabsTrigger value="tools" disabled={isLoading}>
                Tools
              </TabsTrigger>
            </TabsList>

            <TabsContent value="pending" className="max-w-full space-y-4 overflow-auto">
              {isLoadingRequests ? (
                <div className="space-y-2">
                  <Skeleton className="h-10 w-full" />
                  <Skeleton className="h-10 w-full" />
                  <Skeleton className="h-10 w-full" />
                </div>
              ) : (
                <>
                  {pageCount.pending > 1 && (
                    <div className="flex items-center justify-end pr-4">
                      <div className="flex w-full max-w-max items-center justify-between gap-4">
                        <DataTablePagination
                          currentPage={page}
                          setPage={setPage}
                          pageCount={pageCount.pending}
                        />
                        {requestsData?.totalCount && (
                          <p>Total Entries: {requestsData.totalCount}</p>
                        )}
                      </div>
                    </div>
                  )}
                  <DataTable table={requestsTable} />
                </>
              )}
            </TabsContent>

            <TabsContent value="tools" className="max-w-full space-y-4 overflow-auto">
              {isLoadingAssets ? (
                <div className="space-y-2">
                  <Skeleton className="h-10 w-full" />
                  <Skeleton className="h-10 w-full" />
                  <Skeleton className="h-10 w-full" />
                </div>
              ) : (
                <>
                  {pageCount.tools > 1 && (
                    <div className="flex items-center justify-end pr-4">
                      <div className="flex w-full max-w-max items-center justify-between gap-4">
                        <DataTablePagination
                          currentPage={page}
                          setPage={setPage}
                          pageCount={pageCount.tools}
                        />
                        {assetsData?.totalCount && assetsData.totalCount > 0 && (
                          <p>Total Entries: {assetsData.totalCount}</p>
                        )}
                      </div>
                    </div>
                  )}
                  <DataTable table={assetsTable} />
                </>
              )}
            </TabsContent>
          </Tabs>
        </div>
      </CardContent>
    </Card>
  );
};
