import './AdvancedSearch.module.less';

import {
  type Event,
  type Property,
  User,
  UserAttributes,
  type UsersFilter,
} from '@kalos/kalos-rpc';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Card,
  CardHeader,
  CardTitle,
  cn,
  DataTablePagination,
  SimpleSelect,
} from '@kalos/ui';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQueryClient } from '@tanstack/react-query';
import throttle from 'lodash/throttle';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MONTHS, ROWS_PER_PAGE, USA_STATES_OPTIONS, WORKERS_COMP_CODES } from '../../../constants';
import { useAuth } from '../../../context/AuthContext';
import { queryKeys } from '../../../hooks/react-query/constants';
import { useQuoteLineQuery } from '../../../hooks/react-query/useQuoteLineQuery';
import { useTimesheetDepartmentListQuery } from '../../../hooks/react-query/useTimesheetDepartmentQuery';
import { useUserQuery } from '../../../hooks/react-query/useUserQuery';
import {
  type EventsFilter,
  MapClientService,
  type PropertiesFilter,
  S3ClientService,
  UserClientService,
} from '../../../tools/helpers';
import { AddServiceCall } from '../../AddServiceCallGeneral/components/AddServiceCall';
import { CustomerEdit } from '../CustomerEdit';
import { Field } from '../Field';
import { FlatRateSheet } from '../FlatRate';
import { Form } from '../Form';
import { Modal } from '../Modal';
import { type Schema } from '../PlainForm';
import { ContractsSearch } from './ContractsSearch/ContractsSearch';
import { CustomersSearch } from './CustomerSearch/CustomersSearch';
import { EmployeesSearch } from './EmployeesSearch/EmployeesSearch';
import { useAdvancedSearchPaginationStore } from './paginationStore';
import { PropertiesSearch } from './PropertiesSearch/PropertiesSearch';
import { ServiceCallsSearch } from './ServiceCallsSearch/ServiceCallsSearch';

export type Kind = 'serviceCalls' | 'customers' | 'properties' | 'employees' | 'contracts';

export interface AdvancedSearchProps {
  title: string;
  kinds: Kind[];
  deletableEvents?: boolean;
  editableCustomers?: boolean;
  deletableCustomers?: boolean;
  showRecentServiceCallsForEmployee?: boolean;
  onChangeKindSelection?: (kind: Kind) => void;
  printableEmployees?: boolean;
  editableProperties?: boolean;
  deletableProperties?: boolean;
  eventsWithAccounting?: boolean;
  eventsWithAdd?: boolean;
  className?: string;
  onSelectEvent?: (event: Event) => void;
  onSelectProperty?: (property: Property) => void;
  disableParamsSync?: boolean;
  onClose?: () => void;
  omitArchivedJobs?: boolean;
}

export type SearchForm = EventsFilter | UsersFilter | PropertiesFilter;

const ACCOUNTING = 'Accounting';
const SERVICE = 'Service';

export const AdvancedSearch: FC<AdvancedSearchProps> = ({
  title,
  kinds,
  deletableEvents,
  editableCustomers,
  deletableCustomers,
  showRecentServiceCallsForEmployee,
  printableEmployees = false,
  editableProperties,
  deletableProperties,
  eventsWithAccounting = false,
  onChangeKindSelection,
  eventsWithAdd = false,
  className,
  onSelectEvent,
  onSelectProperty,
  disableParamsSync,
  onClose,
  omitArchivedJobs,
}) => {
  const auth = useAuth();
  const loggedUserId = auth.user.id;

  const userQuery = useUserQuery({
    filters: {
      id: loggedUserId || 0,
    },
    enabled: typeof loggedUserId === 'number',
    select(data) {
      return {
        ...data,
        isManager: !!(
          data.isAdmin ||
          data.permissionGroups.filter((el) => el.name == 'Manager' || el.name == 'Payroll')
            .length > 0
        ),
        canAddEmployee: !!(
          data.permissionGroups.filter(
            (el) =>
              el.name == 'HR' ||
              el.name == 'Payroll' ||
              el.name == 'LimitedEmployeeDirectoryAccess',
          ).length > 0
        ),
      };
    },
  });
  const isHR = useMemo(() => {
    return (
      userQuery.data &&
      userQuery.data.permissionGroups.filter((el) => el.name == 'HR' || el.name == 'Payroll')
        .length > 0
    );
  }, [userQuery.data]);

  const [flatRateIsOpen, setFlatRateIsOpen] = useState<boolean>(false);

  const flatRateQuery = useQuoteLineQuery({
    filters: {
      isActive: 1,
      isFlatrate: '1',
      pageNumber: 0,
      withoutLimit: true,
    },
    enabled: eventsWithAdd,
  });

  const [searchParams, setSearchParams] = useSearchParams({
    kind: kinds[0],
  });

  const selectedKind = useMemo(() => {
    const kindFromParams = searchParams.get('kind');
    return kinds.includes(kindFromParams as Kind) ? (kindFromParams as Kind) : kinds[0];
  }, [kinds, searchParams]);

  const setSelectedKind = useCallback(
    (kind: Kind) => {
      setSearchParams((prev) => ({ ...prev, kind }));
    },
    [setSearchParams],
  );

  const page = useAdvancedSearchPaginationStore((state) => state.page);
  const setPage = useAdvancedSearchPaginationStore((state) => state.setPage);
  // To solve https://github.com/kalos-software/kalos-frontend/issues/546
  const [_page, _setPage] = useState(0);

  // synchronize global page (that is used in query) with pagination state if it was changed from outside
  useEffect(() => {
    _setPage(page);
  }, [page]);

  const totalEntriesCount = useAdvancedSearchPaginationStore((state) => state.totalEntries);

  const [accounting, setAccounting] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const reloadServiceCallQuery = useCallback(() => {
    queryClient.refetchQueries({ queryKey: [queryKeys.events.root] });
  }, [queryClient]);

  const [pendingEventAdding, setPendingEventAdding] = useState<boolean>(false);
  const [pendingCustomerEditing, setPendingCustomerEditing] = useState<User>();

  const isAdmin = !!userQuery.data?.isAdmin;
  const canAddEmployee = !!userQuery.data?.canAddEmployee;
  const handleInternalPageChange = useMemo(
    () =>
      throttle(
        (newPageValue: number) => {
          setPage(newPageValue);
        },
        2500,
        {
          leading: true,
          trailing: true,
        },
      ),
    [setPage],
  );

  const handlePageChange = useCallback(
    (updater: React.SetStateAction<number>) => {
      _setPage((prevValue) => {
        const newValue = typeof updater === 'function' ? updater(prevValue) : updater;
        handleInternalPageChange(newValue);
        return newValue;
      });
    },
    [handleInternalPageChange],
  );

  const handleSelectedKindChange = useCallback(
    (selectedKind: Kind) => {
      setSelectedKind(selectedKind);
      setPage(0);
    },
    [setPage, setSelectedKind],
  );

  const handlePendingEventAddingToggle = useCallback(
    (pendingEventAdding: boolean) => () => setPendingEventAdding(pendingEventAdding),
    [setPendingEventAdding],
  );

  const handlePendingCustomerEditingToggle = useCallback(
    (pendingCustomerEditing?: User) => () => setPendingCustomerEditing(pendingCustomerEditing),
    [setPendingCustomerEditing],
  );

  const onSaveCustomer = useCallback(() => {
    setPendingCustomerEditing(undefined);

    queryClient.refetchQueries({ queryKey: [queryKeys.user.root, queryKeys.user.list] });
  }, [queryClient]);

  const handleAccountingToggle = useCallback(
    () => setAccounting(!accounting),
    [accounting, setAccounting],
  );

  const [newEmployeeEditing, setNewEmployeeEditing] = useState<User>();
  const [newUserAttributes, setNewUserAttributes] = useState<UserAttributes>(
    UserAttributes.create(),
  );
  const createNewEmployee = useCallback(async () => {
    const newEmployee = User.create();
    newEmployee.hireDate = new Date().toISOString();
    newEmployee.isEmployee = 1;

    newEmployee.userAttributes = await UserClientService.GetUserAttributes(
      UserAttributes.create({
        userId: newEmployee.id,
        fieldMask: ['UserId'],
      }),
    );
    setNewUserAttributes(newEmployee.userAttributes);

    setNewEmployeeEditing(newEmployee);
  }, []);

  const limitedAccess =
    userQuery.data?.permissionGroups.find(
      (permission) => permission.name === 'LimitedEmployeeDirectoryAccess',
    ) != undefined;
  const departmentsQuery = useTimesheetDepartmentListQuery({ filter: { isActive: 1 } });

  const [employeeUploadedPhoto, setEmployeeUploadedPhoto] = useState<string>('');
  const handleEmployeePhotoUpload = useCallback(
    (file: string | ArrayBuffer | null) => {
      if (!file) return;
      setEmployeeUploadedPhoto(file as string);
    },
    [setEmployeeUploadedPhoto],
  );

  const handleDeleteEmployeePhoto = useCallback(() => {
    if (!newEmployeeEditing) return;
    setEmployeeUploadedPhoto('');
  }, [newEmployeeEditing]);

  const onNewEmployeeClose = useCallback(() => {
    setNewEmployeeEditing(undefined);
    setEmployeeUploadedPhoto('');
  }, []);

  const makeSchemaEmployeesCreate = useCallback(
    (entry: User): Schema<User> => {
      return [
        [{ name: 'isEmployee', type: 'hidden' }],
        [{ headline: true, label: 'Personal Details' }],
        [
          {
            name: 'firstname',
            label: 'First Name',
            required: true,
            disabled: !isAdmin && userQuery.data?.id != entry.id,
          },
          {
            name: 'lastname',
            label: 'Last Name',
            required: true,
            disabled: !isAdmin && userQuery.data?.id != entry.id,
          },
        ],
        [
          {
            name: 'address',
            label: 'Street Address',
            required: true,
            multiline: true,
            disabled: !isAdmin && userQuery.data?.id != entry.id && limitedAccess,
          },
          {
            name: 'city',
            required: true,
            label: 'City',
            disabled: !isAdmin && userQuery.data?.id != entry.id && limitedAccess,
          },
          {
            name: 'zip',
            required: true,
            label: 'Zipcode',
            disabled: !isAdmin && userQuery.data?.id != entry.id && limitedAccess,
          },
          {
            name: 'state',
            label: 'State',
            required: true,
            options: USA_STATES_OPTIONS,
            disabled: !isAdmin && userQuery.data?.id != entry.id && limitedAccess,
          },
        ],
        [
          {
            name: 'empTitle',
            label: 'Title',
            disabled: !isAdmin && limitedAccess,
          },
          {
            name: 'employeeDepartmentID',
            label: 'Employee Segment',
            options:
              departmentsQuery.data?.results.map((d) => ({
                label: `${d.value} - ${d.description}`,
                value: d.id,
              })) ?? [],
            required: true,
            disabled: !isHR && !!newEmployeeEditing && !!newEmployeeEditing.id,
          },
          {
            name: 'managedBy',
            label: 'Assigned Supervisor',
            type: 'technician',
          },
          {
            name: 'empBadgeId',
            label: 'Workers Comp Code',
            options: WORKERS_COMP_CODES,
            type: 'number',
            disabled: !isHR && entry.id == 0,
          },
        ],
        [
          {
            name: 'phone',
            label: 'Primary Phone',
            required: true,
            disabled: !isAdmin && userQuery.data?.id != entry.id && limitedAccess,
          },
          {
            name: 'cellphone',
            label: 'Cell Phone',
            disabled: !isAdmin && userQuery.data?.id != entry.id && limitedAccess,
          },
          {
            name: 'ext',
            label: 'Ext',
            disabled: !isAdmin && userQuery.data?.id != entry.id && limitedAccess,
          },
          {
            name: 'toolFund',
            label: 'Tool Fund Allowance',
            disabled: !isAdmin,
          },
        ],
        [
          {
            name: 'email',
            label: 'Email',
            disabled: !isAdmin && userQuery.data?.id != entry.id,
            required: true,
            validationOnSave(value) {
              if (value.trim().replaceAll(' ', '').length <= 0) return 'This field is required';
              else if (!/^\S+@\S+\.\S+$/.test(value)) return 'Please write correct email address';
              return '';
            },
          },
          {
            name: 'phoneEmail',
            label: 'Email-to-SMS',
            disabled: !isAdmin && userQuery.data?.id != entry.id,
          },
          {},
          {},
        ],
        [{ headline: true, label: 'Employee Permission Details' }],
        [
          {
            name: 'serviceCalls',
            label: 'Runs Jobs',
            type: 'checkbox',
            disabled: !isAdmin,
          },
          {
            name: 'isAdmin',
            label: 'Admin Menu Rights',
            type: 'checkbox',
            disabled: !isAdmin,
          },
          // {
          //   name: 'isAdmin', // FIXME include_in_pdf_list
          //   label: 'Add To Directory PDF',
          //   type: 'checkbox',
          // },
          // {
          //   name: 'isAdmin', // FIXME user_pwreset
          //   label: 'Force P/W Reset',
          //   type: 'checkbox',
          // },
          {
            name: 'paidServiceCallStatus',
            label: '"Paid" Job Status',
            type: 'checkbox',
            disabled: !isAdmin,
          },
          {
            name: 'showBilling',
            label: 'Show billing to user',
            type: 'checkbox',
            disabled: !isAdmin,
          },
        ],
        [
          {
            name: 'isOfficeStaff',
            label: 'Office Staff',
            type: 'checkbox',
            disabled: !isAdmin,
          },
          {
            name: 'isHvacTech',
            label: 'Display On Dispatch?',
            type: 'checkbox',
            disabled: !isAdmin,
          },
          {
            name: 'techAssist',
            label: 'Tech Assist',
            type: 'checkbox',
            disabled: !isAdmin,
          },
          {},
        ],

        [{ headline: true, label: 'Kalos Special Features' }],
        [
          {
            name: 'isColorMute',
            label: 'Color Mute [2017]',
            type: 'checkbox',
            disabled: userQuery.data?.id != entry.id,
          },
          // {
          //   name: 'isAdmin', // FIXME admin_matrics
          //   label: 'Admin Metrics',
          //   type: 'checkbox',
          // },
          // {
          //   name: 'isAdmin', // FIXME tech_matrics
          //   label: 'Tech Metrics',
          //   type: 'checkbox',
          // },
        ],
        // [
        //   {
        //     name: 'isAdmin', // FIXME install_matrics
        //     label: 'Install Metrics',
        //     type: 'checkbox',
        //   },
        //   {
        //     name: 'isAdmin', // FIXME garage_door_matrics
        //     label: 'Garage Door Metrics',
        //     type: 'checkbox',
        //   },
        //   {
        //     name: 'isAdmin', // FIXME refrigeration_matrics
        //     label: 'Refrigeration Metrics',
        //     type: 'checkbox',
        //   },
        // ],
        // [
        //   {
        //     name: 'isAdmin', // FIXME electrician_matrics
        //     label: 'Electrician Metrics',
        //     type: 'checkbox',
        //   },
        //   {},
        //   {},
        // ],
        [{ headline: true, label: 'Paid Time-Off' }],
        [
          {
            name: 'hireDate',
            label: 'Hire Date',
            type: 'mui-date',
            disabled: !!userQuery.data?.permissionGroups.find((el) => el.name == 'HR'),
          },
          // {
          //   // name: 'userAttributes',
          //   label: 'Override Hire Month',
          //   options: MONTHS.map((month, index) => ({
          //     label: month,
          //     value: month,
          //     key: index
          //   })),
          //   defaultValue: entry.userAttributes?.overrideMonth,
          // },
          {
            content: (
              <FormControl sx={{ minWidth: 170 }}>
                <InputLabel>Override Hire Month</InputLabel>
                <Select
                  label={'Override Hire Month'}
                  disabled={true}
                  defaultValue={entry.userAttributes?.overrideMonth}
                  onChange={(data) => {
                    const userAtt = UserAttributes.clone(newUserAttributes);
                    if (userAtt.userId === 0) {
                      userAtt.userId = entry.id;
                    }
                    userAtt.overrideMonth = Number(data.target.value);
                    if (!userAtt.fieldMask.includes('UserId')) {
                      userAtt.fieldMask.push('UserId');
                    }
                    if (!userAtt.fieldMask.includes('OverrideMonth')) {
                      userAtt.fieldMask.push('OverrideMonth');
                    }
                    setNewUserAttributes(userAtt);
                  }}
                >
                  {MONTHS.map((month, index) => {
                    return (
                      <MenuItem value={index + 1} key={index + 1}>
                        {month}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ),
          },
          {
            content: (
              <Field
                label="Currently Allocated PTO"
                type="number"
                name="PTO Value"
                disabled={true}
                value={0}
              />
            ),
          },
          /*{
        name: 'currentPtoAmount',
        label: 'Current Available PTO',
        type: 'number',
        disabled: true,
      }*/
        ],
        // [{ headline: true, label: 'Dispatch Mode Permission' }],
        // [
        //   {
        //     name: 'isAdmin', // FIXME can_access_dispatch_mode
        //     label: 'Access Dispatch Mode',
        //     type: 'checkbox',
        //   },
        //   {
        //     name: 'isAdmin', // FIXME access_dismiss_employee
        //     label: 'Can Dismiss Employee',
        //     type: 'checkbox',
        //   },
        //   {
        //     name: 'isAdmin', // FIXME is_training_admin
        //     label: 'Training Admin',
        //     type: 'checkbox',
        //   },
        // ],
        [{ headline: true, label: 'Photo' }],
        [
          {
            content: (
              <Avatar className="h-40 w-40">
                <AvatarImage className="object-cover" src={employeeUploadedPhoto || entry.image} />
                <AvatarFallback>{`${entry.firstname.at(0)?.toUpperCase() || ''} ${
                  entry.lastname.at(0)?.toUpperCase() || ''
                }`}</AvatarFallback>
              </Avatar>
            ),
          },
          {
            name: 'image',
            type: 'file',
            withDeleteButton: true,
            onDeleteClick: handleDeleteEmployeePhoto,
            label: 'Photo',
            actionsInLabel: true,
            onFileLoad: handleEmployeePhotoUpload,
          },
          {},
          {},
        ],
      ];
    },
    [
      departmentsQuery.data?.results,
      employeeUploadedPhoto,
      handleDeleteEmployeePhoto,
      handleEmployeePhotoUpload,
      isAdmin,
      isHR,
      limitedAccess,
      newEmployeeEditing,
      newUserAttributes,
      userQuery.data?.id,
      userQuery.data?.permissionGroups,
    ],
  );

  const [saving, setSaving] = useState<boolean>(false);
  const onSaveEmployee = useCallback(
    async (data: User) => {
      if (newEmployeeEditing) {
        setSaving(true);
        if (employeeUploadedPhoto) {
          await S3ClientService.uploadFileToS3Bucket(
            data.image,
            employeeUploadedPhoto,
            'kalos-employee-images',
          );
        }
        const newData = User.clone(data);
        const address = newData.address;
        const city = newData.city;
        const addressState = newData.state;
        const zip = newData.zip;
        const geo = await MapClientService.loadGeoLocationByAddress(
          `${address}, ${city}, ${addressState} ${zip}`,
        );
        if (geo) {
          newData.geolocationLat = geo.geolocationLat;
          newData.geolocationLng = geo.geolocationLng;
        }
        if (newData.fieldMask.length > 0) {
          await UserClientService.saveUser(newData, newEmployeeEditing.id);
          queryClient.invalidateQueries({ queryKey: [queryKeys.user.root, queryKeys.user.list] });
        }
        if (newUserAttributes.fieldMask.length > 0) {
          try {
            const currentUserAttributes = await UserClientService.GetUserAttributes(
              UserAttributes.create({
                userId: newUserAttributes.userId,
                fieldMask: ['UserId'],
              }),
            );
            if (currentUserAttributes.id !== 0) {
              await UserClientService.UpdateUserAttributes(newUserAttributes);
            } else {
              await UserClientService.CreateUserAttributes(newUserAttributes);
            }
          } catch (err) {
            console.error(err);
          }
        }
        setNewEmployeeEditing(undefined);
        setNewUserAttributes(UserAttributes.create());
        setSaving(false);
        queryClient.invalidateQueries({ queryKey: [queryKeys.user.root] });
      }
    },
    [employeeUploadedPhoto, newEmployeeEditing, newUserAttributes, queryClient],
  );

  return (
    <Card className={cn('flex flex-col', className)}>
      <CardHeader className="bg-background/95 supports-[backdrop-filter]:bg-background/60 sticky top-0 z-50 col-span-2 w-full space-y-2 border-b backdrop-blur">
        <div className="flex flex-wrap items-center justify-between gap-3 md:flex-nowrap">
          <div className="xs:justify-between xs:flex-nowrap flex flex-wrap items-center justify-center md:w-max md:justify-start">
            <div className="flex items-center">
              <CardTitle className="text-xl tracking-wide">{title}</CardTitle>
              <DataTablePagination
                pageCount={Math.ceil(totalEntriesCount / ROWS_PER_PAGE)}
                currentPage={_page}
                setPage={handlePageChange}
              />
            </div>
            <p className="text-sm">Total Entries count: {totalEntriesCount}</p>
          </div>

          <div className="flex flex-wrap items-center justify-center gap-2 md:w-max md:flex-nowrap ">
            {kinds.includes('employees') && (isAdmin || canAddEmployee) && (
              <Button onClick={createNewEmployee}>Add New Employee</Button>
            )}
            {kinds.includes('customers') && (
              <Button
                onClick={handlePendingCustomerEditingToggle(User.create({ billingTerms: 'COD' }))}
              >
                Add New Customer
              </Button>
            )}
            {eventsWithAccounting && (
              <Button onClick={handleAccountingToggle}>{accounting ? SERVICE : ACCOUNTING}</Button>
            )}
            {eventsWithAdd && (
              <>
                <Button onClick={() => setPendingEventAdding(true)}>Add Job</Button>
                <Button onClick={() => setFlatRateIsOpen(true)}>View Flat Rate</Button>
              </>
            )}
            {onClose && <Button onClick={onClose}>Close</Button>}
          </div>
        </div>
        {kinds.length > 1 && (
          // <div className='p-4'>
          <SimpleSelect
            selectedValue={selectedKind}
            values={kinds}
            onChange={(value) => {
              handleSelectedKindChange(value as Kind);
              if (onChangeKindSelection) {
                onChangeKindSelection(value as Kind);
              }
            }}
          />
          // </div>
        )}
      </CardHeader>
      <div className="w-full flex-1 items-start">
        {selectedKind === 'serviceCalls' && (
          <ServiceCallsSearch
            accounting={accounting}
            showRecentServiceCallsForEmployee={showRecentServiceCallsForEmployee}
            deletableEvents={deletableEvents}
            onClose={onClose}
            showJob
            onSelectEvent={onSelectEvent}
            omitArchivedJobs={omitArchivedJobs}
          />
        )}
        {selectedKind === 'employees' && (
          <EmployeesSearch printableEmployees={printableEmployees} />
        )}
        {selectedKind === 'customers' && (
          <CustomersSearch
            deletableCustomers={deletableCustomers}
            editableCustomers={editableCustomers}
          />
        )}
        {selectedKind === 'properties' && (
          <PropertiesSearch
            showPagination={false}
            onSelectProperty={onSelectProperty}
            deletableProperties={deletableProperties}
            editableProperties={editableProperties}
            disableParamsSync={disableParamsSync}
          />
        )}
        {selectedKind === 'contracts' && <ContractsSearch />}
      </div>
      {flatRateIsOpen && flatRateQuery.isSuccess && flatRateQuery.data?.results && (
        <Modal
          open
          fullScreen
          onClose={() => setFlatRateIsOpen(false)}
          className="Modal-Remove-Scroll"
        >
          <FlatRateSheet onClose={() => setFlatRateIsOpen(false)} />
        </Modal>
      )}
      {pendingEventAdding && (
        <Modal
          open
          onClose={handlePendingEventAddingToggle(false)}
          fullScreen
          className="Modal-Remove-Scroll"
        >
          <AddServiceCall
            onClose={handlePendingEventAddingToggle(false)}
            onSave={reloadServiceCallQuery}
          />
        </Modal>
      )}
      {pendingCustomerEditing && (
        <Modal
          open
          onClose={handlePendingCustomerEditingToggle(undefined)}
          fullScreen
          className="Modal-Remove-Scroll"
        >
          <CustomerEdit
            onClose={handlePendingCustomerEditingToggle(undefined)}
            userId={pendingCustomerEditing.id}
            customer={pendingCustomerEditing}
            onSave={onSaveCustomer}
          />
        </Modal>
      )}
      {newEmployeeEditing && (
        <Modal
          open
          onClose={() => setNewEmployeeEditing(undefined)}
          fullScreen
          className="Modal-Remove-Scroll"
        >
          <Form
            title={`Add Employee`}
            schema={makeSchemaEmployeesCreate(newEmployeeEditing)}
            data={newEmployeeEditing}
            onClose={onNewEmployeeClose}
            onSave={onSaveEmployee}
            disabled={saving}
            stickySectionBar
          />
        </Modal>
      )}
    </Card>
  );
};
