import { type CallId } from '@kalos/kalos-rpc';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  LoadingIcon,
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { useMutation } from '@tanstack/react-query';
import { Headset, Mic, MicOff, Phone, PlusCircleIcon } from 'lucide-react';
import { type ComponentProps } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from 'zustand';

import { softPhoneNewJobRoute } from '../SideMenu/constants';
import {
  useProtectedSoftPhoneStore,
  useSoftPhoneStoreContext,
} from './context/SoftPhoneStore.context';
import { useSoftPhoneWidgetStore } from './context/SoftPhoneWidgetStore';
import { CallStatusToggler } from './SoftPhoneWidget/components/CallStatus';
import { useCallStore, useCallStoreBase } from './SoftPhoneWidget/components/CallStore';
import { InboundCall } from './SoftPhoneWidget/components/InboundCall';
import { OutboundCallShell } from './SoftPhoneWidget/components/OutboundCall';
import { softphoneLog } from './SoftPhoneWidget/components/utils';

export const Softphone = () => {
  const isOpen = useSoftPhoneWidgetStore.use.isOpen();
  const setIsOpen = useSoftPhoneWidgetStore.use.setIsOpen();
  const navigate = useNavigate();

  const { store, requiresAuth, isPending } = useSoftPhoneStoreContext();

  const remoteStream = useCallStore.use.remoteStream();

  const handleSetupClick = () => {
    sessionStorage.setItem('softphone_return_url', window.location.pathname);
    navigate('/softphone/oauth-callback');
  };

  const currentCall = useCallStore.use.currentCall();
  const isConnecting = useCallStoreBase((state) => state.computed.isCallPendingVoice);

  let tooltipContent: React.ReactNode = 'SoftPhone';
  if (currentCall) {
    tooltipContent = (
      <div className="flex flex-col gap-2 text-sm">
        <p>Name: {currentCall.name}</p>
        <p>Phone: {currentCall.number}</p>
      </div>
    );
  }
  if (isConnecting) {
    tooltipContent = 'Connecting...';
  }

  return (
    <>
      <TooltipProvider>
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
          <Tooltip>
            <TooltipContent className="rounded-full" sideOffset={10}>
              {tooltipContent}
            </TooltipContent>
            <SoftPhoneWidgetTrigger />
          </Tooltip>
          <SheetContent className="flex flex-col gap-3" side="right">
            <SheetHeader>
              <SheetTitle>Softphone</SheetTitle>
            </SheetHeader>
            {requiresAuth && (
              <div className="flex flex-col gap-4">
                <SheetDescription>You are not authorized to use the softphone</SheetDescription>
                <SheetClose asChild>
                  <Button onClick={handleSetupClick}>Authorize</Button>
                </SheetClose>
              </div>
            )}
            {store && !requiresAuth && <CallStatusToggler />}
            {store && !requiresAuth && <CallsTabsShell />}
            {isPending && <LoadingIcon className="size-10" />}
          </SheetContent>
        </Sheet>
      </TooltipProvider>
      {remoteStream && <audio autoPlay ref={(ref) => ref && (ref.srcObject = remoteStream)} />}
    </>
  );
};

const SoftPhoneWidgetTrigger = () => {
  const { store, requiresAuth } = useSoftPhoneStoreContext();

  return store && !requiresAuth ? (
    <ActivatedSoftPhoneWidgetTrigger />
  ) : (
    <SheetTrigger asChild>
      <TooltipTrigger asChild>
        <Button
          size="icon"
          variant="secondary"
          className="fixed bottom-4 right-4 z-50 rounded-full"
        >
          <Headset size={15} />
        </Button>
      </TooltipTrigger>
    </SheetTrigger>
  );
};

const ActivatedSoftPhoneWidgetTrigger = () => {
  const store = useProtectedSoftPhoneStore();
  const session = useStore(store, (store) => store.session);

  const isPendingConnection = useCallStoreBase((state) => state.computed.isCallPendingVoice);
  const isCallActive = useCallStoreBase((state) => state.computed.isCallActive);

  let variant: ComponentProps<typeof Button>['variant'] = 'secondary';
  if (session) variant = 'default';
  if (isPendingConnection) variant = 'pulsating';
  if (isCallActive) variant = 'success';

  return (
    <SheetTrigger asChild>
      <TooltipTrigger asChild>
        <Button variant={variant} size="icon" className="fixed bottom-4 right-4 z-50 rounded-full">
          {isCallActive ? <Phone size={15} /> : <Headset size={15} />}
        </Button>
      </TooltipTrigger>
    </SheetTrigger>
  );
};

const useToggleMuteMutation = () => {
  const store = useProtectedSoftPhoneStore();
  const client = useStore(store, (store) => store.client);
  const setCurrentCall = useCallStore.use.setCurrentCall();

  const handleMuteLocalStream = useCallStore.use.handleMuteLocalStream();
  const handleUnmuteLocalStream = useCallStore.use.handleUnmuteLocalStream();

  return useMutation({
    mutationFn: async (args: CallId) => {
      return client.ToggleMute(args);
    },
    onMutate(variables) {
      const currentCall = useCallStore.getState().currentCall;
      if (!currentCall) throw new Error('No current call');
      if (currentCall.isMuted) {
        softphoneLog('unmute mutation triggered, unmuting');
        handleUnmuteLocalStream();
      } else {
        softphoneLog('mute mutation triggered, muting');
        handleMuteLocalStream();
      }
    },
    onSuccess(data, variables, context) {
      const currentCall = useCallStore.getState().currentCall;
      if (!currentCall) throw new Error('No current call');
      setCurrentCall({ ...currentCall, isMuted: !currentCall.isMuted });
    },
    onError(error, variables, context) {
      const currentCall = useCallStore.getState().currentCall;
      if (!currentCall) throw new Error('No current call');

      if (currentCall.isMuted) {
        softphoneLog('mute mutation failed, muting');
        handleMuteLocalStream();
      } else {
        softphoneLog('unmute mutation failed, unmuting');
        handleUnmuteLocalStream();
      }
    },
  });
};
const CallsTabsShell = () => {
  const store = useProtectedSoftPhoneStore();
  const session = useStore(store, (store) => store.session);
  if (!session) return null;
  return <CallsTabs />;
};

const CallsTabs = () => {
  const store = useProtectedSoftPhoneStore();
  const session = useStore(store, (store) => store.session);

  const currentCall = useCallStore.use.currentCall();
  const isCallActive = useCallStore((store) => store.computed.isCallActive);
  const peerConnection = useCallStore.use.peerConnection();
  const handleEndCall = useCallStore.use.handleCallEnd();
  const isMuted = useCallStore((store) => store.computed.isMuted);
  const caller = useCallStore.use.currentCaller();

  const toggleMuteMutation = useToggleMuteMutation();

  const onMute = async () => {
    if (!currentCall) throw new Error('No current call');
    if (!session) throw new Error('No session');
    try {
      await toggleMuteMutation.mutateAsync({
        callId: currentCall.id,
        sessionId: session.sessionId,
        sdp: '',
      });
    } catch (error) {
      console.error('onMute', error);
      toast({
        variant: 'destructive',
        title: 'Failed to mute',
        description: error instanceof Error ? error.message : 'Unknown error',
      });
    }
  };

  const onUnmute = async () => {
    if (!currentCall) throw new Error('No current call');
    if (!session) throw new Error('No session');
    try {
      await toggleMuteMutation.mutateAsync({
        callId: currentCall.id,
        sessionId: session.sessionId,
        sdp: '',
      });
    } catch (error) {
      console.error('onUnmute', error);
      toast({
        variant: 'destructive',
        title: 'Failed to unmute',
        description: error instanceof Error ? error.message : 'Unknown error',
      });
    }
  };

  if (currentCall && isCallActive)
    return (
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader>
            <CardTitle>Outbound Call</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Phone Number: {currentCall.number}</p>
            <p>Status: {currentCall.status}</p>
            <p>Source: {currentCall.source}</p>
            <p>Name: {currentCall.name}</p>
          </CardContent>
          <CardFooter className="flex items-center gap-2">
            {peerConnection && (
              <Button size="sm" onClick={handleEndCall}>
                End Call
              </Button>
            )}
            <TooltipProvider>
              {!isMuted && (
                <Tooltip>
                  <TooltipContent>Turn off microphone</TooltipContent>
                  <TooltipTrigger asChild>
                    <Button
                      size="icon"
                      disabled={toggleMuteMutation.isPending}
                      variant="secondary"
                      onClick={onMute}
                    >
                      <Mic size={15} />
                    </Button>
                  </TooltipTrigger>
                </Tooltip>
              )}
              {isMuted && (
                <Tooltip>
                  <TooltipContent>Turn on microphone</TooltipContent>
                  <TooltipTrigger asChild>
                    <Button
                      size="icon"
                      disabled={toggleMuteMutation.isPending}
                      variant="destructive"
                      onClick={onUnmute}
                    >
                      <MicOff size={15} />
                    </Button>
                  </TooltipTrigger>
                </Tooltip>
              )}
            </TooltipProvider>
          </CardFooter>
        </Card>

        {caller && (
          <Card variant="light">
            <CardHeader>
              <CardDescription>
                Caller: {caller.firstname} {caller.lastname}
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <SheetClose asChild>
                <Button
                  asChild
                  className="flex !h-auto max-w-max items-center gap-2 whitespace-normal break-words"
                >
                  <Link to={softPhoneNewJobRoute}>
                    <PlusCircleIcon size={15} className="shrink-0" /> Add New Job
                  </Link>
                </Button>
              </SheetClose>
            </CardFooter>
          </Card>
        )}
      </div>
    );

  return (
    <div className="space-y-4">
      <Tabs className="flex flex-col items-center justify-center" defaultValue="outbound">
        <TabsList className="mx-auto">
          <TabsTrigger disabled={!!peerConnection} value="outbound">
            Outbound
          </TabsTrigger>
          <TabsTrigger disabled={!!peerConnection} value="inbound">
            Inbound
          </TabsTrigger>
        </TabsList>
        <TabsContent value="outbound">
          <OutboundCallShell />
        </TabsContent>
        <TabsContent value="inbound">
          <InboundCall />
        </TabsContent>
      </Tabs>
    </div>
  );
};
