import { BaseClient } from '../BaseClient';
import { ProjectEvent } from '../compiled-protos/event';
import { EventServiceClient as ProjectsServiceClient } from '../compiled-protos/event.client';

class ProjectEventClient extends BaseClient {
  self: ProjectsServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new ProjectsServiceClient(this.transport);
  }

  public async Get(req: ProjectEvent) {
    const res = await this.self.getProjectEvent(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async BatchGet(req: ProjectEvent) {
    const res = await this.self.batchGetProjectEvent(req, this.getMetaData()).response;
    return res
  }

  public async Create(req: ProjectEvent) {
    const res = await this.self.createProjectEvent(req, this.getMetaDataWithoutCache()).response;
    return res;
  }
  public async Update(req: ProjectEvent) {
    const res = await this.self.updateProjectEvent(req, this.getMetaDataWithoutCache()).response;
    return res;
  }
}

export { ProjectEventClient, ProjectEvent };
