import { type Contract } from '@kalos/kalos-rpc';
import { Card, CardContent, CardHeader, CardTitle } from '@kalos/ui';
import { CalendarClock } from 'lucide-react';
import { useMemo } from 'react';

import { useContractsBatchGetQuery } from '../../../hooks/react-query/useContractsQuery';
import { type EntityFilter } from '../../../hooks/react-query/utils';
import { formatDate } from '../../../tools/helpers';

interface ContractExpirationsPreviewProps {
  onClick: () => void;
}

export const ContractExpirationsPreview: React.FC<ContractExpirationsPreviewProps> = ({
  onClick,
}) => {
  const twoWeeksFromNow = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 14);
    return date.toISOString().split('T')[0];
  }, []);

  const contractsFilterCriteria = useMemo<EntityFilter<Contract>>(
    () => ({
      isActive: 1,
      dateEnded: twoWeeksFromNow,
      orderBy: 'dateEnded',
      orderDir: 'ASC',
    }),
    [twoWeeksFromNow],
  );

  const { data, isLoading, error } = useContractsBatchGetQuery({
    filter: contractsFilterCriteria,
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading contract expiration data</div>;

  const expirations = data?.results
    .slice(0, 5)
    .map((contract) => `${formatDate(contract.dateEnded)}: ${contract.businessName || 'N/A'}`);

  return (
    <Card
      className="flex h-full cursor-pointer flex-col transition-shadow hover:shadow-md"
      onClick={onClick}
    >
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-lg font-semibold">Contract Expirations</CardTitle>
        <CalendarClock className="text-muted-foreground h-6 w-6" />
      </CardHeader>
      <CardContent className="flex-grow">
        <div className="flex h-full flex-col justify-between">
          <div>
            <p className="mb-2 font-medium">Expiring in next 2 weeks:</p>
            <ul className="mb-2 list-disc pl-5">
              {expirations?.map((exp, index) => (
                <li key={index} className="mb-1">
                  {exp}
                </li>
              ))}
            </ul>
            <p className="font-bold">Total: {data?.totalCount || 0}</p>
          </div>
          <p className="text-muted-foreground mt-2 text-xs">Click to view all expirations</p>
        </div>
      </CardContent>
    </Card>
  );
};
