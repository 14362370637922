import { Button, DataTable, DataTableColumnHeader } from '@kalos/ui';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { differenceInMinutes, endOfWeek, format, parseISO, startOfWeek } from 'date-fns';
import { type FC, useMemo } from 'react';

import { type DataList } from '../Timesheet/reducer';

interface Props {
  data: DataList;
  startDate: Date;
}

interface EmployeeSummary {
  employeeId: number;
  employeeName: string;
  regularHours: number;
  overtimeHours: number;
  totalHours: number;
  weekStart: string;
  weekEnd: string;
}

const columns: ColumnDef<EmployeeSummary>[] = [
  {
    accessorKey: 'employeeName',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Employee" />,
  },
  {
    accessorKey: 'regularHours',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Regular Hours" />,
    cell: ({ row }) => row.original.regularHours.toFixed(2),
  },
  {
    accessorKey: 'overtimeHours',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Overtime Hours" />,
    cell: ({ row }) => row.original.overtimeHours.toFixed(2),
  },
  {
    accessorKey: 'totalHours',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Total Hours" />,
    cell: ({ row }) => row.original.totalHours.toFixed(2),
  },
];

interface WeekTableProps {
  weekStart: string;
  data: EmployeeSummary[];
}

const WeekTable: FC<WeekTableProps> = ({ weekStart, data }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div style={{ marginBottom: '2rem' }}>
      <h3 style={{ marginLeft: '7px' }}>Week of {format(parseISO(weekStart), 'MM/dd/yyyy')}</h3>
      <DataTable table={table} />
    </div>
  );
};

const calculateWeeklySummary = (data: DataList): EmployeeSummary[] => {
  const weeklyEmployeeSummaries = new Map<string, Map<number, EmployeeSummary>>();

  Object.values(data).forEach((dayData) => {
    dayData.timesheetLineList.forEach((line) => {
      if (!line.technicianUserId || !line.timeStarted || !line.timeFinished) return;

      const employeeId = line.technicianUserId;
      const employeeName = line.technicianUserName || 'Unknown';
      const timeStarted = parseISO(line.timeStarted);

      // Get week start (Saturday) and end (Friday) for the timesheet
      const weekStartDate = startOfWeek(timeStarted, { weekStartsOn: 6 });
      const weekEndDate = endOfWeek(timeStarted, { weekStartsOn: 6 });
      const weekKey = `${format(weekStartDate, 'yyyy-MM-dd')}_${format(weekEndDate, 'yyyy-MM-dd')}`;

      const minutes = differenceInMinutes(parseISO(line.timeFinished), parseISO(line.timeStarted));
      const hours = minutes / 60;

      if (!weeklyEmployeeSummaries.has(weekKey)) {
        weeklyEmployeeSummaries.set(weekKey, new Map());
      }
      const weekSummaries = weeklyEmployeeSummaries.get(weekKey)!;

      const summary = weekSummaries.get(employeeId) || {
        employeeId,
        employeeName,
        regularHours: 0,
        overtimeHours: 0,
        totalHours: 0,
        weekStart: format(weekStartDate, 'yyyy-MM-dd'),
        weekEnd: format(weekEndDate, 'yyyy-MM-dd'),
      };

      summary.totalHours += hours;
      if (summary.totalHours <= 40) {
        summary.regularHours += hours;
      } else {
        const remainingRegular = Math.max(0, 40 - (summary.totalHours - hours));
        summary.regularHours += remainingRegular;
        summary.overtimeHours += hours - remainingRegular;
      }

      weekSummaries.set(employeeId, summary);
    });
  });

  // Convert the nested maps to a flat array of summaries
  const allSummaries: EmployeeSummary[] = [];
  weeklyEmployeeSummaries.forEach((weekSummaries) => {
    weekSummaries.forEach((summary) => {
      allSummaries.push(summary);
    });
  });

  // Sort by week start date and then by employee name
  return allSummaries.sort((a, b) => {
    const weekCompare = a.weekStart.localeCompare(b.weekStart);
    if (weekCompare !== 0) return weekCompare;
    return a.employeeName.localeCompare(b.employeeName);
  });
};

const exportToCSV = (data: EmployeeSummary[]) => {
  const headers = [
    'Week Start',
    'Week End',
    'Employee Name',
    'Regular Hours',
    'Overtime Hours',
    'Total Hours',
  ];
  const rows = data.map((summary) => [
    format(parseISO(summary.weekStart), 'MM/dd/yyyy'),
    format(parseISO(summary.weekEnd), 'MM/dd/yyyy'),
    summary.employeeName,
    summary.regularHours.toFixed(2),
    summary.overtimeHours.toFixed(2),
    summary.totalHours.toFixed(2),
  ]);

  const csvContent = [
    'Weekly Hours Summary',
    '',
    headers.join(','),
    ...rows.map((row) => row.join(',')),
  ].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `timesheet-summary-${format(new Date(), 'yyyy-MM-dd')}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const EmployeeSummaryView: FC<Props> = ({ data, startDate }) => {
  const summaries = useMemo(() => calculateWeeklySummary(data), [data]);

  // Group summaries by week
  const weeklyGroups = useMemo(() => {
    const groups = new Map<string, EmployeeSummary[]>();
    summaries.forEach((summary) => {
      const weekKey = `${summary.weekStart}_${summary.weekEnd}`;
      if (!groups.has(weekKey)) {
        groups.set(weekKey, []);
      }
      groups.get(weekKey)!.push(summary);
    });
    return Array.from(groups.entries()).sort(([weekA], [weekB]) => weekA.localeCompare(weekB));
  }, [summaries]);

  return (
    <>
      <Button onClick={() => exportToCSV(summaries)} variant="outline">
        Export Summary
      </Button>
      {weeklyGroups.map(([weekKey, weekSummaries]) => {
        const [weekStart] = weekKey.split('_');
        return <WeekTable key={weekKey} weekStart={weekStart} data={weekSummaries} />;
      })}
    </>
  );
};
