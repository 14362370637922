import { type Notification, type User } from '@kalos/kalos-rpc';
import { Button, toast, useConfirm } from '@kalos/ui';
import React, { useState } from 'react';

import { useSearchUserByPhoneInline } from '../../../../hooks/react-query/useSearchUserByPhone';
import { useProtectedSoftPhoneStore } from '../../context/SoftPhoneStore.context';
import { CallQueue } from './CallQueue';
import { useCallStore } from './CallStore';
import { monitorAudioLevels, softphoneLog } from './utils';

export const InboundCall = () => {
  const store = useProtectedSoftPhoneStore();
  const confirm = useConfirm();

  const setPeerConnection = useCallStore.use.setPeerConnection();
  const setIsPendingSound = useCallStore.use.setIsPendingSound();
  const setRemoteStream = useCallStore.use.setRemoteStream();
  const setLocalStream = useCallStore.use.setLocalStream();
  const addOnCallEndListener = useCallStore.use.addOnCallEndListener();
  const handleCallEnd = useCallStore.use.handleCallEnd();
  const setCurrentCall = useCallStore.use.setCurrentCall();
  const currentCall = useCallStore.use.currentCall();
  const setCurrentCaller = useCallStore.use.setCurrentCaller();
  const peerConnection = useCallStore.use.peerConnection();

  const [messages, setMessages] = useState<Notification[]>([]);

  const { fetchUser } = useSearchUserByPhoneInline();

  const createOutboundSubscription = async () => {
    const session = store.getState().session;

    try {
      if (!session) throw new Error('Session not found');
      const peerConnection = new RTCPeerConnection();

      const remoteStream = new MediaStream();
      peerConnection.ontrack = (event) => {
        remoteStream.addTrack(event.track);
        setRemoteStream(remoteStream);

        const soundStream = event.streams[0];

        // Start monitoring using AudioContext
        const [promise, cleanupFn] = monitorAudioLevels(soundStream);
        addOnCallEndListener(cleanupFn);
        promise
          .then(() => {
            console.log('Audio is now coming through!');
            setIsPendingSound(false);
          })
          .catch((err) => {
            console.error('Error monitoring audio:', err);
          });
      };

      // Add client audio track to peer connection
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => peerConnection?.addTrack(track, stream));
      // create SDP offer
      setIsPendingSound(true);
      setPeerConnection(peerConnection);
      setLocalStream(stream);
      const subscription = store.getState().client.SubscribeToEvents(session.sessionId);
      subscription.responses.onMessage(async (message) => {
        softphoneLog('Incoming stream message', { message });
        setMessages((prev) => [...prev, message]);
        let messageType = 'Unknown';
        if (message.metadata['type'] !== '') {
          messageType = message.metadata['type'];
        }
        switch (messageType) {
          case 'incoming': {
            try {
              const offer = await peerConnection.createOffer();
              await peerConnection.setLocalDescription(offer);

              if (!offer.sdp) throw new Error('SDP offer not found');

              softphoneLog('incoming call:Creating call answer');
              const callAnswer = await store
                .getState()
                .client.AnswerCall(message.callId, session.sessionId, offer.sdp);

              softphoneLog('incoming call: answer', callAnswer);

              let customer: User | null = null;
              try {
                customer = await fetchUser({
                  filter: {
                    phone: message.fromNumber,
                  },
                });
              } catch {
                console.warn('No customer found for inbound call');
              }

              const willPickUp = await confirm({
                title: 'Pick up call?',
                body: `You are about to pick up an incoming call from ${message.callerName} (${message.fromNumber}) ${customer ? '(Existing customer)' : ''}`,
                cancelButton: 'Decline',
                actionButton: 'Pick up',
              });
              if (!willPickUp) {
                handleCallEnd();
                return;
              }

              setCurrentCall({ ...callAnswer, source: 'inbound' });
              setCurrentCaller(customer);
              const remoteDesc = new RTCSessionDescription({
                type: 'answer',
                sdp: callAnswer.sdp,
              });
              await peerConnection.setRemoteDescription(remoteDesc);
            } catch (error) {
              console.error('Error answering call', error);
            }
            break;
          }
        }
      });
    } catch (error) {
      handleCallEnd();

      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to start call',
        variant: 'destructive',
      });
    }
  };

  if (currentCall) return 'Finish the call to start listening for new calls';

  return (
    <div className="space-y-4">
      <ul>
        {messages.map((message, index) => (
          <li key={index} className="rounded-md border p-2">
            <div>{message.callId}</div>
            <div>{message.callerName}</div>
            <div>{message.fromNumber}</div>
            <div>{message.type}</div>
          </li>
        ))}
      </ul>
      <Button disabled={!!peerConnection} onClick={createOutboundSubscription}>
        {peerConnection ? 'Listening for the incoming calls' : 'Start listening'}
      </Button>

      {peerConnection && <CallQueue />}
    </div>
  );
};
