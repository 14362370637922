import { BaseClient } from '../BaseClient';
import {
  EmployeeFunction,
  EmployeeFunctionList,
} from '../compiled-protos/user';
import { UserServiceClient as EmployeeFunctionServiceClient } from '../compiled-protos/user.client';

class EmployeeFunctionClient extends BaseClient {
  self: EmployeeFunctionServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new EmployeeFunctionServiceClient(this.transport);
  }

  public async Create(req: EmployeeFunction) {
    let res = EmployeeFunction.create();
    try {
      res = await this.self.createEmployeeFunction(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: EmployeeFunction) {
    const result = await this.self.getEmployeeFunction(req, this.getMetaData())
    return result.response
  }

  public async Update(req: EmployeeFunction) {
    const res = await this.self.updateEmployeeFunction(req, this.getMetaData())
      .response;
    return res;
  }

  public async Delete(req: EmployeeFunction) {
    let res = EmployeeFunction.create();
    try {
      res = await this.self.deleteEmployeeFunction(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: EmployeeFunction) {
    const res = await this.self.batchGetEmployeeFunction(req, this.getMetaData())
      .response;
    return res;
  }

  public deleteEmployeeFunctionById = async (id: number) => {
    const req = EmployeeFunction.create();
    req.id = id;
    try {
      await this.self.deleteEmployeeFunction(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
  };

  public upsertEmployeeFunction = async (
    data: EmployeeFunction,
    userId: number
  ) => {
    data.addeduserid = userId;
    return await this[data.id ? 'Update' : 'Create'](data);
  };
}

export { EmployeeFunction, EmployeeFunctionList, EmployeeFunctionClient };
