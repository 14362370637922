import { type VariantProps } from 'class-variance-authority';
import { forwardRef } from 'react';

import { cn } from '../../utils';
import { badgeVariants } from './badge.variants';
export type BadgeProps<T extends React.ElementType> = React.ComponentPropsWithoutRef<T> &
  VariantProps<typeof badgeVariants>;

export const Badge = forwardRef<HTMLDivElement, BadgeProps<'div'>>(
  ({ className, variant, ...props }, ref) => {
    return <div ref={ref} className={cn(badgeVariants({ variant }), className)} {...props} />;
  },
);

Badge.displayName = 'Badge';
