import { GetCallHistoryRequest } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';
import { useStore } from 'zustand';

import { queryKeys } from '../../../hooks/react-query/constants';
import { type EntityFilter } from '../../../hooks/react-query/utils';
import { useProtectedSoftPhoneStore } from '../context/SoftPhoneStore.context';

export type CallHistoryFilter = EntityFilter<GetCallHistoryRequest>;

export const useGetCallHistoryQuery = ({
  filter = {},
  enabled = true,
}: {
  filter?: CallHistoryFilter;
  enabled?: boolean;
} = {}) => {
  const store = useProtectedSoftPhoneStore();
  const { client } = useStore(store);

  return useQuery({
    queryKey: [
      queryKeys.softphone.root,
      queryKeys.softphone.callHistory,
      queryKeys.softphone.getCallHistoryHash(filter),
    ],
    queryFn: async () => {
      const request = GetCallHistoryRequest.create(filter);
      return client.GetCallHistory(request);
    },
    enabled,
  });
};
