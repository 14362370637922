import { type Event } from '@kalos/kalos-rpc';
import groupBy from 'lodash/groupBy';

export const getJobTypeBreakdown = (calls: Event[]) => {
  const grouped = groupBy(calls, 'jobType');
  return Object.entries(grouped).map(([jobType, calls]) => ({
    jobType: jobType || 'Unspecified',
    count: calls.length,
  }));
};
