// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "gotoadmin.proto" (syntax proto3)
// tslint:disable
import { GoToAdminService } from "./gotoadmin";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service GoToAdminService
 */
export class GoToAdminServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = GoToAdminService.typeName;
        this.methods = GoToAdminService.methods;
        this.options = GoToAdminService.options;
    }
    /**
     * @generated from protobuf rpc: AssignUserToQueue(AssignUserToQueueRequest) returns (Bool);
     */
    assignUserToQueue(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCallQueues(GetCallQueuesRequest) returns (GetCallQueuesResponse);
     */
    getCallQueues(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
