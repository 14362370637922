import { useQuery } from '@tanstack/react-query';
import { useStore } from 'zustand';

import { queryKeys } from '../../../hooks/react-query/constants';
import { useProtectedSoftPhoneStore } from '../context/SoftPhoneStore.context';

export const useGetMeGoToQuery = () => {
  const store = useProtectedSoftPhoneStore();
  const { client } = useStore(store);
  return useQuery({
    queryKey: [queryKeys.softphone.root, queryKeys.softphone.me],
    queryFn: async () => {
      return await client.GetMe();
    },
  });
};
