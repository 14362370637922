import { ClientInfo } from '@kalos/kalos-rpc';
import { Button, toast, useConfirm } from '@kalos/ui';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'zustand';

import { useAuth } from '../../../../context/AuthContext';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { useProtectedSoftPhoneStore } from '../../context/SoftPhoneStore.context';
import { useCallStore } from './CallStore';

export const CallStatusToggler = () => {
  const { user } = useAuth();
  const confirm = useConfirm();

  const store = useProtectedSoftPhoneStore();
  const createSession = useStore(store, (state) => state.createSession);
  const terminateSession = useStore(store, (state) => state.terminateSession);
  const session = useStore(store, (state) => state.session);

  const currentCall = useCallStore.use.currentCall();
  const resetRTCStore = useCallStore.use.handleReset();
  const peerConnection = useCallStore.use.peerConnection();

  const [deviceId] = useLocalStorage(
    'softPhoneDeviceId',
    useMemo(() => `${user.id}-web-softphone`, [user.id]),
  );
  const navigate = useNavigate();
  const handleSetupClick = () => {
    sessionStorage.setItem('softphone_return_url', window.location.pathname);
    navigate('/softphone/oauth-callback');
  };

  const [isActivating, setIsActivating] = useState(false);
  const handleSetToActive = async () => {
    setIsActivating(true);
    try {
      await createSession({
        userId: user.id,
        clientInfo: ClientInfo.create({
          appVersion: '1.0.0',
          deviceId: deviceId,
          platform: 'browser',
          deviceType: 'web',
        }),
      });
    } catch (error) {
      toast({
        title: 'Error setting to active',
        description: error instanceof Error ? error.message : 'Unknown error',
        variant: 'destructive',
      });
      handleSetupClick();

      console.error('Error setting to active:', error);
    } finally {
      setIsActivating(false);
    }
  };

  const [isDeactivating, setIsDeactivating] = useState(false);
  const handleSetToInactive = async () => {
    setIsDeactivating(true);
    try {
      if (!session) throw new Error('Session not found');
      if (currentCall || peerConnection) {
        if (
          !(await confirm({
            title: 'Disconnect Softphone',
            body: 'Are you sure you want to disconnect the softphone? This will abort the current phone',
          }))
        )
          return;
      }

      resetRTCStore();
      await terminateSession({ sessionId: session.sessionId });
    } catch (error) {
      toast({
        title: 'Error setting to inactive',
        description: error instanceof Error ? error.message : 'Unknown error',
        variant: 'destructive',
      });
    } finally {
      setIsDeactivating(false);
    }
  };

  return (
    <div className="flex flex-wrap items-center justify-center gap-2">
      <Button
        onClick={handleSetToActive}
        isLoading={isActivating}
        disabled={isActivating || !!session}
      >
        setup Softphone
      </Button>
      <Button
        onClick={handleSetToInactive}
        isLoading={isDeactivating}
        disabled={isDeactivating || !session}
      >
        Disconnect Softphone
      </Button>
    </div>
  );
};
