import { type CallHistoryItem } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  LoadingIcon,
  Sheet,
  SheetContent,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { format } from 'date-fns';
import { Eye, Phone } from 'lucide-react';
import { useMemo, useState } from 'react';

import { UserDetails } from './UserDetails';

const isInternalExtension = (number: string) => {
  const digits = number.replace(/\D/g, '');
  return digits.length <= 4;
};

interface CallLogTableProps {
  calls: CallHistoryItem[];
  onInitiateCall: (phoneNumber: string) => void;
  onViewJob: (jobId: string) => void;
  isLoading?: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

const columnHelper = createColumnHelper<CallHistoryItem>();

export const CallLogTable = ({
  calls,
  onInitiateCall,
  onViewJob,
  isLoading,
  onNextPage,
  onPreviousPage,
  hasNextPage,
  hasPreviousPage,
}: CallLogTableProps) => {
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>('');
  const [isUserDetailsOpen, setIsUserDetailsOpen] = useState(false);

  const handleViewUser = (phoneNumber: string) => {
    if (phoneNumber.length > 4) {
      setSelectedPhoneNumber(phoneNumber);
      setIsUserDetailsOpen(true);
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('direction', {
        header: 'Call Type',
      }),
      columnHelper.accessor((row) => row.caller?.name || '', {
        id: 'callerName',
        header: 'Name',
      }),
      columnHelper.accessor((row) => row.caller?.number || '', {
        id: 'phoneNumber',
        header: 'Phone Number',
      }),
      columnHelper.accessor('startTime', {
        header: 'Date and Time',
        cell: ({ getValue }) => format(new Date(getValue()), 'MM/dd/yyyy HH:mm:ss'),
      }),
      columnHelper.accessor((row) => row, {
        id: 'actions',
        header: 'Actions',
        cell: ({ getValue }) => {
          const call = getValue();
          const phoneNumber = call.caller?.number || '';
          const canViewUser = phoneNumber.length > 4;

          return (
            <TooltipProvider delayDuration={150}>
              <div className="flex gap-2">
                {!isInternalExtension(phoneNumber) && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={() => onInitiateCall(phoneNumber)}
                        size="icon"
                        variant="outline"
                      >
                        <Phone size={16} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>Call</TooltipContent>
                  </Tooltip>
                )}
                {canViewUser && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={() => handleViewUser(phoneNumber)}
                        size="icon"
                        variant="outline"
                      >
                        <Eye size={16} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>View User</TooltipContent>
                  </Tooltip>
                )}
              </div>
            </TooltipProvider>
          );
        },
      }),
    ],
    [onInitiateCall],
  );

  const table = useReactTable({
    data: calls,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (isLoading) {
    return (
      <div className="flex justify-center p-4">
        <LoadingIcon className="size-10" />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <DataTable table={table} />

        <div className="flex items-center justify-end gap-2">
          <Button variant="outline" onClick={onPreviousPage} disabled={!hasPreviousPage}>
            Previous
          </Button>
          <Button variant="outline" onClick={onNextPage} disabled={!hasNextPage}>
            Next
          </Button>
        </div>
      </div>

      <Sheet open={isUserDetailsOpen} onOpenChange={setIsUserDetailsOpen}>
        <SheetContent className="w-full sm:max-w-xl">
          <UserDetails phoneNumber={selectedPhoneNumber} />
        </SheetContent>
      </Sheet>
    </>
  );
};
