import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Textarea,
} from '@kalos/ui';
import { type ComponentProps, useEffect, useMemo } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z, type ZodString } from 'zod';

type PromptSchema = {
  prompt: string;
};

const defaultPromptSchema = z.string();
export const Prompt = ({
  promptSchema = defaultPromptSchema,
  defaultValue = '',
  trigger,
  onSubmit,
  title,
  label,
  contentClassName,
  inputType = 'input',
  inputTestId,
  closeTestId,
  isLoading,
  onOpenChange: onOpenChangeProp,
  resetOnClose = true,
  ...dialogProps
}: {
  promptSchema?: ZodString;
  defaultValue?: string;
  resetOnClose?: boolean;
  title?: string;
  label?: React.ReactNode;
  onSubmit: SubmitHandler<PromptSchema>;
  trigger?: React.ReactNode;
  inputType?: 'input' | 'textarea';
  inputTestId?: string;
  closeTestId?: string;
  isLoading?: boolean;
  contentClassName?: string;
} & Pick<ComponentProps<typeof Dialog>, 'defaultOpen' | 'modal' | 'onOpenChange' | 'open'>) => {
  const _defaultValue = useMemo(() => ({ prompt: defaultValue }), [defaultValue]);

  const form = useForm<PromptSchema>({
    resolver: zodResolver(
      z.object({
        prompt: promptSchema,
      }),
    ),
    defaultValues: _defaultValue,
  });

  const onOpenChange = (isOpen: boolean) => {
    onOpenChangeProp?.(isOpen);
    if (!isOpen && resetOnClose) {
      form.reset(_defaultValue, {
        keepDirty: false,
        keepDirtyValues: false,
      });
    }
  };

  useEffect(() => {
    if (!dialogProps.open && resetOnClose) {
      form.reset(_defaultValue, {
        keepDirty: false,
        keepDirtyValues: false,
      });
    }
  }, [_defaultValue, dialogProps.open, form, resetOnClose]);

  return (
    <Dialog {...dialogProps} onOpenChange={onOpenChange}>
      <DialogContent showClose={false} className={contentClassName}>
        <form className="flex flex-col gap-4" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex gap-2">
            {title && <h3 className="scroll-m-20 text-xl font-semibold tracking-tight">{title}</h3>}
            <Button
              className="ml-auto"
              size="sm"
              isLoading={isLoading}
              disabled={isLoading || !form.formState.isDirty}
              type="submit"
            >
              Submit
            </Button>
            <DialogClose asChild>
              <Button variant="outline" data-testid={closeTestId} size="sm">
                Close
              </Button>
            </DialogClose>
          </div>
          <div className="flex gap-2">
            <Form {...form}>
              <FormField
                control={form.control}
                name="prompt"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    {label && <FormLabel className="w-auto">{label}</FormLabel>}
                    {inputType === 'input' ? (
                      <Input
                        {...field}
                        value={field.value}
                        disabled={field.disabled || isLoading}
                        data-testid={inputTestId}
                      />
                    ) : (
                      <Textarea
                        {...field}
                        value={field.value}
                        disabled={field.disabled || isLoading}
                        data-testid={inputTestId}
                      />
                    )}
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          </div>
        </form>
      </DialogContent>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
    </Dialog>
  );
};
