import { Card, CardContent, CardHeader, CardTitle } from '@kalos/ui';
import { addHours, format } from 'date-fns';
import { PhoneCall } from 'lucide-react';
import { useEffect, useState } from 'react';

import { useCallWindowQuery } from '../../../hooks/react-query/useCallWindowQuery';

interface CallWindowPreviewProps {
  refreshInterval?: number;
  onClick: () => void;
}

export const CallWindowPreview: React.FC<CallWindowPreviewProps> = ({
  refreshInterval,
  onClick,
}) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  // Calculate start and end times for the current hour
  const startTime = new Date(currentTime.setMinutes(0, 0, 0));
  const endTime = addHours(startTime, 1);

  const formatDateTimeForRequest = (date: Date) => {
    return format(date, 'yyyy-MM-dd HH:mm:ss');
  };

  const {
    data: currentWindow,
    isLoading,
    error,
  } = useCallWindowQuery(
    formatDateTimeForRequest(startTime),
    formatDateTimeForRequest(endTime),
    refreshInterval,
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading call window data</div>;

  const displayStartTime = format(startTime, 'h:mm a');
  const displayEndTime = format(addHours(startTime, 4), 'h:mm a');

  return (
    <Card
      className="flex h-full cursor-pointer flex-col transition-shadow hover:shadow-md"
      onClick={onClick}
    >
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-lg font-semibold">Call Windows</CardTitle>
        <PhoneCall className="text-muted-foreground h-6 w-6" />
      </CardHeader>
      <CardContent className="flex-grow">
        <div className="flex h-full flex-col justify-between">
          <div>
            <p className="font-medium">Current Window:</p>
            <p className="text-lg font-bold">
              {displayStartTime} - {displayEndTime}
            </p>
            <p className="mt-2 font-medium">Calls in current window:</p>
            {currentWindow?.jobsInWindow && currentWindow.jobsInWindow.length > 0 ? (
              <ul className="mb-2 list-disc pl-5">
                {currentWindow.jobsInWindow.slice(0, 5).map((call) => (
                  <li key={call.id} className="mb-1">
                    #{call.id} - {call.name}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="mb-2">No calls scheduled for this window.</p>
            )}
            <p className="font-bold">Total calls: {currentWindow?.callCount || 0}</p>
          </div>
          <p className="text-muted-foreground mt-2 text-xs">Click to view all windows</p>
        </div>
      </CardContent>
    </Card>
  );
};
