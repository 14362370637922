import { useMemo } from 'react';

import { useAuth } from '../../../context/AuthContext';
import { ToolFundSettingsForm } from './ToolFundSettingsForm';
import { ToolFundTransactions } from './ToolFundTransactions';

interface ToolFundProfileProps {
  userId: number;
}

export const ToolFundProfile = ({ userId }: ToolFundProfileProps) => {
  const { user: loggedUser } = useAuth();
  const isAdmin = useMemo(
    () => loggedUser.permissionGroups.some((perm) => perm.name === 'ToolFundAdmin'),
    [loggedUser],
  );

  return (
    <div className="space-y-8 p-4">
      <ToolFundSettingsForm userId={userId} isAdmin={isAdmin} />
      <ToolFundTransactions userId={userId} isAdmin={isAdmin} />
    </div>
  );
};
