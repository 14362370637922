import { type Material, type ServiceItem } from '@kalos/kalos-rpc';

export type State = {
  materials: Material[];
  materialsIds: number[];
  loadingMaterials: boolean;
  error: boolean;
  editing: ServiceItem | undefined;
  openedReadings: number | null;
};

export enum ACTIONS {
  SET_MATERIALS = 'setMaterials',
  SET_MATERIALS_IDS = 'setMaterialIds',
  SET_LOADING_MATERIALS = 'setLoadingMaterials',
  SET_EDITING = 'setEditing',
  SET_ERROR = 'setError',
  SET_OPENED_READINGS = 'setOpenedReadings',
}

export type Action =
  | { type: ACTIONS.SET_MATERIALS; data: Material[] }
  | { type: ACTIONS.SET_MATERIALS_IDS; data: number[] }
  | { type: ACTIONS.SET_LOADING_MATERIALS; data: boolean }
  | { type: ACTIONS.SET_EDITING; data: ServiceItem | undefined }
  | { type: ACTIONS.SET_ERROR; data: boolean }
  | { type: ACTIONS.SET_OPENED_READINGS; data: number | null };

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIONS.SET_ERROR: {
      return {
        ...state,
        error: action.data,
      };
    }
    case ACTIONS.SET_MATERIALS: {
      return {
        ...state,
        materials: action.data,
      };
    }
    case ACTIONS.SET_MATERIALS_IDS: {
      return {
        ...state,
        materialsIds: action.data,
      };
    }
    case ACTIONS.SET_LOADING_MATERIALS: {
      return {
        ...state,
        loadingMaterials: action.data,
      };
    }
    case ACTIONS.SET_EDITING: {
      return {
        ...state,
        editing: action.data,
      };
    }
    case ACTIONS.SET_OPENED_READINGS: {
      return {
        ...state,
        openedReadings: action.data,
      };
    }
    default:
      return state;
  }
};
