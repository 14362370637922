import { LoadingIcon } from '@kalos/ui';
import { type ReactNode, useEffect } from 'react';
import { useStore } from 'zustand';

import { useProtectedSoftPhoneStore } from '../context/SoftPhoneStore.context';

type Props = {
  children: ReactNode;
};

export const SessionWrapper = ({ children }: Props) => {
  const store = useProtectedSoftPhoneStore();
  const session = useStore(store, (state) => state.session);
  const isSessionLoading = useStore(store, (state) => state.isSessionLoading);
  const activateSession = useStore(store, (state) => state.activateSession);

  useEffect(() => {
    if (!session && !isSessionLoading) {
      activateSession().catch(() => {});
    }
  }, [session, isSessionLoading, activateSession]);

  if (isSessionLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingIcon className="size-10" />
      </div>
    );
  }

  if (!session) {
    return null;
  }

  return <div>{children}</div>;
};
