import { zodResolver } from '@hookform/resolvers/zod';
import { type Event } from '@kalos/kalos-rpc';
import {
  Button,
  Checkbox,
  DateTimePicker,
  Dialog,
  DialogContent,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MoneyInput,
  SimpleSelect,
  Textarea,
} from '@kalos/ui';
import { addHours } from 'date-fns';
import { useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';

import { JobSelector } from '../../../components/JobSelector';
import { EVENT_STATUS_LIST, OPTION_ALL } from '../../../constants';
import { useJobSubTypesQuery } from '../../../hooks/react-query/useJobSubTypesQuery';
import { useJobTypesQuery } from '../../../hooks/react-query/useJobTypesQuery';
import { type ZodObjectWithModelKeys } from '../../../tools/typeguargs';
import { type FormProps } from '../../../tools/types';
import { CallWindow } from '../../ComponentsLibrary/CallWindow';
import { TimesheetDepartmentPickerV2 } from '../../ComponentsLibrary/Pickers/newPickers/QueryPickerV2';
import { TechniciansComboboxPicker } from '../../ComponentsLibrary/Pickers/newPickers/TechnicianComboboxPicker';
import { renderDepartment } from '../../ComponentsLibrary/Pickers/newPickers/utils';
import { PAYMENT_TYPE_OPTIONS } from '../../CustomerDetails/components/constants';

type SoftPhoneNewJobFormFields = Pick<
  Event,
  // job description
  | 'description' // job Notes
  | 'highPriority' // high priority
  | 'isCallback' // callback
  | 'isLmpc' // lmpc
  | 'callbackOriginalId' // callback original id
  | 'jobTypeId' // job type
  | 'jobSubtypeId' // job subtype
  | 'departmentId' // department

  // Line items
  | 'logServiceRendered' // service rendered
  | 'amountQuoted' // amount quoted
  | 'diagnosticQuoted' // diagnostic quoted
  // payment
  | 'logPaymentType' // payment type
  | 'logPo' // po
  // scheduling
  | 'logJobStatus'
  | 'logTechnicianAssigned'
> & {
  dateTimeStarted: unknown;
  dateTimeEnded: unknown;
};

const SoftPhoneNewJobFormSchema = (
  z.object({
    description: z.string().min(1, 'Description is required'),
    highPriority: z.boolean(),
    isCallback: z.boolean(),
    isLmpc: z.boolean(),
    callbackOriginalId: z.number(),
    jobTypeId: z.number().min(1, 'Job type is required'),
    jobSubtypeId: z.number().min(1, 'Job subtype is required'),
    departmentId: z.number().min(1, 'Department is required'),
    logServiceRendered: z.string().min(1, 'Service rendered is required'),
    amountQuoted: z.coerce.number().min(0.01, 'Amount quoted is required'),
    diagnosticQuoted: z.boolean(),
    logPaymentType: z.string().min(1, 'Payment type is required'),
    logPo: z.string(),
    dateTimeStarted: z.date(),
    dateTimeEnded: z.date(),
    logJobStatus: z.string(),
    logTechnicianAssigned: z.number(),
  }) satisfies ZodObjectWithModelKeys<SoftPhoneNewJobFormFields>
).superRefine((data, ctx) => {
  if (data.isCallback && !data.callbackOriginalId) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Select original Job or unselect callback',
      path: ['callbackOriginalId'],
    });
  }
  if (data.dateTimeStarted.getTime() > data.dateTimeEnded.getTime()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'End time should be after Start time',
      path: ['dateTimeStarted'],
    });
  }
});
type SoftPhoneNewJobFormSchema = z.infer<typeof SoftPhoneNewJobFormSchema>;

const getDefaultValues = (args?: Partial<SoftPhoneNewJobFormSchema>): SoftPhoneNewJobFormSchema => {
  const currentDate = new Date();
  // get next  hour (14:15 -> 15:00, 14:45 -> 15:00, etc)
  const nextHour = new Date(currentDate);
  nextHour.setMinutes(0, 0, 0);
  nextHour.setHours(nextHour.getHours() + 1);
  return {
    description: '',
    highPriority: false,
    isCallback: false,
    isLmpc: false,
    callbackOriginalId: 0,
    jobTypeId: 0,
    jobSubtypeId: 0,
    departmentId: 0,
    amountQuoted: 0,
    diagnosticQuoted: false,
    logPaymentType: '',
    logPo: '',
    dateTimeStarted: nextHour,
    dateTimeEnded: addHours(nextHour, 4),
    logJobStatus: '',
    logTechnicianAssigned: 0,
    logServiceRendered: '',
    ...args,
  };
};

export const NewJobForm = ({
  defaultValues,
  onSave,
  disabled,
  isLoading,
}: FormProps<SoftPhoneNewJobFormSchema>) => {
  const form = useForm<SoftPhoneNewJobFormSchema>({
    resolver: zodResolver(SoftPhoneNewJobFormSchema),
    defaultValues,
    disabled,
  });

  const submitHandler = form.handleSubmit((data) =>
    onSave({ data, dirtyFields: form.formState.dirtyFields }),
  );
  const isCallback = useWatch({ control: form.control, name: 'isCallback' });

  const jobTypesQuery = useJobTypesQuery({
    select(data) {
      return (
        data.map((jt) => ({
          label: jt.name,
          value: jt.id.toString(),
        })) ?? []
      );
    },
  });
  const jobSubTypesQuery = useJobSubTypesQuery({
    select(data) {
      return (
        data?.results.map((jst) => ({
          label: jst.name,
          value: jst.id.toString(),
        })) ?? []
      );
    },
  });

  return (
    <Form {...form}>
      <form className="grid grid-cols-2 gap-4" onSubmit={submitHandler}>
        <h3>Job description</h3>

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem className="col-span-2 flex flex-col justify-end">
              <FormControl>
                <Textarea {...field} placeholder="Job notes" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex flex-col gap-4">
          <FormField
            control={form.control}
            name="highPriority"
            render={({ field: { onChange, value, ...rest } }) => (
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormLabel className="w-24">Hight Priority?</FormLabel>
                <FormControl>
                  <Checkbox {...rest} onCheckedChange={onChange} checked={value} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="isCallback"
            render={({ field: { onChange, value, ...rest } }) => (
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormLabel className="w-24">is Callback?</FormLabel>
                <FormControl>
                  <Checkbox {...rest} onCheckedChange={onChange} checked={value} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="isLmpc"
            render={({ field: { onChange, value, ...rest } }) => (
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormLabel className="w-24">is LMPC?</FormLabel>
                <FormControl>
                  <Checkbox {...rest} onCheckedChange={onChange} checked={value} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="callbackOriginalId"
          render={({ field }) => (
            <FormItem className="flex flex-col justify-end">
              <FormLabel>Callback regarding Job</FormLabel>
              <FormControl>
                <JobSelector {...field} disabled={field.disabled || !isCallback} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="jobTypeId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Job Type</FormLabel>
              <FormControl>
                <SimpleSelect
                  placeholder={OPTION_ALL}
                  onChange={(value) => {
                    field.onChange(Number(value));
                  }}
                  disabled={jobTypesQuery.isPending}
                  selectedValue={field.value.toString()}
                  values={jobTypesQuery.data ?? []}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="jobSubtypeId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Job Subtype</FormLabel>
              <FormControl>
                <SimpleSelect
                  placeholder={OPTION_ALL}
                  selectedValue={field.value.toString()}
                  disabled={jobSubTypesQuery.isPending}
                  onChange={(value) => {
                    field.onChange(Number(value));
                  }}
                  values={jobSubTypesQuery.data ?? []}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="departmentId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Department</FormLabel>
              <FormControl>
                <TimesheetDepartmentPickerV2
                  onSelect={(d) => field.onChange(d?.id ?? 0)}
                  renderItem={renderDepartment}
                  selected={field.value.toString()}
                  disabled={field.disabled}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <h3 className="col-span-2 text-lg font-semibold">Line Items</h3>

        <FormField
          control={form.control}
          name="logServiceRendered"
          render={({ field }) => (
            <FormItem className="col-span-2 flex flex-col justify-end">
              <FormControl>
                <Textarea {...field} placeholder="Service Needed" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="amountQuoted"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Amount quoted</FormLabel>
              <FormControl>
                <MoneyInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="diagnosticQuoted"
          render={({ field: { onChange, value, ...rest } }) => (
            <FormItem className="flex items-end  gap-2 space-y-0">
              <FormLabel>Diagnostic quoted?</FormLabel>
              <FormControl>
                <Checkbox {...rest} onCheckedChange={onChange} checked={value} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <h3 className="col-span-2 text-lg font-semibold">Payment</h3>

        <FormField
          control={form.control}
          name="logPo"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Po</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="logPaymentType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Payment Type</FormLabel>
              <FormControl>
                <SimpleSelect {...field} values={PAYMENT_TYPE_OPTIONS} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <h3 className="col-span-2 text-lg font-semibold">Scheduling</h3>

        <Dialog>
          <DialogTrigger asChild>
            <div className="col-span-2">
              <Button className="max-w-max self-end" type="button" variant="outline">
                View Calendar
              </Button>
            </div>
          </DialogTrigger>
          <DialogContent className="max-w-screen-lg">
            <CallWindow type="week" />
          </DialogContent>
        </Dialog>

        <FormField
          control={form.control}
          name="dateTimeStarted"
          render={({ field: { ref, ...field } }) => (
            <FormItem className="col-span-2 md:col-span-1">
              <FormLabel>Start date</FormLabel>
              <FormControl>
                <DateTimePicker granularity="minute" hourCycle={12} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="dateTimeEnded"
          render={({ field: { ref, ...field } }) => (
            <FormItem className="col-span-2 md:col-span-1">
              <FormLabel>End date</FormLabel>
              <FormControl>
                <DateTimePicker granularity="minute" hourCycle={12} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="logJobStatus"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Job Status</FormLabel>
              <FormControl>
                <SimpleSelect
                  placeholder={OPTION_ALL}
                  selectedValue={field.value}
                  onChange={field.onChange}
                  values={EVENT_STATUS_LIST}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="logTechnicianAssigned"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Employee Assigned</FormLabel>
              <FormControl>
                <TechniciansComboboxPicker
                  selected={field.value.toString()}
                  onSelect={(u) => field.onChange(u?.id ?? 0)}
                  disabled={field.disabled}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="col-span-2">
          <Button disabled={isLoading || disabled} type="submit">
            Save
          </Button>
        </div>
      </form>
    </Form>
  );
};

NewJobForm.getDefaultValues = getDefaultValues;
