import { type ClientInfo, type ClientSession, type SoftphoneClient } from '@kalos/kalos-rpc';
import { createStore } from 'zustand';

export type SoftPhoneStore = {
  client: SoftphoneClient;
  session: ClientSession | null;
  isSessionLoading: boolean;
  createSession: (args: { userId: number; clientInfo: ClientInfo }) => Promise<ClientSession>;
  terminateSession: (args: { sessionId: string }) => Promise<void>;
  activateSession: () => Promise<void>;
  deactivateSession: () => Promise<void>;
};

export const createSoftPhoneStore = (client: SoftphoneClient) =>
  createStore<SoftPhoneStore>()((set, get) => ({
    client,
    session: null,
    isSessionLoading: false,
    error: null,

    createSession: async ({ userId, clientInfo }) => {
      const client = get().client;
      if (!client) throw new Error('createSession: Client not initialized');

      set({ isSessionLoading: true });
      const session = await client.InitializeClient(userId, clientInfo);
      set({ session, isSessionLoading: false });
      return session;
    },

    terminateSession: async ({ sessionId }) => {
      const client = get().client;
      if (!client) throw new Error('terminateSession: Client not initialized');

      await client.TerminateClient(sessionId);
      set({ session: null });
    },

    activateSession: async () => {
      const state = get();
      if (state.session || state.isSessionLoading) return;

      await state.createSession({
        userId: state.client.userID,
        clientInfo: {
          appVersion: '1.0.0',
          deviceId: `${state.client.userID}-web-softphone`,
          platform: 'browser',
          deviceType: 'web',
        },
      });
    },

    deactivateSession: async () => {
      const state = get();
      if (!state.session) return;
      await state.terminateSession({ sessionId: state.session.sessionId });
    },
  }));
