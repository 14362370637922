import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { useMemo } from 'react';

import { Button } from '../components/button';
import { Calendar } from '../components/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../components/popover';
import { useControllableState } from '../utils/hooks';

type WeekPickerProps = {
  value?: Date;
  onChange?: (date?: Date) => void;
  defaultValue?: Date;
  weekStartOnDay?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
};
const getFirstDayOfWeek = (date: Date, weekStartOnDay: 0 | 1 | 2 | 3 | 4 | 5 | 6) => {
  const firstDay = new Date(date);
  firstDay.setDate(firstDay.getDate() - firstDay.getDay() + weekStartOnDay);
  return firstDay;
};
/**
 * onChange: (date?: Date) => void - will return the first day of the week (start of the week that is provided)
 */
export const WeekPicker = ({
  value,
  onChange,
  defaultValue,
  weekStartOnDay = 0,
}: WeekPickerProps) => {
  const [week, setWeek] = useControllableState({
    defaultProp: defaultValue,
    prop: value,
    onChange,
  });

  const selectedWeekDays = useMemo(() => {
    if (!week) return [];
    const firstDayOfWeek = getFirstDayOfWeek(week, weekStartOnDay);
    const weekDays = Array.from({ length: 7 }, (_, i) => {
      const date = new Date(firstDayOfWeek);
      date.setDate(date.getDate() + i);
      return date;
    });
    return weekDays;
  }, [week, weekStartOnDay]);

  const onSelect = (data?: Date[]) => {
    const lastEl = data?.at(-1);
    if (lastEl) {
      setWeek(getFirstDayOfWeek(lastEl, weekStartOnDay));
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="flex items-center gap-2">
          <CalendarIcon size={15} />
          {week
            ? `${format(selectedWeekDays[0], 'MMM dd, yyyy')} - ${format(selectedWeekDays[6], 'MMM dd, yyyy')}`
            : 'Select week'}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Calendar
          mode="multiple"
          weekStartsOn={weekStartOnDay}
          onSelect={onSelect}
          selected={selectedWeekDays}
          classNames={{
            outside: 'text-muted-foreground',
            selected: 'bg-primary rounded-none last:rounded-r-md first:rounded-l-md text-white',
          }}
        />
      </PopoverContent>
    </Popover>
  );
};
