// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "softphone.proto" (syntax proto3)
// tslint:disable
import { SoftphoneService } from "./softphone";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service SoftphoneService
 */
export class SoftphoneServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = SoftphoneService.typeName;
        this.methods = SoftphoneService.methods;
        this.options = SoftphoneService.options;
    }
    /**
     * Client Session Management
     *
     * @generated from protobuf rpc: InitializeClient(InitializeClientRequest) returns (ClientSession);
     */
    initializeClient(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: TerminateClient(TerminateClientRequest) returns (Empty);
     */
    terminateClient(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SubscribeToEvents(SubscribeRequest) returns (stream Notification);
     */
    subscribeToEvents(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * Call Management
     *
     * @generated from protobuf rpc: StartCall(StartCallRequest) returns (GoToCall);
     */
    startCall(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AnswerCall(CallId) returns (GoToCall);
     */
    answerCall(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RejectCall(CallId) returns (GoToCall);
     */
    rejectCall(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EndCall(CallId) returns (Empty);
     */
    endCall(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ForwardCall(ForwardCallRequest) returns (Empty);
     */
    forwardCall(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Call Controls
     *
     * @generated from protobuf rpc: ToggleMute(CallId) returns (GoToCall);
     */
    toggleMute(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ToggleHold(CallId) returns (GoToCall);
     */
    toggleHold(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SendDTMF(DTMFRequest) returns (GoToCall);
     */
    sendDTMF(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAuthURL(GetAuthURLRequest) returns (GetAuthURLResponse);
     */
    getAuthURL(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ExchangeCode(ExchangeCodeRequest) returns (Bool);
     */
    exchangeCode(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ExchangeCodeWithRedirect(ExchangeCodeRequestWithRedirect) returns (Bool);
     */
    exchangeCodeWithRedirect(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RefreshOAuthToken(Empty) returns (Bool);
     */
    refreshOAuthToken(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserToken(GetUserTokenRequest) returns (Bool);
     */
    getUserToken(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Messages Controls
     *
     * @generated from protobuf rpc: SendMessage(MessageRequest) returns (GoToMessage);
     */
    sendMessage(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: FetchMessages(FetchMessagesRequest) returns (GoToMessageList);
     */
    fetchMessages(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: FetchConversations(FetchConversationsRequest) returns (GoToConversationList);
     */
    fetchConversations(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Status Management
     *
     * @generated from protobuf rpc: UpdateAgentStatus(UpdateAgentStatusRequest) returns (ClientSession);
     */
    updateAgentStatus(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserPresence(GetUserPresenceRequest) returns (UserPresence);
     */
    getUserPresence(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateUserPresence(UpdateUserPresenceRequest) returns (UserPresence);
     */
    updateUserPresence(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCallHistory(GetCallHistoryRequest) returns (GetCallHistoryResponse);
     */
    getCallHistory(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMe(Empty) returns (MeResponse);
     */
    getMe(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: FetchCurrentCallQueue(Empty) returns (ClientSessionList);
     */
    fetchCurrentCallQueue(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * Add to SoftphoneService
     *
     * @generated from protobuf rpc: GenerateTestCalls(GenerateTestCallsRequest) returns (Empty);
     */
    generateTestCalls(input, options) {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateNotificationChannel(SessionID) returns (Bool);
     */
    createNotificationChannel(input, options) {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
