import { type AssignUserToQueueRequest, GoToAdminClient } from '@kalos/kalos-rpc';
import { useMutation } from '@tanstack/react-query';

import { ENDPOINT } from '../../../constants';

const client = new GoToAdminClient(ENDPOINT);

export const useGoToAdminAssignUserToQueueMutation = () => {
  return useMutation({
    mutationFn: (request: AssignUserToQueueRequest) => client.AssignUserToQueue(request),
  });
};
