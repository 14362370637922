import { Badge, Tooltip, TooltipContent, TooltipTrigger } from '@kalos/ui';
import { type ComponentProps } from 'react';

import { JOB_STATUS_COLORS } from '../../../constants';
import { type getJobTypeBreakdown } from '../../ServiceCalendar/components/utils';

type BreakDownBadgeProps = {
  breakdown: ReturnType<typeof getJobTypeBreakdown>;
  totalCount: number;
  status: 'Total' | 'Completed' | 'Confirmed' | 'Pending';
};

type VariantMapType = {
  Total: ComponentProps<typeof Badge>['variant'];
  Completed: ComponentProps<typeof Badge>['variant'];
  Confirmed: string;
  Pending: string;
};

const variantMap: VariantMapType = {
  Total: 'table',
  Completed: 'success',
  Confirmed: `#${JOB_STATUS_COLORS.Confirmed}`,
  Pending: `#${JOB_STATUS_COLORS.Requested}`,
};

/**
 * Must be wrapped in a TooltipProvider
 */
export const BreakDownBadge = ({ breakdown, totalCount, status }: BreakDownBadgeProps) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Badge
          variant={status === 'Total' || status === 'Completed' ? variantMap[status] : undefined}
          style={
            status === 'Confirmed' || status === 'Pending'
              ? { backgroundColor: variantMap[status], color: '#28293D' }
              : undefined
          }
        >
          {totalCount}
        </Badge>
      </TooltipTrigger>
      <TooltipContent side="bottom" sideOffset={10} className="space-y-2 px-1">
        <div className="font-bold">{status} Call count</div>
        {breakdown.map((item) => (
          <div key={item.jobType} className="text-sm">
            {item.jobType}: {item.count}
          </div>
        ))}
      </TooltipContent>
    </Tooltip>
  );
};
