import { GetAuthURLRequest } from '@kalos/kalos-rpc';
import { Button } from '@kalos/ui';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useStore } from 'zustand';

import { useAuth } from '../../context/AuthContext';
import {
  useProtectedSoftPhoneStore,
  useSoftPhoneStoreContext,
} from './context/SoftPhoneStore.context';

export const OAuthConsent = () => {
  const softphoneStoreContext = useSoftPhoneStoreContext();

  if (softphoneStoreContext.isPending) {
    return <div>Loading...</div>;
  }

  if (!softphoneStoreContext.requiresAuth) {
    return <Navigate to="/" />;
  }

  if (softphoneStoreContext.store) {
    return <SoftphoneOauth />;
  }

  return (
    <div>
      No softphone store context. Please refresh the page or if the problem is not solved, contact
      dev support.
    </div>
  );
};

const SoftphoneOauth: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const store = useProtectedSoftPhoneStore();
  const client = useStore(store, (state) => state.client);

  // Handle the callback from GoTo if we have a code
  useEffect(() => {
    const code = searchParams.get('code');
    if (!code || !user.id) return;

    const handleCallback = async () => {
      try {
        // Exchange the code for a token
        const response = await client.ExchangeCodeWithRedirect({
          userId: user.id,
          code,
          redirectUri: `${window.location.origin}/softphone/oauth-callback`,
        });
        if (!response.value) {
          throw new Error('Failed to exchange code');
        }
        // Go back to where we came from
        const returnUrl = sessionStorage.getItem('softphone_return_url') || '/';
        sessionStorage.removeItem('softphone_return_url');
        navigate(returnUrl);
      } catch (err) {
        console.error('Auth failed:', err);
      }
    };

    handleCallback();
  }, [searchParams, user.id, client, navigate]);

  // Start the OAuth flow
  const [isLoading, setIsLoading] = useState(false);
  const handleAuth = async () => {
    if (!user.id) return;

    try {
      setIsLoading(true);
      // Store where we want to return to
      sessionStorage.setItem('softphone_return_url', window.location.pathname);

      const redirectUri = `${window.location.origin}/softphone/oauth-callback`;
      console.log('redirectUri', redirectUri);
      // Get the GoTo auth URL
      const res = await client.GetAuthURL(
        GetAuthURLRequest.create({
          state: user.id.toString(),
          redirectUri,
        }),
      );

      console.log('res', res);

      if (!res?.url) {
        throw new Error('Failed to get authorization URL');
      }

      // Redirect to GoTo login
      window.location.href = res.url;
    } catch (err) {
      console.error('Failed to start auth:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // If we're not handling a callback, show the auth button
  if (!searchParams.get('code')) {
    return (
      <div className="flex flex-col items-center justify-center gap-4 p-4">
        <h2>Softphone Setup</h2>
        <p>To use the softphone, you need to connect to your GoTo account.</p>
        <Button disabled={isLoading} onClick={handleAuth} isLoading={isLoading}>
          Connect to GoTo
        </Button>
      </div>
    );
  }

  // Show loading while handling callback
  return <div>Connecting to GoTo...</div>;
};
