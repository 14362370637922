import { type GoToCall, User } from '@kalos/kalos-rpc';
import { create } from 'zustand';

import { createSelectors } from '../../../../tools/zustand';
import { cleanupRTC, softphoneLog } from './utils';

type CallStore = {
  // properties
  peerConnection: RTCPeerConnection | null;
  isCalling: boolean;
  isPendingSound: boolean;
  remoteStream: MediaStream | null;
  localStream: MediaStream | null;
  onCallEndListeners: (() => void)[];
  currentCall: (GoToCall & { source: 'inbound' | 'outbound' }) | null;
  currentCaller: User | null;
  outboundNumber: string;
  // setters
  setCurrentCall: React.Dispatch<
    React.SetStateAction<(GoToCall & { source: 'inbound' | 'outbound' }) | null>
  >;
  setCurrentCaller: (currentCaller: User | null) => void;
  setPeerConnection: (peerConnection: RTCPeerConnection | null) => void;
  setIsCalling: (isCalling: boolean) => void;
  setIsPendingSound: (isPendingSound: boolean) => void;
  setRemoteStream: (remoteStream: MediaStream | null) => void;
  setLocalStream: (localStream: MediaStream | null) => void;
  addOnCallEndListener: (listener: () => void) => void;
  handleMuteLocalStream: () => void;
  handleUnmuteLocalStream: () => void;
  handleReset: () => void;
  setOutboundNumber: (number: string) => void;
  // methods
  handleCallEnd: () => void;
  // derived
  computed: {
    isCallPendingVoice: boolean;
    isCallActive: boolean;
    isMuted: boolean;
  };
};

export const useCallStoreBase = create<CallStore>()((set, get) => ({
  // properties
  currentCall: null,
  peerConnection: null,
  isCalling: false,
  isPendingSound: false,
  remoteStream: null,
  localStream: null,
  onCallEndListeners: [],
  // currentCaller: null,
  // TODO remove  - this is for the testing
  currentCaller: User.create({
    id: 1571,
    firstname: 'Steve Humphries-Broker/ ',
    lastname: ' Balwinder Burdy-Property Manager',
    phone: '+1 (242) 424-5545',
  }),
  outboundNumber: '',
  // setters
  setCurrentCall: (currentCall) =>
    set({
      currentCall: typeof currentCall === 'function' ? currentCall(get().currentCall) : currentCall,
    }),
  setPeerConnection: (peerConnection) => set({ peerConnection }),
  setIsCalling: (isCalling) => set({ isCalling }),
  setIsPendingSound: (isPendingSound) => set({ isPendingSound }),
  setRemoteStream: (remoteStream) => set({ remoteStream }),
  setLocalStream: (localStream) => set({ localStream }),
  addOnCallEndListener: (listener) =>
    set((state) => ({ onCallEndListeners: [...state.onCallEndListeners, listener] })),
  setOutboundNumber: (outboundNumber) => set({ outboundNumber }),
  // methods
  handleCallEnd: () => {
    softphoneLog('call store: handleCallEnd');
    set((state) => {
      const peerConnection = get().peerConnection;
      if (!peerConnection)
        throw new Error('Peer connection should be set before calling handleCallEnd');
      cleanupRTC({ peerConnection });
      state.onCallEndListeners.forEach((listener) => listener());
      return {
        peerConnection: null,
        onCallEndListeners: [],
        isPendingSound: false,
        isCalling: false,
        currentCall: null,
      };
    });
  },
  handleMuteLocalStream: () => {
    softphoneLog('call store: handleMuteLocalStream');
    get()
      .localStream?.getTracks()
      .forEach((track) => {
        track.enabled = false;
      });
  },
  handleUnmuteLocalStream: () => {
    softphoneLog('call store: handleUnmuteLocalStream');
    get()
      .localStream?.getTracks()
      .forEach((track) => {
        track.enabled = true;
      });
  },
  handleReset: () => {
    softphoneLog('call store: handleReset');
    const peerConnection = get().peerConnection;
    if (peerConnection) cleanupRTC({ peerConnection });
    get().onCallEndListeners.forEach((listener) => listener());
    set({
      peerConnection: null,
      isCalling: false,
      isPendingSound: false,
      currentCall: null,
      remoteStream: null,
      localStream: null,
      onCallEndListeners: [],
    });
  },
  setCurrentCaller: (currentCaller) => set({ currentCaller }),
  // derived
  computed: {
    get isCallPendingVoice() {
      return !!get().peerConnection && (get().isCalling || get().isPendingSound);
    },
    get isCallActive() {
      return !!get().peerConnection && !(get().isCalling || get().isPendingSound);
    },
    get isMuted() {
      return !!get()
        .localStream?.getTracks()
        .some((track) => !track.enabled);
    },
  },
}));

export const useCallStore = createSelectors(useCallStoreBase);
