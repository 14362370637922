// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "service_item.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "service_item.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class Reading$Type extends MessageType {
    constructor() {
        super("Reading", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "service_item_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "tstat_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tstat_brand", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "compressor_amps", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "condensing_fan_amps", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "suction_pressure", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "head_pressure", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "return_temperature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "supply_temperature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "subcool", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "superheat", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "display_unckecked", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "refrigerant_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "blower_amps", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "condenser_fan_capacitor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "compressor_capacitor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "blower_capacitor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "discharge_temperature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "pool_supply_temp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "pool_return_temp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "ambient_air_temp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "return_db", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "return_wb", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "evap_td", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "coil_static_drop", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "tesp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "gas_pressure_in", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 33, name: "gas_pressure_out", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "ll_temp_drop", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 35, name: "sl_temp_drop", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 36, name: "services_rendered_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 37, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 38, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 39, name: "reading_images", kind: "message", T: () => ReadingImageList },
            { no: 40, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 41, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, serviceItemId: 0, date: "", tstatType: "", tstatBrand: "", compressorAmps: "", condensingFanAmps: "", suctionPressure: "", headPressure: "", returnTemperature: "", supplyTemperature: "", subcool: "", superheat: "", notes: "", displayUnckecked: 0, userId: 0, eventId: 0, refrigerantType: "", blowerAmps: "", condenserFanCapacitor: "", compressorCapacitor: "", blowerCapacitor: "", dischargeTemperature: "", poolSupplyTemp: "", poolReturnTemp: "", ambientAirTemp: "", returnDb: "", returnWb: "", evapTd: "", coilStaticDrop: "", tesp: "", gasPressureIn: "", gasPressureOut: "", llTempDrop: "", slTempDrop: "", servicesRenderedId: 0, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 service_item_id */ 2:
                    message.serviceItemId = reader.int32();
                    break;
                case /* string date */ 3:
                    message.date = reader.string();
                    break;
                case /* string tstat_type */ 4:
                    message.tstatType = reader.string();
                    break;
                case /* string tstat_brand */ 5:
                    message.tstatBrand = reader.string();
                    break;
                case /* string compressor_amps */ 6:
                    message.compressorAmps = reader.string();
                    break;
                case /* string condensing_fan_amps */ 7:
                    message.condensingFanAmps = reader.string();
                    break;
                case /* string suction_pressure */ 8:
                    message.suctionPressure = reader.string();
                    break;
                case /* string head_pressure */ 9:
                    message.headPressure = reader.string();
                    break;
                case /* string return_temperature */ 10:
                    message.returnTemperature = reader.string();
                    break;
                case /* string supply_temperature */ 11:
                    message.supplyTemperature = reader.string();
                    break;
                case /* string subcool */ 12:
                    message.subcool = reader.string();
                    break;
                case /* string superheat */ 13:
                    message.superheat = reader.string();
                    break;
                case /* string notes */ 14:
                    message.notes = reader.string();
                    break;
                case /* int32 display_unckecked */ 15:
                    message.displayUnckecked = reader.int32();
                    break;
                case /* int32 user_id */ 16:
                    message.userId = reader.int32();
                    break;
                case /* int32 event_id */ 17:
                    message.eventId = reader.int32();
                    break;
                case /* string refrigerant_type */ 18:
                    message.refrigerantType = reader.string();
                    break;
                case /* string blower_amps */ 19:
                    message.blowerAmps = reader.string();
                    break;
                case /* string condenser_fan_capacitor */ 20:
                    message.condenserFanCapacitor = reader.string();
                    break;
                case /* string compressor_capacitor */ 21:
                    message.compressorCapacitor = reader.string();
                    break;
                case /* string blower_capacitor */ 22:
                    message.blowerCapacitor = reader.string();
                    break;
                case /* string discharge_temperature */ 23:
                    message.dischargeTemperature = reader.string();
                    break;
                case /* string pool_supply_temp */ 24:
                    message.poolSupplyTemp = reader.string();
                    break;
                case /* string pool_return_temp */ 25:
                    message.poolReturnTemp = reader.string();
                    break;
                case /* string ambient_air_temp */ 26:
                    message.ambientAirTemp = reader.string();
                    break;
                case /* string return_db */ 27:
                    message.returnDb = reader.string();
                    break;
                case /* string return_wb */ 28:
                    message.returnWb = reader.string();
                    break;
                case /* string evap_td */ 29:
                    message.evapTd = reader.string();
                    break;
                case /* string coil_static_drop */ 30:
                    message.coilStaticDrop = reader.string();
                    break;
                case /* string tesp */ 31:
                    message.tesp = reader.string();
                    break;
                case /* string gas_pressure_in */ 32:
                    message.gasPressureIn = reader.string();
                    break;
                case /* string gas_pressure_out */ 33:
                    message.gasPressureOut = reader.string();
                    break;
                case /* string ll_temp_drop */ 34:
                    message.llTempDrop = reader.string();
                    break;
                case /* string sl_temp_drop */ 35:
                    message.slTempDrop = reader.string();
                    break;
                case /* int32 services_rendered_id */ 36:
                    message.servicesRenderedId = reader.int32();
                    break;
                case /* repeated string field_mask */ 37:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 38:
                    message.pageNumber = reader.int32();
                    break;
                case /* ReadingImageList reading_images */ 39:
                    message.readingImages = ReadingImageList.internalBinaryRead(reader, reader.uint32(), options, message.readingImages);
                    break;
                case /* string order_by */ 40:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 41:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 service_item_id = 2; */
        if (message.serviceItemId !== 0)
            writer.tag(2, WireType.Varint).int32(message.serviceItemId);
        /* string date = 3; */
        if (message.date !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.date);
        /* string tstat_type = 4; */
        if (message.tstatType !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.tstatType);
        /* string tstat_brand = 5; */
        if (message.tstatBrand !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.tstatBrand);
        /* string compressor_amps = 6; */
        if (message.compressorAmps !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.compressorAmps);
        /* string condensing_fan_amps = 7; */
        if (message.condensingFanAmps !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.condensingFanAmps);
        /* string suction_pressure = 8; */
        if (message.suctionPressure !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.suctionPressure);
        /* string head_pressure = 9; */
        if (message.headPressure !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.headPressure);
        /* string return_temperature = 10; */
        if (message.returnTemperature !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.returnTemperature);
        /* string supply_temperature = 11; */
        if (message.supplyTemperature !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.supplyTemperature);
        /* string subcool = 12; */
        if (message.subcool !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.subcool);
        /* string superheat = 13; */
        if (message.superheat !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.superheat);
        /* string notes = 14; */
        if (message.notes !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.notes);
        /* int32 display_unckecked = 15; */
        if (message.displayUnckecked !== 0)
            writer.tag(15, WireType.Varint).int32(message.displayUnckecked);
        /* int32 user_id = 16; */
        if (message.userId !== 0)
            writer.tag(16, WireType.Varint).int32(message.userId);
        /* int32 event_id = 17; */
        if (message.eventId !== 0)
            writer.tag(17, WireType.Varint).int32(message.eventId);
        /* string refrigerant_type = 18; */
        if (message.refrigerantType !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.refrigerantType);
        /* string blower_amps = 19; */
        if (message.blowerAmps !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.blowerAmps);
        /* string condenser_fan_capacitor = 20; */
        if (message.condenserFanCapacitor !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.condenserFanCapacitor);
        /* string compressor_capacitor = 21; */
        if (message.compressorCapacitor !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.compressorCapacitor);
        /* string blower_capacitor = 22; */
        if (message.blowerCapacitor !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.blowerCapacitor);
        /* string discharge_temperature = 23; */
        if (message.dischargeTemperature !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.dischargeTemperature);
        /* string pool_supply_temp = 24; */
        if (message.poolSupplyTemp !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.poolSupplyTemp);
        /* string pool_return_temp = 25; */
        if (message.poolReturnTemp !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.poolReturnTemp);
        /* string ambient_air_temp = 26; */
        if (message.ambientAirTemp !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.ambientAirTemp);
        /* string return_db = 27; */
        if (message.returnDb !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.returnDb);
        /* string return_wb = 28; */
        if (message.returnWb !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.returnWb);
        /* string evap_td = 29; */
        if (message.evapTd !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.evapTd);
        /* string coil_static_drop = 30; */
        if (message.coilStaticDrop !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.coilStaticDrop);
        /* string tesp = 31; */
        if (message.tesp !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.tesp);
        /* string gas_pressure_in = 32; */
        if (message.gasPressureIn !== "")
            writer.tag(32, WireType.LengthDelimited).string(message.gasPressureIn);
        /* string gas_pressure_out = 33; */
        if (message.gasPressureOut !== "")
            writer.tag(33, WireType.LengthDelimited).string(message.gasPressureOut);
        /* string ll_temp_drop = 34; */
        if (message.llTempDrop !== "")
            writer.tag(34, WireType.LengthDelimited).string(message.llTempDrop);
        /* string sl_temp_drop = 35; */
        if (message.slTempDrop !== "")
            writer.tag(35, WireType.LengthDelimited).string(message.slTempDrop);
        /* int32 services_rendered_id = 36; */
        if (message.servicesRenderedId !== 0)
            writer.tag(36, WireType.Varint).int32(message.servicesRenderedId);
        /* repeated string field_mask = 37; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(37, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 38; */
        if (message.pageNumber !== 0)
            writer.tag(38, WireType.Varint).int32(message.pageNumber);
        /* ReadingImageList reading_images = 39; */
        if (message.readingImages)
            ReadingImageList.internalBinaryWrite(message.readingImages, writer.tag(39, WireType.LengthDelimited).fork(), options).join();
        /* string order_by = 40; */
        if (message.orderBy !== "")
            writer.tag(40, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 41; */
        if (message.orderDir !== "")
            writer.tag(41, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Reading
 */
export const Reading = new Reading$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReadingList$Type extends MessageType {
    constructor() {
        super("ReadingList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Reading },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Reading results */ 1:
                    message.results.push(Reading.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Reading results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Reading.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReadingList
 */
export const ReadingList = new ReadingList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceReadingLine$Type extends MessageType {
    constructor() {
        super("ServiceReadingLine", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "reading_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "unit_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, readingId: 0, unitId: 0, typeId: 0, value: "", fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 reading_id */ 2:
                    message.readingId = reader.int32();
                    break;
                case /* int32 unit_id */ 3:
                    message.unitId = reader.int32();
                    break;
                case /* int32 type_id */ 4:
                    message.typeId = reader.int32();
                    break;
                case /* string value */ 5:
                    message.value = reader.string();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 reading_id = 2; */
        if (message.readingId !== 0)
            writer.tag(2, WireType.Varint).int32(message.readingId);
        /* int32 unit_id = 3; */
        if (message.unitId !== 0)
            writer.tag(3, WireType.Varint).int32(message.unitId);
        /* int32 type_id = 4; */
        if (message.typeId !== 0)
            writer.tag(4, WireType.Varint).int32(message.typeId);
        /* string value = 5; */
        if (message.value !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.value);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServiceReadingLine
 */
export const ServiceReadingLine = new ServiceReadingLine$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceReadingLineList$Type extends MessageType {
    constructor() {
        super("ServiceReadingLineList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServiceReadingLine },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ServiceReadingLine results */ 1:
                    message.results.push(ServiceReadingLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ServiceReadingLine results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ServiceReadingLine.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServiceReadingLineList
 */
export const ServiceReadingLineList = new ServiceReadingLineList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceItemImage$Type extends MessageType {
    constructor() {
        super("ServiceItemImage", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "service_item_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "image_title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "image_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "file_bucket", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, serviceItemId: 0, imageTitle: "", imageName: "", dateCreated: "", fieldMask: [], pageNumber: 0, fileId: 0, fileName: "", fileBucket: "", mimeType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 service_item_id */ 2:
                    message.serviceItemId = reader.int32();
                    break;
                case /* string image_title */ 3:
                    message.imageTitle = reader.string();
                    break;
                case /* string image_name */ 4:
                    message.imageName = reader.string();
                    break;
                case /* string date_created */ 5:
                    message.dateCreated = reader.string();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                case /* int32 file_id */ 8:
                    message.fileId = reader.int32();
                    break;
                case /* string file_name */ 9:
                    message.fileName = reader.string();
                    break;
                case /* string file_bucket */ 10:
                    message.fileBucket = reader.string();
                    break;
                case /* string mime_type */ 11:
                    message.mimeType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 service_item_id = 2; */
        if (message.serviceItemId !== 0)
            writer.tag(2, WireType.Varint).int32(message.serviceItemId);
        /* string image_title = 3; */
        if (message.imageTitle !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.imageTitle);
        /* string image_name = 4; */
        if (message.imageName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.imageName);
        /* string date_created = 5; */
        if (message.dateCreated !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.dateCreated);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        /* int32 file_id = 8; */
        if (message.fileId !== 0)
            writer.tag(8, WireType.Varint).int32(message.fileId);
        /* string file_name = 9; */
        if (message.fileName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.fileName);
        /* string file_bucket = 10; */
        if (message.fileBucket !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.fileBucket);
        /* string mime_type = 11; */
        if (message.mimeType !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.mimeType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServiceItemImage
 */
export const ServiceItemImage = new ServiceItemImage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceItemImageList$Type extends MessageType {
    constructor() {
        super("ServiceItemImageList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServiceItemImage },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ServiceItemImage results */ 1:
                    message.results.push(ServiceItemImage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ServiceItemImage results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ServiceItemImage.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServiceItemImageList
 */
export const ServiceItemImageList = new ServiceItemImageList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceItemMaterial$Type extends MessageType {
    constructor() {
        super("ServiceItemMaterial", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "system_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "part", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "filter_width", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "filter_height", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "filter_thickness", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "quantity", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "vendor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, systemId: 0, typeId: 0, description: "", part: "", filterWidth: "", filterHeight: "", filterThickness: "", quantity: "", isActive: 0, vendor: "", fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 system_id */ 2:
                    message.systemId = reader.int32();
                    break;
                case /* int32 type_id */ 3:
                    message.typeId = reader.int32();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string part */ 5:
                    message.part = reader.string();
                    break;
                case /* string filter_width */ 6:
                    message.filterWidth = reader.string();
                    break;
                case /* string filter_height */ 7:
                    message.filterHeight = reader.string();
                    break;
                case /* string filter_thickness */ 8:
                    message.filterThickness = reader.string();
                    break;
                case /* string quantity */ 9:
                    message.quantity = reader.string();
                    break;
                case /* int32 is_active */ 10:
                    message.isActive = reader.int32();
                    break;
                case /* string vendor */ 11:
                    message.vendor = reader.string();
                    break;
                case /* repeated string field_mask */ 12:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 13:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 system_id = 2; */
        if (message.systemId !== 0)
            writer.tag(2, WireType.Varint).int32(message.systemId);
        /* int32 type_id = 3; */
        if (message.typeId !== 0)
            writer.tag(3, WireType.Varint).int32(message.typeId);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string part = 5; */
        if (message.part !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.part);
        /* string filter_width = 6; */
        if (message.filterWidth !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.filterWidth);
        /* string filter_height = 7; */
        if (message.filterHeight !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.filterHeight);
        /* string filter_thickness = 8; */
        if (message.filterThickness !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.filterThickness);
        /* string quantity = 9; */
        if (message.quantity !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.quantity);
        /* int32 is_active = 10; */
        if (message.isActive !== 0)
            writer.tag(10, WireType.Varint).int32(message.isActive);
        /* string vendor = 11; */
        if (message.vendor !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.vendor);
        /* repeated string field_mask = 12; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 13; */
        if (message.pageNumber !== 0)
            writer.tag(13, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServiceItemMaterial
 */
export const ServiceItemMaterial = new ServiceItemMaterial$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceItemMaterialList$Type extends MessageType {
    constructor() {
        super("ServiceItemMaterialList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServiceItemMaterial },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ServiceItemMaterial results */ 1:
                    message.results.push(ServiceItemMaterial.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ServiceItemMaterial results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ServiceItemMaterial.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServiceItemMaterialList
 */
export const ServiceItemMaterialList = new ServiceItemMaterialList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceItemUnit$Type extends MessageType {
    constructor() {
        super("ServiceItemUnit", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "system_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "brand", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "model", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "serial", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, systemId: 0, typeId: 0, description: "", brand: "", model: "", serial: "", location: "", notes: "", started: "", isActive: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 system_id */ 2:
                    message.systemId = reader.int32();
                    break;
                case /* int32 type_id */ 3:
                    message.typeId = reader.int32();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string brand */ 5:
                    message.brand = reader.string();
                    break;
                case /* string model */ 6:
                    message.model = reader.string();
                    break;
                case /* string serial */ 7:
                    message.serial = reader.string();
                    break;
                case /* string location */ 8:
                    message.location = reader.string();
                    break;
                case /* string notes */ 9:
                    message.notes = reader.string();
                    break;
                case /* string started */ 10:
                    message.started = reader.string();
                    break;
                case /* int32 is_active */ 11:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string field_mask */ 12:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 13:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 system_id = 2; */
        if (message.systemId !== 0)
            writer.tag(2, WireType.Varint).int32(message.systemId);
        /* int32 type_id = 3; */
        if (message.typeId !== 0)
            writer.tag(3, WireType.Varint).int32(message.typeId);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string brand = 5; */
        if (message.brand !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.brand);
        /* string model = 6; */
        if (message.model !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.model);
        /* string serial = 7; */
        if (message.serial !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.serial);
        /* string location = 8; */
        if (message.location !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.location);
        /* string notes = 9; */
        if (message.notes !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.notes);
        /* string started = 10; */
        if (message.started !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.started);
        /* int32 is_active = 11; */
        if (message.isActive !== 0)
            writer.tag(11, WireType.Varint).int32(message.isActive);
        /* repeated string field_mask = 12; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 13; */
        if (message.pageNumber !== 0)
            writer.tag(13, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServiceItemUnit
 */
export const ServiceItemUnit = new ServiceItemUnit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceItemUnitList$Type extends MessageType {
    constructor() {
        super("ServiceItemUnitList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServiceItemUnit },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ServiceItemUnit results */ 1:
                    message.results.push(ServiceItemUnit.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ServiceItemUnit results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ServiceItemUnit.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServiceItemUnitList
 */
export const ServiceItemUnitList = new ServiceItemUnitList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceItem$Type extends MessageType {
    constructor() {
        super("ServiceItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "item", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "brand", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "model", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "serial", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "item2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "brand2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "model2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "serial2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "item3", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "brand3", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "model3", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "serial3", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "filter_size", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "belt_size", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "filter_width", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "filter_length", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "filter_thickness", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "filter_qty", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "filter_part_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "filter_vendor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "sort_order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 28, name: "system_readings_type_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 29, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 30, name: "refrigerant_type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 31, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 33, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, propertyId: 0, type: "", item: "", brand: "", startDate: "", model: "", serial: "", item2: "", brand2: "", model2: "", serial2: "", item3: "", brand3: "", model3: "", serial3: "", filterSize: "", location: "", notes: "", beltSize: "", filterWidth: "", filterLength: "", filterThickness: "", filterQty: "", filterPartNumber: "", filterVendor: "", sortOrder: 0, systemReadingsTypeId: 0, isActive: 0, refrigerantType: 0, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 property_id */ 2:
                    message.propertyId = reader.int32();
                    break;
                case /* string type */ 3:
                    message.type = reader.string();
                    break;
                case /* string item */ 4:
                    message.item = reader.string();
                    break;
                case /* string brand */ 5:
                    message.brand = reader.string();
                    break;
                case /* string start_date */ 6:
                    message.startDate = reader.string();
                    break;
                case /* string model */ 7:
                    message.model = reader.string();
                    break;
                case /* string serial */ 8:
                    message.serial = reader.string();
                    break;
                case /* string item2 */ 9:
                    message.item2 = reader.string();
                    break;
                case /* string brand2 */ 10:
                    message.brand2 = reader.string();
                    break;
                case /* string model2 */ 11:
                    message.model2 = reader.string();
                    break;
                case /* string serial2 */ 12:
                    message.serial2 = reader.string();
                    break;
                case /* string item3 */ 13:
                    message.item3 = reader.string();
                    break;
                case /* string brand3 */ 14:
                    message.brand3 = reader.string();
                    break;
                case /* string model3 */ 15:
                    message.model3 = reader.string();
                    break;
                case /* string serial3 */ 16:
                    message.serial3 = reader.string();
                    break;
                case /* string filter_size */ 17:
                    message.filterSize = reader.string();
                    break;
                case /* string location */ 18:
                    message.location = reader.string();
                    break;
                case /* string notes */ 19:
                    message.notes = reader.string();
                    break;
                case /* string belt_size */ 20:
                    message.beltSize = reader.string();
                    break;
                case /* string filter_width */ 21:
                    message.filterWidth = reader.string();
                    break;
                case /* string filter_length */ 22:
                    message.filterLength = reader.string();
                    break;
                case /* string filter_thickness */ 23:
                    message.filterThickness = reader.string();
                    break;
                case /* string filter_qty */ 24:
                    message.filterQty = reader.string();
                    break;
                case /* string filter_part_number */ 25:
                    message.filterPartNumber = reader.string();
                    break;
                case /* string filter_vendor */ 26:
                    message.filterVendor = reader.string();
                    break;
                case /* int32 sort_order */ 27:
                    message.sortOrder = reader.int32();
                    break;
                case /* int32 system_readings_type_id */ 28:
                    message.systemReadingsTypeId = reader.int32();
                    break;
                case /* int32 is_active */ 29:
                    message.isActive = reader.int32();
                    break;
                case /* int32 refrigerant_type */ 30:
                    message.refrigerantType = reader.int32();
                    break;
                case /* repeated string field_mask */ 31:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 32:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 33:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 34:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 property_id = 2; */
        if (message.propertyId !== 0)
            writer.tag(2, WireType.Varint).int32(message.propertyId);
        /* string type = 3; */
        if (message.type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.type);
        /* string item = 4; */
        if (message.item !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.item);
        /* string brand = 5; */
        if (message.brand !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.brand);
        /* string start_date = 6; */
        if (message.startDate !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.startDate);
        /* string model = 7; */
        if (message.model !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.model);
        /* string serial = 8; */
        if (message.serial !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.serial);
        /* string item2 = 9; */
        if (message.item2 !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.item2);
        /* string brand2 = 10; */
        if (message.brand2 !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.brand2);
        /* string model2 = 11; */
        if (message.model2 !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.model2);
        /* string serial2 = 12; */
        if (message.serial2 !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.serial2);
        /* string item3 = 13; */
        if (message.item3 !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.item3);
        /* string brand3 = 14; */
        if (message.brand3 !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.brand3);
        /* string model3 = 15; */
        if (message.model3 !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.model3);
        /* string serial3 = 16; */
        if (message.serial3 !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.serial3);
        /* string filter_size = 17; */
        if (message.filterSize !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.filterSize);
        /* string location = 18; */
        if (message.location !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.location);
        /* string notes = 19; */
        if (message.notes !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.notes);
        /* string belt_size = 20; */
        if (message.beltSize !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.beltSize);
        /* string filter_width = 21; */
        if (message.filterWidth !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.filterWidth);
        /* string filter_length = 22; */
        if (message.filterLength !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.filterLength);
        /* string filter_thickness = 23; */
        if (message.filterThickness !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.filterThickness);
        /* string filter_qty = 24; */
        if (message.filterQty !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.filterQty);
        /* string filter_part_number = 25; */
        if (message.filterPartNumber !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.filterPartNumber);
        /* string filter_vendor = 26; */
        if (message.filterVendor !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.filterVendor);
        /* int32 sort_order = 27; */
        if (message.sortOrder !== 0)
            writer.tag(27, WireType.Varint).int32(message.sortOrder);
        /* int32 system_readings_type_id = 28; */
        if (message.systemReadingsTypeId !== 0)
            writer.tag(28, WireType.Varint).int32(message.systemReadingsTypeId);
        /* int32 is_active = 29; */
        if (message.isActive !== 0)
            writer.tag(29, WireType.Varint).int32(message.isActive);
        /* int32 refrigerant_type = 30; */
        if (message.refrigerantType !== 0)
            writer.tag(30, WireType.Varint).int32(message.refrigerantType);
        /* repeated string field_mask = 31; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(31, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 32; */
        if (message.pageNumber !== 0)
            writer.tag(32, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 33; */
        if (message.orderBy !== "")
            writer.tag(33, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 34; */
        if (message.orderDir !== "")
            writer.tag(34, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServiceItem
 */
export const ServiceItem = new ServiceItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceItemList$Type extends MessageType {
    constructor() {
        super("ServiceItemList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServiceItem },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ServiceItem results */ 1:
                    message.results.push(ServiceItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ServiceItem results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ServiceItem.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServiceItemList
 */
export const ServiceItemList = new ServiceItemList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Material$Type extends MessageType {
    constructor() {
        super("Material", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "service_item_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "part_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "vendor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "quantity", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, serviceItemId: 0, name: "", partNumber: "", vendor: "", quantity: "", fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 service_item_id */ 2:
                    message.serviceItemId = reader.int32();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string part_number */ 4:
                    message.partNumber = reader.string();
                    break;
                case /* string vendor */ 5:
                    message.vendor = reader.string();
                    break;
                case /* string quantity */ 6:
                    message.quantity = reader.string();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 8:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 service_item_id = 2; */
        if (message.serviceItemId !== 0)
            writer.tag(2, WireType.Varint).int32(message.serviceItemId);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string part_number = 4; */
        if (message.partNumber !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.partNumber);
        /* string vendor = 5; */
        if (message.vendor !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.vendor);
        /* string quantity = 6; */
        if (message.quantity !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.quantity);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 8; */
        if (message.pageNumber !== 0)
            writer.tag(8, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Material
 */
export const Material = new Material$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MaterialList$Type extends MessageType {
    constructor() {
        super("MaterialList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Material },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Material results */ 1:
                    message.results.push(Material.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Material results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Material.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MaterialList
 */
export const MaterialList = new MaterialList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReadingImage$Type extends MessageType {
    constructor() {
        super("ReadingImage", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "reading_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "image_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "file_bucket", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, readingId: 0, imageName: "", dateCreated: "", fileId: 0, fileName: "", fileBucket: "", mimeType: "", fieldMask: [], orderBy: "", orderDir: "", pageNumber: 0, withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 reading_id */ 2:
                    message.readingId = reader.int32();
                    break;
                case /* string image_name */ 4:
                    message.imageName = reader.string();
                    break;
                case /* string date_created */ 5:
                    message.dateCreated = reader.string();
                    break;
                case /* int32 file_id */ 6:
                    message.fileId = reader.int32();
                    break;
                case /* string file_name */ 7:
                    message.fileName = reader.string();
                    break;
                case /* string file_bucket */ 8:
                    message.fileBucket = reader.string();
                    break;
                case /* string mime_type */ 9:
                    message.mimeType = reader.string();
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string order_by */ 11:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 12:
                    message.orderDir = reader.string();
                    break;
                case /* int32 page_number */ 13:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 14:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 reading_id = 2; */
        if (message.readingId !== 0)
            writer.tag(2, WireType.Varint).int32(message.readingId);
        /* string image_name = 4; */
        if (message.imageName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.imageName);
        /* string date_created = 5; */
        if (message.dateCreated !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.dateCreated);
        /* int32 file_id = 6; */
        if (message.fileId !== 0)
            writer.tag(6, WireType.Varint).int32(message.fileId);
        /* string file_name = 7; */
        if (message.fileName !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.fileName);
        /* string file_bucket = 8; */
        if (message.fileBucket !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.fileBucket);
        /* string mime_type = 9; */
        if (message.mimeType !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.mimeType);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string order_by = 11; */
        if (message.orderBy !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 12; */
        if (message.orderDir !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.orderDir);
        /* int32 page_number = 13; */
        if (message.pageNumber !== 0)
            writer.tag(13, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 14; */
        if (message.withoutLimit !== false)
            writer.tag(14, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReadingImage
 */
export const ReadingImage = new ReadingImage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReadingImageList$Type extends MessageType {
    constructor() {
        super("ReadingImageList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReadingImage },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ReadingImage results */ 1:
                    message.results.push(ReadingImage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ReadingImage results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ReadingImage.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReadingImageList
 */
export const ReadingImageList = new ReadingImageList$Type();
/**
 * @generated ServiceType for protobuf service ServiceItemService
 */
export const ServiceItemService = new ServiceType("ServiceItemService", [
    { name: "Create", options: {}, I: ServiceItem, O: ServiceItem },
    { name: "Get", options: {}, I: ServiceItem, O: ServiceItem },
    { name: "BatchGet", options: {}, I: ServiceItem, O: ServiceItemList },
    { name: "Update", options: {}, I: ServiceItem, O: ServiceItem },
    { name: "Delete", options: {}, I: ServiceItem, O: ServiceItem },
    { name: "CreateServiceItemUnit", options: {}, I: ServiceItemUnit, O: ServiceItemUnit },
    { name: "GetServiceItemUnit", options: {}, I: ServiceItemUnit, O: ServiceItemUnit },
    { name: "BatchGetServiceItemUnit", options: {}, I: ServiceItemUnit, O: ServiceItemUnitList },
    { name: "UpdateServiceItemUnit", options: {}, I: ServiceItemUnit, O: ServiceItemUnit },
    { name: "DeleteServiceItemUnit", options: {}, I: ServiceItemUnit, O: ServiceItemUnit },
    { name: "CreateServiceItemMaterial", options: {}, I: ServiceItemMaterial, O: ServiceItemMaterial },
    { name: "GetServiceItemMaterial", options: {}, I: ServiceItemMaterial, O: ServiceItemMaterial },
    { name: "BatchGetServiceItemMaterial", options: {}, I: ServiceItemMaterial, O: ServiceItemMaterialList },
    { name: "UpdateServiceItemMaterial", options: {}, I: ServiceItemMaterial, O: ServiceItemMaterial },
    { name: "DeleteServiceItemMaterial", options: {}, I: ServiceItemMaterial, O: ServiceItemMaterial },
    { name: "CreateServiceItemImage", options: {}, I: ServiceItemImage, O: ServiceItemImage },
    { name: "GetServiceItemImage", options: {}, I: ServiceItemImage, O: ServiceItemImage },
    { name: "BatchGetServiceItemImage", options: {}, I: ServiceItemImage, O: ServiceItemImageList },
    { name: "UpdateServiceItemImage", options: {}, I: ServiceItemImage, O: ServiceItemImage },
    { name: "DeleteServiceItemImage", options: {}, I: ServiceItemImage, O: ServiceItemImage },
    { name: "CreateServiceReadingLine", options: {}, I: ServiceReadingLine, O: ServiceReadingLine },
    { name: "GetServiceReadingLine", options: {}, I: ServiceReadingLine, O: ServiceReadingLine },
    { name: "BatchGetServiceReadingLine", options: {}, I: ServiceReadingLine, O: ServiceReadingLineList },
    { name: "UpdateServiceReadingLine", options: {}, I: ServiceReadingLine, O: ServiceReadingLine },
    { name: "DeleteServiceReadingLine", options: {}, I: ServiceReadingLine, O: ServiceReadingLine },
    { name: "CreateReading", options: {}, I: Reading, O: Reading },
    { name: "GetReading", options: {}, I: Reading, O: Reading },
    { name: "BatchGetReading", options: {}, I: Reading, O: ReadingList },
    { name: "UpdateReading", options: {}, I: Reading, O: Reading },
    { name: "DeleteReading", options: {}, I: Reading, O: Reading },
    { name: "CreateBaseMaterial", options: {}, I: Material, O: Material },
    { name: "GetBaseMaterial", options: {}, I: Material, O: Material },
    { name: "BatchGetBaseMaterial", options: {}, I: Material, O: MaterialList },
    { name: "UpdateBaseMaterial", options: {}, I: Material, O: Material },
    { name: "DeleteBaseMaterial", options: {}, I: Material, O: Material },
    { name: "CreateReadingImage", options: {}, I: ReadingImage, O: ReadingImage },
    { name: "GetReadingImage", options: {}, I: ReadingImage, O: ReadingImage },
    { name: "BatchGetReadingImage", options: {}, I: ReadingImage, O: ReadingImageList },
    { name: "UpdateReadingImage", options: {}, I: ReadingImage, O: ReadingImage },
    { name: "DeleteReadingImage", options: {}, I: ReadingImage, O: Empty }
]);
