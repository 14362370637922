import { GenerateTestCallsRequest } from '@kalos/kalos-rpc';
import { Button, Card, CardFooter, CardHeader, CardTitle, toast } from '@kalos/ui';

import { useProtectedSoftPhoneStore } from '../../context/SoftPhoneStore.context';
import { useGoToAdminAssignUserToQueueMutation } from '../../hooks/goToAdminMutations';
import { useGetMeGoToQuery } from '../../hooks/useGetMeGoToQuery';

export const CallQueue = () => {
  const goToLoggedUserQuery = useGetMeGoToQuery();
  const connectMutation = useGoToAdminAssignUserToQueueMutation();
  const store = useProtectedSoftPhoneStore();

  const handleConnectToCallQueue = async () => {
    if (!goToLoggedUserQuery.isSuccess) throw new Error('User go to data is not loaded');
    try {
      await connectMutation.mutateAsync({
        queueId: '281b11ec-c819-4a48-aea7-78318b7dfa76',
        userKey: goToLoggedUserQuery.data.accountKey,
      });
      toast({
        title: 'Connected to call queue',
        variant: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to connect to call queue',
        variant: 'destructive',
      });
    }
  };
  const handleGenerateIncomingCalls = () => {
    const { client, session } = store.getState();
    const request = GenerateTestCallsRequest.create({
      callDurationMs: 20_000,
      numberOfCalls: 3,
      delayBetweenCallsMs: 3_000,
      testCallerName: 'tester Testerson',
      testCallerNumber: '+1232323',
      sessionId: session?.sessionId,
    });
    console.log(request);
    client.GenerateTestCalls(request);
  };

  return (
    <Card variant="light">
      <CardHeader>
        <CardTitle>Call queue</CardTitle>
      </CardHeader>
      <CardFooter className="space-x-4">
        <Button
          disabled={!goToLoggedUserQuery.isSuccess || !!connectMutation.data}
          isLoading={connectMutation.isPending}
          onClick={handleConnectToCallQueue}
        >
          {connectMutation.data ? 'Connected to call queue' : 'Connect me to call Queue'}
        </Button>

        <Button onClick={handleGenerateIncomingCalls}>Test: generate incoming calls</Button>
      </CardFooter>
    </Card>
  );
};
