// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "gotoconnect.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "gotoconnect.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./common";
import { Bool } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf enum Notification.Type
 */
export var Notification_Type;
(function (Notification_Type) {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    Notification_Type[Notification_Type["UNKNOWN"] = 0] = "UNKNOWN";
    /**
     * @generated from protobuf enum value: INCOMING_CALL = 1;
     */
    Notification_Type[Notification_Type["INCOMING_CALL"] = 1] = "INCOMING_CALL";
    /**
     * @generated from protobuf enum value: CALL_ENDED = 2;
     */
    Notification_Type[Notification_Type["CALL_ENDED"] = 2] = "CALL_ENDED";
    /**
     * @generated from protobuf enum value: HOLD = 3;
     */
    Notification_Type[Notification_Type["HOLD"] = 3] = "HOLD";
    /**
     * @generated from protobuf enum value: UNHOLD = 4;
     */
    Notification_Type[Notification_Type["UNHOLD"] = 4] = "UNHOLD";
    /**
     * @generated from protobuf enum value: MUTE = 5;
     */
    Notification_Type[Notification_Type["MUTE"] = 5] = "MUTE";
    /**
     * @generated from protobuf enum value: UNMUTE = 6;
     */
    Notification_Type[Notification_Type["UNMUTE"] = 6] = "UNMUTE";
})(Notification_Type || (Notification_Type = {}));
// Add these message definitions for notification channels
/**
 * @generated from protobuf enum ChannelType
 */
export var ChannelType;
(function (ChannelType) {
    /**
     * @generated from protobuf enum value: WEBSOCKETS = 0;
     */
    ChannelType[ChannelType["WEBSOCKETS"] = 0] = "WEBSOCKETS";
    /**
     * @generated from protobuf enum value: WEBHOOK = 1;
     */
    ChannelType[ChannelType["WEBHOOK"] = 1] = "WEBHOOK";
})(ChannelType || (ChannelType = {}));
/**
 * @generated from protobuf enum UserAppearance
 */
export var UserAppearance;
(function (UserAppearance) {
    /**
     * @generated from protobuf enum value: USER_APPEARANCE_AVAILABLE = 0;
     */
    UserAppearance[UserAppearance["AVAILABLE"] = 0] = "AVAILABLE";
    /**
     * @generated from protobuf enum value: USER_APPEARANCE_OFFLINE = 1;
     */
    UserAppearance[UserAppearance["OFFLINE"] = 1] = "OFFLINE";
    /**
     * @generated from protobuf enum value: USER_APPEARANCE_AWAY = 2;
     */
    UserAppearance[UserAppearance["AWAY"] = 2] = "AWAY";
    /**
     * @generated from protobuf enum value: USER_APPEARANCE_BUSY = 3;
     */
    UserAppearance[UserAppearance["BUSY"] = 3] = "BUSY";
})(UserAppearance || (UserAppearance = {}));
/**
 * @generated from protobuf enum UserDoNotDisturb
 */
export var UserDoNotDisturb;
(function (UserDoNotDisturb) {
    /**
     * @generated from protobuf enum value: USER_DND_UNSPECIFIED = 0;
     */
    UserDoNotDisturb[UserDoNotDisturb["USER_DND_UNSPECIFIED"] = 0] = "USER_DND_UNSPECIFIED";
    /**
     * @generated from protobuf enum value: USER_DND_ENABLED = 1;
     */
    UserDoNotDisturb[UserDoNotDisturb["USER_DND_ENABLED"] = 1] = "USER_DND_ENABLED";
})(UserDoNotDisturb || (UserDoNotDisturb = {}));
/**
 * @generated from protobuf enum UserStatus
 */
export var UserStatus;
(function (UserStatus) {
    /**
     * @generated from protobuf enum value: USER_STATUS_UNSPECIFIED = 0;
     */
    UserStatus[UserStatus["UNSPECIFIED"] = 0] = "UNSPECIFIED";
    /**
     * @generated from protobuf enum value: USER_STATUS_IN_MEETING = 1;
     */
    UserStatus[UserStatus["IN_MEETING"] = 1] = "IN_MEETING";
})(UserStatus || (UserStatus = {}));
/**
 * @generated from protobuf enum Product
 */
export var Product;
(function (Product) {
    /**
     * @generated from protobuf enum value: PRODUCT_UNSPECIFIED = 0;
     */
    Product[Product["PRODUCT_UNSPECIFIED"] = 0] = "PRODUCT_UNSPECIFIED";
    /**
     * @generated from protobuf enum value: G2M = 1;
     */
    Product[Product["G2M"] = 1] = "G2M";
    /**
     * @generated from protobuf enum value: G2W = 2;
     */
    Product[Product["G2W"] = 2] = "G2W";
    /**
     * @generated from protobuf enum value: G2T = 3;
     */
    Product[Product["G2T"] = 3] = "G2T";
    /**
     * @generated from protobuf enum value: OPENVOICE = 4;
     */
    Product[Product["OPENVOICE"] = 4] = "OPENVOICE";
    /**
     * @generated from protobuf enum value: G2ASEEIT = 5;
     */
    Product[Product["G2ASEEIT"] = 5] = "G2ASEEIT";
    /**
     * @generated from protobuf enum value: G2MFREE = 6;
     */
    Product[Product["G2MFREE"] = 6] = "G2MFREE";
    /**
     * @generated from protobuf enum value: G2ARS = 7;
     */
    Product[Product["G2ARS"] = 7] = "G2ARS";
    /**
     * @generated from protobuf enum value: RAMOBILE = 8;
     */
    Product[Product["RAMOBILE"] = 8] = "RAMOBILE";
    /**
     * @generated from protobuf enum value: G2ASD = 9;
     */
    Product[Product["G2ASD"] = 9] = "G2ASD";
    /**
     * @generated from protobuf enum value: GOVIEW = 10;
     */
    Product[Product["GOVIEW"] = 10] = "GOVIEW";
    /**
     * @generated from protobuf enum value: PROMPT = 11;
     */
    Product[Product["PROMPT"] = 11] = "PROMPT";
    /**
     * @generated from protobuf enum value: JIVE = 12;
     */
    Product[Product["JIVE"] = 12] = "JIVE";
    /**
     * @generated from protobuf enum value: G2C = 13;
     */
    Product[Product["G2C"] = 13] = "G2C";
    /**
     * @generated from protobuf enum value: EA = 14;
     */
    Product[Product["EA"] = 14] = "EA";
})(Product || (Product = {}));
/**
 * @generated from protobuf enum AdminRole
 */
export var AdminRole;
(function (AdminRole) {
    /**
     * @generated from protobuf enum value: ADMIN_ROLE_UNSPECIFIED = 0;
     */
    AdminRole[AdminRole["ADMIN_ROLE_UNSPECIFIED"] = 0] = "ADMIN_ROLE_UNSPECIFIED";
    /**
     * @generated from protobuf enum value: SUPER_USER = 1;
     */
    AdminRole[AdminRole["SUPER_USER"] = 1] = "SUPER_USER";
    /**
     * @generated from protobuf enum value: MANAGE_USERS = 2;
     */
    AdminRole[AdminRole["MANAGE_USERS"] = 2] = "MANAGE_USERS";
    /**
     * @generated from protobuf enum value: MANAGE_SEATS = 3;
     */
    AdminRole[AdminRole["MANAGE_SEATS"] = 3] = "MANAGE_SEATS";
    /**
     * @generated from protobuf enum value: MANAGE_SETTINGS = 4;
     */
    AdminRole[AdminRole["MANAGE_SETTINGS"] = 4] = "MANAGE_SETTINGS";
    /**
     * @generated from protobuf enum value: MANAGE_GROUPS = 5;
     */
    AdminRole[AdminRole["MANAGE_GROUPS"] = 5] = "MANAGE_GROUPS";
    /**
     * @generated from protobuf enum value: RUN_REPORTS = 6;
     */
    AdminRole[AdminRole["RUN_REPORTS"] = 6] = "RUN_REPORTS";
    /**
     * @generated from protobuf enum value: MANAGE_DEVICE_GROUPS = 7;
     */
    AdminRole[AdminRole["MANAGE_DEVICE_GROUPS"] = 7] = "MANAGE_DEVICE_GROUPS";
    /**
     * @generated from protobuf enum value: MANAGE_SETTINGS_PROFILES = 8;
     */
    AdminRole[AdminRole["MANAGE_SETTINGS_PROFILES"] = 8] = "MANAGE_SETTINGS_PROFILES";
    /**
     * @generated from protobuf enum value: MANAGE_ACCOUNT = 9;
     */
    AdminRole[AdminRole["MANAGE_ACCOUNT"] = 9] = "MANAGE_ACCOUNT";
    /**
     * @generated from protobuf enum value: MANAGE_EMERGENCY_SERVICES = 10;
     */
    AdminRole[AdminRole["MANAGE_EMERGENCY_SERVICES"] = 10] = "MANAGE_EMERGENCY_SERVICES";
    /**
     * @generated from protobuf enum value: VIEW_INVOICES = 11;
     */
    AdminRole[AdminRole["VIEW_INVOICES"] = 11] = "VIEW_INVOICES";
    /**
     * @generated from protobuf enum value: VIEW_AND_PAY_INVOICES = 12;
     */
    AdminRole[AdminRole["VIEW_AND_PAY_INVOICES"] = 12] = "VIEW_AND_PAY_INVOICES";
    /**
     * @generated from protobuf enum value: VIEW_CALL_RECORDINGS = 13;
     */
    AdminRole[AdminRole["VIEW_CALL_RECORDINGS"] = 13] = "VIEW_CALL_RECORDINGS";
})(AdminRole || (AdminRole = {}));
// @generated message type with reflection information, may provide speed optimized methods
class SoftphoneDevice$Type extends MessageType {
    constructor() {
        super("SoftphoneDevice", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "account_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "model_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "mac_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "assigned_user_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "license_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { deviceId: "", name: "", accountKey: "", modelId: "", macAddress: "", assignedUserKey: "", licenseKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string account_key */ 3:
                    message.accountKey = reader.string();
                    break;
                case /* string model_id */ 4:
                    message.modelId = reader.string();
                    break;
                case /* string mac_address */ 5:
                    message.macAddress = reader.string();
                    break;
                case /* string assigned_user_key */ 6:
                    message.assignedUserKey = reader.string();
                    break;
                case /* string license_key */ 7:
                    message.licenseKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string account_key = 3; */
        if (message.accountKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.accountKey);
        /* string model_id = 4; */
        if (message.modelId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.modelId);
        /* string mac_address = 5; */
        if (message.macAddress !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.macAddress);
        /* string assigned_user_key = 6; */
        if (message.assignedUserKey !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.assignedUserKey);
        /* string license_key = 7; */
        if (message.licenseKey !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.licenseKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SoftphoneDevice
 */
export const SoftphoneDevice = new SoftphoneDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSoftphoneDeviceRequest$Type extends MessageType {
    constructor() {
        super("CreateSoftphoneDeviceRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "model_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "mac_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { name: "", accountKey: "", modelId: "", macAddress: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string account_key */ 2:
                    message.accountKey = reader.string();
                    break;
                case /* string model_id */ 3:
                    message.modelId = reader.string();
                    break;
                case /* string mac_address */ 4:
                    message.macAddress = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string account_key = 2; */
        if (message.accountKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountKey);
        /* string model_id = 3; */
        if (message.modelId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.modelId);
        /* string mac_address = 4; */
        if (message.macAddress !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.macAddress);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateSoftphoneDeviceRequest
 */
export const CreateSoftphoneDeviceRequest = new CreateSoftphoneDeviceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSoftphoneDeviceRequest$Type extends MessageType {
    constructor() {
        super("GetSoftphoneDeviceRequest", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { deviceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetSoftphoneDeviceRequest
 */
export const GetSoftphoneDeviceRequest = new GetSoftphoneDeviceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSoftphoneDeviceRequest$Type extends MessageType {
    constructor() {
        super("UpdateSoftphoneDeviceRequest", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "model_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "assigned_user_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "license_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { deviceId: "", name: "", modelId: "", assignedUserKey: "", licenseKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string model_id */ 3:
                    message.modelId = reader.string();
                    break;
                case /* string assigned_user_key */ 4:
                    message.assignedUserKey = reader.string();
                    break;
                case /* string license_key */ 5:
                    message.licenseKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string model_id = 3; */
        if (message.modelId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.modelId);
        /* string assigned_user_key = 4; */
        if (message.assignedUserKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.assignedUserKey);
        /* string license_key = 5; */
        if (message.licenseKey !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.licenseKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateSoftphoneDeviceRequest
 */
export const UpdateSoftphoneDeviceRequest = new UpdateSoftphoneDeviceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAuthURLRequest$Type extends MessageType {
    constructor() {
        super("GetAuthURLRequest", [
            { no: 1, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "redirect_uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { state: "", redirectUri: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string state */ 1:
                    message.state = reader.string();
                    break;
                case /* string redirect_uri */ 2:
                    message.redirectUri = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string state = 1; */
        if (message.state !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.state);
        /* string redirect_uri = 2; */
        if (message.redirectUri !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.redirectUri);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetAuthURLRequest
 */
export const GetAuthURLRequest = new GetAuthURLRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAuthURLResponse$Type extends MessageType {
    constructor() {
        super("GetAuthURLResponse", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetAuthURLResponse
 */
export const GetAuthURLResponse = new GetAuthURLResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExchangeCodeRequest$Type extends MessageType {
    constructor() {
        super("ExchangeCodeRequest", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { code: "", userId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ExchangeCodeRequest
 */
export const ExchangeCodeRequest = new ExchangeCodeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExchangeCodeRequestWithRedirect$Type extends MessageType {
    constructor() {
        super("ExchangeCodeRequestWithRedirect", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "redirect_uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { code: "", userId: 0, redirectUri: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* string redirect_uri */ 3:
                    message.redirectUri = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* string redirect_uri = 3; */
        if (message.redirectUri !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.redirectUri);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ExchangeCodeRequestWithRedirect
 */
export const ExchangeCodeRequestWithRedirect = new ExchangeCodeRequestWithRedirect$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OAuthTokenResponse$Type extends MessageType {
    constructor() {
        super("OAuthTokenResponse", [
            { no: 1, name: "access_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "refresh_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "expires_in", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "scope", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "principal", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { accessToken: "", tokenType: "", refreshToken: "", expiresIn: 0, scope: "", principal: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string access_token */ 1:
                    message.accessToken = reader.string();
                    break;
                case /* string token_type */ 2:
                    message.tokenType = reader.string();
                    break;
                case /* string refresh_token */ 3:
                    message.refreshToken = reader.string();
                    break;
                case /* int32 expires_in */ 4:
                    message.expiresIn = reader.int32();
                    break;
                case /* string scope */ 5:
                    message.scope = reader.string();
                    break;
                case /* string principal */ 6:
                    message.principal = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string access_token = 1; */
        if (message.accessToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accessToken);
        /* string token_type = 2; */
        if (message.tokenType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tokenType);
        /* string refresh_token = 3; */
        if (message.refreshToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.refreshToken);
        /* int32 expires_in = 4; */
        if (message.expiresIn !== 0)
            writer.tag(4, WireType.Varint).int32(message.expiresIn);
        /* string scope = 5; */
        if (message.scope !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.scope);
        /* string principal = 6; */
        if (message.principal !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.principal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OAuthTokenResponse
 */
export const OAuthTokenResponse = new OAuthTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserTokenRequest$Type extends MessageType {
    constructor() {
        super("GetUserTokenRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetUserTokenRequest
 */
export const GetUserTokenRequest = new GetUserTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationRequest$Type extends MessageType {
    constructor() {
        super("NotificationRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NotificationRequest
 */
export const NotificationRequest = new NotificationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Notification$Type extends MessageType {
    constructor() {
        super("Notification", [
            { no: 1, name: "type", kind: "enum", T: () => ["Notification.Type", Notification_Type] },
            { no: 2, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "from_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "caller_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "timestamp", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 6, name: "metadata", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value) {
        const message = { type: 0, callId: "", fromNumber: "", callerName: "", timestamp: 0n, metadata: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Notification.Type type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string call_id */ 2:
                    message.callId = reader.string();
                    break;
                case /* string from_number */ 3:
                    message.fromNumber = reader.string();
                    break;
                case /* string caller_name */ 4:
                    message.callerName = reader.string();
                    break;
                case /* int64 timestamp */ 5:
                    message.timestamp = reader.int64().toBigInt();
                    break;
                case /* map<string, string> metadata */ 6:
                    this.binaryReadMap6(message.metadata, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    binaryReadMap6(map, reader, options) {
        let len = reader.uint32(), end = reader.pos + len, key, val;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Notification.metadata");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message, writer, options) {
        /* Notification.Type type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string call_id = 2; */
        if (message.callId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.callId);
        /* string from_number = 3; */
        if (message.fromNumber !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.fromNumber);
        /* string caller_name = 4; */
        if (message.callerName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.callerName);
        /* int64 timestamp = 5; */
        if (message.timestamp !== 0n)
            writer.tag(5, WireType.Varint).int64(message.timestamp);
        /* map<string, string> metadata = 6; */
        for (let k of Object.keys(message.metadata))
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metadata[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Notification
 */
export const Notification = new Notification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebhookRequest$Type extends MessageType {
    constructor() {
        super("WebhookRequest", [
            { no: 1, name: "event_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "from_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "to_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "extension_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "metadata", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value) {
        const message = { eventType: "", callId: "", fromNumber: "", toNumber: "", extensionNumber: "", metadata: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_type */ 1:
                    message.eventType = reader.string();
                    break;
                case /* string call_id */ 2:
                    message.callId = reader.string();
                    break;
                case /* string from_number */ 3:
                    message.fromNumber = reader.string();
                    break;
                case /* string to_number */ 4:
                    message.toNumber = reader.string();
                    break;
                case /* string extension_number */ 5:
                    message.extensionNumber = reader.string();
                    break;
                case /* map<string, string> metadata */ 6:
                    this.binaryReadMap6(message.metadata, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    binaryReadMap6(map, reader, options) {
        let len = reader.uint32(), end = reader.pos + len, key, val;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field WebhookRequest.metadata");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message, writer, options) {
        /* string event_type = 1; */
        if (message.eventType !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.eventType);
        /* string call_id = 2; */
        if (message.callId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.callId);
        /* string from_number = 3; */
        if (message.fromNumber !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.fromNumber);
        /* string to_number = 4; */
        if (message.toNumber !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.toNumber);
        /* string extension_number = 5; */
        if (message.extensionNumber !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.extensionNumber);
        /* map<string, string> metadata = 6; */
        for (let k of Object.keys(message.metadata))
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metadata[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WebhookRequest
 */
export const WebhookRequest = new WebhookRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClientInformation$Type extends MessageType {
    constructor() {
        super("ClientInformation", [
            { no: 1, name: "app_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "app_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "platform", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { appId: "", appVersion: "", platform: "", deviceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string app_id */ 1:
                    message.appId = reader.string();
                    break;
                case /* string app_version */ 2:
                    message.appVersion = reader.string();
                    break;
                case /* string platform */ 3:
                    message.platform = reader.string();
                    break;
                case /* string device_id */ 4:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string app_id = 1; */
        if (message.appId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.appId);
        /* string app_version = 2; */
        if (message.appVersion !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.appVersion);
        /* string platform = 3; */
        if (message.platform !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.platform);
        /* string device_id = 4; */
        if (message.deviceId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ClientInformation
 */
export const ClientInformation = new ClientInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallbackReference$Type extends MessageType {
    constructor() {
        super("CallbackReference", [
            { no: 1, name: "incoming_call_channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "session_management_channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { incomingCallChannelId: "", sessionManagementChannelId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string incoming_call_channel_id */ 1:
                    message.incomingCallChannelId = reader.string();
                    break;
                case /* string session_management_channel_id */ 2:
                    message.sessionManagementChannelId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string incoming_call_channel_id = 1; */
        if (message.incomingCallChannelId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.incomingCallChannelId);
        /* string session_management_channel_id = 2; */
        if (message.sessionManagementChannelId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sessionManagementChannelId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CallbackReference
 */
export const CallbackReference = new CallbackReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoToCall$Type extends MessageType {
    constructor() {
        super("GoToCall", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "is_muted", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "is_on_hold", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "merge_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "has_media", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "ringing_in_media", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "sdp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "answer_after", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "status_message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "in_call_channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "impersonatee_user_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", number: "", name: "", isMuted: false, isOnHold: false, mergeId: "", hasMedia: false, ringingInMedia: false, sdp: "", answerAfter: 0, status: "", statusMessage: "", timestamp: "", inCallChannelId: "", impersonateeUserKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string number */ 2:
                    message.number = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* bool is_muted */ 4:
                    message.isMuted = reader.bool();
                    break;
                case /* bool is_on_hold */ 5:
                    message.isOnHold = reader.bool();
                    break;
                case /* string merge_id */ 6:
                    message.mergeId = reader.string();
                    break;
                case /* bool has_media */ 7:
                    message.hasMedia = reader.bool();
                    break;
                case /* bool ringing_in_media */ 8:
                    message.ringingInMedia = reader.bool();
                    break;
                case /* string sdp */ 9:
                    message.sdp = reader.string();
                    break;
                case /* int32 answer_after */ 10:
                    message.answerAfter = reader.int32();
                    break;
                case /* string status */ 11:
                    message.status = reader.string();
                    break;
                case /* string status_message */ 12:
                    message.statusMessage = reader.string();
                    break;
                case /* string timestamp */ 13:
                    message.timestamp = reader.string();
                    break;
                case /* string in_call_channel_id */ 14:
                    message.inCallChannelId = reader.string();
                    break;
                case /* string impersonatee_user_key */ 15:
                    message.impersonateeUserKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string number = 2; */
        if (message.number !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.number);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* bool is_muted = 4; */
        if (message.isMuted !== false)
            writer.tag(4, WireType.Varint).bool(message.isMuted);
        /* bool is_on_hold = 5; */
        if (message.isOnHold !== false)
            writer.tag(5, WireType.Varint).bool(message.isOnHold);
        /* string merge_id = 6; */
        if (message.mergeId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.mergeId);
        /* bool has_media = 7; */
        if (message.hasMedia !== false)
            writer.tag(7, WireType.Varint).bool(message.hasMedia);
        /* bool ringing_in_media = 8; */
        if (message.ringingInMedia !== false)
            writer.tag(8, WireType.Varint).bool(message.ringingInMedia);
        /* string sdp = 9; */
        if (message.sdp !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.sdp);
        /* int32 answer_after = 10; */
        if (message.answerAfter !== 0)
            writer.tag(10, WireType.Varint).int32(message.answerAfter);
        /* string status = 11; */
        if (message.status !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.status);
        /* string status_message = 12; */
        if (message.statusMessage !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.statusMessage);
        /* string timestamp = 13; */
        if (message.timestamp !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.timestamp);
        /* string in_call_channel_id = 14; */
        if (message.inCallChannelId !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.inCallChannelId);
        /* string impersonatee_user_key = 15; */
        if (message.impersonateeUserKey !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.impersonateeUserKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GoToCall
 */
export const GoToCall = new GoToCall$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoToCallId$Type extends MessageType {
    constructor() {
        super("GoToCallId", [
            { no: 1, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { callId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string call_id */ 1:
                    message.callId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string call_id = 1; */
        if (message.callId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.callId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GoToCallId
 */
export const GoToCallId = new GoToCallId$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateCallRequest$Type extends MessageType {
    constructor() {
        super("CreateCallRequest", [
            { no: 1, name: "dial_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sdp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "extension_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "in_call_channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "impersonatee_user_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { dialString: "", sdp: "", deviceId: "", organizationId: "", extensionNumber: "", inCallChannelId: "", impersonateeUserKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string dial_string */ 1:
                    message.dialString = reader.string();
                    break;
                case /* string sdp */ 2:
                    message.sdp = reader.string();
                    break;
                case /* string device_id */ 3:
                    message.deviceId = reader.string();
                    break;
                case /* string organization_id */ 4:
                    message.organizationId = reader.string();
                    break;
                case /* string extension_number */ 5:
                    message.extensionNumber = reader.string();
                    break;
                case /* string in_call_channel_id */ 6:
                    message.inCallChannelId = reader.string();
                    break;
                case /* string impersonatee_user_key */ 7:
                    message.impersonateeUserKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string dial_string = 1; */
        if (message.dialString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.dialString);
        /* string sdp = 2; */
        if (message.sdp !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sdp);
        /* string device_id = 3; */
        if (message.deviceId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.deviceId);
        /* string organization_id = 4; */
        if (message.organizationId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.organizationId);
        /* string extension_number = 5; */
        if (message.extensionNumber !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.extensionNumber);
        /* string in_call_channel_id = 6; */
        if (message.inCallChannelId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.inCallChannelId);
        /* string impersonatee_user_key = 7; */
        if (message.impersonateeUserKey !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.impersonateeUserKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateCallRequest
 */
export const CreateCallRequest = new CreateCallRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCallRequest$Type extends MessageType {
    constructor() {
        super("UpdateCallRequest", [
            { no: 1, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "in_call_channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { callId: "", inCallChannelId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string call_id */ 1:
                    message.callId = reader.string();
                    break;
                case /* string in_call_channel_id */ 2:
                    message.inCallChannelId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string call_id = 1; */
        if (message.callId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.callId);
        /* string in_call_channel_id = 2; */
        if (message.inCallChannelId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.inCallChannelId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateCallRequest
 */
export const UpdateCallRequest = new UpdateCallRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteCallRequest$Type extends MessageType {
    constructor() {
        super("DeleteCallRequest", [
            { no: 1, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { callId: "", reason: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string call_id */ 1:
                    message.callId = reader.string();
                    break;
                case /* string reason */ 2:
                    message.reason = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string call_id = 1; */
        if (message.callId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.callId);
        /* string reason = 2; */
        if (message.reason !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.reason);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteCallRequest
 */
export const DeleteCallRequest = new DeleteCallRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnswerCallRequest$Type extends MessageType {
    constructor() {
        super("AnswerCallRequest", [
            { no: 1, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sdp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "in_call_channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { callId: "", sdp: "", inCallChannelId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string call_id */ 1:
                    message.callId = reader.string();
                    break;
                case /* string sdp */ 2:
                    message.sdp = reader.string();
                    break;
                case /* string in_call_channel_id */ 3:
                    message.inCallChannelId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string call_id = 1; */
        if (message.callId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.callId);
        /* string sdp = 2; */
        if (message.sdp !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sdp);
        /* string in_call_channel_id = 3; */
        if (message.inCallChannelId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.inCallChannelId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AnswerCallRequest
 */
export const AnswerCallRequest = new AnswerCallRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RingCallRequest$Type extends MessageType {
    constructor() {
        super("RingCallRequest", [
            { no: 1, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "in_call_channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "incoming_notification_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { callId: "", inCallChannelId: "", incomingNotificationTimestamp: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string call_id */ 1:
                    message.callId = reader.string();
                    break;
                case /* string in_call_channel_id */ 2:
                    message.inCallChannelId = reader.string();
                    break;
                case /* string incoming_notification_timestamp */ 3:
                    message.incomingNotificationTimestamp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string call_id = 1; */
        if (message.callId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.callId);
        /* string in_call_channel_id = 2; */
        if (message.inCallChannelId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.inCallChannelId);
        /* string incoming_notification_timestamp = 3; */
        if (message.incomingNotificationTimestamp !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.incomingNotificationTimestamp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RingCallRequest
 */
export const RingCallRequest = new RingCallRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallDestination$Type extends MessageType {
    constructor() {
        super("CallDestination", [
            { no: 1, name: "extension_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { extensionNumber: "", number: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string extension_number */ 1:
                    message.extensionNumber = reader.string();
                    break;
                case /* string number */ 2:
                    message.number = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string extension_number = 1; */
        if (message.extensionNumber !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.extensionNumber);
        /* string number = 2; */
        if (message.number !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.number);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CallDestination
 */
export const CallDestination = new CallDestination$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RejectCallRequest$Type extends MessageType {
    constructor() {
        super("RejectCallRequest", [
            { no: 1, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "action", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "destination", kind: "message", T: () => CallDestination }
        ]);
    }
    create(value) {
        const message = { callId: "", action: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string call_id */ 1:
                    message.callId = reader.string();
                    break;
                case /* string action */ 2:
                    message.action = reader.string();
                    break;
                case /* CallDestination destination */ 3:
                    message.destination = CallDestination.internalBinaryRead(reader, reader.uint32(), options, message.destination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string call_id = 1; */
        if (message.callId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.callId);
        /* string action = 2; */
        if (message.action !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.action);
        /* CallDestination destination = 3; */
        if (message.destination)
            CallDestination.internalBinaryWrite(message.destination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RejectCallRequest
 */
export const RejectCallRequest = new RejectCallRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModifyCallRequest$Type extends MessageType {
    constructor() {
        super("ModifyCallRequest", [
            { no: 1, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sdp", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { callId: "", sdp: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string call_id */ 1:
                    message.callId = reader.string();
                    break;
                case /* string sdp */ 2:
                    message.sdp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string call_id = 1; */
        if (message.callId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.callId);
        /* string sdp = 2; */
        if (message.sdp !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sdp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ModifyCallRequest
 */
export const ModifyCallRequest = new ModifyCallRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoToDTMFRequest$Type extends MessageType {
    constructor() {
        super("GoToDTMFRequest", [
            { no: 1, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { callId: "", tone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string call_id */ 1:
                    message.callId = reader.string();
                    break;
                case /* string tone */ 2:
                    message.tone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string call_id = 1; */
        if (message.callId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.callId);
        /* string tone = 2; */
        if (message.tone !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GoToDTMFRequest
 */
export const GoToDTMFRequest = new GoToDTMFRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebClientInformation$Type extends MessageType {
    constructor() {
        super("WebClientInformation", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "app_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "platform", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "app_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { deviceId: "", appVersion: "", platform: "", appId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                case /* string app_version */ 2:
                    message.appVersion = reader.string();
                    break;
                case /* string platform */ 3:
                    message.platform = reader.string();
                    break;
                case /* string app_id */ 4:
                    message.appId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        /* string app_version = 2; */
        if (message.appVersion !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.appVersion);
        /* string platform = 3; */
        if (message.platform !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.platform);
        /* string app_id = 4; */
        if (message.appId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.appId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WebClientInformation
 */
export const WebClientInformation = new WebClientInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebCallbackReference$Type extends MessageType {
    constructor() {
        super("WebCallbackReference", [
            { no: 1, name: "incoming_call_channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "session_management_channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { incomingCallChannelId: "", sessionManagementChannelId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string incoming_call_channel_id */ 1:
                    message.incomingCallChannelId = reader.string();
                    break;
                case /* string session_management_channel_id */ 2:
                    message.sessionManagementChannelId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string incoming_call_channel_id = 1; */
        if (message.incomingCallChannelId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.incomingCallChannelId);
        /* string session_management_channel_id = 2; */
        if (message.sessionManagementChannelId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sessionManagementChannelId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WebCallbackReference
 */
export const WebCallbackReference = new WebCallbackReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebDevice$Type extends MessageType {
    constructor() {
        super("WebDevice", [
            { no: 1, name: "client_information", kind: "message", T: () => WebClientInformation },
            { no: 2, name: "callback_reference", kind: "message", T: () => WebCallbackReference }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* WebClientInformation client_information */ 1:
                    message.clientInformation = WebClientInformation.internalBinaryRead(reader, reader.uint32(), options, message.clientInformation);
                    break;
                case /* WebCallbackReference callback_reference */ 2:
                    message.callbackReference = WebCallbackReference.internalBinaryRead(reader, reader.uint32(), options, message.callbackReference);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* WebClientInformation client_information = 1; */
        if (message.clientInformation)
            WebClientInformation.internalBinaryWrite(message.clientInformation, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* WebCallbackReference callback_reference = 2; */
        if (message.callbackReference)
            WebCallbackReference.internalBinaryWrite(message.callbackReference, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WebDevice
 */
export const WebDevice = new WebDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateWebDeviceRequest$Type extends MessageType {
    constructor() {
        super("CreateWebDeviceRequest", [
            { no: 1, name: "client_information", kind: "message", T: () => WebClientInformation },
            { no: 2, name: "callback_reference", kind: "message", T: () => WebCallbackReference }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* WebClientInformation client_information */ 1:
                    message.clientInformation = WebClientInformation.internalBinaryRead(reader, reader.uint32(), options, message.clientInformation);
                    break;
                case /* WebCallbackReference callback_reference */ 2:
                    message.callbackReference = WebCallbackReference.internalBinaryRead(reader, reader.uint32(), options, message.callbackReference);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* WebClientInformation client_information = 1; */
        if (message.clientInformation)
            WebClientInformation.internalBinaryWrite(message.clientInformation, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* WebCallbackReference callback_reference = 2; */
        if (message.callbackReference)
            WebCallbackReference.internalBinaryWrite(message.callbackReference, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateWebDeviceRequest
 */
export const CreateWebDeviceRequest = new CreateWebDeviceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebDeviceRequest$Type extends MessageType {
    constructor() {
        super("GetWebDeviceRequest", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { deviceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetWebDeviceRequest
 */
export const GetWebDeviceRequest = new GetWebDeviceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateWebDeviceRequest$Type extends MessageType {
    constructor() {
        super("UpdateWebDeviceRequest", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "client_information", kind: "message", T: () => WebClientInformation },
            { no: 3, name: "callback_reference", kind: "message", T: () => WebCallbackReference }
        ]);
    }
    create(value) {
        const message = { deviceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                case /* WebClientInformation client_information */ 2:
                    message.clientInformation = WebClientInformation.internalBinaryRead(reader, reader.uint32(), options, message.clientInformation);
                    break;
                case /* WebCallbackReference callback_reference */ 3:
                    message.callbackReference = WebCallbackReference.internalBinaryRead(reader, reader.uint32(), options, message.callbackReference);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        /* WebClientInformation client_information = 2; */
        if (message.clientInformation)
            WebClientInformation.internalBinaryWrite(message.clientInformation, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* WebCallbackReference callback_reference = 3; */
        if (message.callbackReference)
            WebCallbackReference.internalBinaryWrite(message.callbackReference, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateWebDeviceRequest
 */
export const UpdateWebDeviceRequest = new UpdateWebDeviceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteWebDeviceRequest$Type extends MessageType {
    constructor() {
        super("DeleteWebDeviceRequest", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { deviceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteWebDeviceRequest
 */
export const DeleteWebDeviceRequest = new DeleteWebDeviceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebSocketChannelData$Type extends MessageType {
    constructor() {
        super("WebSocketChannelData", [
            { no: 1, name: "channel_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "channel_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "is_connected", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { channelType: "", channelUrl: "", isConnected: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string channel_type */ 1:
                    message.channelType = reader.string();
                    break;
                case /* string channel_url */ 2:
                    message.channelUrl = reader.string();
                    break;
                case /* bool is_connected */ 3:
                    message.isConnected = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string channel_type = 1; */
        if (message.channelType !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.channelType);
        /* string channel_url = 2; */
        if (message.channelUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.channelUrl);
        /* bool is_connected = 3; */
        if (message.isConnected !== false)
            writer.tag(3, WireType.Varint).bool(message.isConnected);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WebSocketChannelData
 */
export const WebSocketChannelData = new WebSocketChannelData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationChannel$Type extends MessageType {
    constructor() {
        super("NotificationChannel", [
            { no: 1, name: "channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "channel_nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "channel_data", kind: "message", T: () => WebSocketChannelData },
            { no: 4, name: "channel_lifetime", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "callback_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "resource_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "do_not_disturb_aware", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { channelId: "", channelNickname: "", channelLifetime: 0, callbackUrl: "", resourceUrl: "", doNotDisturbAware: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string channel_id */ 1:
                    message.channelId = reader.string();
                    break;
                case /* string channel_nickname */ 2:
                    message.channelNickname = reader.string();
                    break;
                case /* WebSocketChannelData channel_data */ 3:
                    message.channelData = WebSocketChannelData.internalBinaryRead(reader, reader.uint32(), options, message.channelData);
                    break;
                case /* int32 channel_lifetime */ 4:
                    message.channelLifetime = reader.int32();
                    break;
                case /* string callback_url */ 5:
                    message.callbackUrl = reader.string();
                    break;
                case /* string resource_url */ 6:
                    message.resourceUrl = reader.string();
                    break;
                case /* bool do_not_disturb_aware */ 7:
                    message.doNotDisturbAware = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string channel_id = 1; */
        if (message.channelId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.channelId);
        /* string channel_nickname = 2; */
        if (message.channelNickname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.channelNickname);
        /* WebSocketChannelData channel_data = 3; */
        if (message.channelData)
            WebSocketChannelData.internalBinaryWrite(message.channelData, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 channel_lifetime = 4; */
        if (message.channelLifetime !== 0)
            writer.tag(4, WireType.Varint).int32(message.channelLifetime);
        /* string callback_url = 5; */
        if (message.callbackUrl !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.callbackUrl);
        /* string resource_url = 6; */
        if (message.resourceUrl !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.resourceUrl);
        /* bool do_not_disturb_aware = 7; */
        if (message.doNotDisturbAware !== false)
            writer.tag(7, WireType.Varint).bool(message.doNotDisturbAware);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NotificationChannel
 */
export const NotificationChannel = new NotificationChannel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateNotificationChannelRequest$Type extends MessageType {
    constructor() {
        super("CreateNotificationChannelRequest", [
            { no: 1, name: "channel_type", kind: "enum", T: () => ["ChannelType", ChannelType] },
            { no: 2, name: "channel_lifetime", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { channelType: 0, channelLifetime: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ChannelType channel_type */ 1:
                    message.channelType = reader.int32();
                    break;
                case /* int32 channel_lifetime */ 2:
                    message.channelLifetime = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* ChannelType channel_type = 1; */
        if (message.channelType !== 0)
            writer.tag(1, WireType.Varint).int32(message.channelType);
        /* int32 channel_lifetime = 2; */
        if (message.channelLifetime !== 0)
            writer.tag(2, WireType.Varint).int32(message.channelLifetime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateNotificationChannelRequest
 */
export const CreateNotificationChannelRequest = new CreateNotificationChannelRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNotificationChannelRequest$Type extends MessageType {
    constructor() {
        super("GetNotificationChannelRequest", [
            { no: 1, name: "channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { channelId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string channel_id */ 1:
                    message.channelId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string channel_id = 1; */
        if (message.channelId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.channelId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetNotificationChannelRequest
 */
export const GetNotificationChannelRequest = new GetNotificationChannelRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNotificationChannelsRequest$Type extends MessageType {
    constructor() {
        super("ListNotificationChannelsRequest", [
            { no: 1, name: "page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { pageMarker: "", pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string page_marker */ 1:
                    message.pageMarker = reader.string();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string page_marker = 1; */
        if (message.pageMarker !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.pageMarker);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListNotificationChannelsRequest
 */
export const ListNotificationChannelsRequest = new ListNotificationChannelsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNotificationChannelsResponse$Type extends MessageType {
    constructor() {
        super("ListNotificationChannelsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NotificationChannel },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "next_page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { items: [], pageSize: 0, nextPageMarker: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated NotificationChannel items */ 1:
                    message.items.push(NotificationChannel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* string next_page_marker */ 3:
                    message.nextPageMarker = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated NotificationChannel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            NotificationChannel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* string next_page_marker = 3; */
        if (message.nextPageMarker !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.nextPageMarker);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListNotificationChannelsResponse
 */
export const ListNotificationChannelsResponse = new ListNotificationChannelsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateNotificationChannelRequest$Type extends MessageType {
    constructor() {
        super("UpdateNotificationChannelRequest", [
            { no: 1, name: "channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "channel_type", kind: "enum", T: () => ["ChannelType", ChannelType] },
            { no: 3, name: "channel_lifetime", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { channelId: "", channelType: 0, channelLifetime: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string channel_id */ 1:
                    message.channelId = reader.string();
                    break;
                case /* ChannelType channel_type */ 2:
                    message.channelType = reader.int32();
                    break;
                case /* int32 channel_lifetime */ 3:
                    message.channelLifetime = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string channel_id = 1; */
        if (message.channelId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.channelId);
        /* ChannelType channel_type = 2; */
        if (message.channelType !== 0)
            writer.tag(2, WireType.Varint).int32(message.channelType);
        /* int32 channel_lifetime = 3; */
        if (message.channelLifetime !== 0)
            writer.tag(3, WireType.Varint).int32(message.channelLifetime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateNotificationChannelRequest
 */
export const UpdateNotificationChannelRequest = new UpdateNotificationChannelRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteNotificationChannelRequest$Type extends MessageType {
    constructor() {
        super("DeleteNotificationChannelRequest", [
            { no: 1, name: "channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { channelId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string channel_id */ 1:
                    message.channelId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string channel_id = 1; */
        if (message.channelId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.channelId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteNotificationChannelRequest
 */
export const DeleteNotificationChannelRequest = new DeleteNotificationChannelRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChannelLifetimeRequest$Type extends MessageType {
    constructor() {
        super("GetChannelLifetimeRequest", [
            { no: 1, name: "channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { channelId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string channel_id */ 1:
                    message.channelId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string channel_id = 1; */
        if (message.channelId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.channelId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetChannelLifetimeRequest
 */
export const GetChannelLifetimeRequest = new GetChannelLifetimeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChannelLifetimeResponse$Type extends MessageType {
    constructor() {
        super("ChannelLifetimeResponse", [
            { no: 1, name: "channel_lifetime", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { channelLifetime: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 channel_lifetime */ 1:
                    message.channelLifetime = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 channel_lifetime = 1; */
        if (message.channelLifetime !== 0)
            writer.tag(1, WireType.Varint).int32(message.channelLifetime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ChannelLifetimeResponse
 */
export const ChannelLifetimeResponse = new ChannelLifetimeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefreshChannelLifetimeRequest$Type extends MessageType {
    constructor() {
        super("RefreshChannelLifetimeRequest", [
            { no: 1, name: "channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { channelId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string channel_id */ 1:
                    message.channelId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string channel_id = 1; */
        if (message.channelId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.channelId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RefreshChannelLifetimeRequest
 */
export const RefreshChannelLifetimeRequest = new RefreshChannelLifetimeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Extension$Type extends MessageType {
    constructor() {
        super("Extension", [
            { no: 1, name: "number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "result_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { number: "", organizationId: "", sessionId: "", status: "", resultCode: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string number */ 1:
                    message.number = reader.string();
                    break;
                case /* string organization_id */ 2:
                    message.organizationId = reader.string();
                    break;
                case /* string session_id */ 3:
                    message.sessionId = reader.string();
                    break;
                case /* string status */ 4:
                    message.status = reader.string();
                    break;
                case /* int32 result_code */ 5:
                    message.resultCode = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string number = 1; */
        if (message.number !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.number);
        /* string organization_id = 2; */
        if (message.organizationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.organizationId);
        /* string session_id = 3; */
        if (message.sessionId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.sessionId);
        /* string status = 4; */
        if (message.status !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.status);
        /* int32 result_code = 5; */
        if (message.resultCode !== 0)
            writer.tag(5, WireType.Varint).int32(message.resultCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Extension
 */
export const Extension = new Extension$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateDeviceExtensionsRequest$Type extends MessageType {
    constructor() {
        super("CreateDeviceExtensionsRequest", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "extensions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Extension },
            { no: 4, name: "impersonatee_user_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { deviceId: "", organizationId: "", extensions: [], impersonateeUserKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                case /* string organization_id */ 2:
                    message.organizationId = reader.string();
                    break;
                case /* repeated Extension extensions */ 3:
                    message.extensions.push(Extension.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string impersonatee_user_key */ 4:
                    message.impersonateeUserKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        /* string organization_id = 2; */
        if (message.organizationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.organizationId);
        /* repeated Extension extensions = 3; */
        for (let i = 0; i < message.extensions.length; i++)
            Extension.internalBinaryWrite(message.extensions[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string impersonatee_user_key = 4; */
        if (message.impersonateeUserKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.impersonateeUserKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateDeviceExtensionsRequest
 */
export const CreateDeviceExtensionsRequest = new CreateDeviceExtensionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateDeviceExtensionsResponse$Type extends MessageType {
    constructor() {
        super("CreateDeviceExtensionsResponse", [
            { no: 1, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "extensions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Extension }
        ]);
    }
    create(value) {
        const message = { organizationId: "", extensions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_id */ 1:
                    message.organizationId = reader.string();
                    break;
                case /* repeated Extension extensions */ 2:
                    message.extensions.push(Extension.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string organization_id = 1; */
        if (message.organizationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationId);
        /* repeated Extension extensions = 2; */
        for (let i = 0; i < message.extensions.length; i++)
            Extension.internalBinaryWrite(message.extensions[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateDeviceExtensionsResponse
 */
export const CreateDeviceExtensionsResponse = new CreateDeviceExtensionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDeviceExtensionsRequest$Type extends MessageType {
    constructor() {
        super("GetDeviceExtensionsRequest", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "extension_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { deviceId: "", pageMarker: "", pageSize: 0, organizationId: "", extensionNumber: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                case /* string page_marker */ 2:
                    message.pageMarker = reader.string();
                    break;
                case /* int32 page_size */ 3:
                    message.pageSize = reader.int32();
                    break;
                case /* string organization_id */ 4:
                    message.organizationId = reader.string();
                    break;
                case /* string extension_number */ 5:
                    message.extensionNumber = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        /* string page_marker = 2; */
        if (message.pageMarker !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageMarker);
        /* int32 page_size = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int32(message.pageSize);
        /* string organization_id = 4; */
        if (message.organizationId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.organizationId);
        /* string extension_number = 5; */
        if (message.extensionNumber !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.extensionNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetDeviceExtensionsRequest
 */
export const GetDeviceExtensionsRequest = new GetDeviceExtensionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDeviceExtensionsResponse$Type extends MessageType {
    constructor() {
        super("GetDeviceExtensionsResponse", [
            { no: 1, name: "next_page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "extensions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Extension }
        ]);
    }
    create(value) {
        const message = { nextPageMarker: "", pageSize: 0, extensions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string next_page_marker */ 1:
                    message.nextPageMarker = reader.string();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* repeated Extension extensions */ 3:
                    message.extensions.push(Extension.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string next_page_marker = 1; */
        if (message.nextPageMarker !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nextPageMarker);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* repeated Extension extensions = 3; */
        for (let i = 0; i < message.extensions.length; i++)
            Extension.internalBinaryWrite(message.extensions[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetDeviceExtensionsResponse
 */
export const GetDeviceExtensionsResponse = new GetDeviceExtensionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserPresence$Type extends MessageType {
    constructor() {
        super("UserPresence", [
            { no: 1, name: "user_appearance", kind: "enum", T: () => ["UserAppearance", UserAppearance, "USER_APPEARANCE_"] },
            { no: 2, name: "user_do_not_disturb", kind: "enum", T: () => ["UserDoNotDisturb", UserDoNotDisturb] },
            { no: 3, name: "user_do_not_disturb_expiration", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "user_status", kind: "enum", T: () => ["UserStatus", UserStatus, "USER_STATUS_"] },
            { no: 5, name: "user_note", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { userAppearance: 0, userDoNotDisturb: 0, userDoNotDisturbExpiration: "", userStatus: 0, userNote: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* UserAppearance user_appearance */ 1:
                    message.userAppearance = reader.int32();
                    break;
                case /* UserDoNotDisturb user_do_not_disturb */ 2:
                    message.userDoNotDisturb = reader.int32();
                    break;
                case /* string user_do_not_disturb_expiration */ 3:
                    message.userDoNotDisturbExpiration = reader.string();
                    break;
                case /* UserStatus user_status */ 4:
                    message.userStatus = reader.int32();
                    break;
                case /* string user_note */ 5:
                    message.userNote = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* UserAppearance user_appearance = 1; */
        if (message.userAppearance !== 0)
            writer.tag(1, WireType.Varint).int32(message.userAppearance);
        /* UserDoNotDisturb user_do_not_disturb = 2; */
        if (message.userDoNotDisturb !== 0)
            writer.tag(2, WireType.Varint).int32(message.userDoNotDisturb);
        /* string user_do_not_disturb_expiration = 3; */
        if (message.userDoNotDisturbExpiration !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.userDoNotDisturbExpiration);
        /* UserStatus user_status = 4; */
        if (message.userStatus !== 0)
            writer.tag(4, WireType.Varint).int32(message.userStatus);
        /* string user_note = 5; */
        if (message.userNote !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.userNote);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UserPresence
 */
export const UserPresence = new UserPresence$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserPresenceRequest$Type extends MessageType {
    constructor() {
        super("GetUserPresenceRequest", []);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        return target ?? this.create();
    }
    internalBinaryWrite(message, writer, options) {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetUserPresenceRequest
 */
export const GetUserPresenceRequest = new GetUserPresenceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserPresenceRequest$Type extends MessageType {
    constructor() {
        super("UpdateUserPresenceRequest", [
            { no: 1, name: "user_appearance", kind: "enum", T: () => ["UserAppearance", UserAppearance, "USER_APPEARANCE_"] },
            { no: 2, name: "user_do_not_disturb", kind: "enum", T: () => ["UserDoNotDisturb", UserDoNotDisturb] },
            { no: 3, name: "user_do_not_disturb_expiration", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "user_status", kind: "enum", T: () => ["UserStatus", UserStatus, "USER_STATUS_"] },
            { no: 5, name: "user_note", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { userAppearance: 0, userDoNotDisturb: 0, userDoNotDisturbExpiration: "", userStatus: 0, userNote: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* UserAppearance user_appearance */ 1:
                    message.userAppearance = reader.int32();
                    break;
                case /* UserDoNotDisturb user_do_not_disturb */ 2:
                    message.userDoNotDisturb = reader.int32();
                    break;
                case /* string user_do_not_disturb_expiration */ 3:
                    message.userDoNotDisturbExpiration = reader.string();
                    break;
                case /* UserStatus user_status */ 4:
                    message.userStatus = reader.int32();
                    break;
                case /* string user_note */ 5:
                    message.userNote = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* UserAppearance user_appearance = 1; */
        if (message.userAppearance !== 0)
            writer.tag(1, WireType.Varint).int32(message.userAppearance);
        /* UserDoNotDisturb user_do_not_disturb = 2; */
        if (message.userDoNotDisturb !== 0)
            writer.tag(2, WireType.Varint).int32(message.userDoNotDisturb);
        /* string user_do_not_disturb_expiration = 3; */
        if (message.userDoNotDisturbExpiration !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.userDoNotDisturbExpiration);
        /* UserStatus user_status = 4; */
        if (message.userStatus !== 0)
            writer.tag(4, WireType.Varint).int32(message.userStatus);
        /* string user_note = 5; */
        if (message.userNote !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.userNote);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateUserPresenceRequest
 */
export const UpdateUserPresenceRequest = new UpdateUserPresenceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscribeToCallEventsRequest$Type extends MessageType {
    constructor() {
        super("SubscribeToCallEventsRequest", [
            { no: 1, name: "channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_keys", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AccountKey }
        ]);
    }
    create(value) {
        const message = { channelId: "", accountKeys: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string channel_id */ 1:
                    message.channelId = reader.string();
                    break;
                case /* repeated AccountKey account_keys */ 2:
                    message.accountKeys.push(AccountKey.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string channel_id = 1; */
        if (message.channelId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.channelId);
        /* repeated AccountKey account_keys = 2; */
        for (let i = 0; i < message.accountKeys.length; i++)
            AccountKey.internalBinaryWrite(message.accountKeys[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubscribeToCallEventsRequest
 */
export const SubscribeToCallEventsRequest = new SubscribeToCallEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccountKey$Type extends MessageType {
    constructor() {
        super("AccountKey", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AccountKey
 */
export const AccountKey = new AccountKey$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccountSubscriptionStatus$Type extends MessageType {
    constructor() {
        super("AccountSubscriptionStatus", [
            { no: 1, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { accountId: "", status: 0, message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string account_id */ 1:
                    message.accountId = reader.string();
                    break;
                case /* int32 status */ 2:
                    message.status = reader.int32();
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string account_id = 1; */
        if (message.accountId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountId);
        /* int32 status = 2; */
        if (message.status !== 0)
            writer.tag(2, WireType.Varint).int32(message.status);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AccountSubscriptionStatus
 */
export const AccountSubscriptionStatus = new AccountSubscriptionStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscribeToCallEventsResponse$Type extends MessageType {
    constructor() {
        super("SubscribeToCallEventsResponse", [
            { no: 1, name: "account_statuses", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AccountSubscriptionStatus }
        ]);
    }
    create(value) {
        const message = { accountStatuses: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated AccountSubscriptionStatus account_statuses */ 1:
                    message.accountStatuses.push(AccountSubscriptionStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated AccountSubscriptionStatus account_statuses = 1; */
        for (let i = 0; i < message.accountStatuses.length; i++)
            AccountSubscriptionStatus.internalBinaryWrite(message.accountStatuses[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubscribeToCallEventsResponse
 */
export const SubscribeToCallEventsResponse = new SubscribeToCallEventsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class License$Type extends MessageType {
    constructor() {
        super("License", [
            { no: 1, name: "key", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "channel", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "products", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "admin_roles", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { key: 0n, enabled: false, description: "", type: "", channel: "", products: [], adminRoles: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 key */ 1:
                    message.key = reader.int64().toBigInt();
                    break;
                case /* bool enabled */ 2:
                    message.enabled = reader.bool();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string type */ 4:
                    message.type = reader.string();
                    break;
                case /* string channel */ 5:
                    message.channel = reader.string();
                    break;
                case /* repeated string products */ 6:
                    message.products.push(reader.string());
                    break;
                case /* repeated string admin_roles */ 7:
                    message.adminRoles.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int64 key = 1; */
        if (message.key !== 0n)
            writer.tag(1, WireType.Varint).int64(message.key);
        /* bool enabled = 2; */
        if (message.enabled !== false)
            writer.tag(2, WireType.Varint).bool(message.enabled);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string type = 4; */
        if (message.type !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.type);
        /* string channel = 5; */
        if (message.channel !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.channel);
        /* repeated string products = 6; */
        for (let i = 0; i < message.products.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.products[i]);
        /* repeated string admin_roles = 7; */
        for (let i = 0; i < message.adminRoles.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.adminRoles[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message License
 */
export const License = new License$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminInfo$Type extends MessageType {
    constructor() {
        super("AdminInfo", [
            { no: 1, name: "invited_date", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "enabled_date", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "custom_contact_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "custom_contact_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { invitedDate: 0n, enabledDate: 0n, customContactEmail: "", customContactUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 invited_date */ 1:
                    message.invitedDate = reader.int64().toBigInt();
                    break;
                case /* int64 enabled_date */ 2:
                    message.enabledDate = reader.int64().toBigInt();
                    break;
                case /* string custom_contact_email */ 3:
                    message.customContactEmail = reader.string();
                    break;
                case /* string custom_contact_url */ 4:
                    message.customContactUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int64 invited_date = 1; */
        if (message.invitedDate !== 0n)
            writer.tag(1, WireType.Varint).int64(message.invitedDate);
        /* int64 enabled_date = 2; */
        if (message.enabledDate !== 0n)
            writer.tag(2, WireType.Varint).int64(message.enabledDate);
        /* string custom_contact_email = 3; */
        if (message.customContactEmail !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customContactEmail);
        /* string custom_contact_url = 4; */
        if (message.customContactUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customContactUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AdminInfo
 */
export const AdminInfo = new AdminInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Invitation$Type extends MessageType {
    constructor() {
        super("Invitation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "invited_license_keys", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value) {
        const message = { id: "", invitedLicenseKeys: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* repeated int64 invited_license_keys */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.invitedLicenseKeys.push(reader.int64().toBigInt());
                    else
                        message.invitedLicenseKeys.push(reader.int64().toBigInt());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* repeated int64 invited_license_keys = 2; */
        if (message.invitedLicenseKeys.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.invitedLicenseKeys.length; i++)
                writer.int64(message.invitedLicenseKeys[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Invitation
 */
export const Invitation = new Invitation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Conflict$Type extends MessageType {
    constructor() {
        super("Conflict", [
            { no: 1, name: "account_key", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "license_keys", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "unified_admin", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { accountKey: 0n, licenseKeys: [], unifiedAdmin: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 account_key */ 1:
                    message.accountKey = reader.int64().toBigInt();
                    break;
                case /* repeated int64 license_keys */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.licenseKeys.push(reader.int64().toBigInt());
                    else
                        message.licenseKeys.push(reader.int64().toBigInt());
                    break;
                case /* bool unified_admin */ 3:
                    message.unifiedAdmin = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int64 account_key = 1; */
        if (message.accountKey !== 0n)
            writer.tag(1, WireType.Varint).int64(message.accountKey);
        /* repeated int64 license_keys = 2; */
        if (message.licenseKeys.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.licenseKeys.length; i++)
                writer.int64(message.licenseKeys[i]);
            writer.join();
        }
        /* bool unified_admin = 3; */
        if (message.unifiedAdmin !== false)
            writer.tag(3, WireType.Varint).bool(message.unifiedAdmin);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Conflict
 */
export const Conflict = new Conflict$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Account$Type extends MessageType {
    constructor() {
        super("Account", [
            { no: 1, name: "key", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "licenses", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => License },
            { no: 4, name: "admin_list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AdminInfo },
            { no: 5, name: "invitation", kind: "message", T: () => Invitation },
            { no: 6, name: "conflicts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Conflict }
        ]);
    }
    create(value) {
        const message = { key: 0n, name: "", licenses: [], adminList: [], conflicts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 key */ 1:
                    message.key = reader.int64().toBigInt();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated License licenses */ 3:
                    message.licenses.push(License.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated AdminInfo admin_list */ 4:
                    message.adminList.push(AdminInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Invitation invitation */ 5:
                    message.invitation = Invitation.internalBinaryRead(reader, reader.uint32(), options, message.invitation);
                    break;
                case /* repeated Conflict conflicts */ 6:
                    message.conflicts.push(Conflict.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int64 key = 1; */
        if (message.key !== 0n)
            writer.tag(1, WireType.Varint).int64(message.key);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated License licenses = 3; */
        for (let i = 0; i < message.licenses.length; i++)
            License.internalBinaryWrite(message.licenses[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated AdminInfo admin_list = 4; */
        for (let i = 0; i < message.adminList.length; i++)
            AdminInfo.internalBinaryWrite(message.adminList[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Invitation invitation = 5; */
        if (message.invitation)
            Invitation.internalBinaryWrite(message.invitation, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated Conflict conflicts = 6; */
        for (let i = 0; i < message.conflicts.length; i++)
            Conflict.internalBinaryWrite(message.conflicts[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Account
 */
export const Account = new Account$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeResponse$Type extends MessageType {
    constructor() {
        super("MeResponse", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "locale", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "time_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "admin_roles", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "accounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MeResponse_Account },
            { no: 10, name: "system_roles", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "create_time", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 12, name: "products", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "organization_key", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 14, name: "organization_roles", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "unified_admin", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "org_admin", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { key: "", accountKey: "", email: "", firstName: "", lastName: "", locale: "", timeZone: "", adminRoles: [], accounts: [], systemRoles: [], createTime: 0n, products: [], organizationKey: 0n, organizationRoles: [], unifiedAdmin: false, orgAdmin: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* string account_key */ 2:
                    message.accountKey = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                case /* string first_name */ 4:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 5:
                    message.lastName = reader.string();
                    break;
                case /* string locale */ 6:
                    message.locale = reader.string();
                    break;
                case /* string time_zone */ 7:
                    message.timeZone = reader.string();
                    break;
                case /* repeated string admin_roles */ 8:
                    message.adminRoles.push(reader.string());
                    break;
                case /* repeated MeResponse.Account accounts */ 9:
                    message.accounts.push(MeResponse_Account.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string system_roles */ 10:
                    message.systemRoles.push(reader.string());
                    break;
                case /* int64 create_time */ 11:
                    message.createTime = reader.int64().toBigInt();
                    break;
                case /* repeated string products */ 12:
                    message.products.push(reader.string());
                    break;
                case /* int64 organization_key */ 13:
                    message.organizationKey = reader.int64().toBigInt();
                    break;
                case /* repeated string organization_roles */ 14:
                    message.organizationRoles.push(reader.string());
                    break;
                case /* bool unified_admin */ 15:
                    message.unifiedAdmin = reader.bool();
                    break;
                case /* bool org_admin */ 16:
                    message.orgAdmin = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* string account_key = 2; */
        if (message.accountKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountKey);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        /* string first_name = 4; */
        if (message.firstName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 5; */
        if (message.lastName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.lastName);
        /* string locale = 6; */
        if (message.locale !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.locale);
        /* string time_zone = 7; */
        if (message.timeZone !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.timeZone);
        /* repeated string admin_roles = 8; */
        for (let i = 0; i < message.adminRoles.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.adminRoles[i]);
        /* repeated MeResponse.Account accounts = 9; */
        for (let i = 0; i < message.accounts.length; i++)
            MeResponse_Account.internalBinaryWrite(message.accounts[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* repeated string system_roles = 10; */
        for (let i = 0; i < message.systemRoles.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.systemRoles[i]);
        /* int64 create_time = 11; */
        if (message.createTime !== 0n)
            writer.tag(11, WireType.Varint).int64(message.createTime);
        /* repeated string products = 12; */
        for (let i = 0; i < message.products.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.products[i]);
        /* int64 organization_key = 13; */
        if (message.organizationKey !== 0n)
            writer.tag(13, WireType.Varint).int64(message.organizationKey);
        /* repeated string organization_roles = 14; */
        for (let i = 0; i < message.organizationRoles.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.organizationRoles[i]);
        /* bool unified_admin = 15; */
        if (message.unifiedAdmin !== false)
            writer.tag(15, WireType.Varint).bool(message.unifiedAdmin);
        /* bool org_admin = 16; */
        if (message.orgAdmin !== false)
            writer.tag(16, WireType.Varint).bool(message.orgAdmin);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MeResponse
 */
export const MeResponse = new MeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeResponse_Account$Type extends MessageType {
    constructor() {
        super("MeResponse.Account", [
            { no: 1, name: "key", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "licenses", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MeResponse_License },
            { no: 4, name: "admin_list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MeResponse_Admin },
            { no: 5, name: "invitation", kind: "message", T: () => MeResponse_Invitation },
            { no: 6, name: "admin_roles", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "invited_date", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 8, name: "enabled_date", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 9, name: "unified_admin", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "slow_channel_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "toggle_unified_admin", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "partner", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "parent", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "has_managed_groups", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "parent_account_delegated_admin", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "gtr_lite_banner", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "role_filter_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "role_filter_billing_admin_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { key: 0n, name: "", licenses: [], adminList: [], adminRoles: [], invitedDate: 0n, enabledDate: 0n, unifiedAdmin: false, slowChannelEnabled: false, toggleUnifiedAdmin: false, partner: false, parent: false, hasManagedGroups: false, parentAccountDelegatedAdmin: false, gtrLiteBanner: false, roleFilterEnabled: false, roleFilterBillingAdminEnabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 key */ 1:
                    message.key = reader.int64().toBigInt();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated MeResponse.License licenses */ 3:
                    message.licenses.push(MeResponse_License.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated MeResponse.Admin admin_list */ 4:
                    message.adminList.push(MeResponse_Admin.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* MeResponse.Invitation invitation */ 5:
                    message.invitation = MeResponse_Invitation.internalBinaryRead(reader, reader.uint32(), options, message.invitation);
                    break;
                case /* repeated string admin_roles */ 6:
                    message.adminRoles.push(reader.string());
                    break;
                case /* int64 invited_date */ 7:
                    message.invitedDate = reader.int64().toBigInt();
                    break;
                case /* int64 enabled_date */ 8:
                    message.enabledDate = reader.int64().toBigInt();
                    break;
                case /* bool unified_admin */ 9:
                    message.unifiedAdmin = reader.bool();
                    break;
                case /* bool slow_channel_enabled */ 10:
                    message.slowChannelEnabled = reader.bool();
                    break;
                case /* bool toggle_unified_admin */ 11:
                    message.toggleUnifiedAdmin = reader.bool();
                    break;
                case /* bool partner */ 12:
                    message.partner = reader.bool();
                    break;
                case /* bool parent */ 13:
                    message.parent = reader.bool();
                    break;
                case /* bool has_managed_groups */ 14:
                    message.hasManagedGroups = reader.bool();
                    break;
                case /* bool parent_account_delegated_admin */ 15:
                    message.parentAccountDelegatedAdmin = reader.bool();
                    break;
                case /* bool gtr_lite_banner */ 16:
                    message.gtrLiteBanner = reader.bool();
                    break;
                case /* bool role_filter_enabled */ 17:
                    message.roleFilterEnabled = reader.bool();
                    break;
                case /* bool role_filter_billing_admin_enabled */ 18:
                    message.roleFilterBillingAdminEnabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int64 key = 1; */
        if (message.key !== 0n)
            writer.tag(1, WireType.Varint).int64(message.key);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated MeResponse.License licenses = 3; */
        for (let i = 0; i < message.licenses.length; i++)
            MeResponse_License.internalBinaryWrite(message.licenses[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated MeResponse.Admin admin_list = 4; */
        for (let i = 0; i < message.adminList.length; i++)
            MeResponse_Admin.internalBinaryWrite(message.adminList[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* MeResponse.Invitation invitation = 5; */
        if (message.invitation)
            MeResponse_Invitation.internalBinaryWrite(message.invitation, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated string admin_roles = 6; */
        for (let i = 0; i < message.adminRoles.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.adminRoles[i]);
        /* int64 invited_date = 7; */
        if (message.invitedDate !== 0n)
            writer.tag(7, WireType.Varint).int64(message.invitedDate);
        /* int64 enabled_date = 8; */
        if (message.enabledDate !== 0n)
            writer.tag(8, WireType.Varint).int64(message.enabledDate);
        /* bool unified_admin = 9; */
        if (message.unifiedAdmin !== false)
            writer.tag(9, WireType.Varint).bool(message.unifiedAdmin);
        /* bool slow_channel_enabled = 10; */
        if (message.slowChannelEnabled !== false)
            writer.tag(10, WireType.Varint).bool(message.slowChannelEnabled);
        /* bool toggle_unified_admin = 11; */
        if (message.toggleUnifiedAdmin !== false)
            writer.tag(11, WireType.Varint).bool(message.toggleUnifiedAdmin);
        /* bool partner = 12; */
        if (message.partner !== false)
            writer.tag(12, WireType.Varint).bool(message.partner);
        /* bool parent = 13; */
        if (message.parent !== false)
            writer.tag(13, WireType.Varint).bool(message.parent);
        /* bool has_managed_groups = 14; */
        if (message.hasManagedGroups !== false)
            writer.tag(14, WireType.Varint).bool(message.hasManagedGroups);
        /* bool parent_account_delegated_admin = 15; */
        if (message.parentAccountDelegatedAdmin !== false)
            writer.tag(15, WireType.Varint).bool(message.parentAccountDelegatedAdmin);
        /* bool gtr_lite_banner = 16; */
        if (message.gtrLiteBanner !== false)
            writer.tag(16, WireType.Varint).bool(message.gtrLiteBanner);
        /* bool role_filter_enabled = 17; */
        if (message.roleFilterEnabled !== false)
            writer.tag(17, WireType.Varint).bool(message.roleFilterEnabled);
        /* bool role_filter_billing_admin_enabled = 18; */
        if (message.roleFilterBillingAdminEnabled !== false)
            writer.tag(18, WireType.Varint).bool(message.roleFilterBillingAdminEnabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MeResponse.Account
 */
export const MeResponse_Account = new MeResponse_Account$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeResponse_License$Type extends MessageType {
    constructor() {
        super("MeResponse.License", [
            { no: 1, name: "key", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "channel", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "products", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "admin_roles", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { key: 0n, enabled: false, description: "", type: "", channel: "", products: [], adminRoles: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 key */ 1:
                    message.key = reader.int64().toBigInt();
                    break;
                case /* bool enabled */ 2:
                    message.enabled = reader.bool();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string type */ 4:
                    message.type = reader.string();
                    break;
                case /* string channel */ 5:
                    message.channel = reader.string();
                    break;
                case /* repeated string products */ 6:
                    message.products.push(reader.string());
                    break;
                case /* repeated string admin_roles */ 7:
                    message.adminRoles.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int64 key = 1; */
        if (message.key !== 0n)
            writer.tag(1, WireType.Varint).int64(message.key);
        /* bool enabled = 2; */
        if (message.enabled !== false)
            writer.tag(2, WireType.Varint).bool(message.enabled);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string type = 4; */
        if (message.type !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.type);
        /* string channel = 5; */
        if (message.channel !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.channel);
        /* repeated string products = 6; */
        for (let i = 0; i < message.products.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.products[i]);
        /* repeated string admin_roles = 7; */
        for (let i = 0; i < message.adminRoles.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.adminRoles[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MeResponse.License
 */
export const MeResponse_License = new MeResponse_License$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeResponse_Admin$Type extends MessageType {
    constructor() {
        super("MeResponse.Admin", [
            { no: 1, name: "key", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "invited_date", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 6, name: "activity_time", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 7, name: "enabled_date", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 8, name: "custom_contact_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "custom_contact_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { key: 0n, email: "", firstName: "", lastName: "", invitedDate: 0n, activityTime: 0n, enabledDate: 0n, customContactEmail: "", customContactUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 key */ 1:
                    message.key = reader.int64().toBigInt();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string first_name */ 3:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 4:
                    message.lastName = reader.string();
                    break;
                case /* int64 invited_date */ 5:
                    message.invitedDate = reader.int64().toBigInt();
                    break;
                case /* int64 activity_time */ 6:
                    message.activityTime = reader.int64().toBigInt();
                    break;
                case /* int64 enabled_date */ 7:
                    message.enabledDate = reader.int64().toBigInt();
                    break;
                case /* string custom_contact_email */ 8:
                    message.customContactEmail = reader.string();
                    break;
                case /* string custom_contact_url */ 9:
                    message.customContactUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int64 key = 1; */
        if (message.key !== 0n)
            writer.tag(1, WireType.Varint).int64(message.key);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string first_name = 3; */
        if (message.firstName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 4; */
        if (message.lastName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.lastName);
        /* int64 invited_date = 5; */
        if (message.invitedDate !== 0n)
            writer.tag(5, WireType.Varint).int64(message.invitedDate);
        /* int64 activity_time = 6; */
        if (message.activityTime !== 0n)
            writer.tag(6, WireType.Varint).int64(message.activityTime);
        /* int64 enabled_date = 7; */
        if (message.enabledDate !== 0n)
            writer.tag(7, WireType.Varint).int64(message.enabledDate);
        /* string custom_contact_email = 8; */
        if (message.customContactEmail !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.customContactEmail);
        /* string custom_contact_url = 9; */
        if (message.customContactUrl !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.customContactUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MeResponse.Admin
 */
export const MeResponse_Admin = new MeResponse_Admin$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeResponse_Invitation$Type extends MessageType {
    constructor() {
        super("MeResponse.Invitation", [
            { no: 1, name: "unified_admin", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { unifiedAdmin: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool unified_admin */ 1:
                    message.unifiedAdmin = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* bool unified_admin = 1; */
        if (message.unifiedAdmin !== false)
            writer.tag(1, WireType.Varint).bool(message.unifiedAdmin);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MeResponse.Invitation
 */
export const MeResponse_Invitation = new MeResponse_Invitation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Voicemail$Type extends MessageType {
    constructor() {
        super("Voicemail", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", status: "", timestamp: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string status */ 2:
                    message.status = reader.string();
                    break;
                case /* string timestamp */ 3:
                    message.timestamp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string status = 2; */
        if (message.status !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.status);
        /* string timestamp = 3; */
        if (message.timestamp !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.timestamp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Voicemail
 */
export const Voicemail = new Voicemail$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVoicemailsRequest$Type extends MessageType {
    constructor() {
        super("GetVoicemailsRequest", [
            { no: 1, name: "voicemailbox_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "sort", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { voicemailboxId: "", page: 0, pageSize: 0, status: "", sort: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string voicemailbox_id */ 1:
                    message.voicemailboxId = reader.string();
                    break;
                case /* int32 page */ 2:
                    message.page = reader.int32();
                    break;
                case /* int32 page_size */ 3:
                    message.pageSize = reader.int32();
                    break;
                case /* string status */ 4:
                    message.status = reader.string();
                    break;
                case /* string sort */ 5:
                    message.sort = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string voicemailbox_id = 1; */
        if (message.voicemailboxId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.voicemailboxId);
        /* int32 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int32(message.page);
        /* int32 page_size = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int32(message.pageSize);
        /* string status = 4; */
        if (message.status !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.status);
        /* string sort = 5; */
        if (message.sort !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.sort);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetVoicemailsRequest
 */
export const GetVoicemailsRequest = new GetVoicemailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVoicemailsResponse$Type extends MessageType {
    constructor() {
        super("GetVoicemailsResponse", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "matches_total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Voicemail }
        ]);
    }
    create(value) {
        const message = { page: 0, pageSize: 0, matchesTotal: 0, items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* int32 matches_total */ 3:
                    message.matchesTotal = reader.int32();
                    break;
                case /* repeated Voicemail items */ 4:
                    message.items.push(Voicemail.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* int32 matches_total = 3; */
        if (message.matchesTotal !== 0)
            writer.tag(3, WireType.Varint).int32(message.matchesTotal);
        /* repeated Voicemail items = 4; */
        for (let i = 0; i < message.items.length; i++)
            Voicemail.internalBinaryWrite(message.items[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetVoicemailsResponse
 */
export const GetVoicemailsResponse = new GetVoicemailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VoicemailBox$Type extends MessageType {
    constructor() {
        super("VoicemailBox", [
            { no: 1, name: "voicemailbox_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "account_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "extension_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "last_updated_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "new_message_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "read_message_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { voicemailboxId: "", organizationId: "", accountKey: "", extensionNumber: "", lastUpdatedTimestamp: "", newMessageCount: 0, readMessageCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string voicemailbox_id */ 1:
                    message.voicemailboxId = reader.string();
                    break;
                case /* string organization_id */ 2:
                    message.organizationId = reader.string();
                    break;
                case /* string account_key */ 3:
                    message.accountKey = reader.string();
                    break;
                case /* string extension_number */ 4:
                    message.extensionNumber = reader.string();
                    break;
                case /* string last_updated_timestamp */ 5:
                    message.lastUpdatedTimestamp = reader.string();
                    break;
                case /* int32 new_message_count */ 6:
                    message.newMessageCount = reader.int32();
                    break;
                case /* int32 read_message_count */ 7:
                    message.readMessageCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string voicemailbox_id = 1; */
        if (message.voicemailboxId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.voicemailboxId);
        /* string organization_id = 2; */
        if (message.organizationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.organizationId);
        /* string account_key = 3; */
        if (message.accountKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.accountKey);
        /* string extension_number = 4; */
        if (message.extensionNumber !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.extensionNumber);
        /* string last_updated_timestamp = 5; */
        if (message.lastUpdatedTimestamp !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.lastUpdatedTimestamp);
        /* int32 new_message_count = 6; */
        if (message.newMessageCount !== 0)
            writer.tag(6, WireType.Varint).int32(message.newMessageCount);
        /* int32 read_message_count = 7; */
        if (message.readMessageCount !== 0)
            writer.tag(7, WireType.Varint).int32(message.readMessageCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VoicemailBox
 */
export const VoicemailBox = new VoicemailBox$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVoicemailBoxesRequest$Type extends MessageType {
    constructor() {
        super("GetVoicemailBoxesRequest", [
            { no: 1, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "extension_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { organizationId: "", accountKey: "", extensionNumber: "", pageMarker: "", pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_id */ 1:
                    message.organizationId = reader.string();
                    break;
                case /* string account_key */ 2:
                    message.accountKey = reader.string();
                    break;
                case /* string extension_number */ 3:
                    message.extensionNumber = reader.string();
                    break;
                case /* string page_marker */ 4:
                    message.pageMarker = reader.string();
                    break;
                case /* int32 page_size */ 5:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string organization_id = 1; */
        if (message.organizationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationId);
        /* string account_key = 2; */
        if (message.accountKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountKey);
        /* string extension_number = 3; */
        if (message.extensionNumber !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.extensionNumber);
        /* string page_marker = 4; */
        if (message.pageMarker !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.pageMarker);
        /* int32 page_size = 5; */
        if (message.pageSize !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetVoicemailBoxesRequest
 */
export const GetVoicemailBoxesRequest = new GetVoicemailBoxesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVoicemailBoxesResponse$Type extends MessageType {
    constructor() {
        super("GetVoicemailBoxesResponse", [
            { no: 1, name: "next_page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VoicemailBox }
        ]);
    }
    create(value) {
        const message = { nextPageMarker: "", items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string next_page_marker */ 1:
                    message.nextPageMarker = reader.string();
                    break;
                case /* repeated VoicemailBox items */ 2:
                    message.items.push(VoicemailBox.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string next_page_marker = 1; */
        if (message.nextPageMarker !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nextPageMarker);
        /* repeated VoicemailBox items = 2; */
        for (let i = 0; i < message.items.length; i++)
            VoicemailBox.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetVoicemailBoxesResponse
 */
export const GetVoicemailBoxesResponse = new GetVoicemailBoxesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVoicemailMediaContentRequest$Type extends MessageType {
    constructor() {
        super("GetVoicemailMediaContentRequest", [
            { no: 1, name: "voicemail_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { voicemailId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string voicemail_id */ 1:
                    message.voicemailId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string voicemail_id = 1; */
        if (message.voicemailId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.voicemailId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetVoicemailMediaContentRequest
 */
export const GetVoicemailMediaContentRequest = new GetVoicemailMediaContentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VoicemailMediaContent$Type extends MessageType {
    constructor() {
        super("VoicemailMediaContent", [
            { no: 1, name: "audio_data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "content_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { audioData: new Uint8Array(0), contentType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes audio_data */ 1:
                    message.audioData = reader.bytes();
                    break;
                case /* string content_type */ 2:
                    message.contentType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* bytes audio_data = 1; */
        if (message.audioData.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.audioData);
        /* string content_type = 2; */
        if (message.contentType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.contentType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VoicemailMediaContent
 */
export const VoicemailMediaContent = new VoicemailMediaContent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WarmTransferRequest$Type extends MessageType {
    constructor() {
        super("WarmTransferRequest", [
            { no: 1, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "refer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { callId: "", referId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string call_id */ 1:
                    message.callId = reader.string();
                    break;
                case /* string refer_id */ 2:
                    message.referId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string call_id = 1; */
        if (message.callId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.callId);
        /* string refer_id = 2; */
        if (message.referId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.referId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WarmTransferRequest
 */
export const WarmTransferRequest = new WarmTransferRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BlindTransferRequest$Type extends MessageType {
    constructor() {
        super("BlindTransferRequest", [
            { no: 1, name: "call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "dial_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { callId: "", dialString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string call_id */ 1:
                    message.callId = reader.string();
                    break;
                case /* string dial_string */ 2:
                    message.dialString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string call_id = 1; */
        if (message.callId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.callId);
        /* string dial_string = 2; */
        if (message.dialString !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.dialString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BlindTransferRequest
 */
export const BlindTransferRequest = new BlindTransferRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendMessageRequest$Type extends MessageType {
    constructor() {
        super("SendMessageRequest", [
            { no: 1, name: "owner_phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "contact_phone_numbers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "user_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "media", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageMedia }
        ]);
    }
    create(value) {
        const message = { ownerPhoneNumber: "", contactPhoneNumbers: [], body: "", userKey: "", media: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string owner_phone_number */ 1:
                    message.ownerPhoneNumber = reader.string();
                    break;
                case /* repeated string contact_phone_numbers */ 2:
                    message.contactPhoneNumbers.push(reader.string());
                    break;
                case /* string body */ 3:
                    message.body = reader.string();
                    break;
                case /* string user_key */ 4:
                    message.userKey = reader.string();
                    break;
                case /* repeated MessageMedia media */ 5:
                    message.media.push(MessageMedia.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string owner_phone_number = 1; */
        if (message.ownerPhoneNumber !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ownerPhoneNumber);
        /* repeated string contact_phone_numbers = 2; */
        for (let i = 0; i < message.contactPhoneNumbers.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.contactPhoneNumbers[i]);
        /* string body = 3; */
        if (message.body !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.body);
        /* string user_key = 4; */
        if (message.userKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.userKey);
        /* repeated MessageMedia media = 5; */
        for (let i = 0; i < message.media.length; i++)
            MessageMedia.internalBinaryWrite(message.media[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SendMessageRequest
 */
export const SendMessageRequest = new SendMessageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageMedia$Type extends MessageType {
    constructor() {
        super("MessageMedia", []);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        return target ?? this.create();
    }
    internalBinaryWrite(message, writer, options) {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MessageMedia
 */
export const MessageMedia = new MessageMedia$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoToMedia$Type extends MessageType {
    constructor() {
        super("GoToMedia", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "filename", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "content_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: "", filename: "", contentType: "", size: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string filename */ 2:
                    message.filename = reader.string();
                    break;
                case /* string content_type */ 3:
                    message.contentType = reader.string();
                    break;
                case /* int32 size */ 4:
                    message.size = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string filename = 2; */
        if (message.filename !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.filename);
        /* string content_type = 3; */
        if (message.contentType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.contentType);
        /* int32 size = 4; */
        if (message.size !== 0)
            writer.tag(4, WireType.Varint).int32(message.size);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GoToMedia
 */
export const GoToMedia = new GoToMedia$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoToMessage$Type extends MessageType {
    constructor() {
        super("GoToMessage", [
            { no: 1, name: "owner_phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "contact_phone_numbers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "author_phone_numbers", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "author_external_userKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "direction", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "delivery_statuses", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GoToDeliveryStatus },
            { no: 10, name: "media", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GoToMedia }
        ]);
    }
    create(value) {
        const message = { ownerPhoneNumber: "", contactPhoneNumbers: [], authorPhoneNumbers: "", authorExternalUserKey: "", id: "", timestamp: "", direction: "", body: "", deliveryStatuses: [], media: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string owner_phone_number */ 1:
                    message.ownerPhoneNumber = reader.string();
                    break;
                case /* repeated string contact_phone_numbers */ 2:
                    message.contactPhoneNumbers.push(reader.string());
                    break;
                case /* string author_phone_numbers */ 3:
                    message.authorPhoneNumbers = reader.string();
                    break;
                case /* string author_external_userKey */ 4:
                    message.authorExternalUserKey = reader.string();
                    break;
                case /* string id */ 5:
                    message.id = reader.string();
                    break;
                case /* string timestamp */ 6:
                    message.timestamp = reader.string();
                    break;
                case /* string direction */ 7:
                    message.direction = reader.string();
                    break;
                case /* string body */ 8:
                    message.body = reader.string();
                    break;
                case /* repeated GoToDeliveryStatus delivery_statuses */ 9:
                    message.deliveryStatuses.push(GoToDeliveryStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated GoToMedia media */ 10:
                    message.media.push(GoToMedia.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string owner_phone_number = 1; */
        if (message.ownerPhoneNumber !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ownerPhoneNumber);
        /* repeated string contact_phone_numbers = 2; */
        for (let i = 0; i < message.contactPhoneNumbers.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.contactPhoneNumbers[i]);
        /* string author_phone_numbers = 3; */
        if (message.authorPhoneNumbers !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.authorPhoneNumbers);
        /* string author_external_userKey = 4; */
        if (message.authorExternalUserKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.authorExternalUserKey);
        /* string id = 5; */
        if (message.id !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.id);
        /* string timestamp = 6; */
        if (message.timestamp !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.timestamp);
        /* string direction = 7; */
        if (message.direction !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.direction);
        /* string body = 8; */
        if (message.body !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.body);
        /* repeated GoToDeliveryStatus delivery_statuses = 9; */
        for (let i = 0; i < message.deliveryStatuses.length; i++)
            GoToDeliveryStatus.internalBinaryWrite(message.deliveryStatuses[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* repeated GoToMedia media = 10; */
        for (let i = 0; i < message.media.length; i++)
            GoToMedia.internalBinaryWrite(message.media[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GoToMessage
 */
export const GoToMessage = new GoToMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoToDeliveryStatus$Type extends MessageType {
    constructor() {
        super("GoToDeliveryStatus", [
            { no: 1, name: "message_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "contact_phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "delivery_status_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "delivery_status_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "retryable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { messageId: "", contactPhoneNumber: "", timestamp: "", deliveryStatusDescription: "", deliveryStatusCode: 0, retryable: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message_id */ 1:
                    message.messageId = reader.string();
                    break;
                case /* string contact_phone_number */ 2:
                    message.contactPhoneNumber = reader.string();
                    break;
                case /* string timestamp */ 3:
                    message.timestamp = reader.string();
                    break;
                case /* string delivery_status_description */ 4:
                    message.deliveryStatusDescription = reader.string();
                    break;
                case /* int32 delivery_status_code */ 5:
                    message.deliveryStatusCode = reader.int32();
                    break;
                case /* bool retryable */ 6:
                    message.retryable = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string message_id = 1; */
        if (message.messageId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.messageId);
        /* string contact_phone_number = 2; */
        if (message.contactPhoneNumber !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.contactPhoneNumber);
        /* string timestamp = 3; */
        if (message.timestamp !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.timestamp);
        /* string delivery_status_description = 4; */
        if (message.deliveryStatusDescription !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.deliveryStatusDescription);
        /* int32 delivery_status_code = 5; */
        if (message.deliveryStatusCode !== 0)
            writer.tag(5, WireType.Varint).int32(message.deliveryStatusCode);
        /* bool retryable = 6; */
        if (message.retryable !== false)
            writer.tag(6, WireType.Varint).bool(message.retryable);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GoToDeliveryStatus
 */
export const GoToDeliveryStatus = new GoToDeliveryStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessagesRequest$Type extends MessageType {
    constructor() {
        super("MessagesRequest", [
            { no: 1, name: "owner_phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "contact_phone_number", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "oldest_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "newest_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { ownerPhoneNumber: "", contactPhoneNumber: [], oldestTimestamp: "", newestTimestamp: "", pageMarker: "", pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string owner_phone_number */ 1:
                    message.ownerPhoneNumber = reader.string();
                    break;
                case /* repeated string contact_phone_number */ 2:
                    message.contactPhoneNumber.push(reader.string());
                    break;
                case /* string oldest_timestamp */ 3:
                    message.oldestTimestamp = reader.string();
                    break;
                case /* string newest_timestamp */ 4:
                    message.newestTimestamp = reader.string();
                    break;
                case /* string page_marker */ 5:
                    message.pageMarker = reader.string();
                    break;
                case /* int32 page_size */ 6:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string owner_phone_number = 1; */
        if (message.ownerPhoneNumber !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ownerPhoneNumber);
        /* repeated string contact_phone_number = 2; */
        for (let i = 0; i < message.contactPhoneNumber.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.contactPhoneNumber[i]);
        /* string oldest_timestamp = 3; */
        if (message.oldestTimestamp !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.oldestTimestamp);
        /* string newest_timestamp = 4; */
        if (message.newestTimestamp !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.newestTimestamp);
        /* string page_marker = 5; */
        if (message.pageMarker !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.pageMarker);
        /* int32 page_size = 6; */
        if (message.pageSize !== 0)
            writer.tag(6, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MessagesRequest
 */
export const MessagesRequest = new MessagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoToMessageList$Type extends MessageType {
    constructor() {
        super("GoToMessageList", [
            { no: 1, name: "next_page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GoToMessage }
        ]);
    }
    create(value) {
        const message = { nextPageMarker: "", items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string next_page_marker */ 1:
                    message.nextPageMarker = reader.string();
                    break;
                case /* repeated GoToMessage items */ 2:
                    message.items.push(GoToMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string next_page_marker = 1; */
        if (message.nextPageMarker !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nextPageMarker);
        /* repeated GoToMessage items = 2; */
        for (let i = 0; i < message.items.length; i++)
            GoToMessage.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GoToMessageList
 */
export const GoToMessageList = new GoToMessageList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConversationsRequest$Type extends MessageType {
    constructor() {
        super("ConversationsRequest", [
            { no: 1, name: "owner_phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "contact_phone_number", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "oldest_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "newest_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "unread_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { ownerPhoneNumber: "", contactPhoneNumber: [], oldestTimestamp: "", newestTimestamp: "", pageMarker: "", pageSize: 0, unreadOnly: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string owner_phone_number */ 1:
                    message.ownerPhoneNumber = reader.string();
                    break;
                case /* repeated string contact_phone_number */ 2:
                    message.contactPhoneNumber.push(reader.string());
                    break;
                case /* string oldest_timestamp */ 3:
                    message.oldestTimestamp = reader.string();
                    break;
                case /* string newest_timestamp */ 4:
                    message.newestTimestamp = reader.string();
                    break;
                case /* string page_marker */ 5:
                    message.pageMarker = reader.string();
                    break;
                case /* int32 page_size */ 6:
                    message.pageSize = reader.int32();
                    break;
                case /* bool unread_only */ 7:
                    message.unreadOnly = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string owner_phone_number = 1; */
        if (message.ownerPhoneNumber !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ownerPhoneNumber);
        /* repeated string contact_phone_number = 2; */
        for (let i = 0; i < message.contactPhoneNumber.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.contactPhoneNumber[i]);
        /* string oldest_timestamp = 3; */
        if (message.oldestTimestamp !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.oldestTimestamp);
        /* string newest_timestamp = 4; */
        if (message.newestTimestamp !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.newestTimestamp);
        /* string page_marker = 5; */
        if (message.pageMarker !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.pageMarker);
        /* int32 page_size = 6; */
        if (message.pageSize !== 0)
            writer.tag(6, WireType.Varint).int32(message.pageSize);
        /* bool unread_only = 7; */
        if (message.unreadOnly !== false)
            writer.tag(7, WireType.Varint).bool(message.unreadOnly);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ConversationsRequest
 */
export const ConversationsRequest = new ConversationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoToConversationList$Type extends MessageType {
    constructor() {
        super("GoToConversationList", [
            { no: 1, name: "next_page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GoToConversation }
        ]);
    }
    create(value) {
        const message = { nextPageMarker: "", items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string next_page_marker */ 1:
                    message.nextPageMarker = reader.string();
                    break;
                case /* repeated GoToConversation items */ 2:
                    message.items.push(GoToConversation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string next_page_marker = 1; */
        if (message.nextPageMarker !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nextPageMarker);
        /* repeated GoToConversation items = 2; */
        for (let i = 0; i < message.items.length; i++)
            GoToConversation.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GoToConversationList
 */
export const GoToConversationList = new GoToConversationList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoToConversation$Type extends MessageType {
    constructor() {
        super("GoToConversation", [
            { no: 1, name: "owner_phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "contact_phone_numbers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_message_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "last_message_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "last_read_message_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "last_read_message_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "last_message", kind: "message", T: () => GoToMessage },
            { no: 8, name: "unreadMessagesCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { ownerPhoneNumber: "", contactPhoneNumbers: [], lastMessageId: "", lastMessageTimestamp: "", lastReadMessageId: "", lastReadMessageTimestamp: "", unreadMessagesCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string owner_phone_number */ 1:
                    message.ownerPhoneNumber = reader.string();
                    break;
                case /* repeated string contact_phone_numbers */ 2:
                    message.contactPhoneNumbers.push(reader.string());
                    break;
                case /* string last_message_id */ 3:
                    message.lastMessageId = reader.string();
                    break;
                case /* string last_message_timestamp */ 4:
                    message.lastMessageTimestamp = reader.string();
                    break;
                case /* string last_read_message_id */ 5:
                    message.lastReadMessageId = reader.string();
                    break;
                case /* string last_read_message_timestamp */ 6:
                    message.lastReadMessageTimestamp = reader.string();
                    break;
                case /* GoToMessage last_message */ 7:
                    message.lastMessage = GoToMessage.internalBinaryRead(reader, reader.uint32(), options, message.lastMessage);
                    break;
                case /* int32 unreadMessagesCount */ 8:
                    message.unreadMessagesCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string owner_phone_number = 1; */
        if (message.ownerPhoneNumber !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ownerPhoneNumber);
        /* repeated string contact_phone_numbers = 2; */
        for (let i = 0; i < message.contactPhoneNumbers.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.contactPhoneNumbers[i]);
        /* string last_message_id = 3; */
        if (message.lastMessageId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastMessageId);
        /* string last_message_timestamp = 4; */
        if (message.lastMessageTimestamp !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.lastMessageTimestamp);
        /* string last_read_message_id = 5; */
        if (message.lastReadMessageId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.lastReadMessageId);
        /* string last_read_message_timestamp = 6; */
        if (message.lastReadMessageTimestamp !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.lastReadMessageTimestamp);
        /* GoToMessage last_message = 7; */
        if (message.lastMessage)
            GoToMessage.internalBinaryWrite(message.lastMessage, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* int32 unreadMessagesCount = 8; */
        if (message.unreadMessagesCount !== 0)
            writer.tag(8, WireType.Varint).int32(message.unreadMessagesCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GoToConversation
 */
export const GoToConversation = new GoToConversation$Type();
/**
 * @generated ServiceType for protobuf service GoToConnectService
 */
export const GoToConnectService = new ServiceType("GoToConnectService", [
    { name: "GetAuthURL", options: {}, I: GetAuthURLRequest, O: GetAuthURLResponse },
    { name: "ExchangeCode", options: {}, I: ExchangeCodeRequest, O: Bool },
    { name: "ExchangeCodeWithRedirect", options: {}, I: ExchangeCodeRequestWithRedirect, O: Bool },
    { name: "RefreshOAuthToken", options: {}, I: Empty, O: Bool },
    { name: "HandleWebhook", options: {}, I: WebhookRequest, O: Empty },
    { name: "CreateCall", options: {}, I: CreateCallRequest, O: GoToCall },
    { name: "GetCall", options: {}, I: GoToCallId, O: GoToCall },
    { name: "UpdateCall", options: {}, I: UpdateCallRequest, O: GoToCall },
    { name: "DeleteCall", options: {}, I: DeleteCallRequest, O: Empty },
    { name: "AnswerCall", options: {}, I: AnswerCallRequest, O: GoToCall },
    { name: "RingCall", options: {}, I: RingCallRequest, O: GoToCall },
    { name: "RejectCall", options: {}, I: RejectCallRequest, O: GoToCall },
    { name: "MuteCall", options: {}, I: GoToCallId, O: GoToCall },
    { name: "UnmuteCall", options: {}, I: GoToCallId, O: GoToCall },
    { name: "HoldCall", options: {}, I: GoToCallId, O: GoToCall },
    { name: "UnholdCall", options: {}, I: GoToCallId, O: GoToCall },
    { name: "ModifyCall", options: {}, I: ModifyCallRequest, O: GoToCall },
    { name: "SendDTMF", options: {}, I: GoToDTMFRequest, O: GoToCall },
    { name: "CreateDevice", options: {}, I: CreateSoftphoneDeviceRequest, O: SoftphoneDevice },
    { name: "GetDevice", options: {}, I: GetSoftphoneDeviceRequest, O: SoftphoneDevice },
    { name: "UpdateDevice", options: {}, I: UpdateSoftphoneDeviceRequest, O: SoftphoneDevice },
    { name: "CreateWebDevice", options: {}, I: CreateWebDeviceRequest, O: WebDevice },
    { name: "GetWebDevice", options: {}, I: GetWebDeviceRequest, O: WebDevice },
    { name: "UpdateWebDevice", options: {}, I: UpdateWebDeviceRequest, O: WebDevice },
    { name: "DeleteWebDevice", options: {}, I: DeleteWebDeviceRequest, O: Empty },
    { name: "CreateNotificationChannel", options: {}, I: CreateNotificationChannelRequest, O: NotificationChannel },
    { name: "GetNotificationChannel", options: {}, I: GetNotificationChannelRequest, O: NotificationChannel },
    { name: "ListNotificationChannels", options: {}, I: ListNotificationChannelsRequest, O: ListNotificationChannelsResponse },
    { name: "UpdateNotificationChannel", options: {}, I: UpdateNotificationChannelRequest, O: NotificationChannel },
    { name: "DeleteNotificationChannel", options: {}, I: DeleteNotificationChannelRequest, O: Empty },
    { name: "GetChannelLifetime", options: {}, I: GetChannelLifetimeRequest, O: ChannelLifetimeResponse },
    { name: "RefreshChannelLifetime", options: {}, I: RefreshChannelLifetimeRequest, O: ChannelLifetimeResponse },
    { name: "SubscribeToCallEvents", options: {}, I: SubscribeToCallEventsRequest, O: SubscribeToCallEventsResponse },
    { name: "CreateDeviceExtensions", options: {}, I: CreateDeviceExtensionsRequest, O: CreateDeviceExtensionsResponse },
    { name: "GetDeviceExtensions", options: {}, I: GetDeviceExtensionsRequest, O: GetDeviceExtensionsResponse },
    { name: "GetUserPresence", options: {}, I: GetUserPresenceRequest, O: UserPresence },
    { name: "UpdateUserPresence", options: {}, I: UpdateUserPresenceRequest, O: UserPresence },
    { name: "GetVoicemails", options: {}, I: GetVoicemailsRequest, O: GetVoicemailsResponse },
    { name: "GetVoicemailBoxes", options: {}, I: GetVoicemailBoxesRequest, O: GetVoicemailBoxesResponse },
    { name: "GetVoicemailMediaContent", serverStreaming: true, options: {}, I: GetVoicemailMediaContentRequest, O: VoicemailMediaContent },
    { name: "WarmTransferCall", options: {}, I: WarmTransferRequest, O: Empty },
    { name: "BlindTransferCall", options: {}, I: BlindTransferRequest, O: Empty },
    { name: "SendMessage", options: {}, I: SendMessageRequest, O: GoToMessage },
    { name: "GetBatchMessage", options: {}, I: MessagesRequest, O: GoToMessageList },
    { name: "GetConversations", options: {}, I: ConversationsRequest, O: GoToConversationList }
]);
