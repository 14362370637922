import { ServiceItem, ServiceItemImage, ServiceItemList } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { type ServiceItemImageList } from 'kalos-rpc/src/api/ServiceItem';

import { ServiceItemClientService } from '../../tools/helpers';
import { queryClient } from '../../tools/queryClient';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type ServiceFilter = EntityFilter<ServiceItem>;

export const useServiceItemBatchGetQuery = <TSelectData = ServiceItemList,>({
  filter,
  select,
}: {
  filter: ServiceFilter;
  select?: (data: ServiceItemList) => TSelectData;
}) =>
  useQuery({
    queryKey: [
      queryKeys.serviceItem.root,
      queryKeys.serviceItem.list,
      queryKeys.serviceItem.getHash(filter),
    ],
    queryFn: () => {
      const req = ServiceItem.create(filter);
      return ServiceItemClientService.BatchGet(req);
    },
    select,
  });

export type ServiceItemImageFilter = EntityFilter<ServiceItemImage>;

export const useServiceItemImageBatchGetQuery = <TSelectData = ServiceItemImageList,>({
  filter,
  select,
  enabled,
}: {
  filter: ServiceItemImageFilter;
  select?: (data: ServiceItemImageList) => TSelectData;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: [
      queryKeys.serviceItemImage.root,
      queryKeys.serviceItemImage.list,
      queryKeys.serviceItemImage.getHash(filter),
    ],
    queryFn: () => {
      const req = ServiceItemImage.create(filter);
      return ServiceItemClientService.BatchGetImage(req);
    },
    select,
    enabled,
  });

export const useServiceItemUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (req: ServiceItem) => {
      return ServiceItemClientService.Update(req);
    },
    onSuccess: (result) => {
      queryClient.setQueriesData<ServiceItemList>(
        { queryKey: [queryKeys.serviceItem.root, queryKeys.serviceItem.list] },
        (oldData) => {
          if (oldData) {
            if (oldData.results.find((item) => item.id === result.id)) {
              return ServiceItemList.create({
                ...oldData,
                results: oldData.results.map((item) => (item.id === result.id ? result : item)),
              });
            }
          }
        },
      );
    },
  });
};

export const useServiceItemCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (req: ServiceItem) => {
      return ServiceItemClientService.Create(req);
    },
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.serviceItem.root, queryKeys.serviceItem.list],
      });
    },
  });
};

export const useServiceItemDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (req: ServiceItem) => {
      return ServiceItemClientService.Delete(req);
    },
    async onSuccess(data, variables, context) {
      queryClient.setQueriesData<ServiceItemList>(
        { queryKey: [queryKeys.serviceItem.root, queryKeys.serviceItem.list] },
        (oldData) => {
          if (oldData && oldData.results.find((item) => item.id === data.id)) {
            return ServiceItemList.create({
              results: oldData.results.filter((item) => item.id !== data.id),
              totalCount: oldData.totalCount - 1,
            });
          }
        },
      );
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.serviceItem.root],
      });
    },
  });
};

export const useServiceItemImageCreateMutation = () => {
  return useMutation({
    mutationFn: (arg: Parameters<typeof ServiceItemClientService.CreateServiceItemImage>[0]) =>
      ServiceItemClientService.CreateServiceItemImage(arg),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.serviceItemImage.root, queryKeys.serviceItemImage.list],
      });
      return data;
    },
  });
};

export const useServiceItemImageDeletionMutation = () => {
  return useMutation({
    mutationFn: (arg: Parameters<typeof ServiceItemClientService.DeleteImage>[0]) =>
      ServiceItemClientService.DeleteImage(arg),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.serviceItemImage.root, queryKeys.serviceItemImage.list],
      });
      return data;
    },
  });
};

export const useServiceItemImageBatchGetMutation = () => {
  return useMutation({
    mutationFn: (arg: Parameters<typeof ServiceItemClientService.BatchGetImage>[0]) =>
      ServiceItemClientService.BatchGetImage(arg),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.serviceItemImage.root, queryKeys.serviceItemImage.list],
      });
      return data;
    },
  });
};
