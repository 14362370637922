// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "gotoadmin.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "gotoadmin.proto" (syntax proto3)
// tslint:disable
import { Bool } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf enum Direction
 */
export var Direction;
(function (Direction) {
    /**
     * @generated from protobuf enum value: DIRECTION_UNSPECIFIED = 0;
     */
    Direction[Direction["DIRECTION_UNSPECIFIED"] = 0] = "DIRECTION_UNSPECIFIED";
    /**
     * @generated from protobuf enum value: INBOUND = 1;
     */
    Direction[Direction["INBOUND"] = 1] = "INBOUND";
    /**
     * @generated from protobuf enum value: OUTBOUND = 2;
     */
    Direction[Direction["OUTBOUND"] = 2] = "OUTBOUND";
})(Direction || (Direction = {}));
// @generated message type with reflection information, may provide speed optimized methods
class CallQueue$Type extends MessageType {
    constructor() {
        super("CallQueue", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "extension_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", extensionNumber: "", name: "", type: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string extension_number */ 2:
                    message.extensionNumber = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string type */ 4:
                    message.type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string extension_number = 2; */
        if (message.extensionNumber !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.extensionNumber);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string type = 4; */
        if (message.type !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CallQueue
 */
export const CallQueue = new CallQueue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCallQueuesRequest$Type extends MessageType {
    constructor() {
        super("GetCallQueuesRequest", [
            { no: 1, name: "account_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { accountKey: "", pageMarker: "", pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string account_key */ 1:
                    message.accountKey = reader.string();
                    break;
                case /* string page_marker */ 2:
                    message.pageMarker = reader.string();
                    break;
                case /* int32 page_size */ 3:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string account_key = 1; */
        if (message.accountKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountKey);
        /* string page_marker = 2; */
        if (message.pageMarker !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageMarker);
        /* int32 page_size = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetCallQueuesRequest
 */
export const GetCallQueuesRequest = new GetCallQueuesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCallQueuesResponse$Type extends MessageType {
    constructor() {
        super("GetCallQueuesResponse", [
            { no: 1, name: "next_page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CallQueue }
        ]);
    }
    create(value) {
        const message = { nextPageMarker: "", items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string next_page_marker */ 1:
                    message.nextPageMarker = reader.string();
                    break;
                case /* repeated CallQueue items */ 2:
                    message.items.push(CallQueue.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string next_page_marker = 1; */
        if (message.nextPageMarker !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nextPageMarker);
        /* repeated CallQueue items = 2; */
        for (let i = 0; i < message.items.length; i++)
            CallQueue.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetCallQueuesResponse
 */
export const GetCallQueuesResponse = new GetCallQueuesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignUserToQueueRequest$Type extends MessageType {
    constructor() {
        super("AssignUserToQueueRequest", [
            { no: 1, name: "user_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "queue_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { userKey: "", queueId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_key */ 1:
                    message.userKey = reader.string();
                    break;
                case /* string queue_id */ 2:
                    message.queueId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string user_key = 1; */
        if (message.userKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userKey);
        /* string queue_id = 2; */
        if (message.queueId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.queueId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AssignUserToQueueRequest
 */
export const AssignUserToQueueRequest = new AssignUserToQueueRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCallHistoryRequest$Type extends MessageType {
    constructor() {
        super("GetCallHistoryRequest", [
            { no: 1, name: "user_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "start_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "end_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "extensions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { userKey: "", accountKey: "", startTime: "", endTime: "", pageMarker: "", pageSize: 0, extensions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_key */ 1:
                    message.userKey = reader.string();
                    break;
                case /* string account_key */ 2:
                    message.accountKey = reader.string();
                    break;
                case /* string start_time */ 3:
                    message.startTime = reader.string();
                    break;
                case /* string end_time */ 4:
                    message.endTime = reader.string();
                    break;
                case /* string page_marker */ 5:
                    message.pageMarker = reader.string();
                    break;
                case /* int32 page_size */ 6:
                    message.pageSize = reader.int32();
                    break;
                case /* repeated string extensions */ 7:
                    message.extensions.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string user_key = 1; */
        if (message.userKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userKey);
        /* string account_key = 2; */
        if (message.accountKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountKey);
        /* string start_time = 3; */
        if (message.startTime !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.startTime);
        /* string end_time = 4; */
        if (message.endTime !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.endTime);
        /* string page_marker = 5; */
        if (message.pageMarker !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.pageMarker);
        /* int32 page_size = 6; */
        if (message.pageSize !== 0)
            writer.tag(6, WireType.Varint).int32(message.pageSize);
        /* repeated string extensions = 7; */
        for (let i = 0; i < message.extensions.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.extensions[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetCallHistoryRequest
 */
export const GetCallHistoryRequest = new GetCallHistoryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCallHistoryResponse$Type extends MessageType {
    constructor() {
        super("GetCallHistoryResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CallHistoryItem },
            { no: 2, name: "next_page_marker", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { items: [], nextPageMarker: "", pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CallHistoryItem items */ 1:
                    message.items.push(CallHistoryItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_marker */ 2:
                    message.nextPageMarker = reader.string();
                    break;
                case /* int32 page_size */ 3:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated CallHistoryItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            CallHistoryItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_marker = 2; */
        if (message.nextPageMarker !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageMarker);
        /* int32 page_size = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetCallHistoryResponse
 */
export const GetCallHistoryResponse = new GetCallHistoryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallHistoryItem$Type extends MessageType {
    constructor() {
        super("CallHistoryItem", [
            { no: 1, name: "originator_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "leg_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "caller", kind: "message", T: () => CallerInfo },
            { no: 4, name: "callee", kind: "message", T: () => CallerInfo },
            { no: 5, name: "direction", kind: "enum", T: () => ["Direction", Direction] },
            { no: 6, name: "start_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "answer_time", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "duration", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "hangup_cause", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "user_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "account_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "owner_phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { originatorId: "", legId: "", direction: 0, startTime: "", duration: 0, hangupCause: 0, userKey: "", accountKey: "", ownerPhoneNumber: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string originator_id */ 1:
                    message.originatorId = reader.string();
                    break;
                case /* string leg_id */ 2:
                    message.legId = reader.string();
                    break;
                case /* CallerInfo caller */ 3:
                    message.caller = CallerInfo.internalBinaryRead(reader, reader.uint32(), options, message.caller);
                    break;
                case /* CallerInfo callee */ 4:
                    message.callee = CallerInfo.internalBinaryRead(reader, reader.uint32(), options, message.callee);
                    break;
                case /* Direction direction */ 5:
                    message.direction = reader.int32();
                    break;
                case /* string start_time */ 6:
                    message.startTime = reader.string();
                    break;
                case /* optional string answer_time */ 7:
                    message.answerTime = reader.string();
                    break;
                case /* int32 duration */ 8:
                    message.duration = reader.int32();
                    break;
                case /* int32 hangup_cause */ 9:
                    message.hangupCause = reader.int32();
                    break;
                case /* string user_key */ 10:
                    message.userKey = reader.string();
                    break;
                case /* string account_key */ 11:
                    message.accountKey = reader.string();
                    break;
                case /* string owner_phone_number */ 12:
                    message.ownerPhoneNumber = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string originator_id = 1; */
        if (message.originatorId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.originatorId);
        /* string leg_id = 2; */
        if (message.legId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.legId);
        /* CallerInfo caller = 3; */
        if (message.caller)
            CallerInfo.internalBinaryWrite(message.caller, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* CallerInfo callee = 4; */
        if (message.callee)
            CallerInfo.internalBinaryWrite(message.callee, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* Direction direction = 5; */
        if (message.direction !== 0)
            writer.tag(5, WireType.Varint).int32(message.direction);
        /* string start_time = 6; */
        if (message.startTime !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.startTime);
        /* optional string answer_time = 7; */
        if (message.answerTime !== undefined)
            writer.tag(7, WireType.LengthDelimited).string(message.answerTime);
        /* int32 duration = 8; */
        if (message.duration !== 0)
            writer.tag(8, WireType.Varint).int32(message.duration);
        /* int32 hangup_cause = 9; */
        if (message.hangupCause !== 0)
            writer.tag(9, WireType.Varint).int32(message.hangupCause);
        /* string user_key = 10; */
        if (message.userKey !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.userKey);
        /* string account_key = 11; */
        if (message.accountKey !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.accountKey);
        /* string owner_phone_number = 12; */
        if (message.ownerPhoneNumber !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.ownerPhoneNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CallHistoryItem
 */
export const CallHistoryItem = new CallHistoryItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallerInfo$Type extends MessageType {
    constructor() {
        super("CallerInfo", [
            { no: 1, name: "name", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "number", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional string name */ 1:
                    message.name = reader.string();
                    break;
                case /* optional string number */ 2:
                    message.number = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* optional string name = 1; */
        if (message.name !== undefined)
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* optional string number = 2; */
        if (message.number !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.number);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CallerInfo
 */
export const CallerInfo = new CallerInfo$Type();
/**
 * @generated ServiceType for protobuf service GoToAdminService
 */
export const GoToAdminService = new ServiceType("GoToAdminService", [
    { name: "AssignUserToQueue", options: {}, I: AssignUserToQueueRequest, O: Bool },
    { name: "GetCallQueues", options: {}, I: GetCallQueuesRequest, O: GetCallQueuesResponse }
]);
