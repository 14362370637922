import { SoftphoneClient } from '@kalos/kalos-rpc';
import React, { useMemo } from 'react';

import { ENDPOINT } from '../../../constants';
import { useAuth } from '../../../context/AuthContext';
import { useApiKeyClientServiceQuery } from '../../../hooks/react-query/useApiKeyClientServiceQuery';
import { createSoftPhoneStore } from './SoftPhoneStore';
import { SoftPhoneStoreContext, type SoftPhoneStoreContextValue } from './SoftPhoneStore.context';

type SoftPhoneStoreProviderProps = {
  children: React.ReactNode;
};

export const SoftPhoneStoreProvider = ({ children }: SoftPhoneStoreProviderProps) => {
  const { user } = useAuth();
  const goToTokenQuery = useApiKeyClientServiceQuery({
    filter: { apiUser: user.id, textId: `goto_token_${user.id}` },
    enabled: !!user.id,
  });

  const storeValue = useMemo<SoftPhoneStoreContextValue>(() => {
    if (goToTokenQuery.isPending) {
      return { store: null, requiresAuth: null, isPending: true };
    }
    const client = new SoftphoneClient(ENDPOINT, user.id);
    const store = createSoftPhoneStore(client);
    if (
      !user.id ||
      goToTokenQuery.isError ||
      (goToTokenQuery.isSuccess && !goToTokenQuery.data?.apiKey)
    ) {
      return { store, requiresAuth: true, isPending: false };
    }

    return { store, requiresAuth: false, isPending: false };
  }, [
    goToTokenQuery.isPending,
    goToTokenQuery.isError,
    goToTokenQuery.isSuccess,
    goToTokenQuery.data?.apiKey,
    user.id,
  ]);

  return (
    <SoftPhoneStoreContext.Provider value={storeValue}>{children}</SoftPhoneStoreContext.Provider>
  );
};
