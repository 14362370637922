import { Event, type Property } from '@kalos/kalos-rpc';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  DataTable,
  DataTableColumnHeader,
  DataTablePagination,
  Dialog,
  DialogContent,
  useToast,
} from '@kalos/ui';
import {
  type ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';

import { ROWS_PER_PAGE } from '../../../../constants';
import {
  useEventBatchGetQuery,
  useEventCreateMutation,
} from '../../../../hooks/react-query/useEventsQuery';
import {
  type PropertyFilter,
  usePropertiesBatchGetQuery,
} from '../../../../hooks/react-query/usePropertiesQuery';
import { navigateNewTab } from '../../../../tools/helpers';
import { NewJobForm } from '../../SoftPhoneNewJob/NewJobForm';

interface Props {
  userId: number;
}

const PropertyJobs = ({ propertyId }: { propertyId: number }) => {
  const eventsQuery = useEventBatchGetQuery({
    filters: {
      propertyId,
      isActive: 1,
      orderBy: 'date_started',
      orderDir: 'ASC',
    },
  });

  if (eventsQuery.isLoading) return <div className="py-2">Loading...</div>;
  if (!eventsQuery.data?.results?.length)
    return <div className="text-muted-foreground py-2">No upcoming jobs</div>;

  // Limit to 5 most recent jobs
  const recentJobs = eventsQuery.data.results.slice(0, 5);

  return (
    <div className="flex flex-col gap-2">
      <h4 className="font-medium">Recent Jobs</h4>
      <div className="bg-muted/30 max-h-[200px] space-y-3 overflow-y-auto rounded-md p-3">
        {recentJobs.map((event: Event) => (
          <div
            key={event.id}
            className="bg-card hover:bg-accent/10 rounded-lg border p-3 shadow-sm transition-colors"
          >
            <div className="text-sm font-medium">
              {format(new Date(event.dateStarted), 'MM/dd/yyyy hh:mm a')}
            </div>
            <div className="text-muted-foreground mt-1 line-clamp-2 text-sm">
              {event.description}
            </div>
            <div className="mt-2 flex items-center justify-between gap-2">
              <div className="bg-primary/10 text-primary rounded-full px-2 py-0.5 text-xs">
                {event.logJobStatus || 'No Status'}
              </div>
              <Button variant="ghost" size="sm" onClick={() => navigateNewTab(`/jobs/${event.id}`)}>
                To Job
              </Button>
            </div>
          </div>
        ))}
        {eventsQuery.data.results.length > 5 && (
          <div className="text-muted-foreground text-center text-sm">
            +{eventsQuery.data.results.length - 5} more jobs
          </div>
        )}
      </div>
    </div>
  );
};

export const UserPropertiesList = ({ userId }: Props) => {
  const [page, setPage] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
  const eventCreateMutation = useEventCreateMutation();
  const { toast } = useToast();

  const propertiesFilter = useMemo<PropertyFilter>(
    () => ({
      pageNumber: page,
      userId,
      isActive: 1,
      orderBy: 'property_address',
      orderDir: 'ASC',
    }),
    [page, userId],
  );

  const propertiesQuery = usePropertiesBatchGetQuery({
    filter: propertiesFilter,
  });

  const handleCreateJob = useCallback((property: Property) => {
    setSelectedProperty(property);
  }, []);

  const columns = useMemo<ColumnDef<Property>[]>(
    () => [
      {
        id: 'details',
        header: ({ column }) => <DataTableColumnHeader title="Property Details" column={column} />,
        meta: {
          className: 'w-full',
        },
        cell: ({ row }) => (
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value={row.original.id.toString()}>
              <AccordionTrigger className="hover:no-underline">
                <div className="flex w-full items-center justify-between gap-4 pr-8">
                  <div className="flex flex-col items-start text-left">
                    <div className="font-medium">{row.original.address}</div>
                    <div className="text-muted-foreground text-sm">
                      {row.original.city}, {row.original.state}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <Button
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCreateJob(row.original);
                      }}
                    >
                      Create Job
                    </Button>
                    <span className="text-muted-foreground text-sm">View Jobs</span>
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <div className="space-y-4">
                  <PropertyJobs propertyId={row.original.id} />
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        ),
      },
    ],
    [handleCreateJob],
  );

  const table = useReactTable({
    data: propertiesQuery.data?.results ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: Math.ceil((propertiesQuery.data?.totalCount ?? 0) / ROWS_PER_PAGE),
    state: {
      pagination: {
        pageIndex: page,
        pageSize: ROWS_PER_PAGE,
      },
    },
    manualPagination: true,
  });

  if (!userId) return null;

  return (
    <>
      <div className="bg-card mt-0 space-y-4 rounded-lg border p-4 shadow-sm">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold">Customer Properties</h3>
        </div>
        <div className="space-y-4">
          <DataTable table={table} />
          {(propertiesQuery.data?.totalCount ?? 0) > ROWS_PER_PAGE && (
            <DataTablePagination
              currentPage={page}
              setPage={setPage}
              pageCount={Math.ceil((propertiesQuery.data?.totalCount ?? 0) / ROWS_PER_PAGE)}
            />
          )}
        </div>
      </div>

      <Dialog open={!!selectedProperty} onOpenChange={() => setSelectedProperty(null)}>
        <DialogContent className="max-w-4xl">
          {selectedProperty && (
            <>
              <div className="mb-6">
                <h2 className="text-lg font-semibold">Create New Job</h2>
                <p className="text-muted-foreground text-sm">
                  Creating job for {selectedProperty.address}, {selectedProperty.city}
                </p>
              </div>
              <NewJobForm
                defaultValues={NewJobForm.getDefaultValues({
                  logServiceRendered: `Service at ${selectedProperty.address}, ${selectedProperty.city} ${selectedProperty.state} ${selectedProperty.zip}`,
                })}
                disabled={eventCreateMutation.isPending}
                isLoading={eventCreateMutation.isPending}
                onSave={async ({ data }) => {
                  try {
                    const formattedData = {
                      ...data,
                      logTechnicianAssigned: String(data.logTechnicianAssigned || ''),
                      diagnosticQuoted: data.diagnosticQuoted ? 1 : 0,
                      highPriority: data.highPriority ? 1 : 0,
                      isCallback: data.isCallback ? 1 : 0,
                      isLmpc: data.isLmpc ? 1 : 0,
                      amountQuoted: String(data.amountQuoted || ''),
                      jobTypeId: Number(data.jobTypeId),
                      jobSubtypeId: Number(data.jobSubtypeId),
                      isActive: 1,
                    };
                    const eventData = Event.create({
                      ...formattedData,
                      propertyId: selectedProperty.id,
                    });
                    await eventCreateMutation.mutateAsync(eventData);
                    toast({
                      title: 'Success',
                      description: 'Job created successfully',
                    });
                    setSelectedProperty(null);
                  } catch (error) {
                    toast({
                      title: 'Error',
                      description: `Failed to create job. Please try again. ${error}`,
                      variant: 'destructive',
                    });
                  }
                }}
              />
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
