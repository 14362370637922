import { BaseClient } from '../BaseClient';
import { Empty } from '../compiled-protos/common';
import { type GetCallHistoryRequest } from '../compiled-protos/gotoadmin';
import {
  type ExchangeCodeRequest,
  type ExchangeCodeRequestWithRedirect,
  type GetAuthURLRequest,
  GetAuthURLResponse,
  type GetUserTokenRequest,
} from '../compiled-protos/gotoconnect';
import {
  CallId,
  type ClientInfo,
  DTMFRequest,
  type GenerateTestCallsRequest,
  InitializeClientRequest,
  StartCallRequest,
  SubscribeRequest,
  TerminateClientRequest,
} from '../compiled-protos/softphone';
import { SoftphoneServiceClient } from '../compiled-protos/softphone.client';
class SoftphoneClient extends BaseClient {
  self: SoftphoneServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new SoftphoneServiceClient(this.transport);
  }

  private getSoftphoneMetaData() {
    const md = this.getMetaDataWithoutCache();
    md.meta['x-user-id'] = `${this.userID}`
    return md
  }

  public async InitializeClient(userId: number, clientInfo: ClientInfo) {
    const req = InitializeClientRequest.create({
      userId,
      clientInfo,
    });
    const res = await this.self.initializeClient(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async TerminateClient(sessionId: string) {
    const req = TerminateClientRequest.create({ sessionId });
    try {
      await this.self.terminateClient(req, this.getSoftphoneMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
  }

  public SubscribeToEvents(sessionId: string) {
    const req = SubscribeRequest.create({ sessionId });
    return this.self.subscribeToEvents(req, this.getSoftphoneMetaData());
  }

  public async StartCall(sessionId: string, phoneNumber: string, sdp: string) {
    const req = StartCallRequest.create({
      sessionId,
      phoneNumber,
      sdp,
    });
    const res = await this.self.startCall(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async toggleMute(req: CallId) {
    const res = await this.self.toggleMute(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async AnswerCall(callId: string, sessionId: string, sdp: string) {
    const req = CallId.create({ callId, sessionId, sdp });
    const res = await this.self.answerCall(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async RejectCall(callId: string, sessionId: string) {
    const req = CallId.create({ callId, sessionId });
    const res = await this.self.rejectCall(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async EndCall(callId: string, sessionId: string) {
    const req = CallId.create({ callId, sessionId });
    const res = await this.self.endCall(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async ToggleMute(req: CallId) {
    const res = await this.self.toggleMute(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async ToggleHold(req: CallId) {
    const res = await this.self.toggleHold(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async SendDTMF(callId: string, sessionId: string, digits: string) {
    const req = DTMFRequest.create({
      callId,
      sessionId,
      digits,
    });
    const res = await this.self.sendDTMF(req, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async GetAuthURL(request: GetAuthURLRequest) {
    let res = GetAuthURLResponse.create();
    try {
      res = await this.self.getAuthURL(request, this.getSoftphoneMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async ExchangeCode(request: ExchangeCodeRequest) {
    const res = await this.self.exchangeCode(request, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async ExchangeCodeWithRedirect(request: ExchangeCodeRequestWithRedirect) {
    const res = await this.self.exchangeCodeWithRedirect(request, this.getSoftphoneMetaData()).response;
    return res;
  }

  public async GetUserToken(request: GetUserTokenRequest) {
    const res = await this.self.getUserToken(request, this.getSoftphoneMetaData()).response;
    return res;
  }
  public async GetCurrentCallQueue() {
    const res = await this.self.fetchCurrentCallQueue(Empty.create(), this.getSoftphoneMetaData()).response;
    return res;
  }
  public async GetCallHistory(req: GetCallHistoryRequest) {
    const res = await this.self.getCallHistory(req, this.getSoftphoneMetaData()).response;
    return res;
  }
  public async GetMe() {

    const res = await this.self.getMe(Empty.create(), this.getSoftphoneMetaData()).response;
    return res;
  }
  public async GenerateTestCalls(req: GenerateTestCallsRequest) {
    const res = await this.self.generateTestCalls(req, this.getSoftphoneMetaData()).response;
    return res;
  }
}

export { SoftphoneClient };
export {  GetCallHistoryRequest,CallHistoryItem,Direction } from '../compiled-protos/gotoadmin';

export {
  ExchangeCodeRequest,

  ExchangeCodeRequestWithRedirect,
  GetAuthURLRequest,
  GetAuthURLResponse,
  GetUserTokenRequest,
  OAuthTokenResponse,
  GoToCall,
  Notification,
} from '../compiled-protos/gotoconnect';
export {
  CallId,
  ClientInfo,
  ClientSession,
  DTMFRequest,
  InitializeClientRequest,
  GenerateTestCallsRequest,
  StartCallRequest,
  SubscribeRequest,
  TerminateClientRequest,
} from '../compiled-protos/softphone';
