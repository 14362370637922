import { SearchUserByPhoneRequest } from '@kalos/kalos-rpc';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';

import { EventClientService } from '../../tools/helpers';
import { queryKeys } from './constants';

export const useSearchUserByPhone = ({
  phoneNumber,
  enabled = true,
}: {
  phoneNumber: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.events.root,
      queryKeys.events.searchUserByPhone,
      queryKeys.events.getUserByPhoneHash(phoneNumber),
    ],
    queryFn: async () => {
      const req = SearchUserByPhoneRequest.create({
        phoneNumber,
        intervalDays: 1000,
        dateTarget: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      });

      return await EventClientService.GetUserByPhoneNumber(req);
    },
    enabled: enabled && !!phoneNumber,
  });
};

export const useSearchUserByPhoneInline = () => {
  const queryClient = useQueryClient();

  const fetchUser = useCallback(
    async ({ filter }: { filter: { phone: string } }) => {
      const phoneNumber = filter.phone;
      const queryKey = [
        queryKeys.events.root,
        queryKeys.events.searchUserByPhone,
        queryKeys.events.getUserByPhoneHash(phoneNumber),
      ];

      const cache = queryClient.getQueryData(queryKey);
      if (!cache) {
        const req = SearchUserByPhoneRequest.create({
          phoneNumber,
          intervalDays: 1000,
          dateTarget: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        });

        const result = await EventClientService.GetUserByPhoneNumber(req);
        queryClient.setQueryData(queryKey, result);
        return result.user || null;
      }

      return (cache as any).user || null;
    },
    [queryClient],
  );

  return useMemo(() => ({ fetchUser }), [fetchUser]);
};
