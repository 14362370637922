import { BaseClient } from '../BaseClient';
import { ApiKey, ApiKeyList } from '../compiled-protos/api_key';
import { ApiKeyServiceClient } from '../compiled-protos/api_key.client';

class ApiKeyClient extends BaseClient {
  self: ApiKeyServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new ApiKeyServiceClient(this.transport);
  }

  public async Create(req: ApiKey) {
    let res = ApiKey.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async getKeyByTextID(ID: string) {
    const req = ApiKey.create();
    req.textId = ID;
    return await this.Get(req);
  }

  public async Get(req: ApiKey) {
    let res = ApiKey.create();
    try {
      res = await this.self.get(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return res;
    }
    return res;
  }

  public async Update(req: ApiKey) {
    let res = ApiKey.create();
    try {
      res = await this.self.update(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: ApiKey) {
    let res = ApiKey.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: ApiKey) {
    let res = ApiKeyList.create();
    try {
      res = await this.self.batchGet(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public getKeyByKeyName = async (keyName: string) => {
    const req = ApiKey.create();
    req.textId = keyName;
    try {
      return await this.Get(req);
    } catch (err) {
      console.log(err);
      return;
    }
  };
}

export { ApiKey, ApiKeyList, ApiKeyClient };
