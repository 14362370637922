import { CallCountRequest, EventClient } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '../../constants';

const EventClientService = new EventClient(ENDPOINT);

export const useCallWindowQuery = (
  startTime: string,
  endTime: string,
  refreshInterval?: number,
) => {
  return useQuery({
    queryKey: ['callWindow', startTime, endTime],
    queryFn: async () => {
      const req = CallCountRequest.create({
        startTime,
        endTime,
      });

      const res = await EventClientService.getCurrentDayServiceCallWindows(req);
      return {
        callCount: res?.callCount || 0,
        jobsInWindow: res?.jobsInWindow || [],
      };
    },
    refetchInterval: refreshInterval,
  });
};
