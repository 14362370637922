import { Tabs, TabsContent, TabsList, TabsTrigger } from '@kalos/ui';
import { ExternalLink } from 'lucide-react';

import { CallLog } from './components/CallLog';
import { CallWindowPreview } from './components/CallWindowPreview';
import { ContractExpirationsPreview } from './components/ContractExpirationsPreview';

const CustomerServiceDashboard: React.FC = () => {
  const handleExternalNavigation = (url: string) => {
    window.location.href = url;
  };

  return (
    <div className="flex h-full flex-col">
      <section className="bg-muted/30 flex h-14 w-full items-center justify-between border-b px-6">
        <h1 className="text-2xl font-semibold">Customer Connect</h1>
      </section>

      <Tabs defaultValue="callLog" className="flex h-[calc(100%-3.5rem)]">
        <div className="flex h-full w-full">
          <div className="bg-muted/30 w-64 border-r">
            <TabsList className="flex w-full flex-col space-y-1 bg-transparent p-6 pt-32">
              <TabsTrigger value="callLog" className="w-full justify-start px-4">
                Call Log
              </TabsTrigger>
              <TabsTrigger value="callWindow" className="w-full justify-start px-4">
                Call Window
              </TabsTrigger>
              <TabsTrigger value="contracts" className="w-full justify-start px-4">
                Contracts
              </TabsTrigger>
              <TabsTrigger
                value="settings"
                className="flex w-full items-center justify-start gap-2 px-4"
                onClick={() => handleExternalNavigation('https://app.goto.com/shell-settings/')}
              >
                Settings
                <ExternalLink className="h-3 w-3" />
              </TabsTrigger>
              <TabsTrigger
                value="reporting"
                className="flex w-full items-center justify-start gap-2 px-4"
                onClick={() =>
                  handleExternalNavigation('https://app.goto.com/analytics/kalos/dashboard')
                }
              >
                Reporting
                <ExternalLink className="h-3 w-3" />
              </TabsTrigger>
            </TabsList>
          </div>

          <div className="flex-1 p-8">
            <TabsContent value="callWindow">
              <div className="rounded-lg border p-6">
                <h1 className="mb-6 text-2xl font-bold">Call Window</h1>
                <CallWindowPreview
                  refreshInterval={30000}
                  onClick={() => console.log('Navigate to full call window view')}
                />
              </div>
            </TabsContent>

            <TabsContent value="contracts">
              <div className="rounded-lg border p-6">
                <h1 className="mb-6 text-2xl font-bold">Contract Expirations</h1>
                <ContractExpirationsPreview
                  onClick={() => console.log('Navigate to full contracts view')}
                />
              </div>
            </TabsContent>

            <TabsContent value="callLog">
              <div className="rounded-lg border p-6">
                <h1 className="mb-6 text-2xl font-bold">Call Log</h1>
                <CallLog />
              </div>
            </TabsContent>
          </div>
        </div>
      </Tabs>
    </div>
  );
};

export default CustomerServiceDashboard;
